import { UserInterface } from '../profile/user';

/**
 * Guard funtion for typescript to recognize currentUser and currentUser.rights is not null
 * @param currentUser UserInterface | undefined
 * @returns
 */
export function isRightsNonNullable(
  currentUser: UserInterface | undefined
): currentUser is UserInterface & { rights: NonNullable<UserInterface['rights']> } {
  return !!currentUser?.rights;
}

export type EnsurePropsNonNullable<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};
