import React from 'react';
import { BodyText, Heading, Button, ButtonType, Separator } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import { QA_TEST_IDS } from '../../../../../../tests/qaTestIds';
import { TEST_IDS } from '../../../../../../tests/testids';
import './styles.scss';

interface ContextualHelpProps {
  showHelpContext: () => void;
}

export const ContextualHelp: React.FC<ContextualHelpProps> = ({ showHelpContext }) => {
  const { t } = useTranslation();

  return (
    <div className="contextual-help">
      <Separator />
      {/* eslint-disable-next-line enforce-ids-in-jsx/missing-ids */}
      <Button
        className="hide-contextual-help-button"
        id={QA_TEST_IDS.hide_contextual_help_button}
        dataTa={TEST_IDS.hide_contextual_help_button}
        onClick={showHelpContext}
        buttonType={ButtonType.Secondary}
      >
        {t('general.hide')}
      </Button>
      <Heading level={3}>{t('site.alarms.settings.threshold.form.contextualHelp.title')}</Heading>
      <BodyText>
        <b>{t('site.alarms.settings.threshold.form.contextualHelp.activate.title')}: </b>
        {t('site.alarms.settings.threshold.form.contextualHelp.activate.text')}
      </BodyText>

      <BodyText>
        <b>{t('site.alarms.settings.device.form.contextualHelp.type.title')}: </b>
        {t('site.alarms.settings.device.form.contextualHelp.type.text')}:
      </BodyText>
      <BodyText>
        <ul>
          <li>
            {t('site.alarms.device.rule.names.calibration')}:{' '}
            {t('site.alarms.settings.device.form.contextualHelp.calibration.text')}
          </li>
          <li>
            {t('site.alarms.device.rule.names.communication')}:{' '}
            {t('site.alarms.settings.device.form.contextualHelp.communication.text')}
          </li>
          <li>
            {t('site.alarms.device.rule.names.error')}:{' '}
            {t('site.alarms.settings.device.form.contextualHelp.error.text')}
          </li>
          <li>
            {t('site.alarms.device.rule.names.battery')}:{' '}
            {t('site.alarms.settings.device.form.contextualHelp.battery.text')}
          </li>
          <li>
            {t('site.alarms.device.rule.names.configuration')}:{' '}
            {t('site.alarms.settings.device.form.contextualHelp.configuration.text')}
          </li>
        </ul>
      </BodyText>
      <BodyText>
        <b>{t('site.alarms.settings.threshold.form.contextualHelp.acknowledgement.title')}: </b>
        {t('site.alarms.settings.threshold.form.contextualHelp.acknowledgement.text')}
      </BodyText>
      <BodyText>
        <b>{t('site.alarms.settings.threshold.form.contextualHelp.delay.title')}: </b>
        {t('site.alarms.settings.threshold.form.contextualHelp.delay.text')}
      </BodyText>
    </div>
  );
};
