import * as React from 'react';
import { useRef } from 'react';
import { Size, Button, ButtonType } from '@vaisala/rockhopper-components';
import Resizer from 'react-image-file-resizer';
import './picture-uploader.scss';
import photoPlaceholder from '../../../assets/images/photo-placeholder-70x70.png';

interface ProfilePictureUploaderProps {
  htmlId?: string;
  title: string;
  pictureUrl: string | undefined | null;
  onChange: (value: string) => void;
  disabled: boolean;
}

const ProfilePictureUploader = (props: ProfilePictureUploaderProps): JSX.Element => {
  const [profilePictureUrl, setProfilePictureUrl] = React.useState(props.pictureUrl);

  const resizeImage = (file: Blob, maxWidth: number, maxHeight: number): Promise<any> =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        maxWidth,
        maxHeight,
        'JPEG',
        100,
        0,
        uri => {
          resolve(uri);
        },
        'base64'
      );
    });

  const inputFile = useRef<HTMLInputElement>(null);

  return (
    <div className="picture-uploader">
      <input
        id="picture-uploader"
        type="file"
        disabled={props.disabled}
        accept="image/jpeg, image/jpg, image/png, image/bmp"
        ref={inputFile}
        className="picture-uploader-file-input-field"
        onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.files && e.target.files.length > 0) {
            setProfilePictureUrl(URL.createObjectURL(e.target.files[0]));
            const resizedImg: string = await resizeImage(e.target.files[0], 70, 70);
            props.onChange(resizedImg);
          }
        }}
      />

      <img className="picture-uploader-img" src={profilePictureUrl || photoPlaceholder} alt="Profile picture" />

      <Button
        id="picture-uploader-props-html"
        className="picture-uploader-change-picture-button"
        htmlId={props.htmlId}
        buttonSize={Size.S}
        buttonType={ButtonType.Link}
        onClick={() => inputFile.current?.click()}
      >
        {props.title}
      </Button>
    </div>
  );
};

export default ProfilePictureUploader;
