import { Separator } from '@vaisala/rockhopper-components';
import React from 'react';

interface ModalFooter {
  buttons?: JSX.Element;
}

const ModalFooter: React.FC<ModalFooter> = ({ buttons, children }) => {
  return (
    <div className="modal-footer">
      {buttons && <div className="modal-footer__actions">{buttons}</div>}
      {children && <div className="modal-footer__end">{children}</div>}
    </div>
  );
};

export default ModalFooter;
