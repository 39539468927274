import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_DEVICE_BASE_URI } from '../../constants';
import { DataSourceListItemModel, DeviceListItemModel, DeviceModel } from '../../types';
import { DeviceApiUris, replaceTemplateValues, getConstantFromLocalStorage } from '../../utils';
import { updateHeaders } from './utils';
import _ from 'lodash';
import { LS_COMPANY_CUSTOMER_ID } from '../../constants';

const reducerPath = 'devicesApi';
const baseUrl = API_DEVICE_BASE_URI;

export enum DeviceTags {
  DEVICES = 'Devices',
  DEVICE = 'device',
  DATA_SOURCES = 'Data Sources'
}

export type DatasourcesPerSiteArgs = {
  customerId: string;
  siteId: string;
  meas_name?: string;
  unit_name?: string;
  available?: boolean;
};

export type EditDeviceArgs = {
  deviceId: string;
  siteId: string;
  config: {
    device?: {
      apId: number;
    };
    display: {
      enabled: boolean;
      brightness?: number;
    };
    led?: {
      enabled: boolean;
      brightness?: number;
    };
    unit?: {
      metric: boolean;
    };
  };
};

export type RebootArgs = {
  customer_id: string;
  device_id: string;
  site_id: string;
};

export const devicesApi = createApi({
  reducerPath,
  baseQuery: fetchBaseQuery({
    baseUrl,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    prepareHeaders: async (headers, { endpoint }) => {
      headers = await updateHeaders(headers);
      return headers;
    }
  }),
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: [DeviceTags.DEVICES, DeviceTags.DATA_SOURCES, DeviceTags.DEVICE],
  endpoints: builder => ({
    getDevicesPerSite: builder.query<DeviceListItemModel[], { customerId: string; siteId: string }>({
      query: ({ customerId, siteId }) =>
        replaceTemplateValues(DeviceApiUris.list, { customer_id: customerId, site_id: siteId }),
      transformResponse: (response: any) => response.devices,
      providesTags: [{ type: DeviceTags.DEVICES, id: 'LIST' }]
    }),
    getDataSourcesPerSite: builder.query<DataSourceListItemModel[], DatasourcesPerSiteArgs>({
      query: ({ customerId, siteId, meas_name = null, available = null, unit_name = null }) => {
        const url = replaceTemplateValues(DeviceApiUris.dataSourcesList, { customer_id: customerId, site_id: siteId });
        const params: Record<string, string> = {};
        if (meas_name != null) {
          params['meas_name'] = meas_name;
        }
        // Only add the available parameter when we pass true. If the attribute is present, the BE will filter for available data sources.
        if (available === true) {
          params['available'] = 'true';
        }
        if (unit_name != null) {
          params['unit_name'] = unit_name;
        }

        return { url, params };
      },
      transformResponse: (response: any) => response.items,
      providesTags: [{ type: DeviceTags.DATA_SOURCES, id: 'LIST' }]
    }),
    editDeviceProperties: builder.mutation<any, EditDeviceArgs>({
      query({ siteId, deviceId, ...body }) {
        const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID) ?? '';
        return {
          url: replaceTemplateValues(DeviceApiUris.editDeviceProperties, {
            customer_id: customerId,
            device_id: deviceId,
            site_id: siteId
          }),
          method: 'PATCH',
          body
        };
      },
      invalidatesTags: (_result, error, args) => {
        if (!error) {
          return [{ type: DeviceTags.DEVICE, id: args.deviceId }];
        }
        return [];
      }
    }),
    getDevicePerSite: builder.query<DeviceModel, { customerId: string; siteId: string; deviceId: string | null }>({
      query: ({ customerId, siteId, deviceId }) => {
        const url = replaceTemplateValues(DeviceApiUris.single, {
          customer_id: customerId,
          site_id: siteId,
          device_id: deviceId
        });
        return url;
      },
      providesTags: (_result, error, args) => {
        if (error) {
          return [];
        }
        return [{ type: DeviceTags.DEVICE, id: args.deviceId ?? undefined }];
      }
    }),
    restart: builder.mutation<any, RebootArgs>({
      query: ({ customer_id, device_id, site_id }) => {
        const url = replaceTemplateValues(DeviceApiUris.restart, { customer_id, device_id, site_id });
        return {
          url,
          method: 'POST'
        };
      },
      invalidatesTags: (_result, error, args) => {
        if (!error) {
          return [{ type: DeviceTags.DEVICE, id: args.device_id }];
        }
        return [];
      }
    })
  })
});

export const {
  useGetDevicesPerSiteQuery,
  useGetDataSourcesPerSiteQuery,
  useGetDevicePerSiteQuery,
  useEditDevicePropertiesMutation,
  useRestartMutation
} = devicesApi;
