import { API_SITE_MANAGEMENT_BASE_URI, LS_COMPANY_CUSTOMER_ID, SiteNodeTypes } from '../../constants';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  DeleteTreeNodeInterface,
  EditTreeNodeInterface,
  LinkTreeNodeInterface,
  LocationNode,
  ZoneNode,
  SiteNode
} from '../../siteTree/site';
import { getConstantFromLocalStorage, replaceTemplateValues, SitesApiUris } from '../../utils';
import { GenericMutationTrigger, updateHeaders } from './utils';
import { CreateTreeNodeInterface } from '../../siteTree/site';
import { buildDataSource } from '../../utils/common';
import { DataSourceListItemModel } from '../../types';

type GetSiteArgs = {
  customerId: string;
  /**
   * base64 converted username
   */
  username: string;
  siteId: string;
};

export type EditNodeArgs = {
  editedNode: EditTreeNodeInterface;
  node_id: string;
  name: string;
  node_type: SiteNodeTypes;
};

export type LinkNodeArgs = {
  linkedNode: LinkTreeNodeInterface;
  node_id: string;
  name: string;
  node_type: SiteNodeTypes;
};
export interface UnlinkNodeArgs {
  node_id: string;
  name: string;
  dataSource: DataSourceListItemModel;
}
export type MoveNodeArgs = {
  id: string;
  parent_id: string;
  name: string;
  position: number;
  type: SiteNodeTypes;
};

export type ToggleNodeActiveArgs = {
  node: LocationNode;
  active: boolean;
  unlink?: boolean;
};

export type SiteMutation<T> = GenericMutationTrigger<T, 'Site' | 'Nodes', 'siteApi'>;

export type ToggleNodeActiveMutation = SiteMutation<ToggleNodeActiveArgs>;

export enum Tags {
  Nodes = 'Nodes',
  Site = 'Site'
}

export const siteApi = createApi({
  reducerPath: 'siteApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_SITE_MANAGEMENT_BASE_URI,
    prepareHeaders: async headers => {
      headers = await updateHeaders(headers);
      return headers;
    }
  }),
  // refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: [Tags.Site, Tags.Nodes],
  endpoints: builder => ({
    getSite: builder.query<SiteNode, GetSiteArgs>({
      query: ({ customerId, username, siteId }) =>
        `${API_SITE_MANAGEMENT_BASE_URI}${SitesApiUris.list
          .replace('{customer_id}', customerId)
          .replace('{user_name}', username)
          .replace('{site_id}', siteId)}`,
      providesTags: result =>
        result
          ? [
              { type: Tags.Site, id: result.node_id },
              ...(result.children ? result.children : []).map(
                ({ node_id }) => ({ type: Tags.Nodes, id: node_id } as const)
              ),
              { type: Tags.Nodes, id: 'LIST' }
            ]
          : [Tags.Site, { type: Tags.Nodes, id: 'LIST' }]
    }),
    addNode: builder.mutation<Pick<SiteNode, 'node_id'>, CreateTreeNodeInterface>({
      query(body) {
        const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID) ?? '';
        return {
          url: SitesApiUris.createTreeNode.replace('{customer_id}', customerId),
          method: 'POST',
          body
        };
      },
      invalidatesTags: () => [{ type: Tags.Nodes, id: 'LIST' }, Tags.Site]
    }),
    linkNode: builder.mutation<any, LinkNodeArgs>({
      query(body) {
        const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID) ?? '';
        return {
          url: SitesApiUris.linkTreeNode.replace('{customer_id}', customerId).replace('{node_id}', body.node_id),
          method: 'POST',
          body: body.linkedNode
        };
      },
      invalidatesTags: (result, error, body) => [{ type: Tags.Nodes, id: body.node_id }, Tags.Site]
    }),
    editNode: builder.mutation<any, EditNodeArgs>({
      query(node) {
        const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID) ?? '';

        return {
          url: SitesApiUris.editTreeNode.replace('{customer_id}', customerId).replace('{node_id}', node.node_id),
          method: 'PUT',
          body: node.editedNode
        };
      },
      invalidatesTags: node => [{ type: Tags.Nodes, id: node.node_id }, Tags.Site]
    }),
    moveNode: builder.mutation<ZoneNode | LocationNode, MoveNodeArgs>({
      query({ id, type, ...body }) {
        const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID) ?? '';

        return {
          url: SitesApiUris.moveTreeNode.replace('{customer_id}', customerId).replace('{node_id}', id),
          method: 'PATCH',
          body: body
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: Tags.Nodes, id }, Tags.Site]
    }),
    toggleNodeActive: builder.mutation<any, ToggleNodeActiveArgs>({
      query({ node, ...body }) {
        const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID) ?? '';
        return {
          url: replaceTemplateValues(SitesApiUris.toggleNodeActive, { customer_id: customerId, node_id: node.node_id }),
          method: 'PATCH',
          body
        };
      },
      invalidatesTags: (result, error, { node, ...body }) => [{ type: Tags.Nodes, id: node.node_id }, Tags.Site]
    }),
    getLocation: builder.query<LocationNode, { customerId: string; id: string | null }>({
      query: ({ customerId, id }) => {
        return `/tenant/${customerId}/locations/${id}`;
      },
      keepUnusedDataFor: 10,
      providesTags: (result, error, _args) => {
        if (error) {
          return [];
        }
        return [{ type: Tags.Nodes, id: result?.node_id }];
      }
    }),
    getNodeLocations: builder.query<LocationNode[], { customerId: string; username: string; nodeId: string }>({
      query: ({ customerId, username, nodeId }) => {
        return `/tenant/${customerId}/users/${username}/nodes/${nodeId}`;
      },
      providesTags: (result, error, _args) => {
        if (error) {
          return [];
        }
        return result
          ? [
              ...result.map(({ node_id }) => ({ type: Tags.Nodes, id: node_id } as const)),
              { type: Tags.Nodes, id: 'LIST' }
            ]
          : [{ type: Tags.Nodes, id: 'LIST' }];
      }
    }),
    deleteNode: builder.mutation<any, DeleteTreeNodeInterface>({
      query({ node_id }) {
        const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID) ?? '';
        return {
          url: SitesApiUris.deleteTreeNode.replace('{customer_id}', customerId).replace('{node_id}', node_id),
          method: 'DELETE'
        };
      },
      invalidatesTags: (result, error, { node_id }) => [{ type: Tags.Nodes, id: node_id }, Tags.Site]
    }),
    unlinkNode: builder.mutation<any, UnlinkNodeArgs>({
      query({ node_id, name, dataSource }) {
        const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID) ?? '';
        const dataSourceStr = buildDataSource(dataSource);
        return {
          url: SitesApiUris.unlinkTreeNode
            .replace('{customer_id}', customerId)
            .replace('{node_id}', node_id)
            .replace('{data_source}', dataSourceStr),
          method: 'DELETE'
        };
      },
      invalidatesTags: (result, error, { node_id }) => [{ type: Tags.Nodes, id: node_id }, Tags.Site]
    })
  })
});

export const {
  useGetSiteQuery,
  useGetLocationQuery,
  useGetNodeLocationsQuery,
  useAddNodeMutation,
  useLinkNodeMutation,
  useEditNodeMutation,
  useDeleteNodeMutation,
  useMoveNodeMutation,
  useUnlinkNodeMutation,
  useToggleNodeActiveMutation
} = siteApi;
