import * as ActionType from '../actions/actionTypes';
import { subDays } from 'date-fns';
import { LocationCustomProps, LocationTreeFormattedNode } from '../../components/Reports/Locations';
import { VaiColor } from '@vaisala/rockhopper-design-tokens';
import { getInitialAssignedChartColors, getTodayWithNearestAvailableTime } from '../../utils';
import { LocationNode, SiteNode } from '../../siteTree/site';

interface genericAction {
  readonly type: string;
  readonly error?: any;
}

interface getReportAction {
  readonly type: string;
  readonly error?: any;
  readonly loading?: boolean;
  readonly interval?: IntervalDate;
  readonly date?: Date;
  readonly locations?: LocationNode[];
  readonly selectedFormattedLocations?: { [key: string]: LocationTreeFormattedNode };
  readonly locationsProps?: LocationCustomProps;
  readonly selectedLocationsNum?: number;
  readonly selectedLocationsIDs?: string[];
  readonly selectedLocationsSKs?: string[];
  readonly graphsNum?: number;
  readonly visibleLocations?: { [key: string]: { visible: boolean; color: VaiColor } };
  readonly assignedChartColors?: { [key in VaiColor]?: string | null };
}

export type ReportAction = getReportAction | genericAction;

export type IntervalDate = { from: Date; to: Date };

export interface ReportState {
  readonly error?: any;
  readonly isApiLoading: boolean;
  readonly intervalDate: IntervalDate;
  readonly locations: LocationNode[] | SiteNode[];
  readonly selectedFormattedLocations: { [key: string]: LocationTreeFormattedNode };
  readonly selectedLocationsCustomProps: LocationCustomProps;
  readonly selectedLocationsNum: number;
  readonly selectedLocationsIDs: string[];
  readonly selectedLocationsSKs: string[];
  readonly graphsNum: number;
  readonly visibleLocations: { [key: string]: { visible: boolean; color: VaiColor | null | undefined } };
  readonly assignedChartColors: { [key in VaiColor]?: string | null };
  readonly isTesting: boolean;
}

const today = getTodayWithNearestAvailableTime();

const initialState: ReportState = {
  isApiLoading: false,
  error: undefined,
  intervalDate: {
    from: subDays(today, 1),
    to: today
  },
  locations: [],
  selectedFormattedLocations: {},
  selectedLocationsCustomProps: {},
  selectedLocationsNum: 0,
  selectedLocationsIDs: [],
  selectedLocationsSKs: [],
  graphsNum: 1,
  visibleLocations: {},
  assignedChartColors: getInitialAssignedChartColors(),
  isTesting: false
};

export default function reportsReducer(state: ReportState = initialState, action: ReportAction): ReportState {
  switch (action.type) {
    case ActionType.SET_REPORT_INTERVAL_DATE:
      return {
        ...state,
        intervalDate: (action as getReportAction).interval ?? state.intervalDate
      };
    case ActionType.SET_REPORT_API_LOADING:
      return { ...state, isApiLoading: (action as getReportAction).loading ?? state.isApiLoading };
    case ActionType.SET_REPORT_LOCATIONS:
      return { ...state, locations: (action as getReportAction).locations ?? state.locations };
    case ActionType.SET_REPORT_SELECTED_FORMATTED_LOCATIONS:
      return {
        ...state,
        selectedFormattedLocations:
          (action as getReportAction).selectedFormattedLocations ?? state.selectedFormattedLocations
      };
    case ActionType.SET_REPORT_SELECTED_LOCATIONS_CUSTOM_PROPS:
      return {
        ...state,
        selectedLocationsCustomProps: (action as getReportAction).locationsProps ?? state.selectedLocationsCustomProps
      };
    case ActionType.SET_REPORT_SELECTED_LOCATIONS_NUM:
      return {
        ...state,
        selectedLocationsNum: (action as getReportAction).selectedLocationsNum ?? state.selectedLocationsNum
      };
    case ActionType.SET_REPORT_SELECTED_LOCATIONS_IDS:
      return {
        ...state,
        selectedLocationsIDs: (action as getReportAction).selectedLocationsIDs ?? state.selectedLocationsIDs
      };
    case ActionType.SET_REPORT_SELECTED_LOCATIONS_SK:
      return {
        ...state,
        selectedLocationsSKs: (action as getReportAction).selectedLocationsSKs ?? state.selectedLocationsSKs
      };
    case ActionType.SET_REPORT_GRAPHS_NUM:
      return { ...state, graphsNum: (action as getReportAction).graphsNum ?? state.graphsNum };
    case ActionType.SET_REPORT_VISIBLE_LOCATIONS:
      return { ...state, visibleLocations: (action as getReportAction).visibleLocations ?? state.visibleLocations };
    case ActionType.SET_REPORT_ASSIGNED_CHART_COLORS:
      return {
        ...state,
        assignedChartColors: (action as getReportAction).assignedChartColors ?? state.assignedChartColors
      };
    case ActionType.RESET_REPORTS_STATE:
      return {
        ...initialState,
        // today: getTodayWithNearestAvailableTime(),
        intervalDate: {
          from: subDays(new Date(), 1),
          to: new Date()
        }
      };
    default:
      return state;
  }
}
