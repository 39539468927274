import React from 'react';
import { DeviceListItemModel } from '../../types';
import { List } from '@vaisala/rockhopper-components';
import { TEST_IDS } from '../../tests/testids';
import useHtmlId, { IUseHtmlIDProps } from '../../hooks/useHtmlId';
import DeviceManagerListItem from './DeviceManagerListItem';

interface Props extends IUseHtmlIDProps {
  devices: DeviceListItemModel[];
  highlighted?: string;
  routeId?: string;
  onListItemClick?: (deviceId: string) => void;
}

const DeviceManagerList: React.FC<Props> = ({ devices, highlighted, routeId, onListItemClick, htmlId }) => {
  const { getId } = useHtmlId({ htmlId });
  return (
    <List id={getId('device-list')} data-ta={TEST_IDS.device_manager_device_list} className="device-container">
      {devices.map((device, index) => (
        <DeviceManagerListItem
          key={getId(`list-item--${index}`)}
          device={device}
          routeId={routeId}
          onClick={onListItemClick}
          highlighted={highlighted}
          htmlId={htmlId}
        />
      ))}
    </List>
  );
};

export default DeviceManagerList;
