import AccessRestricted, { AccessRestrictedProps } from '../../../AccessRestricted/AccessRestricted';
import CenteredSpinner from '../../../BaseComponents/CenteredSpinner';
import Error404, { Error404Props } from '../../../Error/Error404';
import Error500, { Error500Props } from '../../../Error/Error500';
import React from 'react';

interface Props {
  isFetching: boolean;
  unexpectedErrorProps?: Error500Props;
  isAccessRestricted: boolean;
  accessRestrictedProps?: AccessRestrictedProps;
  isNotFound?: boolean;
  notFoundProps?: Error404Props;
}

export const FallbackContent: React.FC<Props> = ({
  isFetching,
  unexpectedErrorProps,
  isAccessRestricted,
  accessRestrictedProps,
  isNotFound,
  notFoundProps
}): JSX.Element => {
  switch (true) {
    case isFetching:
      return <CenteredSpinner />;
    case isNotFound:
      return <Error404 {...(notFoundProps || {})} />;
    case isAccessRestricted:
      return <AccessRestricted {...(accessRestrictedProps || {})} />;
    default:
      return <Error500 {...(unexpectedErrorProps || {})} />;
  }
};
