import React from 'react';
import { Grid } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import { AlarmSettingFormHeaderRow } from '../../../../Alarms/shared/AlarmSettingFormHeaderRow';

export const FormHeaders: React.FC = () => {
  const { t } = useTranslation();

  return (
    <AlarmSettingFormHeaderRow>
      <Grid.Col md={1} lg={1} xl={1}>
        {t('site.alarms.settings.threshold.form.headers.activate')}
      </Grid.Col>
      <Grid.Col md={3} lg={3} xl={3}>
        {t('site.alarms.settings.threshold.form.headers.value')}
      </Grid.Col>
      <Grid.Col md={3} lg={3} xl={3}>
        {t('site.alarms.settings.threshold.form.headers.priority')}
      </Grid.Col>
      <Grid.Col md={3} lg={3} xl={3}>
        {t('site.alarms.settings.threshold.form.headers.acknowledgement')}
      </Grid.Col>
      <Grid.Col md={2} lg={2} xl={2}>
        {t('site.alarms.settings.threshold.form.headers.delay')}
      </Grid.Col>
    </AlarmSettingFormHeaderRow>
  );
};
