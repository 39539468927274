import React from 'react';
import { TEST_IDS } from '../../../tests/testids';
import { QA_TEST_IDS } from '../../../tests/qaTestIds';
import { DEFAULT_NO_MEASUREMENT } from '../../../constants';
import { roundHalfToEven } from '../../../utils';

type StatusMeasurementProps = {
  id?: string;
  value: number | null | undefined;
  decimals?: number | null;
  dataTa?: string;
  className?: string | null | undefined;
};

const StatusMeasurement: React.FC<StatusMeasurementProps> = ({
  id = QA_TEST_IDS.location_value,
  value,
  decimals,
  dataTa = TEST_IDS.location_value,
  className = undefined,
  children
}) => {
  if (value === null || value === undefined)
    return (
      <span id={id} className={className === null ? undefined : className} data-ta={dataTa}>
        {DEFAULT_NO_MEASUREMENT}
      </span>
    );

  const roundedValue = roundHalfToEven(value, decimals ?? 0);

  return (
    <>
      <span className={className === null ? undefined : className} id={id} data-ta={dataTa}>
        {roundedValue}
      </span>
      {children}
    </>
  );
};

export default StatusMeasurement;
