/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { cloneDeep, find, isEmpty } from 'lodash';
import {
  Action,
  NodeModel,
  NodeState,
  TreeFamilyModel,
  TreeMenu,
  TreeMethods,
  TreeModel
} from '@vaisala/community-tree-menu';
import {
  Button,
  ButtonType,
  Flex,
  InputField,
  Size,
  Icon,
  MenuButton,
  MenuButtonToggle,
  MenuButtonList,
  MenuButtonListItem,
  Separator,
  Checkbox
} from '@vaisala/rockhopper-components';
import { VaiSpacing, VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { ResizableBox } from './ResizableBox';
import { BaseNode, SiteNode } from '../../siteTree/site';
import {
  ALARM_STATUS_TREE_MENU_STATE,
  TreeMenuNode,
  TreeNodeState,
  isLocationNode,
  siteTreeConvertor,
  userSettings
} from '../../utils/common';
import { useTranslation } from 'react-i18next';
import { EmptyTree } from './Tree/EmptyTree';
import { LOCATION_KEY, PARENT_SITE_KEY, SiteNodeTypes, UserSettings } from '../../constants';
import { EmptyState } from '../Utils/EmptyState/EmptyState';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import CenteredSpinner from '../BaseComponents/CenteredSpinner';
import { IllustrationType } from '../Utils/EmptyState/Illustration';
import { selectSelectedNodeId, setSelectedNodeId, setShowInactiveNodes } from '../../store/siteTree';
import useSiteTreeActions from '../../hooks/useSiteTreeActions';
import useUserSettings from '../../hooks/useUserSettings';
import { CreateActionDialogs } from './CreateActionDialogs';
import { EditActionDialogs } from './EditActionDialogs';
import { SiteSortValue, sortTree } from '../../utils/site';
import { TEST_IDS } from '../../tests/testids';
import './Tree/siteTree.scss';
import './Tree/Resizable.scss';
import '@vaisala/community-tree-menu/dist/style.css';
import i18n from '../../i18n';

const HIGHLIGHTED: NodeState = 'highlighted';

export enum NodeActions {
  NEW_ZONE = 'newZone',
  NEW_LOCATION = 'newLocation',
  EDIT_NODE = 'editSelectedItem',
  MOVE_NODE = 'arrangeSiteTree'
}

interface SiteProps {
  site: SiteNode | undefined;
  siteLoading: boolean;
  canManageSites: boolean;
  userInfoLoading: boolean;
  highlightedId: string | null;
  toggleHighlight: (node_id?: string) => void;
}

export interface NodeItem extends NodeModel {
  id: string;
  parent_id: string;
  type: string;
  icon: VaiIcon;
  children?: NodeItem[];
  position: number;
}

export const getNodeObjectById = (nodeId: string | number | null, tree: TreeMenuNode[]): TreeMenuNode => {
  const filteredTree = tree.filter(site => {
    return site.id === nodeId;
  });

  return filteredTree[0];
};

export const getChildList = (parentId: string | number | null, tree: any[]) => {
  const list: string[] = [];
  tree.map(node => {
    if (node.parent === parentId) {
      list.push(node.text.toLowerCase());
    }
  });
  return list;
};

export type SortOptions = 'byDefault' | 'alphabetically' | 'reverseAlphabetically';

const addHighlight = (tree: TreeMenuNode[], highlightedId: string | null) =>
  tree.map(node => {
    if (node.id === highlightedId) {
      return { ...node, states: [HIGHLIGHTED] };
    } else {
      return { ...node, states: node.states?.filter(state => state !== HIGHLIGHTED) ?? [] };
    }
  });

const SiteTree = ({
  site,
  canManageSites,
  siteLoading,
  userInfoLoading,
  highlightedId,
  toggleHighlight
}: SiteProps) => {
  const { t } = useTranslation();
  const ref = useRef<TreeMethods>(null);
  const dispatch = useAppDispatch();
  const { username, siteListSorting, showInactiveLocations } = useUserSettings();
  const [searchCriteria, setSearchCriteria] = useState('');
  const [selectedType, setSelectedType] = useState<SiteNodeTypes>();
  const [isTreeOpen, setIsTreeOpen] = useState(false);
  const [treeData, setTreeData] = useState<TreeMenuNode[][]>([]);
  const [initialTreeData, setInitialTreeData] = useState<TreeMenuNode[][]>([]);
  const [editingTree, setEditingTree] = useState(false);
  const treeDataRef = useRef<TreeMenuNode[][]>([]);
  const [sortBy, setSortBy] = useState<SortOptions>(siteListSorting);
  const [displayInactiveLocations, setDisplayInactiveLocations] = useState(showInactiveLocations);
  const {
    moveTreeNode,
    addNodeResultDetails,
    createTreeNode,
    deleteTreeNode,
    deleteTreeNodeResultDetails,
    editTreeNode,
    editTreeNodeResultDetails
  } = useSiteTreeActions();
  const selectedNodeId = useAppSelector(selectSelectedNodeId);
  const isSelectedId = useRef(selectedNodeId);
  const [currentAction, setCurrentAction] = useState<NodeActions | null>(null);
  const [openIds, setOpenIds] = useState<(string | number)[]>([]);

  const openAll = () => {
    if (!isTreeOpen) {
      ref.current?.openAll();
      setIsTreeOpen(true);
    } else {
      ref.current?.closeAll();
      setIsTreeOpen(false);
    }
  };

  useEffect(() => {
    const node = treeData[0]?.find(node => node.id === highlightedId);
    const parentIds = getNodeParentsIds(node);
    ref.current?.open(parentIds);
  }, [highlightedId, treeData]);

  useEffect(() => {
    !isEmpty(treeData) &&
      !isEmpty(treeData[0]) &&
      setTreeData(trees => {
        const clonedTrees = cloneDeep(trees);
        clonedTrees[0] = addHighlight(clonedTrees[0], highlightedId);
        return clonedTrees;
      });
  }, [highlightedId]);

  // The actual tree that is returned is more than NodeModel[].
  interface ExtendedTree extends NodeModel {
    position?: number;
    type?: SiteNodeTypes;
  }

  const updateSingleTree = (
    index: number | string,
    tree: ExtendedTree[],
    dragSource: NodeModel | undefined,
    dropTarget: NodeModel | undefined
  ) => {
    // NOTE: needed to match the community components types eg. NodeModel | undefined so handle that case gracefully although I'm not sure our application can actually have undefined values.
    if (!dragSource || !dropTarget) {
      return;
    }
    const newTree = [...tree];
    const childList = getChildList(dropTarget.id, newTree).concat(getChildList(dragSource.id, newTree));
    const parentName = dropTarget.text;
    childList.push(parentName.toLowerCase());
    let sourceName = dragSource.text;
    const firstChildOccuranceIndex = childList.indexOf(sourceName.toLowerCase());
    if (firstChildOccuranceIndex > -1) {
      childList.splice(firstChildOccuranceIndex, 1);
    }
    let counter = 1;
    while (childList.filter(node => node === sourceName.toLowerCase()).length) {
      if (counter === 1) {
        sourceName = sourceName + '(' + counter + ')';
      } else {
        sourceName = sourceName.substring(0, sourceName.lastIndexOf('(') + 1) + counter + ')';
      }
      counter++;
    }

    newTree.map((node, i) => {
      if (node.id === dragSource.id) {
        const newNode = { ...node };
        newNode.text = sourceName;
        newTree[i] = newNode;
      }
    });
    const newData = [...treeData];
    newData[index as number] = newTree as TreeMenuNode[];
    setTreeData(newData);
    const dragSourceNode = tree.find(node => node.id === dragSource.id);
    const dropTargetChildren = tree.filter(node => node.parent === dropTarget.id);
    const nodeAboveDropIndex = dropTargetChildren.findIndex(node => node.id === dragSource.id) - 1;
    // set the new position to the position property of the sibling node above + 1.
    // if no sibling nodes above set position to 1, it's the first item.
    const position = nodeAboveDropIndex >= 0 ? (dropTargetChildren?.[nodeAboveDropIndex]?.position ?? 0) + 1 : 1;

    if (!dragSourceNode || (dragSourceNode.position === position && dragSource.parent === dropTarget.id)) {
      // when dragging onto the same position in the same parent child list don't call the move as nothing has changed.
      return;
    }
    moveTreeNode({
      id: dragSource.id.toString(),
      parent_id: dropTarget.id.toString(),
      name: sourceName,
      position: position,
      type: dragSourceNode.type as SiteNodeTypes
    });
  };

  useEffect(() => {
    if (selectedNodeId && treeData[0]) {
      const isNodeFound = treeData[0]?.some(node => node.id === selectedNodeId);
      if (isSelectedId.current !== selectedNodeId && isNodeFound) {
        ref.current?.select(selectedNodeId);
        isSelectedId.current = selectedNodeId;
      }
      const selectedItemObject = getNodeObjectById(selectedNodeId, treeData[0]);
      if (selectedItemObject) {
        const openParents = getNodeParentsIds(selectedItemObject);
        setOpenIds(openParents);
        setSelectedType(selectedItemObject.type);
      }
    }
  }, [selectedNodeId, treeData, ref.current]);

  useEffect(() => {
    if (site && !userInfoLoading) {
      const flattenTreeData = displayInactiveLocations
        ? siteTreeConvertor(site, [], PARENT_SITE_KEY, selectedNodeId)
        : siteTreeConvertor(site, [], PARENT_SITE_KEY, selectedNodeId).map(innerArray =>
            innerArray.filter(obj => obj.active === true)
          );
      treeDataRef.current = flattenTreeData;
      setTreeData(flattenTreeData);
      setInitialTreeData(flattenTreeData);
    }
  }, [site, canManageSites, userInfoLoading, displayInactiveLocations, i18n.language]);

  useEffect(() => {
    /**
     * When new site data gets stored in the state (initialTreeData). Check if we have another sort option selected from before and mutate treeData to match the previously set sorting option. Only in view mode.
     */
    if (sortBy !== SiteSortValue.byDefault && !editingTree) {
      handleSortChange(sortBy);
    }
  }, [initialTreeData, sortBy]);

  const shouldProcessOpenIds = useRef(true);

  useEffect(() => {
    // this effect is mainly for openning nodes that are parents of the selected node
    // and removing alarming state as they will be open
    if (shouldProcessOpenIds.current && ref.current) {
      ref.current.open(openIds);
      setTreeData(trees => {
        let updatedTrees = trees;
        openIds.forEach(id => {
          if (!isLocationNode(getNodeObjectById(id, trees[0]) as BaseNode)) {
            updatedTrees = removeAlarmingFromTreeNode(id, updatedTrees);
          }
        });
        return updatedTrees;
      });
      shouldProcessOpenIds.current = false;
    }
  }, [openIds, ref.current]);

  const isSortActiveClass = React.useCallback(
    (value: SiteSortValue): string => {
      return cx({ 'vai-menu-button__list-item--active': sortBy && sortBy === value });
    },
    [sortBy]
  );

  const handleSortChange = (selectedValue: SortOptions) => {
    setSortBy(selectedValue);
    userSettings.set(username, UserSettings.SITE_LIST_SORTING, selectedValue);

    return setTreeData(trees => {
      let sortedTrees: TreeMenuNode[][] = [];
      const clonedTrees = cloneDeep(trees);

      if (site && selectedValue === SiteSortValue.byDefault) {
        sortedTrees = siteTreeConvertor(site, [], PARENT_SITE_KEY, selectedNodeId);
      } else {
        sortedTrees = [sortTree(clonedTrees[0], selectedValue)];
      }
      if (sortedTrees[0]) {
        sortedTrees[0] = addHighlight(sortedTrees[0], highlightedId);
      }

      return sortedTrees;
    });
  };

  const handleNodeMove = (handleAction: NodeActions.MOVE_NODE) => {
    toggleHighlight();

    switch (handleAction) {
      case NodeActions.MOVE_NODE:
        setEditingTree(!editingTree);
        handleSortChange(SiteSortValue.byDefault);
        break;
    }
  };

  const getNodeParentsIds = (node: TreeMenuNode | null | undefined, ids: string[] = []): string[] => {
    if (node) {
      const id = node.id;
      ids = [...ids, id];

      const parentNode = find(treeDataRef.current[0], treeNode => {
        return treeNode.id === node.parent;
      });

      if (parentNode && parentNode.parent !== null) {
        return getNodeParentsIds(parentNode, ids);
      }
      return ids;
    }
    return [];
  };

  const getNodeParents = (child: { parent: any }, arr: any[]) => {
    const result: TreeMenuNode[] = [];
    while (child.parent !== null) {
      for (const entity of initialTreeData[0]) {
        if (child.parent === entity.id) {
          if (!arr.some((e: { id: any }) => e.id === entity.id)) {
            result.push(entity);
          }
          child = entity;
        }
      }
    }
    return result;
  };

  const getParents = (arr: any) => {
    let entityArray = [...arr];
    for (const child of arr) {
      entityArray = entityArray.concat(getNodeParents(child, entityArray));
    }
    return entityArray;
  };

  const filter = (e: { target: { value: any } }) => {
    toggleHighlight();
    const keyword = e.target.value;
    if (keyword !== '') {
      const results = initialTreeData[0].filter((site: { text: string }) => {
        return site.text.toLowerCase().includes(keyword.toLowerCase());
      });
      const treeArray = getParents(results);
      if (treeArray.length == 0) {
        setTreeData([
          initialTreeData[0].filter((site: { parent: any }) => {
            return site.parent === null;
          })
        ]);
      } else {
        setTreeData([treeArray]);
      }
      if (!isTreeOpen) {
        openAll();
      }
    } else {
      setTreeData(initialTreeData);
      openAll();
      // If the text field is empty, show all zones.
    }
    setSearchCriteria(keyword);
  };

  const removeAlarmingFromTreeNode = (nodeId: string | number, trees: TreeMenuNode[][]) => {
    const updatedTrees: TreeMenuNode[][] = [
      trees[0].map(node => {
        if (node.id === nodeId) {
          return {
            ...node,
            states:
              node?.states?.filter(
                state => ![TreeNodeState.WARNING, TreeNodeState.ALARMING, TreeNodeState.INFO].includes(state)
              ) ?? [],
            actions: node?.actions?.filter(action => action !== 'alarm') ?? []
          };
        } else {
          return node;
        }
      })
    ];
    return updatedTrees;
  };

  const addAlarmingToTreeNode = (nodeId: string | number, trees: TreeMenuNode[][]) => {
    const updatedTrees: TreeMenuNode[][] = trees.map(tree =>
      tree.map(node => {
        if (node.id === nodeId) {
          const { actions = [], states = [], status = '' } = node ?? {};
          const nodeStatus = status as keyof typeof ALARM_STATUS_TREE_MENU_STATE;
          return {
            ...node,
            states: !states.includes(ALARM_STATUS_TREE_MENU_STATE[nodeStatus])
              ? [...states, ALARM_STATUS_TREE_MENU_STATE[nodeStatus]]
              : states ?? [],
            actions: node.data?.alarms ? ['alarm', ...actions] : actions
          };
        } else {
          return node;
        }
      })
    );
    return updatedTrees;
  };

  const onNodeExpandToggled = (props: Action) => {
    if (props.action === 'TOGGLE::NODE') {
      const isExpanding = props.data.openIds.includes(props.id);
      if (isExpanding) {
        setTreeData(trees => removeAlarmingFromTreeNode(props.id, trees));
      } else {
        setTreeData(trees => addAlarmingToTreeNode(props.id, trees));
      }
    }
  };

  const onNodeSelected = (props: Action) => {
    if (props.action === 'CLICK::NODE' && props.data.treeFamilyWithSelection) {
      let result = props.data.treeFamilyWithSelection[0].map(site =>
        site.actions?.includes('edit') ? { ...site, actions: site.actions.filter(item => item !== 'edit') } : site
      ) as TreeMenuNode[];

      let isLocation = false;

      result = result.map(node => {
        if (node.id === props.id) {
          isLocationNode((node as unknown) as BaseNode) && (isLocation = true);
          const updatedNode: TreeMenuNode = {
            ...node,
            actions: [...(node.actions ?? []), 'edit'],
            states: Array.from(new Set([...(node.states ?? []), TreeNodeState.SELECTED])) as NodeState[]
          };
          return updatedNode;
        } else {
          return node;
        }
      });

      if (!isLocation) {
        setTreeData(removeAlarmingFromTreeNode(props.id, [result]));
      } else {
        setTreeData([result]);
      }
      // TODO: Not a good approach but a workaround for adding alarm details to the closed accordions.
      // This is manually manipulating react life cycle. We need to refactor this component to be simple and easyly maintainable.

      shouldProcessOpenIds.current = true;
      const selectedItemObject = getNodeObjectById(props.id, treeDataRef?.current?.[0] ?? []);
      setOpenIds(getNodeParentsIds(selectedItemObject).filter(id => id !== selectedNodeId));
      dispatch(setSelectedNodeId(props.id));
    }
  };

  const onNodeEdit = (props: Action) => {
    if (props.action === 'CLICK::EDIT') {
      toggleHighlight();
      setCurrentAction(NodeActions.EDIT_NODE);
    }
  };

  let siteContent = <CenteredSpinner htmlId="site-tree-spinner" />;

  if (!canManageSites && !userInfoLoading && !site)
    siteContent = <EmptyState heading="site.noSiteRights" illustration={IllustrationType.nothingSelected} />;
  if (!siteLoading && site && Object.keys(site).length !== 0 && treeData[0] && treeData[0].length > 0) {
    siteContent = (
      <TreeMenu
        ref={ref}
        // FIXME: community-tree-menu types are not up to date. We add 'info' into the options.
        trees={treeData as TreeFamilyModel}
        editing={editingTree}
        maxHeight={editingTree ? 650 : 800}
        defaultTreeIndex={0}
        emptyTreeRender={() => (
          <EmptyTree message={searchCriteria.length > 0 ? t('site.emptySearchResult') : t('site.emptyEditTree')} />
        )}
        forceChevron
        onAction={(props: Action) => {
          onNodeSelected(props);
          onNodeEdit(props);
          onNodeExpandToggled(props);
          if (props.action === 'CHANGE::OPEN_NODES') {
            setOpenIds(props.data.openIds);
          }
        }}
        onDrop={(
          tree: TreeModel,
          {
            treeIndex,
            dragSource,
            dropTarget
          }: { treeIndex: number; dragSource: NodeModel | undefined; dropTarget: NodeModel | undefined }
        ) => updateSingleTree(treeIndex, tree, dragSource, dropTarget)}
      />
    );
  }

  const closeModal = () => {
    setCurrentAction(null);
    addNodeResultDetails.reset();
    editTreeNodeResultDetails.reset();
    deleteTreeNodeResultDetails.reset();
  };

  const toggleShowInactive = () => {
    const newDisplayStatus = !displayInactiveLocations;
    dispatch(setShowInactiveNodes(newDisplayStatus));
    userSettings.set(username, UserSettings.LOCATIONS_SHOW_INACTIVE, newDisplayStatus);
    setDisplayInactiveLocations(newDisplayStatus);
  };

  return (
    <>
      {currentAction && [NodeActions.NEW_ZONE, NodeActions.NEW_LOCATION].includes(currentAction) && (
        <CreateActionDialogs
          onDismiss={closeModal}
          currentAction={currentAction}
          treeData={treeData}
          addNodeApiDetails={{
            createTreeNode,
            addNodeResultDetails: {
              isLoading: addNodeResultDetails?.isLoading,
              isQueryEnded: addNodeResultDetails?.isQueryEnded
            }
          }}
        />
      )}
      {currentAction === NodeActions.EDIT_NODE && (
        <EditActionDialogs
          deleteTreeNodeApiDetails={{
            deleteTreeNode,
            result: {
              isLoading: deleteTreeNodeResultDetails?.isLoading,
              isQueryEnded: deleteTreeNodeResultDetails?.isQueryEnded
            }
          }}
          editTreeNodeApiDetails={{
            editTreeNode,
            result: {
              isLoading: editTreeNodeResultDetails?.isLoading,
              isQueryEnded: editTreeNodeResultDetails?.isQueryEnded
            }
          }}
          onDismiss={closeModal}
          treeData={treeData}
          currentAction={currentAction}
        />
      )}
      <Flex
        className={editingTree ? 'site-tree-editing-border' : ''}
        id="site-flex-container"
        style={{ height: '100%' }}
      >
        <ResizableBox limits={{ width: { min: 350, max: 800 } }}>
          <>
            <div
              id="site-tree-container"
              className={canManageSites ? '' : 'no-permission'}
              data-ta={TEST_IDS.site_tree_container}
            >
              <InputField
                htmlId="site-search-input"
                dataTa={TEST_IDS.site_tree_search}
                name="search"
                width={Size.Container}
                placeholder="Search"
                value={searchCriteria}
                onChange={filter}
                endIcon={<Icon name={VaiIcon.Search} size={Size.M} />}
              />
              <br />
              <Flex id="site-buttons-flex-container">
                <Flex.Item id="site-buttons-flex-first" alignSelf={'flex-start'} flexBasis={'33%'}>
                  <MenuButton dataTa={TEST_IDS.site_tree_show_inactive_menu_button}>
                    <MenuButtonToggle
                      dataTa={TEST_IDS.site_tree_show_inactive_menu_button_show_toggle}
                      buttonType={ButtonType.Secondary}
                    >
                      {t('general.show')}
                    </MenuButtonToggle>
                    <MenuButtonList>
                      <MenuButtonListItem
                        dataTa={TEST_IDS.site_tree_show_inactive_menu_button_show_all}
                        onSelect={ref.current?.closeAll}
                      >
                        {t('site.hideAll')}
                      </MenuButtonListItem>
                      <MenuButtonListItem
                        dataTa={TEST_IDS.site_tree_show_inactive_menu_button_hide_all}
                        onSelect={ref.current?.openAll}
                      >
                        {t('site.showAll')}
                      </MenuButtonListItem>
                      <Separator className="vai-margin-none" />
                      <div
                        data-ta={TEST_IDS.site_tree_show_inactive_menu_button_checkbox_container}
                        className="show-inactive-locations-container vai-padding-s"
                      >
                        <Checkbox
                          dataTa={TEST_IDS.site_tree_show_inactive_menu_button_checkbox}
                          checked={displayInactiveLocations}
                          onChange={toggleShowInactive}
                          label={t('site.showInactive')}
                        />
                      </div>
                    </MenuButtonList>
                  </MenuButton>
                </Flex.Item>
                <Flex.Item id="site-buttons-flex-second" alignSelf={'flex-start'} flexBasis={'33%'}>
                  <MenuButton id="site-sort-sites" style={{ marginLeft: VaiSpacing.M }} disabled={editingTree}>
                    <MenuButtonToggle id="site-sort-sites-menu-toggle" buttonType={ButtonType.Secondary}>
                      {t('site.sort')}
                    </MenuButtonToggle>
                    <MenuButtonList id="site-sort-sites-menu-list">
                      <MenuButtonListItem
                        id="site-sort-sites-default"
                        onSelect={() => handleSortChange(SiteSortValue.byDefault)}
                        className={isSortActiveClass(SiteSortValue.byDefault)}
                        disabled={editingTree}
                      >
                        {t('site.sortByDefault')}
                      </MenuButtonListItem>
                      <MenuButtonListItem
                        id="site-sort-sites-alphabet"
                        onSelect={() => handleSortChange(SiteSortValue.alphabetically)}
                        className={isSortActiveClass(SiteSortValue.alphabetically)}
                        disabled={editingTree}
                      >
                        {t('site.sortAlphabetically')}
                      </MenuButtonListItem>
                      <MenuButtonListItem
                        id="site-sort-sites-reverse-alphabet"
                        onSelect={() => handleSortChange(SiteSortValue.reverseAlphabetically)}
                        className={isSortActiveClass(SiteSortValue.reverseAlphabetically)}
                        disabled={editingTree}
                      >
                        {t('site.sortReverseAlphabetically')}
                      </MenuButtonListItem>
                    </MenuButtonList>
                  </MenuButton>
                </Flex.Item>
                {canManageSites && (
                  <Flex.Item id="site-buttons-flex-third" alignSelf={'flex-start'} flexBasis={'33%'}>
                    <MenuButton id="site-edit-sites" style={{ marginLeft: VaiSpacing.M }} disabled={editingTree}>
                      <MenuButtonToggle id="site-edit-sites-toggle">{t('site.edit')}</MenuButtonToggle>
                      <MenuButtonList>
                        <MenuButtonListItem
                          id="site-edit-new-zone"
                          disabled={selectedType === LOCATION_KEY || editingTree}
                          onSelect={() => {
                            setCurrentAction(NodeActions.NEW_ZONE);
                            toggleHighlight();
                          }}
                        >
                          <Icon
                            id="site-edit-new-zone-icon"
                            className="site-tree-accordion-icon"
                            name={VaiIcon.FolderOpen}
                          />
                          {t('site.newZone')}
                        </MenuButtonListItem>
                        <MenuButtonListItem
                          id="site-edit-new-location"
                          disabled={selectedType === LOCATION_KEY || editingTree}
                          onSelect={() => {
                            setCurrentAction(NodeActions.NEW_LOCATION);
                            toggleHighlight();
                          }}
                        >
                          <Icon
                            id="site-edit-new-location-icon"
                            className="site-tree-accordion-icon"
                            name={VaiIcon.MapMarker}
                          />
                          {t('site.newLocation')}
                        </MenuButtonListItem>
                        <MenuButtonListItem
                          id="site-edit-arrange-site"
                          onSelect={() => handleNodeMove(NodeActions.MOVE_NODE)}
                        >
                          <Icon
                            id="site-edit-arrange-site-icon"
                            className="site-tree-accordion-icon"
                            name={VaiIcon.TreeControl}
                          />
                          {t('site.arrangeSiteTree')}
                        </MenuButtonListItem>
                        <MenuButtonListItem
                          id="site-edit-selected"
                          onSelect={() => {
                            setCurrentAction(NodeActions.EDIT_NODE);
                            toggleHighlight();
                          }}
                          disabled={editingTree}
                        >
                          <Icon className="site-edit-selected-icon" name={VaiIcon.Edit} />
                          {t('site.editSelectedItem')}
                        </MenuButtonListItem>
                      </MenuButtonList>
                    </MenuButton>
                  </Flex.Item>
                )}
              </Flex>
              <div id="site-tree-rearrange-message" className={editingTree ? '' : 'site-tree-display-none'}>
                {t('site.rearrangeSiteMessage')}
              </div>
              <br />
              {siteContent}
              <section className="site-tree-exit-button-container">
                <Button
                  id="site-tree-exit-button"
                  className={editingTree ? '' : 'site-tree-display-none'}
                  onClick={() => setEditingTree(!editingTree)}
                >
                  {t('general.exit')}
                </Button>
              </section>
            </div>
            <Separator className={editingTree ? 'site-tree-exit-button-separator' : 'site-tree-display-none'} />
          </>
        </ResizableBox>
      </Flex>
    </>
  );
};

export default SiteTree;
