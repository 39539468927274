import { Flex, Heading, Icon, IconProps, Size } from '@vaisala/rockhopper-components';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { TranslationKey } from '../../react-i18next';
import { TEST_IDS } from '../../tests/testids';

interface ModalHeader {
  title?: TranslationKey;
  titleParams?: { [key: string]: string };
  type?: string;
  iconName?: VaiIcon;
}

const ModalHeader = ({ type, title, titleParams, iconName }: ModalHeader) => {
  const { t } = useTranslation();

  let iconDetails: IconProps | undefined;

  if (type === 'alert-dialog') {
    // for alert-dialogs we use a general warning icon.
    iconName = VaiIcon.Warning;
    iconDetails = {
      size: Size.XL,
      className: 'alert-dialog--icon'
    };
  }

  const iconClassName = cx('modal-header__icon', iconDetails?.className);
  /**
   * modal-header default className.
   * Adds modified classnames to the list depending on:
   * - modal is type alert-dialog
   * - iconName is available.
   */
  const headerClassName = cx(
    'modal-header',
    { 'modal-header--alert': type === 'alert-dialog' },
    { 'modal-header--icon': iconName }
  );

  return (
    <Flex className={headerClassName} justifyContent="center">
      {(iconName || type === 'alert-dialog') && (
        <Icon size={Size.L} name={iconName} {...iconDetails} className={iconClassName} />
      )}
      <Heading dataTa={TEST_IDS.users_edit_profile_dialog_heading} className="modal-header__heading">
        {title && t(title, titleParams)}
      </Heading>
    </Flex>
  );
};

export default ModalHeader;
