import { Icon, IconProps, Size } from '@vaisala/rockhopper-components';
import { VaiColor, VaiIcon } from '@vaisala/rockhopper-design-tokens';
import React from 'react';
import { CO2_ID, HUMIDITY_ID, TEMP_ID } from '../../../constants';
import { MeasurementType } from '../../../siteTree/site';
import { TEST_IDS } from '../../../tests/testids';

type Props = IconProps & {
  type: MeasurementType | null;
};

const MeasurementTypeIcon = ({ size = Size.M, type, htmlId, ...rest }: Props) => {
  if (!type) {
    return null;
  }
  const getIconData = (value: MeasurementType): IconProps => {
    const icons: Record<MeasurementType, IconProps> = {
      [CO2_ID]: { name: VaiIcon.Dust, color: VaiColor.BlueDark },
      [TEMP_ID]: { name: VaiIcon.Thermometer, color: VaiColor.BlueDark },
      [HUMIDITY_ID]: { name: VaiIcon.Humidity, color: VaiColor.BlueDark }
    };
    return icons[value];
  };
  return (
    <Icon
      htmlId={htmlId}
      size={size}
      data-value={type}
      dataTa={TEST_IDS.measurement_icon}
      {...getIconData(type)}
      {...rest}
    />
  );
};

export default MeasurementTypeIcon;
