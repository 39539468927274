import { useEffect, useState } from 'react';
import { findNode } from '../utils/site';
import { SiteNode } from '../siteTree/site';

/**
 * Returns the description of the selected zone or site
 * @param site The site object
 * @param id The id of the selected zone or site
 * @returns The description of the selected zone or site
 */
const useZoneorSiteDescription = (site: SiteNode | undefined, id: string) => {
  const [description, setDescription] = useState<string>('');

  useEffect(() => {
    if (!id && site) {
      return setDescription(site?.description ?? '');
    }
    if (site) {
      const selectedLocation = findNode(site.children, id);
      return setDescription(selectedLocation?.description ?? '');
    }
  }, [site, id]);

  return { description };
};

export default useZoneorSiteDescription;
