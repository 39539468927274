import * as AuthActionType from '../actions/actionTypes';
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from '../../constants';
import { AuthError, MFAError } from '../actions/auth';

interface LoginAction {
  readonly type: string;
  readonly error?: string;
  readonly changingPasswordError?: string;
}
interface LogoutAction {
  readonly type: string;
  readonly error?: AuthError;
}

interface ChangePasswordAction {
  readonly type: string;
  readonly error: string;
}

interface SendResetCodeAction {
  readonly type: string;
  readonly error?: string;
}

interface ResettingPasswordAction {
  readonly type: string;
  readonly error?: string;
}

interface ResetErrorAction {
  readonly type: string;
  readonly error?: string;
}

interface SetUpPasswordPolicyAction {
  readonly type: string;
  readonly passwordLength: number;
  readonly NumberRequired: boolean;
  readonly SpecialCharRequired: boolean;
  readonly UpperCaseRequired: boolean;
  readonly LowerCaseRequired: boolean;
  readonly error?: string;
}

interface SetupPasswordAction {
  readonly type: string;
  readonly error: string;
  readonly userName?: string;
}

interface UserCredentialAction {
  readonly type: string;
  readonly mfaUsername: string;
  readonly mfaPassword: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  readonly mfaUser: object;
}

interface SetupMFAAction {
  readonly type: string;
  readonly error?: MFAError;
}

type AuthAction =
  | LoginAction
  | LogoutAction
  | ChangePasswordAction
  | SendResetCodeAction
  | ResettingPasswordAction
  | SetUpPasswordPolicyAction
  | UserCredentialAction
  | SetupMFAAction
  | ResetErrorAction;

export interface AuthState {
  readonly error?: any;
  readonly loading: boolean;
  readonly isAuthenticated?: boolean;
  readonly changingPassword: boolean;
  readonly passwordChanged?: boolean;
  readonly changingPasswordError?: string;
  readonly sendingResetCode: boolean;
  readonly sendingResetCodeError?: string;
  readonly resettingPassword: boolean;
  readonly isPasswordReset: boolean;
  readonly resettingPasswordError?: string;
  readonly loadingPolicy?: boolean;
  readonly passwordPolicyLength?: number;
  readonly passwordPolicyMaxLength?: number;
  readonly passwordPolicyNumberRequired?: boolean;
  readonly passwordPolicySpecialCharRequired?: boolean;
  readonly passwordPolicyUpperCaseRequired?: boolean;
  readonly passwordPolicyLowerCaseRequired?: boolean;
  readonly passwordPolicyError?: string;
  readonly isSignUp?: boolean;
  readonly setUpPasswordError?: string;
  readonly isPasswordConfirmed?: boolean;
  readonly isTenantInfoLoaded?: boolean;
  readonly userName?: string;
  readonly isMFA?: boolean;
  readonly mfaUsername?: string;
  readonly mfaPassword?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  readonly mfaUser?: object;
  readonly isUserProfileCompleted: boolean;
  readonly isChangePasswordEmailSent?: boolean;
}

export const initialState: AuthState = {
  loading: false,
  isAuthenticated: undefined, // When undefined, show just spinner...
  changingPassword: false,
  sendingResetCode: false,
  resettingPassword: false,
  isPasswordReset: false,
  loadingPolicy: false,
  passwordPolicyLength: PASSWORD_MIN_LENGTH,
  passwordPolicyMaxLength: PASSWORD_MAX_LENGTH,
  passwordPolicyNumberRequired: false,
  passwordPolicySpecialCharRequired: false,
  passwordPolicyUpperCaseRequired: false,
  passwordPolicyLowerCaseRequired: false,
  isSignUp: false,
  isPasswordConfirmed: false,
  isTenantInfoLoaded: false,
  isMFA: false,
  isUserProfileCompleted: true
};

export default function authReducer(state: AuthState = initialState, action: AuthAction): AuthState {
  switch (action.type) {
    case AuthActionType.LOGIN:
      return { ...state, loading: true, isAuthenticated: false, error: undefined };
    case AuthActionType.LOGIN_SUCCESS:
      return { ...state, loading: false, isAuthenticated: true };
    case AuthActionType.LOGIN_ERROR:
      return { ...state, loading: false, error: (action as LoginAction).error, isAuthenticated: false };
    case AuthActionType.LOGOUT:
      return { ...state, loading: false, error: (action as LogoutAction).error || undefined, isAuthenticated: false };
    case AuthActionType.CHANGING_PASSWORD:
      return { ...state, changingPassword: true, passwordChanged: false, changingPasswordError: undefined };
    case AuthActionType.CHANGING_PASSWORD_SUCCESS:
      return { ...state, changingPassword: false, passwordChanged: true };
    case AuthActionType.CHANGING_PASSWORD_FAIL:
      return { ...state, changingPassword: false, changingPasswordError: (action as ChangePasswordAction).error };
    case AuthActionType.SET_RESETTING_PASSWORD_ERROR:
      return { ...state, resettingPasswordError: (action as ResettingPasswordAction).error };
    case AuthActionType.SET_AUTH_ERROR:
      return { ...state, error: (action as ResetErrorAction).error };
    case AuthActionType.SEND_RESET_PASSWORD_EMAIL_SUCCESS:
      return { ...state, isChangePasswordEmailSent: true };
    case AuthActionType.SEND_RESET_PASSWORD_EMAIL_FAIL:
      return {
        ...state,
        isChangePasswordEmailSent: false,
        changingPasswordError: (action as ChangePasswordAction).error
      };
    case AuthActionType.CHANGING_PASSWORD_START:
    case AuthActionType.CHANGING_PASSWORD_COMPLETED:
      return { ...state, changingPassword: false, changingPasswordError: undefined, passwordChanged: false };
    case AuthActionType.SENDING_RESET_CODE_INIT:
      return {
        ...state,
        sendingResetCode: false,
        sendingResetCodeError: undefined,
        isPasswordReset: false,
        error: undefined
      };
    case AuthActionType.SENDING_RESET_CODE:
      return { ...state, sendingResetCode: true, sendingResetCodeError: undefined };
    case AuthActionType.SENDING_RESET_CODE_SUCCESS:
      return { ...state, sendingResetCode: false };
    case AuthActionType.SENDING_RESET_CODE_FAIL:
      return { ...state, sendingResetCode: false, sendingResetCodeError: (action as SendResetCodeAction).error };
    case AuthActionType.RESETTING_PASSWORD:
      return { ...state, resettingPassword: true, resettingPasswordError: undefined };
    case AuthActionType.RESETTING_PASSWORD_SUCCESS:
      return { ...state, resettingPassword: false, isPasswordReset: true };
    case AuthActionType.RESETTING_PASSWORD_FAIL:
      return { ...state, resettingPassword: false, resettingPasswordError: (action as ResettingPasswordAction).error };
    case AuthActionType.PASSWORD_POLICY_START:
      return { ...state, loadingPolicy: true };
    case AuthActionType.PASSWORD_POLICY_SUCCESS:
      return {
        ...state,
        passwordPolicyLength: (action as SetUpPasswordPolicyAction).passwordLength,
        passwordPolicyNumberRequired: (action as SetUpPasswordPolicyAction).NumberRequired,
        passwordPolicySpecialCharRequired: (action as SetUpPasswordPolicyAction).SpecialCharRequired,
        passwordPolicyUpperCaseRequired: (action as SetUpPasswordPolicyAction).UpperCaseRequired,
        passwordPolicyLowerCaseRequired: (action as SetUpPasswordPolicyAction).LowerCaseRequired,
        loadingPolicy: false
      };
    case AuthActionType.PASSWORD_POLICY_FAIL:
      return {
        ...state,
        passwordPolicyLength: PASSWORD_MIN_LENGTH,
        passwordPolicyMaxLength: PASSWORD_MAX_LENGTH,
        passwordPolicyNumberRequired: false,
        passwordPolicySpecialCharRequired: false,
        passwordPolicyUpperCaseRequired: false,
        passwordPolicyLowerCaseRequired: false,
        passwordPolicyError: (action as SetUpPasswordPolicyAction).error,
        loadingPolicy: false
      };
    case AuthActionType.SIGNUP_START:
      return { ...state, isSignUp: true, loading: false };
    case AuthActionType.SETUP_PASSWORD_START:
      return { ...state, isPasswordConfirmed: false, changingPasswordError: undefined, loading: true };
    case AuthActionType.SETUP_PASSWORD_SUCCESS_WITHOUT_PROFILE_COMPLETED:
      return {
        ...state,
        loading: false,
        isPasswordConfirmed: true,
        isSignUp: false,
        userName: (action as SetupPasswordAction).userName
      };
    case AuthActionType.SETUP_PASSWORD_FAIL:
      return {
        ...state,
        isPasswordConfirmed: false,
        loading: false,
        changingPasswordError: (action as SetupPasswordAction).error
      };
    case AuthActionType.SETUP_PASSWORD_SUCCESS_WITH_PROFILE_COMPLETED:
      return { ...state, isSignUp: false, loading: false, isAuthenticated: true };
    case AuthActionType.SETUP_PROFILE_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isUserProfileCompleted: true,
        isPasswordConfirmed: false,
        loading: false
      };
    case AuthActionType.LOADING_TENANT_INFO_FAIL:
      return { ...state, isTenantInfoLoaded: false };
    case AuthActionType.LOADING_TENANT_INFO_SUCCESS:
      return { ...state, isTenantInfoLoaded: true };
    case AuthActionType.MFA_START:
      return {
        ...state,
        isMFA: true,
        loading: false,
        mfaUsername: (action as UserCredentialAction).mfaUsername,
        mfaPassword: (action as UserCredentialAction).mfaPassword,
        mfaUser: (action as UserCredentialAction).mfaUser,
        error: undefined
      };
    case AuthActionType.MFA_PREP:
      return { ...state, loading: true, error: undefined };
    case AuthActionType.MFA_SUCCESS:
      return { ...state, loading: false, isMFA: false, isAuthenticated: true, error: undefined };
    case AuthActionType.MFA_FAIL:
      return { ...state, loading: false, isMFA: false, error: (action as SetupMFAAction).error };
    case AuthActionType.MFA_END:
      return { ...state, loading: false, isMFA: false, error: undefined };
    case AuthActionType.RESEND_MFA_FAIL:
      return { ...state, error: MFAError.ResendException };
    case AuthActionType.USER_PROFILE_SETUP:
      return {
        ...state,
        isUserProfileCompleted: false,
        loading: false,
        userName: (action as SetupPasswordAction).userName
      };
    default:
      return state;
  }
}
