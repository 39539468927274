import { Flex, Icon, Size } from '@vaisala/rockhopper-components';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import {
  addMinutes,
  addSeconds,
  format,
  isAfter,
  isBefore,
  isSameDay,
  set,
  setMilliseconds,
  subMinutes,
  subSeconds,
  subYears
} from 'date-fns';
import * as React from 'react';
import { connect } from 'react-redux';
import { STEP_INTERVAL_DIRECTION, STEP_INTERVAL_SIZE } from '../../../constants';
import TimeContext from '../../../context/TimeContext';
import { reportsDispatchActions, StoreState } from '../../../store';
import { getNearestEnabledTimeOption } from '../../../utils';

import './chart-interval-arrows.scss';

type ChartIntervalArrowsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    stepInterval: {
      quarterStep: number;
      fullStep: number;
    };
  };

const ChartIntervalArrows: React.FunctionComponent<ChartIntervalArrowsProps> = ({
  reports,
  setReportIntervalDate,
  stepInterval
}) => {
  // Used to disable arrows if next interval is more than today's date or prevInterval is less than 3 years earlier
  const [nextPrevInterval, setNextPrevInterval] = React.useState({
    nextQuarter: addMinutes(reports.intervalDate.to, stepInterval.quarterStep),
    nextFull: addMinutes(reports.intervalDate.to, stepInterval.fullStep),
    prevQuarter: subMinutes(reports.intervalDate.from, stepInterval.quarterStep),
    prevFull: subMinutes(reports.intervalDate.from, stepInterval.fullStep)
  });

  const { today } = React.useContext(TimeContext);

  React.useEffect(() => {
    setNextPrevInterval({
      nextQuarter: addSeconds(reports.intervalDate.to, stepInterval.quarterStep),
      nextFull: addSeconds(reports.intervalDate.to, stepInterval.fullStep),
      prevQuarter: subSeconds(reports.intervalDate.from, stepInterval.quarterStep),
      prevFull: subSeconds(reports.intervalDate.from, stepInterval.fullStep)
    });
  }, [stepInterval]);

  const moveGraphInterval = (direction: STEP_INTERVAL_DIRECTION, stepSize = STEP_INTERVAL_SIZE.QUARTER) => {
    const interval = stepSize === STEP_INTERVAL_SIZE.QUARTER ? stepInterval.quarterStep : stepInterval.fullStep;

    if (direction === STEP_INTERVAL_DIRECTION.LEFT) {
      setReportIntervalDate({
        from: subSeconds(reports.intervalDate.from, interval),
        to: subSeconds(reports.intervalDate.to, interval)
      });
    } else if (direction === STEP_INTERVAL_DIRECTION.RIGHT) {
      setReportIntervalDate({
        from: addSeconds(reports.intervalDate.from, interval),
        to: addSeconds(reports.intervalDate.to, interval)
      });
    } else {
      const time = getNearestEnabledTimeOption(format(today, 'HH:mm'));
      const toDate = set(today, { minutes: +time.split(':')[1] });

      setReportIntervalDate({
        from: subSeconds(toDate, stepInterval.fullStep),
        to: toDate
      });
    }
  };

  return (
    <Flex className="date-wrapper" alignItems="center" justifyContent="space-around">
      <Icon
        size={Size.L}
        name={VaiIcon.DoubleChevronLeft}
        className={`arrow ${
          isBefore(setMilliseconds(nextPrevInterval.prevFull, 0), setMilliseconds(subYears(today, 3), 0))
            ? 'disabled'
            : ''
        }`}
        onClick={() => moveGraphInterval(STEP_INTERVAL_DIRECTION.LEFT, STEP_INTERVAL_SIZE.FULL)}
      />
      <Icon
        size={Size.L}
        name={VaiIcon.ChevronLeft}
        className={`arrow ${
          isBefore(setMilliseconds(nextPrevInterval.prevQuarter, 0), setMilliseconds(subYears(today, 3), 0))
            ? 'disabled'
            : ''
        }`}
        onClick={() => moveGraphInterval(STEP_INTERVAL_DIRECTION.LEFT, STEP_INTERVAL_SIZE.QUARTER)}
      />

      <span>{format(reports.intervalDate.from, 'HH:mm')}</span>
      <span>{format(reports.intervalDate.from, 'yyyy-MM-dd')}</span>

      <Icon size={Size.M} name={VaiIcon.ArrowRight} className="date-separator" />

      <span>{format(reports.intervalDate.to, 'HH:mm')}</span>
      <span>{format(reports.intervalDate.to, 'yyyy-MM-dd')}</span>

      <Icon
        size={Size.L}
        name={VaiIcon.ChevronRight}
        className={`arrow ${
          isAfter(setMilliseconds(nextPrevInterval.nextQuarter, 0), setMilliseconds(today, 0)) ? 'disabled' : ''
        }`}
        onClick={() => moveGraphInterval(STEP_INTERVAL_DIRECTION.RIGHT, STEP_INTERVAL_SIZE.QUARTER)}
      />
      <Icon
        size={Size.L}
        name={VaiIcon.DoubleChevronRight}
        className={`arrow ${
          isAfter(setMilliseconds(nextPrevInterval.nextFull, 0), setMilliseconds(today, 0)) ? 'disabled' : ''
        }`}
        onClick={() => moveGraphInterval(STEP_INTERVAL_DIRECTION.RIGHT, STEP_INTERVAL_SIZE.FULL)}
      />
      <Icon
        size={Size.L}
        name={VaiIcon.ChevronRight}
        className={`right-edge-icon arrow ${
          getNearestEnabledTimeOption(format(today, 'HH:mm')) ===
            getNearestEnabledTimeOption(format(reports.intervalDate.to, 'HH:mm')) &&
          isSameDay(reports.intervalDate.to, today)
            ? 'disabled'
            : ''
        }`}
        onClick={() => moveGraphInterval(STEP_INTERVAL_DIRECTION.RIGHT_EDGE)}
      />
    </Flex>
  );
};

const mapStateToProps = ({ reports }: StoreState) => ({
  reports
});

const mapDispatchToProps = (dispatch: any) => ({
  setReportIntervalDate: (interval: { from: Date; to: Date }) =>
    dispatch(reportsDispatchActions.setReportIntervalDate(interval))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartIntervalArrows);
