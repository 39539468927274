import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceState, VAINET, DeviceCalibrationInterval } from '../../constants';
import {
  Button,
  ButtonType,
  Flex,
  FlexItem,
  Heading,
  Icon,
  Separator,
  Size,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableStyle
} from '@vaisala/rockhopper-components';
import withDevice from '../../hoc/withDevice';
import { DeviceModel } from '../../types';
import { QA_TEST_IDS } from '../../tests/qaTestIds';
import { TEST_IDS } from '../../tests/testids';
import { timestampToTimeString, validateChannel, DeviceType } from '../../utils/common';
import { showDialog } from '../../store/dialog';
import { useAppDispatch } from '../../store/hooks';
import EditGatewayProperties from './EditDeviceProperties/EditGatewayProperties';
import EditRFLProperties from './EditDeviceProperties/EditRFLPropreties';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import RestartDeviceModal from './RestartDeviceModal';

interface DeviceManagerPropertiesProps {
  hasDevices: boolean;
  device: DeviceModel;
}

const DeviceManagerProperties = ({ hasDevices, device }: DeviceManagerPropertiesProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isNewDevice = () => (device.status ? device.status.state === DeviceState.NewDevice : true);
  const isRestartButtonDisabled = device.status?.state !== DeviceState.Online;

  const onRestartDeviceClick = () => {
    if (!isRestartButtonDisabled) {
      dispatch(
        showDialog(
          <RestartDeviceModal
            deviceId={device.deviceId}
            deviceModel={device.properties?.attributes?.productCode || DeviceType.AP10}
          />
        )
      );
    }
  };

  const getDisplayBrightness = (): string => {
    if (isNewDevice()) {
      return '--';
    }
    const brightness = device.properties?.config?.reported?.display?.brightness;
    const value = parseInt(`${brightness}`);
    if (isNaN(value)) {
      return '--';
    }
    return `${value} %`;
  };

  const getLEDBrightness = (): string => {
    if (isNewDevice()) {
      return '--';
    }
    const brightness = device.properties?.config?.reported?.led?.brightness;
    const value = parseInt(`${brightness}`);
    if (isNaN(value)) {
      return '--';
    }
    return `${value} %`;
  };

  const getLEDEnabled = (): string => {
    if (isNewDevice() || !device.properties?.config?.reported?.led) {
      return '--';
    }
    return device.properties.config.reported.led.enabled ? t('general.on') : t('general.off');
  };

  const getDisplayEnabled = (): string => {
    if (isNewDevice() || !device.properties?.config?.reported?.display) {
      return '--';
    }
    return device.properties.config.reported.display.enabled ? t('general.on') : t('general.off');
  };

  const getUnit = (): string => {
    if (isNewDevice() || !device.properties?.config?.reported?.device) {
      return '--';
    }
    return device.properties?.config?.reported?.device?.metricUnitsEnabled
      ? t('deviceManager.metric')
      : t('deviceManager.imperial');
  };

  const getChannel = (): string => {
    if (isNewDevice()) {
      return '--';
    }
    return validateChannel(device.properties?.config?.reported?.device?.apId);
  };

  const renderEditGatewayProperties = () => {
    dispatch(showDialog(<EditGatewayProperties config={device.properties.config} deviceId={device.deviceId} />));
  };

  const renderEditRFLProperties = () => {
    dispatch(showDialog(<EditRFLProperties config={device.properties.config} deviceId={device.deviceId} />));
  };

  const renderGateway = () => {
    const restartDeviceId = QA_TEST_IDS.device_properties_restart_device_button;
    return (
      <Stack className="properties-stack">
        <Flex alignItems="center">
          <FlexItem flexShrink={0}>
            <Heading
              dataTa={TEST_IDS.device_properties_device_id_heading}
              level={1}
              className="no-word-wrap vai-margin-sm"
            >
              {device.deviceId}
            </Heading>
          </FlexItem>
          <FlexItem>
            <Button
              id={QA_TEST_IDS.edit_button}
              dataTa={TEST_IDS.edit_button}
              buttonType={ButtonType.Link}
              buttonSize={Size.M}
              onClick={renderEditGatewayProperties}
              className="vai-margin-left-l"
            >
              {t('general.edit')}
            </Button>
          </FlexItem>
        </Flex>
        <Heading level={4} htmlId={QA_TEST_IDS.device_properties_heading} className="vai-margin-sm">
          {t('deviceManager.deviceProperties')}
        </Heading>
        <Table className="properties-table borderless" tableStyle={TableStyle.Plain}>
          <TableBody>
            <TableRow>
              <TableCell htmlId={QA_TEST_IDS.device_properties_hostname_label}>{t('deviceManager.hostname')}</TableCell>
              <TableCell
                htmlId={QA_TEST_IDS.device_properties_hostname_value}
                dataTa={TEST_IDS.device_properties_hostname_value}
              >
                {device.deviceId || '--'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell htmlId={QA_TEST_IDS.device_properties_channel_label}>
                {t('general.channel', { vainet: VAINET })}
              </TableCell>
              <TableCell
                htmlId={QA_TEST_IDS.device_properties_channel_value}
                dataTa={TEST_IDS.device_properties_channel_value}
              >
                {getChannel()}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell htmlId={QA_TEST_IDS.device_properties_firmware_version_label}>
                {t('deviceManager.firmwareVersion')}
              </TableCell>
              <TableCell
                htmlId={QA_TEST_IDS.device_properties_firmware_version_value}
                dataTa={TEST_IDS.device_properties_firmware_version_value}
              >
                {device?.firmware || '--'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Separator />
        <Table className="properties-table borderless" tableStyle={TableStyle.Plain}>
          <TableBody>
            <TableRow>
              <TableCell htmlId={QA_TEST_IDS.device_properties_display_enabled_label}>
                {t('deviceManager.display')}
              </TableCell>
              <TableCell
                htmlId={QA_TEST_IDS.device_properties_display_enabled_value}
                dataTa={TEST_IDS.device_properties_display_enabled_value}
              >
                {getDisplayEnabled()}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell htmlId={QA_TEST_IDS.device_properties_display_percent_label}>
                {t('deviceManager.displayBrightness')}
              </TableCell>
              <TableCell
                htmlId={QA_TEST_IDS.device_properties_display_percent_value}
                dataTa={TEST_IDS.device_properties_display_percent_value}
              >
                {getDisplayBrightness()}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell htmlId={QA_TEST_IDS.device_properties_led_enabled_label}>{t('deviceManager.LED')}</TableCell>
              <TableCell
                htmlId={QA_TEST_IDS.device_properties_led_enabled_value}
                dataTa={TEST_IDS.device_properties_led_enabled_value}
              >
                {getLEDEnabled()}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell htmlId={QA_TEST_IDS.device_properties_led_percent_label}>
                {t('deviceManager.LEDBrightness')}
              </TableCell>
              <TableCell
                htmlId={QA_TEST_IDS.device_properties_led_percent_value}
                dataTa={TEST_IDS.device_properties_led_percent_value}
              >
                {getLEDBrightness()}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Separator />
        <FlexItem>
          <Button
            startIcon={
              <Icon
                htmlId={QA_TEST_IDS.device_properties_restart_device_icon}
                className="vai-margin-right-sm"
                name={VaiIcon.Refresh}
              />
            }
            id={restartDeviceId}
            disabled={isRestartButtonDisabled}
            onClick={onRestartDeviceClick}
            buttonType={ButtonType.Link}
          >
            {t('deviceManager.restartDevice')}
          </Button>
        </FlexItem>
      </Stack>
    );
  };

  const renderLogger = () => {
    return (
      <Stack className="properties-stack">
        <Flex alignItems="center">
          <FlexItem flexShrink={0}>
            <Heading
              dataTa={TEST_IDS.device_properties_device_id_heading}
              level={1}
              className="no-word-wrap vai-margin-sm"
            >
              {t('deviceManager.deviceProperties')}
            </Heading>
          </FlexItem>
          <FlexItem>
            {/* eslint-disable enforce-ids-in-jsx/missing-ids */}
            <Button
              id={QA_TEST_IDS.edit_button}
              dataTa={TEST_IDS.edit_button}
              buttonType={ButtonType.Link}
              buttonSize={Size.M}
              className="vai-margin-left-l"
              onClick={renderEditRFLProperties}
            >
              {t('general.edit')}
            </Button>
            {/* eslint-enable enforce-ids-in-jsx/missing-ids */}
          </FlexItem>
        </Flex>
        <Table className="properties-table borderless" tableStyle={TableStyle.Plain}>
          <TableBody>
            <TableRow>
              <TableCell htmlId={QA_TEST_IDS.device_properties_current_host_label}>
                {t('deviceManager.currentHost')}
              </TableCell>
              <TableCell
                htmlId={QA_TEST_IDS.device_properties_current_host_value}
                dataTa={TEST_IDS.device_properties_current_host_value}
              >
                {device.status?.gateway || '--'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell htmlId={QA_TEST_IDS.device_properties_device_class_label}>
                {t('deviceManager.deviceClass')}
              </TableCell>
              <TableCell
                htmlId={QA_TEST_IDS.device_properties_device_class_value}
                dataTa={TEST_IDS.device_properties_device_class_value}
              >
                {device.properties.attributes.isLogger ? t('deviceManager.RFLDataLogger') : '--'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell htmlId={QA_TEST_IDS.device_properties_hardware_model_label}>
                {t('deviceManager.hardwareModel')}
              </TableCell>
              <TableCell
                htmlId={QA_TEST_IDS.device_properties_hardware_model_value}
                dataTa={TEST_IDS.device_properties_hardware_model_value}
              >
                {device.properties.attributes.productCode ? device.properties.attributes.productCode : '--'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell htmlId={QA_TEST_IDS.device_properties_firmware_version_label}>
                {t('deviceManager.firmwareVersion')}
              </TableCell>
              <TableCell
                htmlId={QA_TEST_IDS.device_properties_firmware_version_value}
                dataTa={TEST_IDS.device_properties_firmware_version_value}
              >
                {device?.firmware || '--'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell htmlId={QA_TEST_IDS.device_properties_serial_number_label}>
                {t('deviceManager.serialNumber')}
              </TableCell>
              <TableCell
                htmlId={QA_TEST_IDS.device_properties_serial_number_value}
                dataTa={TEST_IDS.device_properties_serial_number_value}
              >
                {device.properties.attributes.serialNumber ? device.properties.attributes.serialNumber : '--'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Separator />
        <Table className="properties-table borderless" tableStyle={TableStyle.Plain}>
          <TableBody>
            <TableRow>
              <TableCell htmlId={QA_TEST_IDS.device_properties_rfl_led_label}>{t('deviceManager.LED')}</TableCell>
              <TableCell
                htmlId={QA_TEST_IDS.device_properties_rfl_led_value}
                dataTa={TEST_IDS.device_properties_rfl_led_value}
              >
                {getLEDEnabled()}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell htmlId={QA_TEST_IDS.device_properties_rfl_display_label}>
                {t('deviceManager.display')}
              </TableCell>
              <TableCell
                htmlId={QA_TEST_IDS.device_properties_rfl_display_value}
                dataTa={TEST_IDS.device_properties_rfl_display_value}
              >
                {getDisplayEnabled()}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell htmlId={QA_TEST_IDS.device_properties_unit_label}>{t('deviceManager.unit')}</TableCell>
              <TableCell
                htmlId={QA_TEST_IDS.device_properties_unit_value}
                dataTa={TEST_IDS.device_properties_unit_value}
              >
                {getUnit()}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {device.status?.probes && device.status?.probes.length > 0 && <Separator />}
        {device.status?.probes?.map((row, i) => (
          <Table key={i} className="properties-table borderless" tableStyle={TableStyle.Plain}>
            <TableBody>
              <TableRow>
                <TableCell htmlId={QA_TEST_IDS.device_properties_probe_id_label}>
                  <b>{t('deviceManager.ProbeId')}</b>
                </TableCell>
                <TableCell>{row.probe_sn}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell htmlId={QA_TEST_IDS.device_properties_calibration_date_label}>
                  {t('deviceManager.lastCalibrationDate')}
                </TableCell>
                <TableCell
                  htmlId={QA_TEST_IDS.device_properties_calibration_date_value}
                  dataTa={TEST_IDS.device_properties_calibration_date_value}
                >
                  {row.calibration_date ? timestampToTimeString(row.calibration_date) : '--'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell htmlId={QA_TEST_IDS.device_properties_calibration_interval_label}>
                  {t('deviceManager.calibrationInterval')}
                </TableCell>
                <TableCell
                  htmlId={QA_TEST_IDS.device_properties_calibration_interval_value}
                  dataTa={TEST_IDS.device_properties_calibration_interval_value}
                >
                  {t('deviceManager.calibrationIntervalMessage', { monthsNumber: DeviceCalibrationInterval })}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ))}
      </Stack>
    );
  };

  return device.properties?.attributes?.isLogger ? renderLogger() : renderGateway();
};

export default withDevice({
  htmlId: QA_TEST_IDS.device_properties,
  displayName: 'DeviceManagerProperties'
})(DeviceManagerProperties);
