import * as React from 'react';
import {
  Size,
  Button,
  ButtonType,
  BodyText,
  NotificationType,
  Icon,
  Stack,
  FlexItem,
  Flex
} from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../store/hooks';
import { hideDialog } from '../../../store/dialog';
import { ModalContainer } from '../../Modals/ModalContainer';
import { TEST_IDS } from '../../../tests/testids';
import { useDeleteUserGroupsMutation } from '../../../store/services/userApi';
import { GroupInterface, UserInterface } from '../../../profile/user';
import { getConstantFromLocalStorage } from '../../../utils';
import { LS_COMPANY_CUSTOMER_ID } from '../../../constants';
import CenteredSpinner from '../../BaseComponents/CenteredSpinner';
import { refreshGroups } from '../../../store/actions/profile';
import { addNotification } from '../../../store/notifications';
import useHtmlId from '../../../hooks/useHtmlId';
import { VaiColor, VaiIcon } from '@vaisala/rockhopper-design-tokens';
import './delete-user-groups-dialog.scss';
import { QA_TEST_IDS } from '../../../tests/qaTestIds';

interface Props {
  user: UserInterface;
  groups: GroupInterface[];
}

const htmlId = 'remove-user-groups-dialog';

const DeleteUserGroupsDialog = ({ user, groups }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { getId } = useHtmlId({ htmlId });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [deleteUserGroups] = useDeleteUserGroupsMutation();
  const customerId: string = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID) ?? '';
  const username: string =
    user.first_name && user.last_name ? `${user.first_name.trim()} ${user.last_name.trim()}` : user.email ?? '';

  const deleteUserGroupsImpl = async () => {
    setLoading(true);
    const errors: (GroupInterface | [any, GroupInterface])[] = [];
    const successes: GroupInterface[] = [];
    await Promise.all(
      // For each group, manually trigger the deleteUserGroups method and catch any errors that may be produced
      groups.map(async group => {
        const { group_id } = group;
        const request = deleteUserGroups({ customerId, email: user.email ?? '', groupId: group_id });
        try {
          const response = await request.unwrap();
          // push the group into the successes list to make the success toaster below
          if ((response.accepted?.length || 0) > 0) {
            successes.push(group);
          }
          // push the current error and group to produce error toaster below
          if ((response.rejected?.length || 0) > 0) {
            errors.push(group);
          }
        } catch (e) {
          errors.push([e, group]);
          console.error(e);
        }
      })
    );
    setLoading(false);
    if (successes.length) {
      dispatch(
        addNotification({
          type: NotificationType.Ok,
          content: t('profile.deleteUserGroupsSuccessToaster', { count: successes.length, username })
        })
      );
    }
    if (errors.length) {
      dispatch(
        addNotification({
          type: NotificationType.Error,
          content: t('profile.deleteUserGroupsErrorToaster', { count: errors.length, username })
        })
      );
    }
    // TODO: Remove this when refactoring User component to use RTK. For now we trigger the groups to refresh by setting groupRefresh in the store to true.
    dispatch(refreshGroups());
    // Close the dialog when we're done
    onDismiss();
  };

  const onDismiss = () => dispatch(hideDialog());

  const confirmButtonId = getId('confirm-button');
  const cancelButtonId = getId('cancel-button');
  const modalButtons = (
    <>
      <Button id={confirmButtonId} onClick={deleteUserGroupsImpl} buttonType={ButtonType.Secondary} disabled={loading}>
        {loading ? <CenteredSpinner htmlId={`${confirmButtonId}-spinner`} className="" /> : t('profile.remove')}
      </Button>
      <Button id={cancelButtonId} dataTa={TEST_IDS.cancel_button} onClick={onDismiss}>
        {t('general.cancel')}
      </Button>
    </>
  );

  return (
    <ModalContainer
      dataTa={TEST_IDS.group_delete_dialog}
      id={htmlId}
      isOpen
      showCloseIcon={true}
      onDismiss={onDismiss}
      width={Size.M}
      dismissOnOverlayClick={false}
      title="profile.deleteUserGroupsHeading"
      role="alert-dialog"
      buttons={modalButtons}
    >
      <BodyText id={getId('body-text')}>
        <Stack className="delete-user-groups-stack">
          <div id={getId('msg-1')}>{t('profile.deleteUserGroupsMsg', { username })}</div>
          <ul id={getId('group-list')} className="delete-user-groups-list">
            {groups.map(group => (
              <li key={group.group_id}>{group.group_name}</li>
            ))}
          </ul>
          {groups.length === user.groups?.length && (
            <Flex alignItems="center" className="alert-red">
              <FlexItem>
                <Icon
                  htmlId={getId('user-with-no-groups-icon')}
                  name={VaiIcon.AlertAlarm}
                  color={VaiColor.AlertError}
                  size={Size.L}
                  className="vai-margin-right-s"
                />
              </FlexItem>
              <FlexItem id={getId('user-with-no-groups-msg')} flexGrow={1}>
                {t('profile.userWithNoGroupsWillNotHaveAccess', { username })}
              </FlexItem>
            </Flex>
          )}
        </Stack>
      </BodyText>
    </ModalContainer>
  );
};

export default DeleteUserGroupsDialog;
