import * as React from 'react';
import { Size, Modal, Heading, Button, Flex } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';

import './confirmation-dialog.scss';

type ConfirmationDialogProps = {
  isVisible: boolean;
  onDismiss: () => void;
};

const ConfirmationDialog = (props: ConfirmationDialogProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div data-ta="confirmation-dialog-id">
      <Modal
        id="confirmation-dialog-id"
        htmlId="confirmation-dialog-id"
        isOpen={props.isVisible}
        showCloseIcon={true}
        onDismiss={props.onDismiss}
        width={Size.M}
        dismissOnOverlayClick={false}
      >
        <Heading className="vai-margin-top-none confirmation-header">{t('reports.isBeingGenerated')}</Heading>

        <Flex flexDirection="column" className="confirmation-content">
          <p>{t('reports.linkWillBeEmailed')}</p>
          <p>{t('reports.mayTakeFewMinutes')}</p>
        </Flex>

        <Flex className="vai-margin-top-m confirmation-bottom-bar" justifyContent="center" alignItems="center">
          <Button
            id="confirmation-confirm"
            dataTa="confirmation-confirm"
            htmlId="confirmation-confirm"
            onClick={props.onDismiss}
          >
            {t('general.ok')}
          </Button>
        </Flex>
      </Modal>
    </div>
  );
};

export default ConfirmationDialog;
