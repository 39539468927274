import React from 'react';
import { TEST_IDS } from '../../../../tests/testids';
import { QA_TEST_IDS } from '../../../../tests/qaTestIds';

interface Props {
  collectedErrors: string[];
}

export const ErrorsDisplay: React.FC<Props> = ({ collectedErrors }) => {
  return (
    <div
      id={QA_TEST_IDS.alarms_settings_threshold_levels_error_display}
      data-ta={TEST_IDS.alarm_setting_form_error_display}
    >
      {collectedErrors.map(label => {
        return (
          <p key={label} className="alert-red">
            {label}
          </p>
        );
      })}
    </div>
  );
};
