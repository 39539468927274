import * as React from 'react';
import { DataTable, DataTableColumn, TableSortDirection } from '@vaisala/rockhopper-data-table';
import { DEVICE_TYPE, LARGE_SCREEN_IN_PX, MEDIUM_SCREEN_SIZE_IN_PX, SMALL_SCREEN_SIZE_IN_PX } from '../../constants';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { EventsNotFound } from './EventsNotFound';
import { TEST_IDS } from '../../tests/testids';
import { QA_TEST_IDS } from '../../tests/qaTestIds';
import cx from 'classnames';

import './event-table.scss';
import { EventInterface } from '../../events/events';

interface EventsTableProps {
  rows: EventInterface[];
  setRows: React.Dispatch<React.SetStateAction<EventInterface[]>>;
  isLoading: boolean;
  deviceType?: string;
  activeRowIndex: number | null;
  setActiveRowIndex: React.Dispatch<React.SetStateAction<number | null>>;
  updateRowVisibleEndIdx: (rowIndex: number) => void;
  showDetailView: boolean;
  handleOnRowClick: (rowIdx: number, row: any) => void;
  minHeight: number;
}

/**
 * Used to match cell.name as Union of specific strings instead of just string so translation types won't break.
 *
 * Always update this list of keys to match headerCells.
 *
 * Const assertion would be better on the headerCells to not need this manual Union. But the datagrid library makes the headerCells mutable and that causes issues with as const as it's read-only.
 */
interface DataTableColumnStrictKey extends DataTableColumn {
  name: 'object' | 'action' | 'source' | 'category' | 'location' | 'parameters' | 'time';
}

const headerCells: DataTableColumnStrictKey[] = [
  { key: 'object', sortable: true, name: 'object' },
  { key: 'action', sortable: true, name: 'action' },
  { key: 'source', sortable: false, name: 'source' },
  { key: 'category', sortable: false, name: 'category' },
  { key: 'affectedlocation', sortable: false, name: 'location' },
  { key: 'parameters', sortable: false, name: 'parameters' },
  { key: 'time', sortable: false, name: 'time' }
];

export const ROW_SELECTED_CLASS = 'row-selected';

const EventsTable = (props: EventsTableProps): JSX.Element => {
  const {
    rows,
    setRows,
    deviceType,
    activeRowIndex,
    setActiveRowIndex,
    isLoading,
    updateRowVisibleEndIdx,
    showDetailView,
    handleOnRowClick,
    minHeight
  } = props;
  const { t } = useTranslation();

  const filterCellsByDeviceType = () => {
    let cells = headerCells;
    if (deviceType && [DEVICE_TYPE[LARGE_SCREEN_IN_PX], DEVICE_TYPE[MEDIUM_SCREEN_SIZE_IN_PX]].includes(deviceType)) {
      cells = cells.filter(cell => {
        return cell.key !== 'affectedlocation' && cell.key !== 'parameters';
      });
    } else if (deviceType && [DEVICE_TYPE[SMALL_SCREEN_SIZE_IN_PX]].includes(deviceType)) {
      cells = cells.filter(cell => {
        return (
          cell.key !== 'affectedlocation' &&
          cell.key !== 'parameters' &&
          cell.key !== 'category' &&
          cell.key !== 'source'
        );
      });
    }
    return cells;
  };

  const [tableHeaders, setTableHeaders] = React.useState(filterCellsByDeviceType());

  React.useEffect(() => {
    if (!showDetailView) {
      setTableHeaders(filterCellsByDeviceType());
    }
  }, [showDetailView]);

  React.useEffect(() => {
    setTableHeaders(filterCellsByDeviceType());
  }, [deviceType]);

  const rowGetter = (rowIdx: number) => {
    const row: any = { ...rows[rowIdx] };

    if (row?.parameters && row.parameters.length > 0) {
      const parametersStringArr = row.parameters.map((arr: any[]) => {
        return arr[0];
      });
      row.parameters = parametersStringArr.join(', ');
    }
    return row;
  };
  const onSort = (column: string, direction: TableSortDirection) => {
    const multiplier = direction === 'DESC' ? -1 : 1;
    let currRow: EventInterface;
    const typedColumn = column as 'object' | 'action';
    if (activeRowIndex !== null) {
      currRow = rows[activeRowIndex];
    }
    const newRows = rows
      .slice()
      .sort((a, b) => (a[typedColumn] == b[typedColumn] ? 0 : a[typedColumn] < b[typedColumn] ? 1 : -1) * multiplier);
    setRows(newRows);
    setActiveRowIndex(newRows.findIndex(row => row.eventId === currRow?.eventId));
  };

  const handleOnScroll = (scrollState: any) => {
    const { rowVisibleEndIdx } = scrollState;
    const isLastRow = rowVisibleEndIdx === rows.length;
    if (isLoading || !isLastRow) {
      return;
    }

    updateRowVisibleEndIdx(rowVisibleEndIdx);
  };

  const handleRowClick = (rowIdx: number, row: any) => {
    if (rowIdx < 0) {
      // when clicking on the column header row, do nothing.
      return;
    }
    handleOnRowClick(rowIdx, row);

    const headers = [...tableHeaders];
    const filteredHeaders = headers.filter(header => {
      return ['object', 'action', 'time', 'source'].includes(header.key);
    });
    setTableHeaders(filteredHeaders);
  };
  /* eslint-disable react/prop-types */
  const rowRenderer = ({ renderBaseRow, ...props }: any) => {
    const rowStyle = cx('vai-table-custom-row', { [ROW_SELECTED_CLASS]: props.idx === activeRowIndex });
    return (
      <div data-ta={TEST_IDS.custom_row} className={rowStyle}>
        {renderBaseRow({ ...props })}
      </div>
    );
  };

  const isNotFound = isEmpty(rows) && !isLoading;
  /* eslint-enable react/prop-types */
  return (
    <>
      {!isNotFound ? (
        <DataTable
          columns={tableHeaders.map(cell => {
            return { ...cell, name: t(`events.table.header.${cell.name}`) };
          })}
          dataTa={TEST_IDS.events_table}
          htmlId={QA_TEST_IDS.events_table}
          rowGetter={rowGetter}
          rowsCount={rows?.length}
          onSort={onSort}
          minColumnWidth={150}
          rowRenderer={rowRenderer}
          minHeight={minHeight}
          onScroll={handleOnScroll}
          onRowClick={handleRowClick}
          enableCellSelect={false}
          hasFocusBorder={false}
          debounceVerticalScrollbar={true}
        />
      ) : (
        <EventsNotFound />
      )}
      {isLoading && <div className={'loadMoreRows'}> {t('events.table.loading')}</div>}
    </>
  );
};

export default EventsTable;
