import React from 'react';
import { Grid } from '@vaisala/rockhopper-components';
import CenteredSpinner from '../../../BaseComponents/CenteredSpinner';
import './alarmSettingsDisplayContainer.scss';

interface Props {
  isLoading: boolean;
}

export const AlarmSettingsDisplayContainer: React.FC<Props> = ({ isLoading, children }) => {
  return <Grid className="alarm-settings-display-container">{isLoading ? <CenteredSpinner /> : <>{children}</>}</Grid>;
};
