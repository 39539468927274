import React from 'react';
import { useStepsContext } from './StepsContextProvider';

interface Props {
  steps: React.ReactElement<any, React.NamedExoticComponent<object>>[];
}
export const CurrentStep: React.FC<Props> = ({ steps }) => {
  const { currentStepIndex } = useStepsContext();
  return steps[currentStepIndex];
};
