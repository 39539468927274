/* eslint-disable react/prop-types */
import * as React from 'react';
import { AccordionList, Accordion, Flex, Checkbox, Icon, Size } from '@vaisala/rockhopper-components';

import './accordion-tree.scss';
import { LocationTreeFormattedNode } from '../Locations';
import {
  DEVICES_MEAS_ID,
  LOCATION_KEY,
  LS_COMPANY_CUSTOMER_ID,
  PARENT_SITE_KEY,
  SiteNodeTypes
} from '../../../constants';
import { reportsDispatchActions, StoreState } from '../../../store';
import { connect } from 'react-redux';
import { VaiColor, VaiIcon } from '@vaisala/rockhopper-design-tokens';
import {
  filterInactiveLocations,
  getConstantFromLocalStorage,
  isLocationNode,
  isSiteNode,
  isZoneNode,
  modifyResponseData
} from '../../../utils/common';
import TruncatedText from '../../Utils/TruncatedText/TruncatedText';
import { useTranslation } from 'react-i18next';
import { useGetSiteNodesQuery } from '../../../store/services/reportsApi';
import CenteredSpinner from '../../BaseComponents/CenteredSpinner';
import { EmptyState } from '../../Utils/EmptyState/EmptyState';
import { IllustrationType } from '../../Utils/EmptyState/Illustration';
import useUserSettings from '../../../hooks/useUserSettings';
import _ from 'lodash';
import { LocationNode, ZoneNode, SiteNode } from '../../../siteTree/site';
import { ReportState } from '../../../store/reducers/reports';

type AccordionTreeProps = {
  locationsTree: { [key: string]: LocationTreeFormattedNode };
  setLocationsTree: React.Dispatch<React.SetStateAction<{ [key: string]: LocationTreeFormattedNode }>>;
  setLocationsCount: React.Dispatch<React.SetStateAction<number>>;
  locationsCount: number;
} & ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

type SitesChildren = {
  [key: string]: SitesChildren;
};

const AccordionTree: React.FunctionComponent<AccordionTreeProps> = ({
  locationsTree,
  setLocationsTree,
  setLocationsCount,
  locationsCount,
  setReportSelectedFormattedLocations,
  setReportLocations,
  selectedFormattedLocations
}) => {
  const { t } = useTranslation();
  const [sitesChildrenCount, setSitesChildrenCount] = React.useState<SitesChildren>({});

  const [formattedData, setFormattedData] = React.useState<{
    [key: string]: LocationTreeFormattedNode;
  }>(locationsTree);

  const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID) ?? '';
  const { username } = useUserSettings();

  const { data: sites, isFetching } = useGetSiteNodesQuery(
    { customerId, siteId: PARENT_SITE_KEY, username: btoa(username) },
    { skip: !username || username === 'undefined', refetchOnMountOrArgChange: true }
  );

  React.useEffect(() => {
    if (sites && sites.length > 0 && Object.keys(sites[0]).length > 0) {
      const filteredSites = sites.map(site => {
        const filteredChildren = filterInactiveLocations(site.children);
        return { ...site, children: filteredChildren };
      });
      setReportLocations(filteredSites);

      const formattedData = modifyResponseData(filteredSites, selectedFormattedLocations);
      setFormattedData(formattedData);
      setReportSelectedFormattedLocations(formattedData);

      setSitesChildrenCount(calculateSiteChildrenCount(filteredSites));
    }
  }, [sites]);

  React.useEffect(() => {
    setLocationsTree(formattedData);
  }, [formattedData]);

  React.useEffect(() => {
    if (Object.keys(locationsTree).length > 0) {
      setFormattedData(_.cloneDeep(locationsTree));
    }
  }, [locationsTree]);

  const AccordionTitle = ({
    node,
    className,
    noChildren,
    showLocNum
  }: {
    node: any;
    className?: string;
    noChildren?: boolean;
    showLocNum?: boolean;
  }) => {
    const wrapper = React.useRef<HTMLDivElement | null>(null);
    const [showCheckbox, setShowCheckbox] = React.useState<boolean>(false);

    const onCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
      const formattedDataClone = JSON.parse(JSON.stringify(formattedData));

      let neededNode: { [key: string]: any } = {};
      const parentNodes: { [key: string]: any }[] = [];

      node.hierarchy.forEach((id: string, index: number) => {
        if (index !== node.hierarchy.length - 1) {
          parentNodes.unshift(Object.keys(neededNode).length > 0 ? neededNode.children[id] : formattedDataClone[id]);
        }

        neededNode = Object.keys(neededNode).length > 0 ? neededNode.children[id] : formattedDataClone[id];
      });

      // Select or deselect nodes recursively
      const [newNode, count] = changeSelectionNodeAndChildren(neededNode, event.target.checked);
      neededNode = newNode;

      const siteCount = count > 0 ? count : 0;
      setLocationsCount(neededNode.type === SiteNodeTypes.SITE ? siteCount : count);

      // Rerender all parent nodes not just the adjacent parent
      parentNodes.forEach(n => {
        areAllChildrenSelected(n);
      });

      setFormattedData(formattedDataClone);
    };

    const preventDefaultAndStopPropagation = (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const changeSelectionNodeAndChildren = (node: any, state: boolean, locationsNum = locationsCount) => {
      const prevNodeState = node.selected;
      node.selected = state;

      if (node.type === LOCATION_KEY && prevNodeState !== state) {
        state ? locationsNum++ : locationsNum--;
      }

      if (Object.keys(node.children).length > 0) {
        Object.values(node.children).forEach(n => {
          const [children, num] = changeSelectionNodeAndChildren(n, state, locationsNum);

          node = {
            ...node,
            children: { ...children }
          };

          locationsNum = num;
        });
      }

      return [node, locationsNum];
    };

    const changeCheckingState = (
      node: LocationTreeFormattedNode,
      children: LocationTreeFormattedNode[],
      selectedNum: number,
      partialSelectedNum: number
    ) => {
      if (selectedNum === children.length) {
        // All children are checked
        wrapper?.current?.classList.remove('partially-checked');
        node.partialSelected = false;
        node.selected = true;
        return true;
      } else if (selectedNum !== 0 || partialSelectedNum > 0) {
        // Partially-checked children
        node.selected = false;
        node.partialSelected = true;
        wrapper?.current?.classList.add('partially-checked');
        return false;
      } else if (selectedNum === 0) {
        // No children selected
        wrapper?.current?.classList.remove('partially-checked');
        node.partialSelected = false;
        node.selected = false;
        return false;
      }
    };

    const areAllChildrenSelected = (n = node) => {
      const children: LocationTreeFormattedNode[] = Object.values(_.cloneDeep(n.children));

      let selectedChildrenNum = 0;
      let partialSelectedNum = 0;

      children.forEach(child => {
        if (Object.keys(child.children).length > 0) {
          areAllChildrenSelected(child);
        }

        if (child.selected) {
          selectedChildrenNum++;
        } else if (child.partialSelected) {
          partialSelectedNum++;
        }
      });

      return children.length > 0 ? changeCheckingState(n, children, selectedChildrenNum, partialSelectedNum) : false;
    };

    const renderIcon = () => {
      if (node.type === SiteNodeTypes.SITE) {
        return <Icon name={VaiIcon.MapMarkerHexagon} color={VaiColor.BlueDark} size={Size.M} />;
      } else if (node.type === LOCATION_KEY) {
        switch (node.meas_id) {
          case DEVICES_MEAS_ID.TEMPERATURE:
            return <Icon name={VaiIcon.Thermometer} color={VaiColor.BlueDark} size={Size.M} />;
          case DEVICES_MEAS_ID.HUMIDITY:
            return <Icon name={VaiIcon.Humidity} color={VaiColor.BlueDark} size={Size.M} />;
          case DEVICES_MEAS_ID.CO2:
            return <Icon name={VaiIcon.Dust} color={VaiColor.BlueDark} size={Size.M} />;
          default:
            return;
        }
      }

      return <></>;
    };

    React.useEffect(() => {
      // Make sure that the wrapper ref is not null
      if (!showCheckbox) {
        setShowCheckbox(true);
      }
    }, []);

    return (
      <Flex
        alignItems="center"
        justifyContent="space-between"
        className={`${noChildren && `last-child-wrapper`} ${className}`}
        style={{ width: '100%' }}
      >
        <div ref={wrapper} onClick={preventDefaultAndStopPropagation} className="checkbox-wrapper">
          {showCheckbox && (
            <Checkbox
              id={`checkbox`}
              name={node.node_id}
              onMouseDown={preventDefaultAndStopPropagation}
              checked={areAllChildrenSelected() || node.selected}
              onChange={onCheck}
            />
          )}
          <Flex alignItems="center" className={`node-title ${node.type === SiteNodeTypes.SITE && 'site-node'}`}>
            {renderIcon()}
            <TruncatedText text={node.name} className="node-text" />
          </Flex>
        </div>

        {showLocNum && (
          <Flex className="vai-accordion__summary" style={{ alignSelf: 'flex-start' }}>
            {calculateLocationsNumForNode(node)} {t('reports.locations')}
          </Flex>
        )}
      </Flex>
    );
  };

  // Expected output {"SITE_ID": {"ZONE_ID": NUMBER, "ZONE_ID": NUMBER}}
  const calculateSiteChildrenCount = (
    data: LocationNode[] | SiteNode[] | (ZoneNode | LocationNode)[],
    count: any = {}
  ) => {
    if (data.length === 0) {
      count['count'] = 0;
    }

    data.forEach((node: LocationNode | SiteNode | ZoneNode) => {
      if (isLocationNode(node)) {
        count['count'] = ((count['count'] as number) || 0) + 1;
      } else if (isSiteNode(node)) {
        count[node.node_id] = { ...calculateSiteChildrenCount(node.children, {}) };
      } else if (isZoneNode(node)) {
        count[node.node_id] = { ...calculateSiteChildrenCount(node.children, {}) };
      } else {
        count['count'] = 0;
      }
    });

    return count;
  };

  const calculateLocationsNumForNode = (node: LocationTreeFormattedNode, sitesChildren = sitesChildrenCount) => {
    let count = 0;

    if (Object.keys(sitesChildren).length > 0 && node) {
      let currentNode: any;

      node.hierarchy.forEach(id => {
        currentNode = currentNode ? currentNode[id] : sitesChildren[id];
      });

      if (node.type !== SiteNodeTypes.SITE && currentNode.count) {
        count += currentNode.count;
      }

      const childrenNodeCount = Object.keys(currentNode);

      if (childrenNodeCount.length > 0) {
        childrenNodeCount.forEach(key => {
          if (key !== 'count') {
            count += calculateLocationsNumForNode(node.children[key], sitesChildren[node.node_id]);
          }
        });
      }
    }

    return count;
  };

  const renderTree = (data: { [key: string]: LocationTreeFormattedNode }) => {
    return Object.values(data).map((node: LocationTreeFormattedNode) => {
      if (Object.keys(node.children).length > 0) {
        return (
          <AccordionList
            key={node.node_id}
            defaultKeys={node.type === SiteNodeTypes.SITE ? node.node_id : undefined}
            exclusive={false}
            htmlId="locations-tree"
          >
            {node.show && (
              <Accordion
                key={node.node_id}
                accordionKey={node.node_id}
                title={<AccordionTitle node={node} />}
                summary={`${calculateLocationsNumForNode(node)} ${t('reports.locations')}`}
                className={node.type === SiteNodeTypes.SITE ? 'site-header' : ''}
              >
                {renderTree(node.children)}
              </Accordion>
            )}
          </AccordionList>
        );
      } else {
        return (
          node.show && (
            <AccordionTitle
              node={node}
              key={node.node_id}
              noChildren
              {...(node.type === SiteNodeTypes.ZONE ? { showLocNum: true } : {})}
            />
          )
        );
      }
    });
  };

  return (
    <>
      {Object.keys(formattedData).length > 0 && !isFetching ? (
        renderTree(formattedData)
      ) : isFetching ? (
        <CenteredSpinner />
      ) : (
        <EmptyState heading="site.noSiteRights" illustration={IllustrationType.nothingSelected} />
      )}
    </>
  );
};

const mapStateToProps = ({ reports }: StoreState) => ({
  selectedFormattedLocationsNum: reports.selectedLocationsNum,
  selectedFormattedLocations: reports.selectedFormattedLocations
});

const mapDispatchToProps = (dispatch: any) => ({
  setReportSelectedFormattedLocations: (locations: ReportState['selectedFormattedLocations']) =>
    dispatch(reportsDispatchActions.setReportSelectedFormattedLocations(locations)),
  setReportLocations: (locations: ReportState['locations']) =>
    dispatch(reportsDispatchActions.setReportLocations(locations))
});

export default connect(mapStateToProps, mapDispatchToProps)(AccordionTree);
