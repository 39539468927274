import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonType,
  Flex,
  Form,
  Heading,
  Icon,
  Select,
  SelectProps,
  Size,
  Stack
} from '@vaisala/rockhopper-components';
import { DataTable, DataTableColumn, TableSortDirection } from '@vaisala/rockhopper-data-table';
import { TranslationKey } from '../../react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  convertLocalTimeToUTC,
  convertToMiliSeconds,
  getConstantFromLocalStorage,
  getNodeTypeTranslationKey,
  roundHalfToEven,
  routes,
  sortDuration,
  sortNumber,
  sortPriority,
  sortType,
  timestampToTimeString,
  userSettings
} from '../../utils';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { EmptyState } from '../Utils/EmptyState/EmptyState';
import { IllustrationType } from '../Utils/EmptyState/Illustration';
import { useResizeObserver } from '../../hooks/useResizeObserver';
import {
  AlarmTypeFormatter,
  PriorityIconFormatter,
  TimesinceFormatter,
  ValueWithUnitFormatter
} from './SiteTableFormatters';
import {
  ALARM_PRIORITY,
  ALARM_THRESHOLD_TYPE,
  ALARM_UX_STATUS,
  ALARM_UX_STATUS_VALUE_TRANSLATION_MAP,
  AckRequirement,
  AlarmAcknowledgementTranslationKey,
  AlarmCategoryTranslationKey,
  AlarmTicket,
  DEVICE_ALARM_RULE_NAMES,
  DeviceSymbolUnitIds,
  LS_COMPANY_CUSTOMER_ID,
  LS_USERNAME,
  PARENT_SITE_KEY,
  SiteNodeTypes,
  UserSettings
} from '../../constants';

import './index.scss';
import { showDialog } from '../../store/dialog';
import AlarmDetailsModal from './AlarmDetails/AlarmDetailsModal';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { TEST_IDS } from '../../tests/testids';
import makeTestId, { IMakeTestIdProps } from '../../utils/makeTestId';
import { AlarmType, ICurrentAlarmsArgs, IHistoricalAlarmsArgs, alarmsApi } from '../../store/services/alarmsApi';
import { useInfiniteQuery } from '../../hooks/useInfiniteQuery';
import CenteredSpinner from '../BaseComponents/CenteredSpinner';
import { alarmSubTypeTranslationKeys, thresholdLevelTranslationKeys } from './Setting/ThresholdAlarmAccordion/shared';
import TimeContext from '../../context/TimeContext';
import useDateFilterOptions, { NullableDateFilterOptions } from '../../hooks/useDateFilterOptions';
import { isValid, set } from 'date-fns';
import _ from 'lodash';
import { selectTimeZone } from '../../store/profile';
import { PriorityTranslationKeyMap } from './Alarms/shared/PrioritySelector';
import { deviceRuleNameTranslations } from './Setting/DeviceAlarmAccordion/DeviceAlarmSettingsDialog/shared';
import { ColumnEventCallback } from '@vaisala/rockhopper-data-table/dist/esm/DataTable';

interface GenericMultiSelectProps<T extends string | number | symbol>
  extends IMakeTestIdProps,
    Omit<SelectProps<T[]>, 'dataTa'> {
  optionsMap: Record<T, TranslationKey>;
  label?: TranslationKey;
  containerClasses?: string;
}

export function GenericMultiSelect<T extends string | number | symbol>(props: GenericMultiSelectProps<T>) {
  const { dataTa, label, optionsMap, containerClasses, ...selectProps } = props;
  const { getTestId } = makeTestId({ dataTa });
  const { t } = useTranslation();

  return (
    <div data-ta={getTestId(TEST_IDS.container)} className={containerClasses}>
      {label && <label className="strong">{t(label)}</label>}
      <Select<T[]>
        showSearch={false}
        maxTagCount={0}
        maxTagPlaceholder={props?.value ? t('general.numSelected', { count: props.value?.length || 0 }) : undefined}
        placeholder={t('general.any')}
        dropdownMatchSelectWidth={false}
        dataTa={getTestId(TEST_IDS.select)}
        mode="multiple"
        allowClear
        {...selectProps}
      >
        {Object.entries(optionsMap).map(([option, translationKey]) => (
          <Select.Option key={option} dataTa={getTestId(TEST_IDS.option)} value={option}>
            {t(translationKey as TranslationKey)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

interface Props extends IMakeTestIdProps {
  onError: (error: any) => void;
}

export const HISTORICAL_ALARMS_DATA_TA = 'historical-alarms';

export interface AlarmsFilterOptions extends NullableDateFilterOptions {
  status: ALARM_UX_STATUS[];
  priority: ALARM_PRIORITY[];
  type: AlarmType[];
}

const getInitialFilterOptions = (username: string): AlarmsFilterOptions => {
  const fromSetting: string | null | undefined = userSettings.get(username, UserSettings.ALARMS_FROM_DATE);
  let fromDate: Date | null = null;
  if (fromSetting) {
    const systemDate = new Date(fromSetting);
    if (isValid(systemDate)) {
      fromDate = set(systemDate, { seconds: 0, milliseconds: 0 });
    }
  }

  const toSetting: string | null | undefined = userSettings.get(username, UserSettings.ALARMS_TO_DATE);
  let toDate: Date | null = null;
  if (toSetting) {
    const systemDate = new Date(toSetting);
    if (isValid(systemDate)) {
      toDate = set(systemDate, { seconds: 0, milliseconds: 0 });
    }
  }
  const status: ALARM_UX_STATUS[] =
    (userSettings.getCommaSeparated(
      username,
      UserSettings.ALARMS_STATUS,
      Object.values(ALARM_UX_STATUS)
    ) as ALARM_UX_STATUS[]) || [];
  const priority: ALARM_PRIORITY[] =
    (userSettings.getCommaSeparated(
      username,
      UserSettings.ALARMS_PRIORITY,
      Object.values(ALARM_PRIORITY)
    ) as ALARM_PRIORITY[]) || [];
  const typeValues: AlarmType[] = [...Object.values(ALARM_THRESHOLD_TYPE), ...Object.values(DEVICE_ALARM_RULE_NAMES)];
  const type: AlarmType[] =
    (userSettings.getCommaSeparated(username, UserSettings.ALARMS_TYPE, typeValues) as AlarmType[]) || [];
  return { fromDate, toDate, status, priority, type };
};

export const AlarmsList: React.FC<Props> = ({ onError, dataTa }) => {
  const { id = PARENT_SITE_KEY, showAlarmDetail, ...rest } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const topRef = React.useRef<HTMLDivElement>(null);
  const customer_id = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID) ?? '';
  const username = getConstantFromLocalStorage(LS_USERNAME) ?? '';
  const timezone = useAppSelector(selectTimeZone);
  const { t } = useTranslation();
  const { getTestId } = makeTestId({ dataTa: dataTa || HISTORICAL_ALARMS_DATA_TA });
  const isZone = id.startsWith('ZN');
  const isSite = id.startsWith('ST');
  const { today } = React.useContext(TimeContext);
  const { fromDate, toDate, status, priority, type } = getInitialFilterOptions(username);
  const [selectedStatus, setSelectedStatus] = React.useState<ALARM_UX_STATUS[]>(status);
  const [selectedPriority, setSelectedPriority] = React.useState<ALARM_PRIORITY[]>(priority);
  const [selectedType, setSelectedType] = React.useState<AlarmType[]>(type);

  const [, headerHeight] = useResizeObserver(topRef, 0, false);

  const initial: NullableDateFilterOptions = {
    fromDate,
    toDate
  };
  const onChangeFromDate = (newFromDate: Date) => {
    userSettings.set(username, UserSettings.ALARMS_FROM_DATE, newFromDate.toISOString());
  };
  const onChangeFromTime = (newFromDate: Date) => {
    userSettings.set(username, UserSettings.ALARMS_FROM_DATE, newFromDate.toISOString());
  };
  const onChangeToDate = (newToDate: Date) => {
    userSettings.set(username, UserSettings.ALARMS_TO_DATE, newToDate.toISOString());
  };
  const onChangeToTime = (newToDate: Date) => {
    userSettings.set(username, UserSettings.ALARMS_TO_DATE, newToDate.toISOString());
  };
  const { filterOptions, renderFrom, renderTo } = useDateFilterOptions({
    today,
    initial,
    onChangeFromDate,
    onChangeFromTime,
    onChangeToDate,
    onChangeToTime,
    dataTa: getTestId()
  });
  // NOTE: store the filters that have been applied and only update them when the apply button is pressed. This is to avoid the query
  // from being triggered automatically when any of the filters change. The reason it is done this way is because if we change the skip
  // attribute back to true, the data parameter for the query is reset.
  const [appliedFilters, setAppliedFilters] = React.useState<AlarmsFilterOptions>({
    ...filterOptions,
    status,
    priority,
    type
  });
  const nodeType = isZone ? SiteNodeTypes.ZONE : isSite ? SiteNodeTypes.SITE : SiteNodeTypes.LOCATION;
  const { isLoading, isFetching, data: { tickets: alarms = [] } = { tickets: [] }, error } = useInfiniteQuery<
    IHistoricalAlarmsArgs
  >(
    {
      customer_id,
      node_id: id,
      from: appliedFilters.fromDate && convertToMiliSeconds(convertLocalTimeToUTC(appliedFilters.fromDate, timezone)),
      to:
        appliedFilters.toDate &&
        set(convertToMiliSeconds(convertLocalTimeToUTC(appliedFilters.toDate, timezone)), {
          seconds: 59,
          milliseconds: 999
        }).getTime(),
      priority: appliedFilters?.priority?.length > 0 ? appliedFilters.priority : null,
      status: appliedFilters?.status?.length > 0 ? appliedFilters.status : null,
      type: appliedFilters?.type?.length > 0 ? appliedFilters.type : null
    },
    alarmsApi,
    'getHistoricalAlarms',
    true
  );

  React.useEffect(() => {
    if (showAlarmDetail === 'true') {
      dispatch(showDialog(<AlarmDetailsModal ticketId={id} />));
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    userSettings.set(username, UserSettings.WAS_VIEWING_HISTORICAL_ALARMS, true);
  }, [username]);

  React.useEffect(() => {
    if (error) {
      onError?.(error);
    }
  }, [error]);

  const handleClick = () => {
    const idPath = id && isSite ? '' : `/${id}`;
    navigate(`${routes.site.url}/${routes.site.alarms.url}${isZone ? '/zone' : ''}${idPath}`);
  };

  const allFilterOptions = { ...filterOptions, status: selectedStatus, priority: selectedPriority, type: selectedType };

  const handleApply = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    setAppliedFilters(allFilterOptions);
  };

  const showCurrentAlarmsDataTa = getTestId(TEST_IDS.show_current);

  const canApply = !_.isEqual(allFilterOptions, appliedFilters);

  const renderFilterBar = () => {
    const applyButtonDataTa = getTestId(TEST_IDS.apply_button);
    const fromPickersWrapperDataTa = getTestId(TEST_IDS.from_pickers_wrapper);
    const toPickersWrapperDataTa = getTestId(TEST_IDS.to_pickers_wrapper);
    const startDateLabelDataTa = getTestId(`${TEST_IDS.start_date}--${TEST_IDS.label}`);
    const endDateLabelDataTa = getTestId(`${TEST_IDS.end_date}--${TEST_IDS.label}`);
    return (
      <div data-ta={getTestId(TEST_IDS.filter_bar)} className="bg-grey-light vai-margin-top-m vai-padding-s">
        <Flex className="vai-padding-horizontal-s" flexWrap="wrap">
          <Form.Item
            label={
              <div data-ta={startDateLabelDataTa} className="strong">
                {t('general.startTimeFrom')}
              </div>
            }
            className="flex-direction-column margin-bottom-s--forced"
          >
            <Flex dataTa={fromPickersWrapperDataTa}>{renderFrom()}</Flex>
          </Form.Item>
          <Form.Item
            label={
              <div data-ta={endDateLabelDataTa} className="strong">
                {t('events.filterBar.to')}
              </div>
            }
            className="flex-direction-column margin-bottom-s--forced"
          >
            <Flex dataTa={toPickersWrapperDataTa}>{renderTo()}</Flex>
          </Form.Item>
          <Flex className="alarm-filter-row">
            <Flex.Item className="vai-padding-right-s">
              <GenericMultiSelect<AlarmType>
                containerClasses="margin-top-s--forced"
                dataTa={getTestId(TEST_IDS.alarm_type)}
                label="general.type"
                optionsMap={{ ...thresholdLevelTranslationKeys, ...deviceRuleNameTranslations }}
                value={selectedType}
                defaultValue={type}
                onChange={(value: AlarmType[]) => {
                  userSettings.set(username, UserSettings.ALARMS_TYPE, value.join(','));
                  setSelectedType(value);
                }}
              />
            </Flex.Item>
            <Flex.Item className="vai-padding-right-s">
              <GenericMultiSelect<ALARM_UX_STATUS>
                containerClasses="margin-top-s--forced"
                dataTa={getTestId(TEST_IDS.status)}
                label="site.status"
                optionsMap={ALARM_UX_STATUS_VALUE_TRANSLATION_MAP}
                value={selectedStatus}
                defaultValue={status}
                onChange={(value: ALARM_UX_STATUS[]) => {
                  userSettings.set(username, UserSettings.ALARMS_STATUS, value.join(','));
                  setSelectedStatus(value);
                }}
              />
            </Flex.Item>
            <Flex.Item>
              <GenericMultiSelect<ALARM_PRIORITY>
                containerClasses="margin-top-s--forced"
                dataTa={getTestId(TEST_IDS.priority)}
                label="general.priority"
                optionsMap={PriorityTranslationKeyMap}
                value={selectedPriority}
                defaultValue={priority}
                onChange={(value: ALARM_PRIORITY[]) => {
                  userSettings.set(username, UserSettings.ALARMS_PRIORITY, value.join(','));
                  setSelectedPriority(value);
                }}
              />
            </Flex.Item>
          </Flex>
          <Flex.Item>
            <Button
              id={applyButtonDataTa}
              className="vai-margin-top-m vai-margin-left-s"
              dataTa={applyButtonDataTa}
              disabled={!canApply}
              onClick={handleApply}
            >
              {t('events.filterBar.apply')}
            </Button>
          </Flex.Item>
        </Flex>
      </div>
    );
  };

  return (
    <>
      <div ref={topRef}>
        <Stack direction="row" justify="space-between" spacing={Size.S} align="center">
          <AlarmListHeading dataTa={getTestId()} translationKey="alarms.searchAll">
            <div data-ta={getTestId(TEST_IDS.subheading)} className="grey-medium">
              {t('site.alarms.historical.subheading')}
            </div>
          </AlarmListHeading>
          <Button
            id={showCurrentAlarmsDataTa}
            dataTa={showCurrentAlarmsDataTa}
            className="alarm-list-button"
            onClick={handleClick}
            buttonType={ButtonType.Secondary}
            startIcon={<Icon name={VaiIcon.AlertBell} />}
          >
            {t('site.alarms.historical.showCurrentAlarms')}
          </Button>
        </Stack>
        {renderFilterBar()}
        {!(isLoading || isFetching) && alarms.length > 0 && (
          <div data-ta={getTestId(TEST_IDS.num_results)} className="strong vai-margin-top-m">
            {t('general.numResults', { count: alarms.length })}
          </div>
        )}
      </div>
      <AlarmsDataTable
        nodeType={nodeType}
        dataTa={getTestId()}
        alarms={alarms}
        isLoading={isLoading || isFetching}
        timezone={timezone}
        headerHeight={headerHeight}
        type="HISTORICAL"
      />
    </>
  );
};

interface CurrentProps extends IMakeTestIdProps {
  onError: (error: any) => void;
}

export const CURR_ALARMS_DATA_TA = 'current-alarms';

export const CurrentAlarmsList: React.FC<CurrentProps> = ({ onError, dataTa }) => {
  const { id = PARENT_SITE_KEY, showAlarmDetail, ...rest } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const timezone = useAppSelector(selectTimeZone);
  const customer_id = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID) ?? '';
  const username = getConstantFromLocalStorage(LS_USERNAME) ?? '';
  const { t } = useTranslation();
  const { getTestId } = makeTestId({ dataTa: dataTa || CURR_ALARMS_DATA_TA });
  const isZone = id.startsWith('ZN');
  const isSite = id.startsWith('ST');
  const nodeType = isZone ? SiteNodeTypes.ZONE : isSite ? SiteNodeTypes.SITE : SiteNodeTypes.LOCATION;
  const { isLoading, isFetching, data: { tickets: alarms = [] } = { tickets: [] }, error, refetch } = useInfiniteQuery<
    ICurrentAlarmsArgs
  >({ customer_id, node_id: id }, alarmsApi, 'getCurrentAlarms', { skip: id == null }, true);

  React.useEffect(() => {
    userSettings.set(username, UserSettings.WAS_VIEWING_HISTORICAL_ALARMS, false);
  }, [username]);

  React.useEffect(() => {
    if (error) {
      onError?.(error);
    }
  }, [error]);

  React.useEffect(() => {
    if (id) refetch();
    if (showAlarmDetail === 'true') {
      dispatch(showDialog(<AlarmDetailsModal ticketId={id} />));
    }
  }, [dispatch, id]);

  const handleClick = () => {
    // HACK: Site is a special case right now where site can can only be ST1 and we are not passing it into the URL.
    //   This logic is bound to change when multi-site comes into play.
    const idPath = id && isSite ? '' : `/${id}`;
    navigate(
      `${routes.site.url}/${routes.site.alarms.url}/${isZone ? 'zone/' : ''}${
        routes.site.alarms.historical.url
      }${idPath}`
    );
  };

  const searchAllAlarmsDataTa = getTestId(TEST_IDS.current_alarms.search_all_alarms);

  return (
    <>
      <Stack direction="row" justify="space-between" spacing={Size.S} align="center">
        <AlarmListHeading dataTa={getTestId()} translationKey={'site.alarms.current.heading'}>
          <div data-ta={getTestId(TEST_IDS.alarm_count)} className="grey-medium">
            {t('alarms.alarm', { count: alarms.length })}
          </div>
        </AlarmListHeading>
        <Button
          id={searchAllAlarmsDataTa}
          dataTa={searchAllAlarmsDataTa}
          className="alarm-list-button"
          onClick={handleClick}
          startIcon={<Icon name={VaiIcon.Search} />}
        >
          {t('alarms.searchAll')}
        </Button>
      </Stack>
      <AlarmsDataTable
        nodeType={nodeType}
        dataTa={getTestId()}
        alarms={alarms}
        isLoading={isLoading || isFetching}
        timezone={timezone}
      />
    </>
  );
};

interface AlarmListHeadingProps extends IMakeTestIdProps {
  translationKey: TranslationKey;
}

export const AlarmListHeading: React.FC<AlarmListHeadingProps> = ({ translationKey, dataTa, children }) => {
  const { t } = useTranslation();
  const { getTestId } = makeTestId({ dataTa });
  return (
    <Stack spacing={Size.XS}>
      <Heading dataTa={getTestId(TEST_IDS.heading)} level={2}>
        {t(translationKey)}
      </Heading>
      {children}
    </Stack>
  );
};

interface NoAlarmsProps extends IMakeTestIdProps {
  nodeTranslationKey: TranslationKey;
}

export const NoCurrentAlarms: React.FC<NoAlarmsProps> = ({ dataTa, nodeTranslationKey }) => {
  const { t } = useTranslation();
  return (
    <EmptyState
      dataTa={dataTa}
      heading="site.alarms.current.emptyHeading"
      headingParams={{ zoneOrLocation: t(nodeTranslationKey) }}
      description="site.alarms.current.emptyDescription"
      descriptionParams={{ zoneOrLocation: t(nodeTranslationKey) }}
      illustration={IllustrationType.emptyTable}
    />
  );
};

export const NoHistoricalAlarms: React.FC<IMakeTestIdProps> = ({ dataTa }) => {
  return (
    <EmptyState
      dataTa={dataTa}
      heading="general.nothingFound"
      description="site.alarms.historical.emptyDescription"
      illustration={IllustrationType.nothingFound}
    />
  );
};

export type AlarmsDataTableType = 'CURRENT' | 'HISTORICAL';

interface AlarmsDataTableProps extends IMakeTestIdProps {
  alarms: AlarmTicket[];
  nodeType: SiteNodeTypes;
  isLoading: boolean;
  dataTa: string;
  timezone: string;
  headerHeight?: number;
  type?: AlarmsDataTableType;
}

type FormattedAlarmRow = {
  id: string;
  priority: ALARM_PRIORITY;
  location: string;
  category: string;
  type: string[];
  acknowledgement: string;
  start: string;
  end: string;
  duration: number;
  value: {
    unit: DeviceSymbolUnitIds;
    roundedValue: string;
  };
};

export const AlarmsDataTable: React.FC<AlarmsDataTableProps> = ({
  alarms = [],
  nodeType,
  isLoading,
  dataTa,
  type,
  timezone,
  headerHeight = 45
}) => {
  const tableType: AlarmsDataTableType = type || 'CURRENT';
  const ref = React.useRef(null);
  const { getTestId } = makeTestId({ dataTa });
  const [, height] = useResizeObserver(ref, 400, false);
  const [sortDirection, setSortDirection] = React.useState<TableSortDirection>(TableSortDirection.Descending);
  // TODO: Typing on the columns for the DataTableColumns
  const [sortColumn, setSortColumn] = React.useState<string>('start');
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const nodeTranslationKey: TranslationKey = React.useMemo(() => getNodeTypeTranslationKey(nodeType), [nodeType]);

  const sort = (sortColumn: string, sortDirection: TableSortDirection) => {
    // Return the default sorting when the sort direction has been reset
    if (sortDirection === 'NONE') {
      return formattedRows;
    }

    // TODO: Would like to use FormattedAlarmRow here but it makes us need to strongly type the sort column
    const comparer = (a: any, b: any) => {
      if (sortDirection === TableSortDirection.Ascending) {
        return a[sortColumn]?.localeCompare(b[sortColumn]);
      } else if (sortDirection === TableSortDirection.Descending) {
        return b[sortColumn].localeCompare(a[sortColumn]);
      }
    };
    let whichComparer = comparer;
    switch (sortColumn) {
      case 'priority':
        whichComparer = sortPriority(sortDirection);
        break;
      case 'type':
        whichComparer = sortType(sortDirection);
        break;
      case 'duration':
        whichComparer = sortDuration(sortDirection);
        break;
      case 'value':
        whichComparer = sortNumber(sortDirection);
        break;
    }
    return [...formattedRows].sort(whichComparer);
  };

  const formattedRows: FormattedAlarmRow[] = React.useMemo(() => {
    return [...alarms]
      .sort((a, b) => b.start - a.start)
      .map(alarm => {
        const end = alarm.end ? timestampToTimeString(alarm.end) : null;
        const { decimal_places, value, subtype } = alarm;
        const roundedValue = roundHalfToEven(value, decimal_places);
        const subtypeTranslationKey =
          thresholdLevelTranslationKeys[subtype as ALARM_THRESHOLD_TYPE] || alarmSubTypeTranslationKeys[subtype];
        const isCurrent = alarm.end == null || alarm.acknowledgement === AckRequirement.REQUIRED;
        return {
          id: alarm.ticket_id,
          priority: alarm.priority,
          location: alarm.location,
          category: t(AlarmCategoryTranslationKey[alarm.category]),
          // NOTE: translate beforehand so it will update when language changes, and also will sort on the translated value
          type: [alarm.type, t(subtypeTranslationKey)],
          // NOTE: translate this in place so the translated value is sorted and not the key
          acknowledgement: t(AlarmAcknowledgementTranslationKey[alarm.acknowledgement]),
          start: timestampToTimeString(alarm.start),
          end: end || '--',
          duration: end ? alarm.end - alarm.start : 0,
          value: { unit: alarm.unit, roundedValue },
          isCurrent
        };
      });
  }, [alarms, i18n.language, timezone]);

  const [rows, setRows] = React.useState(formattedRows);

  React.useEffect(() => {
    setRows(sort(sortColumn, sortDirection));
  }, [alarms, i18n.language, timezone]);

  const getRow = (rowIdx: number) => {
    return rows[rowIdx];
  };

  const openAlarmDetail = (row: FormattedAlarmRow) => {
    dispatch(showDialog(<AlarmDetailsModal ticketId={row.id} />));
  };

  const onClick: ColumnEventCallback = (_e, row) => openAlarmDetail(getRow(row.rowIdx));

  const Columns: DataTableColumn[] = [
    {
      key: 'priority',
      name: t('general.priority'),
      width: 75,
      sortable: true,
      formatter: PriorityIconFormatter,
      events: { onClick }
    },
    {
      key: 'location',
      name: t('general.location'),
      sortable: true,
      resizable: true,
      events: { onClick }
    },
    { key: 'category', sortable: true, name: t('general.category'), events: { onClick } },
    {
      key: 'type',
      name: t('general.type'),
      sortable: true,
      formatter: AlarmTypeFormatter,
      events: { onClick }
    },
    { key: 'acknowledgement', name: t('general.acknowledgement'), sortable: true, width: 175, events: { onClick } },
    { key: 'start', name: t('general.startTime'), sortable: true, events: { onClick } },
    { key: 'end', name: t('general.endTime'), sortable: true, events: { onClick } },
    {
      key: 'duration',
      name: t('general.duration'),
      formatter: TimesinceFormatter,
      sortable: true,
      events: { onClick }
    },
    { key: 'value', name: t('general.value'), formatter: ValueWithUnitFormatter, sortable: true, events: { onClick } }
  ];

  const renderNoAlarms = () => {
    return tableType === 'CURRENT' ? (
      <NoCurrentAlarms dataTa={dataTa} nodeTranslationKey={nodeTranslationKey} />
    ) : (
      <NoHistoricalAlarms dataTa={dataTa} />
    );
  };

  return (
    <div className="vai-padding-top-m alarms-datatable" ref={ref}>
      {!isLoading && alarms?.length === 0 ? (
        renderNoAlarms()
      ) : isLoading ? (
        <Stack align="center">
          <CenteredSpinner className="vai-margin-top-xxl" dataTa={getTestId(TEST_IDS.spinner)} />
          <Heading level={4} className="vai-margin-top-l">
            {t('events.loadingResults')}
          </Heading>
        </Stack>
      ) : (
        <DataTable
          dataTa={getTestId(TEST_IDS.table)}
          rowGetter={getRow}
          sortColumn="start"
          sortDirection={TableSortDirection.Descending}
          rowsCount={rows.length}
          minColumnWidth={150}
          hasFocusBorder={false}
          /** In the test suite, the container height ends up being very small and height - headerHeight ends up being negative,
           * causing the dataTable to have a negative height and thus not rendering any data. The height must exceed the required
           * height of the data or there will be missing rows */
          minHeight={process.env.NODE_ENV === 'test' ? 100000 : (height ?? 0) - headerHeight}
          onGridSort={(sortColumn: string, sortDirection: TableSortDirection) => {
            setSortColumn(sortColumn);
            setSortDirection(sortDirection);
            setRows(sort(sortColumn, sortDirection));
          }}
          columns={Columns}
        />
      )}
    </div>
  );
};

export default AlarmsList;
