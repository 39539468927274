import React from 'react';
import { Flex } from '@vaisala/rockhopper-components';
import { ALARM_PRIORITY, ALARM_THRESHOLD_TYPE } from '../../../../constants';
import { AlarmPriorityIcons, AlarmThresholdIcons } from './AlarmIcons';
import { QA_TEST_IDS } from '../../../../tests/qaTestIds';
import { TEST_IDS } from '../../../../tests/testids';
import './alarmDisplay.scss';

interface Props {
  threshold: ALARM_THRESHOLD_TYPE;
  priority: ALARM_PRIORITY;
  label: string;
}

export const AlarmDisplay: React.FC<Props> = ({ priority, label, threshold }) => {
  const ThresholdIcon = AlarmThresholdIcons[threshold];
  const PriorityIcon = AlarmPriorityIcons[priority];
  return (
    <Flex className="alarm-display" alignItems="center" justifyContent="space-between">
      <ThresholdIcon />
      <h3
        data-ta={TEST_IDS.alarm_display}
        id={`${QA_TEST_IDS.alarms_settings_threshold_level_alarm_display_$level}${threshold}`}
      >
        {label}
      </h3>
      <PriorityIcon />
    </Flex>
  );
};
