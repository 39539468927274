import { Icon, Size } from '@vaisala/rockhopper-components';
import React from 'react';
import { DEVICE_STATUS_ICON_TYPE } from '../../../constants';
import { getDeviceBatteryIcon, getDeviceSignalIcon } from '../../../utils';

type DeviceStatusIconProps = {
  type: DEVICE_STATUS_ICON_TYPE;
  level: number;
};

const DeviceStatusIcon = ({ type, level }: DeviceStatusIconProps) => {
  if (level === undefined || level === null) return null;

  const iconName = type === DEVICE_STATUS_ICON_TYPE.BATTERY ? getDeviceBatteryIcon(level) : getDeviceSignalIcon(level);
  return (
    <div>
      <Icon dataTa={`device-status-icon-${iconName}`} name={iconName} size={Size.L} />
    </div>
  );
};
export default DeviceStatusIcon;
