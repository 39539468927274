import React from 'react';
import { Grid } from '@vaisala/rockhopper-components';

export const GreenZone = () => {
  return (
    <Grid.Row id="green-zone">
      <Grid.Col />
    </Grid.Row>
  );
};
