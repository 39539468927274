import React from 'react';
import { useTranslation } from 'react-i18next';
import { IPasswordPolicy } from '../../utils';

export interface IPasswordHelpText {
  passwordPolicy: IPasswordPolicy;
  titleClass?: string;
}

export const PasswordHelpText = ({ passwordPolicy, titleClass }: IPasswordHelpText) => {
  const { t } = useTranslation();

  const passwordRequirements: Array<string> = [];

  passwordPolicy.isUpperCaseRequired
    ? passwordRequirements.push(t('password.passwordHelperUpperCaseRequirement'))
    : null;
  passwordPolicy.isLowerCaseRequired
    ? passwordRequirements.push(t('password.passwordHelperLowerCaseRequirement'))
    : null;
  passwordPolicy.isNumberRequired ? passwordRequirements.push(t('password.passwordHelperNumberRequirement')) : null;
  passwordPolicy.isSpecialCharRequired
    ? passwordRequirements.push(t('password.passwordHelperSpecialCharRequirement'))
    : null;
  const listItems = passwordRequirements.map((item, index) => <li key={`password-requirement-${index}`}>{item}</li>);

  return (
    <>
      <div id="password-help-text-blurb" className="vai-margin-bottom-s">
        {t('password.passwordHelperTextBlurb', { length: passwordPolicy.minimumLength })}
      </div>
      {listItems.length > 0 ? (
        <>
          <h4 id="password-help-text-title" className={titleClass}>
            {t('password.passwordHelperText')}
          </h4>
          <ul id="password-help-text-policy-list" className="vai-margin-bottom-m">
            {listItems}
          </ul>
        </>
      ) : null}
    </>
  );
};
