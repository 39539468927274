import * as React from 'react';
import './delete-group-users.scss';
import { Size, Button, ButtonType, BodyText } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../store/hooks';
import { hideDialog } from '../../../store/dialog';
import { ModalContainer } from '../../Modals/ModalContainer';
import { TEST_IDS } from '../../../tests/testids';
import { UserInterface } from '../../../profile/user';

interface UserDeletionDialogProps {
  deleteGroupUsers: (users: UserInterface[], groupId: string, groupName: string) => void;
  selectedUsers: UserInterface[];
  group_id: string;
  group_name: string;
}

const DeleteGroupUsersDialog = (props: UserDeletionDialogProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const deleteGroupUsers = () => {
    props.deleteGroupUsers(props.selectedUsers, props.group_id, props.group_name);
    onDismiss();
  };

  const onDismiss = () => dispatch(hideDialog());

  const modalButtons = (
    <>
      <Button
        id="delete-group-users-confirm"
        htmlId="delete-group-users-confirm"
        onClick={deleteGroupUsers}
        buttonType={ButtonType.Secondary}
        dataTa={TEST_IDS.group_remove_user_confirm_button}
      >
        {t('profile.remove')}
      </Button>
      <Button id="delete-group-users-cancel" htmlId="delete-group-users-cancel" onClick={onDismiss}>
        {t('profile.cancel')}
      </Button>
    </>
  );

  return (
    <ModalContainer
      dataTa={TEST_IDS.group_delete_dialog}
      id="delete-group-users-dialog"
      isOpen
      htmlId="delete-group-users-dialog"
      showCloseIcon={true}
      onDismiss={onDismiss}
      width={Size.M}
      dismissOnOverlayClick={false}
      title="profile.deleteGroupUsersTitle"
      role="alert-dialog"
      buttons={modalButtons}
    >
      <BodyText id="elete-group-users-body-text" className="delete-group-users-body">
        {t('profile.removeGroupUsersMsg', { numberOfUsers: props.selectedUsers.length, groupName: props.group_name })}
      </BodyText>
    </ModalContainer>
  );
};

export default DeleteGroupUsersDialog;
