import React from 'react';
import { Grid, RowProps } from '@vaisala/rockhopper-components';
import mergeClassNames from 'classnames';
import { TEST_IDS } from '../../../../tests/testids';
import './alarmSettingFormRow.scss';

interface Props extends RowProps {
  enabled?: boolean;
  hasError?: boolean;
  hasWarning?: boolean;
}

export const AlarmSettingFormRow: React.FC<Props> = ({
  enabled,
  children,
  className,
  hasError,
  hasWarning,
  ...rest
}) => {
  let classNameForEnabled = '';
  const classNameForError = hasError ? HAS_ERROR : '';
  const classNameForWarning = hasWarning ? HAS_WARNING : '';

  if (enabled !== undefined) {
    classNameForEnabled = enabled ? 'enabled' : 'disabled';
  }

  const classNames = mergeClassNames(
    classNameForEnabled,
    classNameForError,
    classNameForWarning,
    className,
    'alarm-setting-form-row'
  );

  return (
    <Grid.Row dataTa={TEST_IDS.alarm_setting_form_row} {...rest} className={classNames}>
      {children}
    </Grid.Row>
  );
};

export const HAS_ERROR = 'has-error';
export const HAS_WARNING = 'has-warning';
