import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex } from '@vaisala/rockhopper-components';
import { Illustration, IllustrationType } from '../Utils/EmptyState/Illustration';
import { useLocation } from 'react-router-dom';

export type Error500Props = {
  retry?: () => void;
  error?: Error;
  resetErrorBoundary?: (event?: any) => void;
  onReload?: () => void;
};

const Error500 = ({ resetErrorBoundary, retry, onReload }: Error500Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const errorLocation = React.useRef(location.pathname);

  const reload = () => {
    onReload ? onReload() : window.location.reload();
  };

  React.useEffect(() => {
    // If we navigate away. Reset the previous error boundary.
    if (location.pathname !== errorLocation.current) {
      resetErrorBoundary?.(null);
      reload();
    }
  }, [location.pathname]);

  return (
    <Flex flexWrap="wrap" flexDirection="column" justifyContent="space-around" className="h-100">
      <Flex justifyContent="center" flexWrap="wrap-reverse">
        <Flex flexDirection="column" justifyContent="space-between">
          <Flex.Item className="vai-margin-bottom-m">
            <h2>{t('general.somethingWentWrong')}</h2>
          </Flex.Item>
          <Flex.Item id="error-500-description" className="vai-margin-bottom-l">
            {t('general.errorPageDescription')}
          </Flex.Item>
          <Flex.Item>
            {retry ? (
              <Button id="error-500-reload-button" onClick={retry}>
                {t('general.retry')}
              </Button>
            ) : (
              <Button id="error-500-reload-button" onClick={reload}>
                {t('general.refresh')}
              </Button>
            )}
          </Flex.Item>
        </Flex>
        <Flex.Item className="vai-margin-left-xl vai-margin-right-xl">
          <Illustration illustration={IllustrationType.genericError} />
        </Flex.Item>
      </Flex>
      {/* {isDevelopment() ? <pre className="vai-margin-left-m">{error?.stack}</pre> : null} */}
    </Flex>
  );
};

export default Error500;
