import { BodyText, Flex, FlexItem, Icon, Size, Spinner } from '@vaisala/rockhopper-components';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { timestampToTimeString } from '../../../utils';
import {
  DeviceStatuses,
  LocationStatuses,
  ThresholdStatuses,
  THRESHOLD_STATUS,
  STATUS_TYPES,
  ObjectValues,
  LocationLastMeasuredStatuses,
  LOCATION_LAST_MEASURED_STATUS,
  ALARM_PRIORITY,
  DEVICE_ALARM_STATUS
} from '../../../constants';
import { TranslationKey } from '../../../react-i18next';
import { ThresholdStatus } from './ThresholdStatus';
import { DeviceStatus } from './DeviceStatus';
import { getIcon } from '../../../utils/site';
import { MeasurementType } from '../../../siteTree/site';

export type Status =
  | DeviceStatuses
  | LocationStatuses
  | ThresholdStatuses
  | LocationLastMeasuredStatuses
  | number
  | null;

interface StatusItemProps {
  status: Status;
  isLoading?: boolean;
  label: string;
  translationKey?: TranslationKey;
  type: ObjectValues<typeof STATUS_TYPES>;
  dataTa?: string;
  alarmCount?: number;
  measurementType?: MeasurementType;
  infotip?: ReactElement;
}

export const ALARM_PRIORITY_SUFFIXES = {
  [ALARM_PRIORITY.CRITICAL]: '--critical',
  [ALARM_PRIORITY.MODERATE]: '--warning',
  [ALARM_PRIORITY.INFO]: '--info'
};

export const STATUS_ITEM = 'status-item';

const isStatusError = (type: StatusItemProps['type'], status: Status | undefined) => {
  if (type === STATUS_TYPES.DEVICE) {
    if (status === DEVICE_ALARM_STATUS.NOT_SET) {
      return true;
    }
  }
  if (type === STATUS_TYPES.THRESHOLD) {
    if (status === THRESHOLD_STATUS.NOT_SET) {
      return true;
    }
  }
  if (type === STATUS_TYPES.MEASUREMENT) {
    if (typeof status !== 'number' && status !== LOCATION_LAST_MEASURED_STATUS.RETRIEVING_DATA) {
      return true;
    }
  }
  return false;
};

const renderMeasurementTypeIcon = (measurementType: MeasurementType): JSX.Element => {
  return <Icon className="status-item__icon" size={Size.M} {...getIcon(measurementType)} />;
};

export const StatusItem = ({
  status,
  label,
  type,
  translationKey,
  dataTa,
  isLoading,
  alarmCount,
  measurementType,
  infotip
}: StatusItemProps) => {
  const { t } = useTranslation();

  const hasStatusError: boolean = isStatusError(type, status);

  const getStatusValue = (status: Status, hasStatusError: boolean) => {
    if (hasStatusError) {
      if (type === STATUS_TYPES.THRESHOLD) {
        return <ThresholdStatus status={status} alarmCount={alarmCount} />;
      }
      if (type === STATUS_TYPES.DEVICE) {
        return <DeviceStatus status={status} alarmCount={alarmCount} />;
      }
      return t(status as TranslationKey);
    }

    if (type === STATUS_TYPES.DEVICE) {
      return <DeviceStatus status={status} alarmCount={alarmCount} />;
    }

    if (type === STATUS_TYPES.MEASUREMENT) {
      if (typeof status === 'number') {
        return timestampToTimeString(status);
      } else {
        return (
          <>
            <Icon className="status-item__icon status-item__icon--sync" name={VaiIcon.Sync} size={Size.M} />
            {t(status as TranslationKey)}
          </>
        );
      }
    }

    if (status && type === STATUS_TYPES.THRESHOLD) {
      return <ThresholdStatus status={status} alarmCount={alarmCount} />;
    }
  };

  const statusItemSuffix = hasStatusError ? '--error' : status ? ALARM_PRIORITY_SUFFIXES[status as ALARM_PRIORITY] : '';

  return (
    <FlexItem flexGrow={3} className={`${STATUS_ITEM} ${statusItemSuffix ? `${STATUS_ITEM}${statusItemSuffix}` : ''}`}>
      <Flex flexDirection="row" flexWrap="nowrap" alignContent="center">
        <FlexItem alignSelf="center">
          {type === STATUS_TYPES.THRESHOLD && measurementType && renderMeasurementTypeIcon(measurementType)}
          {type === STATUS_TYPES.DEVICE && (
            <Icon size={Size.M} className="status-item__icon" name={VaiIcon.DataLogger} />
          )}
        </FlexItem>
        <div>
          <BodyText className="status-item__label" dataTa={dataTa ? `${dataTa}-label` : undefined}>
            {translationKey ? t(translationKey) : label}
          </BodyText>

          <FlexItem className="status-item__value" dataTa={dataTa}>
            {!isLoading && status ? getStatusValue(status, hasStatusError) : <Spinner className="spinner-small" />}
          </FlexItem>
        </div>
        {infotip && status == DEVICE_ALARM_STATUS.UNLINKED && <div style={{ alignSelf: 'center' }}>{infotip}</div>}
      </Flex>
    </FlexItem>
  );
};
