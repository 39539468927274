import * as React from 'react';
import validator from 'validator';
import {
  GROUP_NAME_MIN_CHARACHTERS_LENGTH,
  GROUP_NAME_MAX_CHARACHTERS_LENGTH,
  GROUP_DESCRIPTION_MAX_CHARACHTERS_LENGTH
} from '../../../constants';
import { Size, InputField, Form, Label, Button, ButtonType, TextArea } from '@vaisala/rockhopper-components';
import { GroupInterface } from '../../../profile/user';
import { createGroupsInterface } from '../../../profile/group';
import { useTranslation } from 'react-i18next';
import { getErrorClass } from '../../../utils';
import { useAppDispatch } from '../../../store/hooks';
import { hideDialog } from '../../../store/dialog';
import { ModalContainer } from '../../Modals/ModalContainer';
import { TEST_IDS } from '../../../tests/testids';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';

interface CreateGroupsDialogProps {
  groups: GroupInterface[];
  createGroups: (group: createGroupsInterface) => void;
}

const CreateGroupsDialog = (props: CreateGroupsDialogProps): JSX.Element => {
  const { t } = useTranslation();
  const [groupName, setGroupName] = React.useState('');
  const [groupNameError, setGroupNameError] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [descriptionError, setDescriptionError] = React.useState('');
  const dispatch = useAppDispatch();

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const { value, name } = e.currentTarget;
    if (name === 'groupName') {
      setGroupName(value);
      value.length > GROUP_NAME_MAX_CHARACHTERS_LENGTH
        ? setGroupNameError(t('profile.createGroupsMaxLengthError', { charLen: GROUP_NAME_MAX_CHARACHTERS_LENGTH }))
        : setGroupNameError('');
    } else if (name === 'description') {
      setDescription(value);
      value.length > GROUP_DESCRIPTION_MAX_CHARACHTERS_LENGTH
        ? setDescriptionError(
            t('profile.createGroupsMaxLengthError', { charLen: GROUP_DESCRIPTION_MAX_CHARACHTERS_LENGTH })
          )
        : setDescriptionError('');
    }
  };
  const onDismiss = () => {
    dispatch(hideDialog());
  };
  const onCreateGroup = () => {
    let errorExist = false;
    const trimmedGroupName = validator.trim(groupName);
    const trimmedDescription = validator.trim(description);
    setGroupNameError('');
    setDescriptionError('');
    if (!trimmedGroupName) {
      errorExist = true;
      setGroupNameError(t('profile.groupNameEmptyError'));
    } else if (trimmedGroupName.length > GROUP_NAME_MAX_CHARACHTERS_LENGTH) {
      errorExist = true;
      setGroupNameError(t('profile.createGroupsMaxLengthError'));
    } else if (props.groups.map(a => a.group_name).includes(trimmedGroupName)) {
      errorExist = true;
      setGroupNameError(t('profile.groupNameDuplicateError'));
    } else if (trimmedGroupName.length < GROUP_NAME_MIN_CHARACHTERS_LENGTH) {
      errorExist = true;
      setGroupNameError(t('profile.createGroupsMinLengthError', { charLen: GROUP_NAME_MIN_CHARACHTERS_LENGTH }));
    } else if (trimmedDescription.length > GROUP_DESCRIPTION_MAX_CHARACHTERS_LENGTH) {
      errorExist = true;
    }
    if (!errorExist) {
      const newGroup: createGroupsInterface = {
        group_name: trimmedGroupName,
        description: trimmedDescription
      };
      props.createGroups(newGroup);
      dispatch(hideDialog());
    }
  };

  const isNameInvalid = !groupName || groupNameError.length > 0;
  const isDescriptionInvalid = descriptionError.length > 0;

  const isConfirmDisabled = isNameInvalid || isDescriptionInvalid;
  const modalButtons = (
    <>
      <Button
        id="create-groups-confirm"
        htmlId="create-groups-confirm"
        disabled={isConfirmDisabled}
        onClick={onCreateGroup}
        dataTa={TEST_IDS.group_create_dialog_create_group_button}
      >
        {t('profile.createGroupsConfirmation')}
      </Button>
      <Button
        id="create-groups-cancel"
        htmlId="create-groups-cancel"
        onClick={onDismiss}
        buttonType={ButtonType.Secondary}
      >
        {t('profile.cancel')}
      </Button>
    </>
  );

  return (
    <ModalContainer
      id="create-groups-dialog"
      isOpen
      htmlId="create-groups-dialog"
      showCloseIcon={true}
      onDismiss={onDismiss}
      width={Size.S}
      dismissOnOverlayClick={false}
      buttons={modalButtons}
      iconName={VaiIcon.UserGroup}
      title="profile.createGroupsTitle"
    >
      <Form id="create-groups-form">
        <Label id="create-groups-name-label">
          {t('profile.creaGroupsName')}
          <InputField
            required={true}
            htmlId="create-groups-name"
            width={Size.Container}
            value={groupName}
            name="groupName"
            onChange={onChange}
            className={getErrorClass(groupNameError)}
            data-ta={TEST_IDS.group_create_dialog_name_field}
          />
          <span className="input-error">{groupNameError}</span>
        </Label>
        <Label id="create-groups-description-label">
          {t('profile.createGroupsDescription')}
          <TextArea
            htmlId="create-groups-description"
            width={Size.Container}
            value={description}
            name="description"
            onChange={onChange}
            rows={2}
            className={getErrorClass(descriptionError)}
            data-ta={TEST_IDS.group_create_dialog_description_field}
          />
          <span className="input-error">{descriptionError}</span>
        </Label>
      </Form>
    </ModalContainer>
  );
};

export default CreateGroupsDialog;
