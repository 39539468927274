import * as React from 'react';
import { connect } from 'react-redux';
import { subDays, subWeeks, subMonths } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Form, Size, Button, ButtonType, Flex } from '@vaisala/rockhopper-components';
import { LocationsFilterTimeSpan } from '../Locations';
import { reportsDispatchActions, StoreState } from '../../../store';
import GenerateReportDialog from '../GenerateReportDialog/GenerateReportDialog';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import TimeContext from '../../../context/TimeContext';
import useDateFilterOptions from '../../../hooks/useDateFilterOptions';
import { TEST_IDS } from '../../../tests/testids';
import './locations-filter-bar.scss';

type LocationsFilterBarProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const LocationsFilterBar = ({
  setReportIntervalDate,
  intervalDate,
  selectedLocationsNum
}: LocationsFilterBarProps): JSX.Element => {
  const { t } = useTranslation();
  const { today } = React.useContext(TimeContext);
  const [timespan, setTimespan] = React.useState<LocationsFilterTimeSpan | null>(LocationsFilterTimeSpan.ONE_DAY);

  const resetTimeSpan = () => {
    setTimespan(null);
  };

  const { filterOptions, setFilterOptions, renderFrom, renderTo } = useDateFilterOptions({
    today,
    onChangeFromTime: resetTimeSpan,
    onChangeToTime: resetTimeSpan,
    onChangeFromDate: resetTimeSpan,
    onChangeToDate: resetTimeSpan
  });

  // To change locations filter when graph arrows are clicked without entering infinite loop
  const [outsideRender, setOutsideRender] = React.useState(false);

  const [showDialogs, setShowDialogs] = React.useState({
    generateReport: false,
    confirmation: false
  });

  React.useEffect(() => {
    if (
      filterOptions.fromDate?.toString() !== intervalDate.from.toString() ||
      filterOptions.toDate?.toString() !== intervalDate.to.toString()
    ) {
      setOutsideRender(true);
      setFilterOptions({
        fromDate: intervalDate.from,
        toDate: intervalDate.to
      });
    }
  }, [intervalDate.from, intervalDate.to]);

  React.useEffect(() => {
    if (outsideRender) {
      setOutsideRender(false);
    } else {
      setReportIntervalDate({ from: filterOptions.fromDate, to: filterOptions.toDate });
    }
  }, [filterOptions.fromDate, filterOptions.toDate]);

  const timeSpanButtons = [
    {
      id: '24-hr-btn',
      compareWith: LocationsFilterTimeSpan.ONE_DAY,
      onClick: () => {
        changeDatesFilters(LocationsFilterTimeSpan.ONE_DAY);
      },
      text: t('reports.filterBar.1day')
    },
    {
      id: '1-week-btn',
      compareWith: LocationsFilterTimeSpan.ONE_WEEK,
      onClick: () => {
        changeDatesFilters(LocationsFilterTimeSpan.ONE_WEEK);
      },
      text: t('reports.filterBar.1week')
    },
    {
      id: '1-month-btn',
      compareWith: LocationsFilterTimeSpan.ONE_MONTH,
      onClick: () => {
        changeDatesFilters(LocationsFilterTimeSpan.ONE_MONTH);
      },
      text: t('reports.filterBar.1month')
    }
  ];

  const changeDatesFilters = (timeSpan: LocationsFilterTimeSpan) => {
    switch (timeSpan) {
      case LocationsFilterTimeSpan.ONE_DAY:
        setTimespan(LocationsFilterTimeSpan.ONE_DAY);
        setFilterOptions({
          fromDate: subDays(today, 1),
          toDate: today
        });
        break;
      case LocationsFilterTimeSpan.ONE_WEEK:
        setTimespan(LocationsFilterTimeSpan.ONE_WEEK);
        setFilterOptions({
          fromDate: subWeeks(today, 1),
          toDate: today
        });
        break;
      case LocationsFilterTimeSpan.ONE_MONTH:
        setTimespan(LocationsFilterTimeSpan.ONE_MONTH);
        setFilterOptions({
          fromDate: subMonths(today, 1),
          toDate: today
        });
        break;
      default:
        break;
    }
  };

  return (
    <div id="locations-filter-bar" data-ta={TEST_IDS.locations_filter_bar}>
      <div id="locations-filter-bar-left-item" className={'item-left'}>
        <Flex alignItems="flex-end" className="filter-span-wrapper">
          {timeSpanButtons.map(btnOptions => (
            <Button
              dataTa={btnOptions.id}
              key={btnOptions.id}
              buttonSize={Size.S}
              onClick={btnOptions.onClick}
              buttonType={timespan === btnOptions.compareWith ? ButtonType.Primary : ButtonType.Secondary}
            >
              {btnOptions.text}
            </Button>
          ))}
        </Flex>

        <Flex className="date-pickers-wrapper">
          <Flex alignItems="flex-end">
            <Form.Item label={t('events.filterBar.from')} className="form-item">
              <Flex id="from-pickers-wrapper" data-value={intervalDate.from.toISOString()}>
                {renderFrom({
                  datePickerProps: {
                    id: 'reports-filter-bar-from-date-picker'
                  },
                  timePickerProps: {
                    id: 'reports-filter-bar-from-time-picker',
                    testId: 'timepicker-fromTime'
                  }
                })}
              </Flex>
            </Form.Item>
          </Flex>

          <Flex alignItems="flex-end">
            <Form.Item label={t('events.filterBar.to')} className="form-item">
              <Flex id="to-pickers-wrapper" data-value={intervalDate.to.toISOString()}>
                {renderTo({
                  datePickerProps: {
                    id: 'reports-filter-bar-to-date-picker'
                  },
                  timePickerProps: {
                    id: 'reports-filter-bar-to-time-picker',
                    testId: 'timepicker-toTime'
                  }
                })}
              </Flex>
            </Form.Item>
          </Flex>
        </Flex>
      </div>

      <div className="item-right" id="locations-filter-bar-right-item">
        <Form.Item className="empty-label" labelWidth={Size.S}>
          <Button
            id="report-generate-filter-button"
            buttonSize={Size.M}
            disabled={selectedLocationsNum === 0}
            type="button"
            onClick={() => setShowDialogs({ ...showDialogs, generateReport: true })}
          >
            {t('reports.filterBar.report')}
          </Button>
        </Form.Item>

        {showDialogs.generateReport && (
          <GenerateReportDialog
            isVisible={showDialogs.generateReport}
            onDismiss={() => setShowDialogs({ ...showDialogs, generateReport: false })}
            showConfirmation={() => setShowDialogs({ ...showDialogs, confirmation: true, generateReport: false })}
          />
        )}

        {showDialogs.confirmation && (
          <ConfirmationDialog
            isVisible={showDialogs.confirmation}
            onDismiss={() => setShowDialogs({ ...showDialogs, confirmation: false })}
          />
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  setReportIntervalDate: (interval: { from: Date; to: Date }) =>
    dispatch(reportsDispatchActions.setReportIntervalDate(interval))
});

const mapStateToProps = ({ reports }: StoreState) => ({
  intervalDate: reports.intervalDate,
  selectedLocationsNum: reports.selectedLocationsNum
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationsFilterBar);
