import { cloneDeep } from 'lodash';
import { ALARM_THRESHOLD_TYPE } from '../../../../constants';
import { ThresholdAlarmSettings } from '../../../../store/services/alarmsApi';
import { Errors } from './shared';

/**
 *
 * @param rule - Threshold level rule. Used to access threshold level values.
 * @param error - Current level error state. It will mutate object in place with updated errors.
 * @param unitDetails - Threshold settings unit details. Used to access min and max limits for threshold units.
 * @returns hasError value and passed in error state. hasError is used to validate passed in rule if not interested in updating error state.
 */
export const validateThresholdValue = (
  rule: ThresholdAlarmSettings['template']['rules'][keyof typeof ALARM_THRESHOLD_TYPE],
  error: Errors[keyof typeof ALARM_THRESHOLD_TYPE],
  unitDetails: ThresholdAlarmSettings['unit']
) => {
  const clonedError = cloneDeep(error);
  if (rule.value === null) {
    clonedError.value.cannotBeEmptyValue.hasError = true;
    clonedError.value.units.min.hasError = false;
    clonedError.value.units.max.hasError = false;
  } else {
    switch (true) {
      case rule.value > unitDetails.max:
        clonedError.value.cannotBeEmptyValue.hasError = false;
        clonedError.value.units.min.hasError = false;
        clonedError.value.units.max.hasError = true;
        break;
      case rule.value < unitDetails.min:
        clonedError.value.cannotBeEmptyValue.hasError = false;
        clonedError.value.units.max.hasError = false;
        clonedError.value.units.min.hasError = true;
        break;
      default:
        clonedError.value.cannotBeEmptyValue.hasError = false;
        clonedError.value.units.min.hasError = false;
        clonedError.value.units.max.hasError = false;
    }
  }

  clonedError.hasError = aggregateLevelHasError(clonedError);

  return { hasError: error.hasError, error: clonedError };
};

/**
 * It will aggregate hasError logic from level error state
 * @param Error - error object for threshold level
 * @returns - if the level error has any validation error in it. It is used to style row when level has any error in it.
 */

function aggregateLevelHasError(error: Errors[keyof typeof ALARM_THRESHOLD_TYPE]) {
  return (
    error.delay.cannotBeEmptyValue.hasError ||
    error.delay.hours.max.hasError ||
    error.delay.minutes.max.hasError ||
    error.value.cannotBeEmptyValue.hasError ||
    error.value.units.min.hasError ||
    error.value.units.max.hasError
  );
}

/**
 * Will validate threshold level values in descending order. Equal values will fail validation.
 * @param rules - Threshold level rules object. Used to validate enabled threshold level values
 * @returns - returns object with hasError property.
 */
export const validateOrder = (rules: ThresholdAlarmSettings['template']['rules']) => {
  let hasError = false;
  const order: number[] = [];

  for (const level of Object.keys(ALARM_THRESHOLD_TYPE)) {
    const rule = rules[level as ALARM_THRESHOLD_TYPE];
    rule.value != null && rule.enabled && order.push(rule.value);
  }

  const sortedOrder = [...order].sort((a: number, b: number) => b - a);

  // Order matches
  sortedOrder.forEach((value, index) => {
    if (value !== order[index]) {
      hasError = true;
    }
  });

  // Level avlues are not equal
  hasDuplicates<number>(sortedOrder) && (hasError = true);

  return { hasError };
};

/**
 *
 * @param errors - errors state
 * @returns set of unique errors labels. Used to display unique errors messages.
 */

export function collectSetOfErrors(errors: Errors) {
  const errorsSet = new Set<string>();
  for (const level of Object.keys(ALARM_THRESHOLD_TYPE)) {
    const { cannotBeEmptyValue, units } = errors[level as ALARM_THRESHOLD_TYPE].value;
    const { max, min } = units;
    cannotBeEmptyValue.hasError && errorsSet.add(cannotBeEmptyValue.label);
    max.hasError && errorsSet.add(max.label);
    min.hasError && errorsSet.add(min.label);

    errors.order.hasError && errorsSet.add(errors.order.label);

    const delay = errors[level as ALARM_THRESHOLD_TYPE].delay;
    delay.cannotBeEmptyValue.hasError && errorsSet.add(delay.cannotBeEmptyValue.label);
    delay.hours.max.hasError && errorsSet.add(delay.hours.max.label);
    delay.minutes.max.hasError && errorsSet.add(delay.minutes.max.label);
  }

  const collectedErrors = Array.from(errorsSet);

  return collectedErrors;
}

/**
 * It will set all the validation error state of the passed in error to false.
 * It will mutate the object in place
 * @param error - level error.
 */
export const clearLevelErrors = (error: Errors[keyof typeof ALARM_THRESHOLD_TYPE]) => {
  error.delay.cannotBeEmptyValue.hasError = false;
  error.delay.hours.max.hasError = false;
  error.delay.minutes.max.hasError = false;
  error.hasError = false;
  error.value.cannotBeEmptyValue.hasError = false;
  error.value.units.max.hasError = false;
  error.value.units.min.hasError = false;
};

// Source: https://stackoverflow.com/questions/7376598/in-javascript-how-do-i-check-if-an-array-has-duplicate-values
function hasDuplicates<T>(array: Array<T>) {
  return new Set(array).size !== array.length;
}
