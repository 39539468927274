import React from 'react';
import { useTranslation } from 'react-i18next';
import { convertMillisecondsToDHMS } from '../../../utils';

interface Props {
  timestamp: number; // timestamp in milliseconds,
  dataTa?: null | string;
}

const Timesince: React.FC<Props> = ({ timestamp, dataTa }) => {
  const { t } = useTranslation();

  const timesince = React.useMemo(() => {
    const { days, minutes, hours, seconds } = convertMillisecondsToDHMS(timestamp);
    const timeParts: string[] = [];
    if (days > 0) {
      timeParts.push(t('general.day', { count: days }));
    }
    if (hours > 0) {
      timeParts.push(t('general.hour', { count: hours }));
    }
    if (minutes > 0) {
      timeParts.push(t('general.minute', { count: minutes }));
    }
    if (seconds > 0) {
      timeParts.push(t('general.second', { count: seconds }));
    }
    return timeParts.join(' ');
  }, [timestamp]);

  return (
    <span title={timesince} data-ta={dataTa}>
      {timesince}
    </span>
  );
};

export default Timesince;
