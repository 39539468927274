import React from 'react';
import { Size, Flex, Icon } from '@vaisala/rockhopper-components';

import { useTranslation } from 'react-i18next';
import { AckRequirement } from '../../../../constants';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import './acknowledge-message-alert.scss';
import makeTestId, { IMakeTestIdProps } from '../../../../utils/makeTestId';
import { TEST_IDS } from '../../../../tests/testids';
interface Props extends IMakeTestIdProps {
  acknowledgeRequired: AckRequirement;
}
const AcknowledgeMessageAlert: React.FC<Props> = ({ acknowledgeRequired, dataTa }) => {
  const { getTestId } = makeTestId({ dataTa });
  const { t } = useTranslation();
  return (
    <div data-ta={getTestId(TEST_IDS.error_message_container)}>
      {acknowledgeRequired === AckRequirement.REQUIRED && (
        <Flex
          dataTa={getTestId(TEST_IDS.ack_required_container)}
          className="acknowledge-message-alert-required-container"
          flexWrap="wrap"
          flexDirection="row"
        >
          <Flex.Item dataTa={getTestId(TEST_IDS.error_message_exclamation)} flexBasis="10%">
            <Icon
              dataTa={getTestId(TEST_IDS.error_message_exclamation_icon)}
              name={VaiIcon.Exclamation}
              size={Size.M}
            />
          </Flex.Item>
          <Flex.Item dataTa={getTestId(TEST_IDS.error_message_ack_required)} flexBasis="90%">
            {t('alarms.deviceAlarmDetails.acknowledgementRequired')}
          </Flex.Item>
        </Flex>
      )}
      <Flex
        dataTa={getTestId(TEST_IDS.no_ack_container)}
        className="acknowledge-message-alert-no-acknowledgement-container"
        flexWrap="wrap"
        flexDirection="row"
      >
        <Flex.Item dataTa={getTestId(TEST_IDS.cross_icon_container)} flexBasis="10%">
          <Icon dataTa={getTestId(TEST_IDS.cross_icon)} name={VaiIcon.Cross} size={Size.M} />
        </Flex.Item>
        <Flex.Item dataTa={getTestId(TEST_IDS.no_ack_text)} flexBasis="90%">
          {t('alarms.deviceAlarmDetails.noAcknowledgement')}
        </Flex.Item>
      </Flex>
    </div>
  );
};
export default AcknowledgeMessageAlert;
