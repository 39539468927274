import { BodyText, Flex, FlexItem, Size, Stack } from '@vaisala/rockhopper-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
  ALARM_PRIORITY,
  DEVICE_ALARM_STATUS,
  LOCATION_STATUS_TRANSLATION_KEYS,
  LS_COMPANY_CUSTOMER_ID
} from '../../../constants';
import { useLocationStatus } from '../../../hooks/useLocationStatus';
import { ALARM_PRIORITY_SUFFIXES, StatusItem } from './StatusItem';
import { showMeasurementSymbol } from '../../../utils/site';
import CenteredSpinner from '../../BaseComponents/CenteredSpinner';
import LocationHeader from '../Location/LocationHeader';
import { useGetLocationQuery } from '../../../store/services/siteApi';
import { getConstantFromLocalStorage, getHighestAlarmPriority } from '../../../utils';
import { useAppSelector } from '../../../store/hooks';
import { selectCurrentlyRemoving } from '../../../store/siteTree';
import { selectCurrentUser } from '../../../store/profile';
import StatusGraph from './StatusGraph';
import StatusMeasurement from './StatusMeasurement';
import { useThresholdStatus } from '../../../hooks/useThresholdStatus';
import { useDeviceStatus } from '../../../hooks/useDeviceStatus';

import './status.scss';

import { TEST_IDS } from '../../../tests/testids';
import makeTestId from '../../../utils/makeTestId';
import Infotip from '../../Utils/Infotip';

interface Props {
  onError?: (error: any) => void;
}

/**
 * Component for rendering infotip in the device status for unlinked locations.
 */
const DeviceStatusInfoTip = ({ htmlId, dataTa }: { htmlId?: string; dataTa?: string }) => {
  const { t } = useTranslation();
  return (
    <Infotip
      htmlId={htmlId}
      iconProps={{ className: 'device-status-infotip__icon' }}
      popoverProps={{ className: 'device-status-infotip', placement: 'bottom-end', dataTa: dataTa }}
    >
      <h4>{t('infotip.deviceStatusUnlinked')}</h4>
      <ol>
        <li>{t('infotip.deviceStatusUnlinked.para1')}</li>
        <li>{t('infotip.deviceStatusUnlinked.para2')}</li>
        <li>{t('infotip.deviceStatusUnlinked.para3')}</li>
      </ol>
    </Infotip>
  );
};

export const Status: React.FC<Props> = ({ onError }) => {
  const { dataTa } = makeTestId({ dataTa: TEST_IDS.status });
  const id = useParams<{ id?: string }>().id ?? '';
  const { t } = useTranslation();
  const currentlyRemovingNodeId = useAppSelector(selectCurrentlyRemoving);
  const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID) ?? '';
  const currentUser = useAppSelector(selectCurrentUser);
  const { currentData: location, error } = useGetLocationQuery(
    { customerId, id },
    { skip: id === currentlyRemovingNodeId }
  );

  React.useEffect(() => {
    if (onError && error) {
      onError(error);
    }
  }, [error]);

  const { locationStatus, locationLastMeasurement } = useLocationStatus(location);
  const { isLoading: isThresholdStatusLoading, status: thresholdStatus } = useThresholdStatus(location);
  const { isLoading: isDeviceStatusLoading, status: deviceStatus } = useDeviceStatus(location);

  const statusValueClass = (): string | undefined => {
    if (locationStatus === LOCATION_STATUS_TRANSLATION_KEYS.DEACTIVATED) {
      return `${STATUS_VALUE}--inactive`;
    }
    if (
      locationStatus === LOCATION_STATUS_TRANSLATION_KEYS.NO_DATA_SOURCE ||
      deviceStatus === DEVICE_ALARM_STATUS.UNLINKED
    ) {
      return `${STATUS_VALUE}--unlinked`;
    }
    if (location?.is_stale) {
      return `${STATUS_VALUE}--stale`;
    }
    if (location && (location.alarms_count || 0) > 0) {
      const priority = getHighestAlarmPriority(
        location.threshold_alarms.highest_priority ?? ALARM_PRIORITY.INFO,
        location.device_alarms.highest_priority ?? ALARM_PRIORITY.INFO
      );
      return priority ? `${STATUS_VALUE}${ALARM_PRIORITY_SUFFIXES[priority]}` : undefined;
    }
    return `${STATUS_VALUE}--active`;
  };

  if (!location) {
    return (
      <>
        <CenteredSpinner />
      </>
    );
  }

  return (
    <>
      <LocationHeader deviceStatus={deviceStatus} measurementType={location.meas_id} location={location} />
      <Flex
        data-ta={TEST_IDS.location_status_value}
        alignItems="center"
        className={`${STATUS_VALUE} ${statusValueClass()}`}
      >
        <FlexItem flexGrow={2}>
          <Flex flexDirection="row" flexWrap="nowrap">
            <BodyText className="status-value__title">
              <StatusMeasurement
                value={location.is_stale || !location.active ? null : location.raw_value}
                decimals={location.decimal_places}
              />
            </BodyText>
            <BodyText
              className="status-value__title status-value__title--measurement"
              dataTa={TEST_IDS.location_measurement_symbol}
            >
              {showMeasurementSymbol(location.symbol_id)}
            </BodyText>
          </Flex>
        </FlexItem>
        <FlexItem flexBasis={'200px'}>
          <Stack spacing={Size.S}>
            {!location.active && (
              <>
                <div
                  id={TEST_IDS.location_deactivated_status}
                  data-ta={TEST_IDS.location_deactivated_status}
                  className="alert-red strong"
                >
                  {t('site.statusLocationDeactivated')}
                </div>
                <div
                  id={TEST_IDS.location_will_not_alarm}
                  data-ta={TEST_IDS.location_will_not_alarm}
                  className="grey-medium"
                >
                  {t('site.locationWillNotAlarm')}
                </div>
              </>
            )}
            {currentUser?.canManageSites && (
              <Link
                id={TEST_IDS.go_to_settings_link}
                data-ta={TEST_IDS.go_to_settings_link}
                className="status-value__settings"
                to={`../../settings/${location.node_id}`}
              >
                {t('site.statusDetailSettings')}
              </Link>
            )}
          </Stack>
        </FlexItem>
      </Flex>
      <Flex
        flexDirection="row"
        flex-wrap="nowrap"
        alignItems="stretch"
        alignContent="stretch"
        justifyContent="space-between"
        className="status-collection"
      >
        <StatusItem
          type="measurement"
          status={locationLastMeasurement}
          label="Last measurement"
          translationKey="site.statusLastMeasurement"
          dataTa={TEST_IDS.location_timestamp}
        />
        <hr className="status-item__vertical-separator" />

        <StatusItem
          status={thresholdStatus ?? null}
          alarmCount={location.threshold_alarms?.count}
          isLoading={isThresholdStatusLoading}
          label="Threshold status"
          type="threshold"
          translationKey="site.statusThreshold"
          dataTa={TEST_IDS.location_threshold}
          measurementType={location?.meas_id}
        />
        <hr className="status-item__vertical-separator" />
        <StatusItem
          status={deviceStatus ?? null}
          alarmCount={location.device_alarms?.count}
          isLoading={isDeviceStatusLoading}
          type="device"
          label="Device status"
          translationKey="site.statusDevice"
          dataTa={TEST_IDS.location_device_status}
          infotip={
            <DeviceStatusInfoTip
              htmlId={TEST_IDS.location_device_status_tooltip}
              dataTa={TEST_IDS.location_device_status_tooltip}
            />
          }
        />
      </Flex>
      <StatusGraph dataTa={dataTa} location={location} />
    </>
  );
};

export const STATUS_VALUE = 'status-value';
