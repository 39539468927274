export const LOGIN = 'AUTH::LOGIN';
export const LOGIN_ERROR = 'AUTH::LOGIN_ERROR';
export const LOGIN_SUCCESS = 'AUTH::LOGIN_SUCCESS';
export const LOGOUT = 'AUTH::LOGOUT';
export const CHANGING_PASSWORD_START = 'AUTH::CHANGING_PASSWORD_START';
export const CHANGING_PASSWORD = 'AUTH::CHANGING_PASSWORD';
export const CHANGING_PASSWORD_SUCCESS = 'AUTH::CHANGING_PASSWORD_SUCCESS';
export const CHANGING_PASSWORD_FAIL = 'AUTH::CHANGING_PASSWORD_FAILED';
export const CHANGING_PASSWORD_COMPLETED = 'AUTH::CHANGING_PASSWORD_COMPLETED';
export const SENDING_RESET_CODE_INIT = 'AUTH::SENDING_RESET_CODE_INIT';
export const SENDING_RESET_CODE = 'AUTH::SENDING_RESET_CODE';
export const SENDING_RESET_CODE_SUCCESS = 'AUTH::SENDING_RESET_CODE_SUCCESS';
export const SENDING_RESET_CODE_FAIL = 'AUTH::SENDING_RESET_CODE_FAIL';
export const RESETTING_PASSWORD = 'AUTH::RESETTING_PASSWORD';
export const RESETTING_PASSWORD_SUCCESS = 'AUTH::RESETTING_PASSWORD_SUCCESS';
export const RESETTING_PASSWORD_FAIL = 'AUTH::RESETTING_PASSWORD_FAIL';
export const PASSWORD_POLICY_START = 'AUTH::PASSWORD_POLICY_START';
export const PASSWORD_POLICY_SUCCESS = 'AUTH::PASSWORD_POLICY_SUCCESS';
export const PASSWORD_POLICY_FAIL = 'AUTH::PASSWORD_POLICY_FAIL';
export const SIGNUP_START = 'AUTH::SIGNUP_START';
export const SIGNUP_SUCCESS = 'AUTH::SIGNUP_SUCCESS';
export const SETUP_PASSWORD_START = 'AUTH::SETUP_PASSWORD_START';
export const SETUP_PASSWORD_SUCCESS_WITH_PROFILE_COMPLETED = 'AUTH::SETUP_PASSWORD_SUCCESS_WITH_PROFILE_COMPLETED';
export const SETUP_PASSWORD_SUCCESS_WITHOUT_PROFILE_COMPLETED =
  'AUTH::SETUP_PASSWORD_SUCCESS_WITHOUT_PROFILE_COMPLETED';
export const SETUP_PASSWORD_FAIL = 'AUTH::SETUP_PASSWORD_FAIL';
export const SETUP_PROFILE_SUCCESS = 'AUTH::SETUP_PROFILE_SUCCESS';
export const LOADING_TENANT_INFO_FAIL = 'AUTH::LOADING_TENANT_INFO_FAIL';
export const LOADING_TENANT_INFO_SUCCESS = 'AUTH::LOADING_TENANT_INFO_SUCCESS';
export const MFA_START = 'AUTH::MFA_START';
export const MFA_PREP = 'AUTH::MFA_PREP';
export const MFA_SUCCESS = 'AUTH::MFA_SUCCESS';
export const MFA_FAIL = 'AUTH::MFA_FAIL';
export const MFA_END = 'AUTH::MFA_END';
export const RESEND_MFA_FAIL = 'AUTH::RESEND_MFA_FAIL';
export const USER_PROFILE_SETUP = 'AUTH::USER_PROFILE_SETUP';
export const SEND_RESET_PASSWORD_EMAIL_SUCCESS = 'AUTH::SEND_RESET_PASSWORD_EMAIL_SUCCESS';
export const SEND_RESET_PASSWORD_EMAIL_FAIL = 'AUTH::SEND_RESET_PASSWORD_EMAIL_FAIL';
export const SET_RESETTING_PASSWORD_ERROR = 'AUTH::SET_RESETTING_PASSWORD_ERROR';
export const SET_AUTH_ERROR = 'AUTH::SET_AUTH_ERROR';

export const GET_USER_LIST_START = 'PROFILE::GET_USER_LIST_START';
export const GET_USER_LIST_SUCCESS = 'PROFILE::GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAIL = 'PROFILE::GET_USER_LIST_FAIL';
export const GET_USER_PROFILE_START = 'PROFILE::GET_USER_PROFILE_START';
export const GET_USER_PROFILE_SUCCESS = 'PROFILE::GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAIL = 'PROFILE::GET_USER_PROFILE_FAIL';
export const GET_MY_USER_PROFILE_ON_INIT = 'PROFILE::GET_USER_PROFILE_ON_INIT';
export const GET_MY_USER_PROFILE_ON_INIT_SUCCESS = 'PROFILE::GET_USER_PROFILE_ON_INIT_SUCCESS';
export const GET_MY_USER_PROFILE_ON_INIT_FAIL = 'PROFILE::GET_USER_PROFILE_ON_INIT_FAIL';
export const GET_MY_USER_PROFILE_START = 'PROFILE::GET_MY_USER_PROFILE_START';
export const GET_MY_USER_PROFILE_SUCCESS = 'PROFILE::GET_MY_USER_PROFILE_SUCCESS';
export const GET_MY_USER_PROFILE_FAIL = 'PROFILE::GET_USER_MY_PROFILE_FAIL';
export const TOGGLE_USER_ACTIVATION_SUCCESS = 'PROFILE::TOGGLE_USER_ACTIVATION_SUCCESS';
export const TOGGLE_USER_ACTIVATION_FAIL = 'PROFILE::TOGGLE_USER_ACTIVATION_FAIL';
export const EDIT_PROFILE_SUCCESS = 'PROFILE::EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_FAIL = 'PROFILE::EDIT_PROFILE_FAIL';
export const GET_GROUP_LIST_START = 'PROFILE::GET_GROUP_LIST_START';
export const GET_GROUP_LIST_SUCCESS = 'PROFILE::GET_GROUP_LIST_SUCCESS';
export const GET_GROUP_LIST_FAIL = 'PROFILE::GET_GROUP_LIST_FAIL';
export const INVITE_USERS_SUCCESS = 'PROFILE::INVITE_USERS_SUCCESS';
export const INVITE_USERS_FAIL = 'PROFILE::INVITE_USERS_FAIL';
export const ADD_GROUP_MEMBERS_SUCCESS = 'PROFILE::ADD_GROUP_MEMBERS_SUCCESS';
export const DELETE_USER_SUCCESS = 'PROFILE::DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'PROFILE::DELETE_USER_FAIL';
export const CREATE_GROUPS_SUCCESS = 'PROFILE::CREATE_GROUPS_SUCCESS';
export const CREATE_GROUPS_FAIL = 'PROFILE::CREATE_GROUPS_FAIL';
export const GET_GROUP_DETAILS_START = 'PROFILE::GET_GROUP_DETAILS_START';
export const GET_GROUP_DETAILS_SUCCESS = 'PROFILE::GET_GROUP_DETAILS_SUCCESS';
export const GET_GROUP_DETAILS_FAIL = 'PROFILE::GET_GROUP_DETAILS_FAIL';
export const EDIT_GROUP_SUCCESS = 'PROFILE::EDIT_GROUP_SUCCESS';
export const EDIT_GROUP_FAIL = 'PROFILE::EDIT_GROUP_FAIL';
export const DELETE_GROUP_SUCCESS = 'PROFILE::DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAIL = 'PROFILE::DELETE_GROUP_FAIL';
export const TOGGLE_GROUP_ACTIVATION_SUCCESS = 'PROFILE::TOGGLE_GROUP_ACTIVATION_SUCCESS';
export const TOGGLE_GROUP_ACTIVATION_FAIL = 'PROFILE::TOGGLE_GROUP_ACTIVATION_FAIL';
export const DELETE_GROUP_USERS_SUCCESS = 'PROFILE::DELETE_USERS_FROM_GROUP_SUCCESS';
export const DELETE_GROUP_USERS_FAIL = 'PROFILE::DELETE_USERS_FROM_GROUP_FAIL';
export const REFRESH_GROUPS = 'PROFILE::REFRESH_GROUPS';

export const SHOW_DIALOG = 'DIALOG:SHOW_DIALOG';
export const HIDE_DIALOG = 'DIALOG:HIDE_DIALOG';

export const NOTIFICATION_TYPE_ADD = 'NOTIFICATION::ADD';
export const NOTIFICATION_TYPE_REMOVE = 'NOTIFICATION::REMOVE';

export const GET_SITE_START = 'SITETREE::GET_SITE_START';
export const GET_SITE_SUCCESS = 'SITETREE::GET_SITE_SUCCESS';
export const GET_SITE_FAIL = 'SITETREE::GET_SITE_FAIL';
export const CREATE_TREE_NODE_SUCCESS = 'SITETREE::CREATE_TREE_NODE_SUCCESS';
export const CREATE_TREE_NODE_FAIL = 'SITETREE::CREATE_TREE_NODE_FAIL';
export const EDIT_TREE_NODE_SUCCESS = 'SITETREE::EDIT_TREE_NODE_SUCCESS';
export const EDIT_TREE_NODE_FAIL = 'SITETREE::EDIT_TREE_NODE_FAIL';
export const DELETE_TREE_NODE_SUCCESS = 'SITETREE::DELETE_TREE_NODE_SUCCESS';
export const DELETE_TREE_NODE_FAIL = 'SITETREE::DELETE_TREE_NODE_FAIL';
export const CLEAR_EXPAND_NODE_IDS = 'SITETREE::CLEAR_EXPAND_NODE_IDS';

export const GET_EVENT_LIST_START = 'EVENT::GET_EVENT_LIST_START';
export const GET_EVENT_LIST_SUCCESS = 'EVENT::GET_EVENT_LIST_SUCCESS';
export const GET_EVENT_LIST_FAIL = 'EVENT::GET_EVENT_LIST_FAIL';
export const GET_EVENT_LIST_TOTAL_COUNT = 'EVENT::GET_EVENT_LIST_TOTAL_COUNT';

export const GET_EVENT_DETAIL_START = 'EVENT::GET_EVENT_DETAIL_START';
export const GET_EVENT_DETAIL_SUCCESS = 'EVENT::GET_EVENT_DETAIL_SUCCESS';
export const GET_EVENT_DETAIL_FAIL = 'EVENT::GET_EVENT_DETAIL_FAIL';

export const SET_REPORT_API_LOADING = 'REPORT::SET_REPORT_API_LOADING';
export const SET_REPORT_INTERVAL_DATE = 'REPORT::SET_INTERVAL_DATE';
export const SET_REPORT_LOCATIONS = 'REPORT::SET_LOCATIONS';
export const SET_REPORT_SELECTED_FORMATTED_LOCATIONS = 'REPORT::SET_SELECTED_FORMATTED_LOCATIONS';
export const SET_REPORT_SELECTED_LOCATIONS_CUSTOM_PROPS = 'REPORT::SET_SELECTED_LOCATIONS_CUSTOM_PROPS';
export const SET_REPORT_SELECTED_LOCATIONS_NUM = 'REPORT::SET_SELECTED_LOCATIONS_NUM';
export const SET_REPORT_SELECTED_LOCATIONS_IDS = 'REPORT::SET_SELECTED_LOCATIONS_IDS';
export const SET_REPORT_SELECTED_LOCATIONS_SK = 'REPORT::SET_SELECTED_LOCATIONS_SK';
export const SET_REPORT_GRAPHS_NUM = 'REPORT::SET_GRAPHS_NUM';
export const SET_REPORT_VISIBLE_LOCATIONS = 'REPORT::SET_VISIBLE_LOCATIONS';
export const SET_REPORT_ASSIGNED_CHART_COLORS = 'REPORT::SET_ASSIGNED_CHART_COLORS';
export const RESET_REPORTS_STATE = 'REPORT::RESET_REPORTS_STATE';
