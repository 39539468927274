import * as React from 'react';
import { Size, Button, ButtonType, BodyText } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../store/hooks';
import { hideDialog } from '../../../store/dialog';
import { ModalContainer } from '../../Modals/ModalContainer';
import { TEST_IDS } from '../../../tests/testids';

interface UserDeletionDialogProps {
  deleteGroup: (groupId: string, groupName: string) => void;
  groupId: string;
  groupName: string;
}

const GroupDeletionDialog = ({ deleteGroup, groupId, groupName }: UserDeletionDialogProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const deleteGroupOnClick = () => {
    deleteGroup(groupId, groupName);
    onDismiss();
  };

  const onDismiss = () => dispatch(hideDialog());

  const modalButtons = (
    <>
      <Button
        dataTa={TEST_IDS.group_delete_dialog_confirm}
        id="group-deletion-confirm"
        htmlId="group-deletion-confirm"
        onClick={deleteGroupOnClick}
      >
        {t('profile.groupDeletionConfirm')}
      </Button>
      <Button
        id="group-deletion-cancel"
        htmlId="group-deletion-cancel"
        onClick={onDismiss}
        buttonType={ButtonType.Secondary}
      >
        {t('profile.cancel')}
      </Button>
    </>
  );

  return (
    <ModalContainer
      id="group-deletion-dialog"
      data-ta={TEST_IDS.group_delete_dialog}
      isOpen
      htmlId="group-deletion-dialog"
      onDismiss={onDismiss}
      width={Size.S}
      dismissOnOverlayClick={false}
      title="profile.groupDeletionTitle"
      role="alert-dialog"
      showCloseIcon
      buttons={modalButtons}
    >
      <BodyText id="delete-group-body-text" className="delete-group-body">
        {t('profile.groupDeletionMsg')}
      </BodyText>
    </ModalContainer>
  );
};

export default GroupDeletionDialog;
