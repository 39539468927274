import { Checkbox, Flex, FlexItem, Grid, Icon, InputField, Size } from '@vaisala/rockhopper-components';
import React, { useEffect, useRef, useState } from 'react';
import { VaiColor, VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import CenteredSpinner from '../../../BaseComponents/CenteredSpinner';
import { TEST_IDS } from '../../../../tests/testids';
import { QA_TEST_IDS } from '../../../../tests/qaTestIds';
import { useTranslation } from 'react-i18next';
import { RIGHTS_MASK } from '../../../../rightsConstants';
import { thingHasRights } from '../../../../utils';
import { Group } from '../../../../store/services/userApi';
import { LocationNode } from '../../../../siteTree/site';
import './groupsSelect.scss';

interface Props {
  onSelect: (groups: string[]) => void;
  isLoading: boolean;
  domIds?: { selectorId: string; checkboxId: string };
  groups: Group[] | undefined;
  selectedGroups: Group['group_id'][];
  checkCanAcknowledgeAlarms: (group: Group) => boolean;
  onBlur: (groupList: GroupSelectItem[]) => void;
}

const defaultIds = { selectorId: '', checkboxId: '' };

type GroupSelectItem = {
  id: string;
  name: string;
  description: string;
  numberOfUsers: number;
  selected: boolean;
  canAcknowledgeAlarms: boolean;
};

const GroupsSelect: React.FC<Props> = ({
  onSelect,
  isLoading,
  groups,
  checkCanAcknowledgeAlarms,
  selectedGroups,
  onBlur,
  domIds = defaultIds
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation();
  const [groupsList, setGroupsList] = useState<GroupSelectItem[]>([]);

  useEffect(() => {
    const groupList: GroupSelectItem[] = [];
    groups?.map(group => {
      const canAcknowledgeAlarms = checkCanAcknowledgeAlarms(group);
      groupList.push({
        id: group.group_id,
        name: group.group_name,
        numberOfUsers: group.users.length,
        selected: selectedGroups.includes(group.group_id),
        canAcknowledgeAlarms,
        description: group.description
      });
    });

    setGroupsList(groupList);
  }, [groups, selectedGroups]);

  const openDropdown = () => {
    setIsDropdownOpen(true);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const validationRef = useRef(() => {
    return;
  });

  useEffect(() => {
    validationRef.current = () => onBlur(groupsList);
  }, [groupsList]);

  const ref = useOutsideClick<HTMLDivElement>(() => {
    closeDropdown();
    validationRef.current();
  });

  const allSelected = groupsList.every(group => group.selected === true);
  const numberOfSelectedGroups = groupsList.filter(group => group.selected).length;
  const totalNumberOfGroups = groupsList.length;
  const display = groupsList
    .filter(group => group.selected)
    .map(group => group.name)
    .join(', ');
  const filteredList = groupsList.filter(group =>
    group.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
  );
  const searchResultNotFound = filteredList.length === 0;

  const { alarms_settings_threshold_notifications_group_select_checkbox_select_all } = QA_TEST_IDS;

  return (
    <div className="notification-group-select">
      {!isDropdownOpen ? (
        <Flex
          dataTa={TEST_IDS.alarm_settings_threshold_notifications_form_group_select.selector}
          id={domIds.selectorId}
          htmlId={domIds.selectorId}
          className="notification-group-selector"
          onClick={openDropdown}
          alignItems="center"
        >
          {numberOfSelectedGroups === 0 && <FlexItem flexGrow={1}>None</FlexItem>}
          {numberOfSelectedGroups > 0 && (
            <FlexItem className="notification-group-selector-display" flexGrow={1}>
              {display}
            </FlexItem>
          )}
          <FlexItem>
            <Icon name={VaiIcon.ChevronDown} size={Size.M} />
          </FlexItem>
        </Flex>
      ) : (
        <div ref={ref} className="group-select">
          <p className="selected-group-display">{`${numberOfSelectedGroups}/${totalNumberOfGroups} groups selected`}</p>
          <InputField
            data-ta={TEST_IDS.alarm_settings_threshold_notifications_form_group_select.search_field}
            id={QA_TEST_IDS.alarms_settings_threshold_notifications_group_select_search_field}
            htmlId={QA_TEST_IDS.alarms_settings_threshold_notifications_group_select_search_field}
            className="search-field"
            placeholder="Search"
            endIcon={<Icon name={VaiIcon.Search} />}
            value={searchValue}
            onChange={event => {
              setSearchValue(event.target.value);
            }}
          />
          <div className="group-list">
            {isLoading && <CenteredSpinner />}
            <ul>
              {!searchResultNotFound && (
                <>
                  <li key="selectAll" data-ta={TEST_IDS.alarm_settings_threshold_notifications_form_group_select.item}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Col md={1} xl={1} lg={1}>
                          <Checkbox
                            id={alarms_settings_threshold_notifications_group_select_checkbox_select_all}
                            htmlId={alarms_settings_threshold_notifications_group_select_checkbox_select_all}
                            onChange={event => {
                              const updatedList = groupsList.map(listItem => {
                                listItem.selected = event.target.checked;
                                return listItem;
                              });
                              setGroupsList(updatedList);
                              onSelect(updatedList.filter(group => group.selected).map(group => group.id));
                            }}
                            checked={allSelected}
                          />
                        </Grid.Col>
                        <Grid.Col md={10} xl={10} lg={10}>
                          {t('general.selectAll')}
                        </Grid.Col>
                      </Grid.Row>
                    </Grid>
                  </li>
                  <hr />
                </>
              )}
              {groupsList
                .filter(group => group.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
                .map(group => {
                  const checkboxId = `${domIds.checkboxId}${group.name}`;
                  return (
                    <li
                      key={group.name}
                      data-ta={TEST_IDS.alarm_settings_threshold_notifications_form_group_select.item}
                    >
                      <Grid>
                        <Grid.Row>
                          <Grid.Col md={1} xl={1} lg={1}>
                            <Checkbox
                              id={checkboxId}
                              htmlId={checkboxId}
                              onChange={event => {
                                const updatedList = [...groupsList];
                                const foundGroup = updatedList.find(listItem => listItem.id === group.id);
                                if (foundGroup) {
                                  foundGroup.selected = event.target.checked;
                                  setGroupsList(updatedList);
                                  onSelect(updatedList.filter(group => group.selected).map(group => group.id));
                                }
                              }}
                              checked={group.selected}
                            />
                          </Grid.Col>
                          <Grid.Col className="group-dropdown-group-details" md={10} xl={10} lg={10}>
                            <Grid.Row>
                              <Grid.Col xs={9} lg={9} xl={9}>
                                <div>{group.name}</div>
                              </Grid.Col>
                              <Grid.Col md={3} xs={3} lg={3} xl={3}>
                                <div className="no-word-wrap">{group.numberOfUsers} Users</div>
                              </Grid.Col>
                            </Grid.Row>
                            {group.description && (
                              <Grid.Row>
                                <Grid.Col lg={9} xl={9}>
                                  <div>
                                    <div className="group-description text-ellipsis">{group.description}</div>
                                  </div>
                                </Grid.Col>
                                <Grid.Col lg={3} xl={3}>
                                  {/* placeholder */}
                                </Grid.Col>
                              </Grid.Row>
                            )}
                            <Grid.Row>
                              <Grid.Col>{!group.canAcknowledgeAlarms && <CannotAcknowledgeAlarms />}</Grid.Col>
                            </Grid.Row>
                          </Grid.Col>
                        </Grid.Row>
                      </Grid>
                      <hr />
                    </li>
                  );
                })}
              {searchResultNotFound && !isLoading && 'No result'}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupsSelect;

function CannotAcknowledgeAlarms() {
  const { t } = useTranslation();

  return (
    <div className="cannot-acknowledge-alarms">
      <Icon name={VaiIcon.AlertInfo} color={VaiColor.AquaVaisala} /> {t('site.cannotAcknowledgeAlarms')}
    </div>
  );
}

export function checkCanAcknowledgeAlarmsCurried(node_id: LocationNode['node_id']) {
  return (group: Group) => {
    if (group.rights) {
      return thingHasRights([RIGHTS_MASK.ACKNOWLEDGE_ALARMS], group.rights['*'] || group.rights[node_id]);
    }
    return false;
  };
}
