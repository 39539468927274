import { Icon, Size } from '@vaisala/rockhopper-components';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import * as React from 'react';
import validator from 'validator';
import useHtmlId from '../../../hooks/useHtmlId';
import { TEST_IDS } from '../../../tests/testids';
import './tag-input.scss';

interface TagInputProps {
  tags: string[];
  addTagData: (e: any) => void;
  removeTagData: (index: number) => void;
  onTagsUpdated?: (validTags: string[]) => void;
  validation?: string;
  placeholder: string;
  htmlId?: string;
}

interface Tag {
  name: string;
  valid: boolean;
}

const TagInput = (props: TagInputProps): JSX.Element => {
  const [tags, setTags] = React.useState<Tag[]>([]);
  const { getId } = useHtmlId({ htmlId: props.htmlId, separator: '__' });

  React.useEffect(() => {
    const temp: Tag[] = [];
    const validTags: string[] = [];
    props.tags.map(tag => {
      const valid = props.validation === 'email' ? validator.isEmail(tag) : true;
      if (valid) validTags.push(tag);
      temp.push({ name: tag, valid });
    });
    props.onTagsUpdated?.(validTags);
    setTags(temp);
  }, [props.tags]);

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const valid = props.validation === 'email' ? validator.isEmail(value) : true;
    if (valid) {
      props.addTagData(event);
    }
  };

  return (
    <div id={getId('container')} className="tag-input">
      <ul id={getId('ul')} className="tags">
        {tags.map((tag, index) => (
          <li
            data-ta={TEST_IDS.tag_input_list_item}
            key={index}
            id={getId(`tag-${tag.name}`)}
            className={tag.valid ? 'tag valid-tag' : 'tag invalid-tag'}
          >
            <span id={getId(`tag-title-${tag.name}`)} className="tag-title">
              {tag.name}
            </span>
            <Icon
              id={getId(`tag-close-icon-${tag.name}`)}
              dataTa={'tag-close-icon'}
              className="tag-close-icon"
              onClick={() => props.removeTagData(index)}
              name={VaiIcon.Cross}
              size={Size.XS}
            />
          </li>
        ))}
      </ul>
      {/* eslint-disable-next-line enforce-ids-in-jsx/missing-ids */}
      <input
        id={props.htmlId}
        data-ta={TEST_IDS.tag_input_field}
        type="text"
        onKeyUp={event => {
          return event.key === 'Enter' || event.key === ',' || event.key === ' ' || event.key === ';'
            ? props.addTagData(event)
            : null;
        }}
        onBlur={handleBlur}
        placeholder={tags.length > 0 ? '' : props.placeholder}
      />
    </div>
  );
};

export default TagInput;
