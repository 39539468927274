import * as ActionType from '../actions/actionTypes';
import { EventInterface } from '../../events/events';

interface genericAction {
  readonly type: string;
  readonly error?: any;
}

interface getEventAction {
  readonly type: string;
  readonly events?: EventInterface[];
  readonly start?: number;
  readonly error?: any;
  readonly eventDetail?: any;
  readonly totalCount?: number;
}

type EventAction = getEventAction | genericAction;

export interface EventState {
  readonly eventsError?: any;
  readonly eventsLoading: boolean;
  readonly events?: EventInterface[];
  readonly eventDetail?: any;
  readonly totalCount?: number;
  readonly start?: number;
}

export const initialState: EventState = {
  eventsLoading: false,
  events: [],
  eventsError: undefined,
  eventDetail: {},
  totalCount: 0,
  start: 0
};

export default function eventsReducer(state: EventState = initialState, action: EventAction): EventState {
  switch (action.type) {
    case ActionType.LOGOUT:
      return { ...state, ...initialState };
    case ActionType.GET_EVENT_LIST_START:
      return { ...state, eventsLoading: true, eventsError: undefined };
    case ActionType.GET_EVENT_LIST_SUCCESS:
      return {
        ...state,
        start: (action as getEventAction).start,
        events: (action as getEventAction).events,
        eventsLoading: false
      };
    case ActionType.GET_EVENT_LIST_TOTAL_COUNT:
      return {
        ...state,
        totalCount: (action as getEventAction).totalCount,
        eventsLoading: false
      };
    case ActionType.GET_EVENT_LIST_FAIL:
      return {
        ...state,
        eventsLoading: false,
        eventsError: (action as getEventAction).error
      };
    case ActionType.GET_EVENT_DETAIL_SUCCESS:
      return {
        ...state,
        eventDetail: (action as getEventAction).eventDetail,
        eventsLoading: false
      };
    default:
      return state;
  }
}
