import React from 'react';
import { BodyText, Heading } from '@vaisala/rockhopper-components';
import { Illustration, IllustrationType } from '../Utils/EmptyState/Illustration';
import { useTranslation } from 'react-i18next';
import { QA_TEST_IDS } from '../../tests/qaTestIds';
import { TEST_IDS } from '../../tests/testids';

export const EventsNotFound = () => {
  const { t } = useTranslation();
  const { events_not_found_text, events_not_found_subtext } = QA_TEST_IDS;

  return (
    <div data-ta={TEST_IDS.events_not_found} id={QA_TEST_IDS.events_not_found} className="centered-elements-box">
      <Illustration
        dataTa={TEST_IDS.events_not_found_icon}
        id={QA_TEST_IDS.events_not_found_icon}
        illustration={IllustrationType.nothingFound}
      />
      <Heading htmlId={events_not_found_text} level={2}>
        {t('general.nothingFound')}
      </Heading>
      <BodyText htmlId={events_not_found_subtext}>{t('events.notfound.bodytext')}</BodyText>
    </div>
  );
};
