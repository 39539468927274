import React from 'react';
import { DeviceModel } from '../../../types';
import { DeviceDataSourceCard } from './DeviceDataSourceCard';

type DeviceManagerDataSourcesProps = {
  device: DeviceModel;
};

const DeviceManagerDataSources = ({ device }: DeviceManagerDataSourcesProps) => {
  const dataSources = device.status?.datasources;
  return (
    <div className="device-datasources">
      {dataSources &&
        dataSources.map(dataSource => {
          return (
            <DeviceDataSourceCard
              key={`${dataSource.probe_model}-${dataSource.probe_sn}-${dataSource.meas_id}-${dataSource.location_id}`}
              device={device}
              dataSource={dataSource}
            />
          );
        })}
    </div>
  );
};
export default DeviceManagerDataSources;
