import { nanoid } from '@reduxjs/toolkit';
import { Button, ButtonType, Flex, Icon, Size, Stack } from '@vaisala/rockhopper-components';
import { VaiColor, VaiIcon } from '@vaisala/rockhopper-design-tokens';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useHtmlId, { IUseHtmlIDProps } from '../../hooks/useHtmlId';
import { TranslationKey } from '../../react-i18next';
import { TEST_IDS } from '../../tests/testids';

interface Props extends IUseHtmlIDProps {
  refreshResults?: () => void;
  errorMsgTranslationKey: TranslationKey;
}

const ListError: React.FC<Props> = ({
  errorMsgTranslationKey,
  htmlId = nanoid(),
  refreshResults = undefined,
  children
}) => {
  const { getId } = useHtmlId({ htmlId });
  const { t } = useTranslation();
  return (
    <Stack dataTa={TEST_IDS.list_error_container} id={getId('error-container')} spacing={Size.XS}>
      <Flex
        dataTa={TEST_IDS.list_error_text_container}
        id={getId('error-text-container')}
        alignItems="center"
        justifyContent="center"
      >
        <Icon
          id={getId('error-icon')}
          dataTa={TEST_IDS.list_error_icon}
          name={VaiIcon.Warning}
          className="vai-margin-right-s"
          color={VaiColor.Red}
          size={Size.L}
        />
        <h3 data-ta={TEST_IDS.list_error_text} id={getId('error-text')}>
          {t(errorMsgTranslationKey)}
        </h3>
      </Flex>
      {children}
      {refreshResults && (
        <Flex
          dataTa={TEST_IDS.list_error_refresh_button_container}
          id={getId('error-reload-button-container')}
          justifyContent="center"
        >
          {/* eslint-disable-next-line enforce-ids-in-jsx/missing-ids */}
          <Button id={getId('error-reload-button')} buttonType={ButtonType.Secondary} onClick={refreshResults}>
            {t('general.refresh')}
            {/* <Icon name={VaiIcon.Refresh} size={Size.XS} style={{alignContent: "center"}} className="vai-margin-right-xs" /> */}
          </Button>
        </Flex>
      )}
    </Stack>
  );
};

export default ListError;
