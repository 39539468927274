import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_ATLASSIAN_STATUS_BASE_URI } from '../../constants';
import { AtlassianApiUris } from '../../utils';

type Component = {
  id: string;
  name: string;
  status: string;
  created_at: string;
  updated_at: string;
  position: number;
  description: string | null;
  showcase: boolean;
  start_date: string;
  group_id: string | null;
  page_id: string;
  group: boolean;
  only_show_if_degraded: boolean;
};

type Page = {
  id: string;
  name: string;
  url: string;
  time_zone: string;
  updated_at: string;
};

export type AtlassianStatusApiResponse = {
  page: Page;
  components: Component[];
};

const getAppcuesHeaders = (headers: Headers): Headers => {
  headers.set('Accept', 'application/json');
  headers.set('Content-Type', 'application/json');
  headers.set('Accept-Encoding', 'gzip, deflate, br');
  return headers;
};

export const atlassianApi = createApi({
  reducerPath: 'atlassianApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_ATLASSIAN_STATUS_BASE_URI}`,
    prepareHeaders: async headers => {
      headers = getAppcuesHeaders(headers);
      return headers;
    }
  }),

  endpoints: builder => ({
    getStatus: builder.query<AtlassianStatusApiResponse, void>({
      query: () => {
        return {
          url: AtlassianApiUris.components
        };
      }
    })
  })
});

export const { useGetStatusQuery } = atlassianApi;
