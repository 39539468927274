import { useEffect, useState } from 'react';
import { LocationNode } from '../siteTree/site';
import { ALARM_PRIORITY, DEVICE_ALARM_STATUS } from '../constants';
import { isAlarmState } from '../utils';

/**
 *
 * @param location {@link LocationNode}
 * @returns boolean
 *
 *  **Unlinked**
 * * If the location does not have device info.
 */
function isDeviceUnlinked(location: LocationNode): boolean {
  return !location?.device;
}

/**
 *
 * @param location {@link LocationNode}
 * @returns boolean
 *
 *  **Alarming**
 * * If the location has alarm rules activated and has device alarms.
 */
function isDeviceAlarming(location: LocationNode): boolean {
  const isAlarmSet = isAlarmState.set(location.device_alarm_set);
  const isAlarmDefault = isAlarmState.default(location.device_alarm_set);
  const hasDeviceAlarms = !!location?.device_alarms && location.device_alarms.count > 0;
  return (isAlarmSet || isAlarmDefault) && hasDeviceAlarms;
}

/**
 *
 * @param location {@link LocationNode}
 * @returns boolean
 *
 *  **OK**
 * * If the location does not have any current device alarms.
 */
function isDeviceOK(location: LocationNode): boolean {
  if (!location.device_alarm_set) {
    return true;
  }
  const isAlarmSet = isAlarmState.set(location.device_alarm_set);
  const isAlarmDefault = isAlarmState.default(location.device_alarm_set);
  const hasDeviceAlarms = !!location?.device_alarms && location.device_alarms.count > 0;
  return (isAlarmSet || isAlarmDefault) && !hasDeviceAlarms;
}

/**
 *
 * @param location {@link LocationNode}
 * @returns boolean
 *
 *  **Not set**
 * * If the location does not have alarm rules activated.
 */
export function isDeviceAlarmsNotSet(location: LocationNode): boolean {
  return !location.device_alarm_set || isAlarmState.notSet(location.device_alarm_set);
}

interface StatusDetails {
  isLoading: boolean;
  status: DEVICE_ALARM_STATUS | ALARM_PRIORITY | undefined;
}

export const useDeviceStatus = (location: LocationNode | undefined) => {
  const [statusDetails, setStatusDetails] = useState<StatusDetails>({
    isLoading: true,
    status: undefined
  });

  useEffect(() => {
    setStatusDetails(() => {
      const updatedDetails: StatusDetails = {
        isLoading: false,
        status: undefined
      };
      if (!location) {
        updatedDetails.isLoading = true;
        return updatedDetails;
      }
      switch (true) {
        case isDeviceUnlinked(location):
          updatedDetails.status = DEVICE_ALARM_STATUS.UNLINKED;
          break;
        case isDeviceAlarmsNotSet(location):
          updatedDetails.status = DEVICE_ALARM_STATUS.NOT_SET;
          break;
        case isDeviceAlarming(location):
          updatedDetails.status = location.device_alarms?.highest_priority ?? undefined;
          break;
        case isDeviceOK(location):
          updatedDetails.status = DEVICE_ALARM_STATUS.OK;
          break;
        default:
          updatedDetails.isLoading = true;
          break;
      }
      return updatedDetails;
    });
  }, [location]);

  return statusDetails;
};
