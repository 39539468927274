import * as React from 'react';
import './user-deletion-dialog.scss';
import { Size, Button, ButtonType, BodyText, NotificationType } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../store/hooks';
import { ModalContainer } from '../../Modals/ModalContainer';
import { useNavigate } from 'react-router-dom';
import { getConstantFromLocalStorage, routes } from '../../../utils';
import { useDeleteUserMutation } from '../../../store/services/userApi';
import { LS_COMPANY_CUSTOMER_ID } from '../../../constants';
import { addNotification } from '../../../store/notifications';
import * as ActionType from '../../../store/actions/actionTypes';
import { ProfileError } from '../../../store/actions/profile';
import { ButtonWithLoader } from '../../ButtonWithLoader/ButtonWithLoader';

interface UserDeletionDialogProps {
  userName: string;
  onDismiss: () => void;
}

const UserDeletionDialog = ({ onDismiss, userName }: UserDeletionDialogProps): JSX.Element => {
  const { t } = useTranslation();
  const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID) ?? '';
  const dispatch = useAppDispatch();
  const [deleteUser, deleteUserResult] = useDeleteUserMutation();
  const navigate = useNavigate();

  const handleDeleteUser = () => {
    deleteUser({ username: userName, customerId });
  };

  const goToUsers = () => {
    navigate(`${routes.profile.url}/${routes.profile.users.url}`);
  };

  React.useEffect(() => {
    if (deleteUserResult.isSuccess) {
      dispatch({ type: ActionType.DELETE_USER_SUCCESS });
      goToUsers();
      dispatch(
        addNotification({
          type: NotificationType.Ok,
          content: t('profile.notifyUserDeleted')
        })
      );
      onDismiss();
    }
    if (deleteUserResult.isError) {
      dispatch({ type: ActionType.DELETE_USER_FAIL, error: ProfileError.customerIdError });
      dispatch(
        addNotification({
          type: NotificationType.Error,
          content: t('profile.notifyDeleteUserFailed')
        })
      );
      onDismiss();
    }
  }, [deleteUserResult.isSuccess, deleteUserResult.isError]);

  const modalButtons = (
    <>
      <ButtonWithLoader
        htmlId="user-deletion-confirm"
        disabled={deleteUserResult.isLoading}
        loading={deleteUserResult.isLoading}
        dataTa="user-deletion-confirm"
        onClick={handleDeleteUser}
      >
        {t('profile.delete')}
      </ButtonWithLoader>
      <Button
        id="user-deletion-cancel"
        htmlId="user-deletion-cancel"
        onClick={onDismiss}
        buttonType={ButtonType.Secondary}
      >
        {t('profile.cancel')}
      </Button>
    </>
  );
  return (
    <ModalContainer
      id="user-deletion-dialog"
      isOpen
      htmlId="user-deletion-dialog"
      onDismiss={onDismiss}
      width={Size.S}
      showCloseIcon
      dismissOnOverlayClick={false}
      title="profile.userDeletionTitle"
      role="alert-dialog"
      buttons={modalButtons}
    >
      <BodyText id="delete-user-text" className="delete-user-body">
        {t('profile.userDeletionMsg', { userName })}
      </BodyText>
    </ModalContainer>
  );
};

export default UserDeletionDialog;
