import React from 'react';
import makeTestId, { IMakeTestIdProps } from '../../../utils/makeTestId';
import { ButtonType, Size, Stack, StackProps } from '@vaisala/rockhopper-components';
import { TranslationKey } from '../../../react-i18next';
import { useTranslation } from 'react-i18next';
import { TEST_IDS } from '../../../tests/testids';
import { ButtonWithLoader } from '../../ButtonWithLoader/ButtonWithLoader';

export enum TimespanOptions {
  THREE_HOURS = 'THREE_HOURS',
  SIX_HOURS = 'SIX_HOURS',
  TWELVE_HOURS = 'TWELVE_HOURS',
  TWENTY_FOUR_HOURS = 'TWENTY_FOUR_HOURS'
}

export const numHoursForTimespan: Record<TimespanOptions, number> = {
  [TimespanOptions.THREE_HOURS]: 3,
  [TimespanOptions.SIX_HOURS]: 6,
  [TimespanOptions.TWELVE_HOURS]: 12,
  [TimespanOptions.TWENTY_FOUR_HOURS]: 24
};

type Props = {
  timespanOptions?: TimespanOptions[];
  selectedTimespan?: TimespanOptions;
  handleOnClick?: (timespan: TimespanOptions, numHours: number) => void;
  isLoading?: boolean;
} & IMakeTestIdProps &
  StackProps;

export const timespanOptionsTranslationMap: Record<TimespanOptions, TranslationKey> = {
  [TimespanOptions.THREE_HOURS]: 'general.threeHours',
  [TimespanOptions.SIX_HOURS]: 'general.sixHours',
  [TimespanOptions.TWELVE_HOURS]: 'general.twelveHours',
  [TimespanOptions.TWENTY_FOUR_HOURS]: 'general.twentyFourHours'
};

const Timespan: React.FC<Props> = ({
  dataTa,
  className,
  timespanOptions = Object.values(TimespanOptions),
  selectedTimespan,
  isLoading,
  handleOnClick
}) => {
  const { getTestId } = makeTestId({ dataTa });
  const { t } = useTranslation();

  const handleClick = (timespan: TimespanOptions, numHours: number) => () => {
    handleOnClick?.(timespan, numHours);
  };

  const isTimespanSelected = (timespan: TimespanOptions): boolean => {
    return timespan === selectedTimespan;
  };

  const getButtonType = (timespan: TimespanOptions): ButtonType => {
    return isTimespanSelected(timespan) ? ButtonType.Primary : ButtonType.Secondary;
  };

  return (
    <Stack dataTa={getTestId(TEST_IDS.container)} className={className} direction="row" spacing={Size.None}>
      {timespanOptions.map((timespan: TimespanOptions) => {
        const isSelected = isTimespanSelected(timespan);
        return (
          <ButtonWithLoader
            key={`button--${timespan}`}
            buttonType={getButtonType(timespan)}
            loading={isSelected ? isLoading : false}
            disabled={isSelected ? isLoading : false}
            onClick={handleClick(timespan, numHoursForTimespan[timespan])}
            dataTa={getTestId(TEST_IDS.concat(TEST_IDS.button, timespan))}
          >
            {t(timespanOptionsTranslationMap[timespan])}
          </ButtonWithLoader>
        );
      })}
    </Stack>
  );
};

export default Timespan;
