import { ALARM_PRIORITY, ALARM_THRESHOLD_TYPE } from '../../constants';

export enum LocationsFilterTimeSpan {
  ONE_DAY = '24hr',
  ONE_WEEK = '1week',
  ONE_MONTH = '1month'
}

export interface LocationNode {
  name: string;
  type: string;
  node_id: string;
  parent_id: string | null;
  children?: LocationNode[];
  description?: string;
  symbol_id: string;
  meas_id?: string;
  status?: string;
  created?: number;
  changed?: number;
  active?: boolean;
  position?: number;
  measurement_points?: Measurement_Points[];
  alarms_count: number;
}

export type Threshold_Setting = {
  value: number | null;
  color: string | null;
  category: string;
  priority: ALARM_PRIORITY;
  enabled: boolean;
  ack_required?: boolean;
  delay_sec?: number | null;
};

export type Measurement_Points = {
  t: number;
  v: number;
  // TODO: Do we really need ALARM_THRESHOLD_TYPE and THRESHOLD_TYPE enums?
  //   They should probably be consolidated as they represent the same thing, right?
  threshold_rules?: Record<keyof typeof ALARM_THRESHOLD_TYPE, Threshold_Setting>;
};

// export type Threshold_Values = { value: number; unit: string; type: THRESHOLD_TYPE };
export type Threshold_Values = { min: number; max: number };

export interface LocationTreeFormattedNode {
  name: string;
  type: string;
  node_id: string;
  parent_id: string | null;
  selected: boolean;
  partialSelected: boolean;
  decimal_places: number;
  show: boolean;
  showThreshold?: boolean;
  visibleOnGraph?: boolean;
  hierarchy: string[];
  most_recent?: string | null;
  average?: string | null;
  minimum?: string | null;
  maximum?: string | null;
  children: { [key: string]: LocationTreeFormattedNode };
  description?: string;
  symbol_id: string;
  meas_id?: string;
  measurement_points?: Measurement_Points[];
  threshold_values?: Threshold_Values | null;
  sk: string;
}

export interface CustomProps {
  measurement_points?: Measurement_Points[];
  threshold_values?: Threshold_Values;
  showThreshold: boolean;
  visibleOnGraph: boolean;
  selected: boolean;
  hierarchy: string[];
  decimal_places: number;
  symbol_id: string;
  name: string;
  most_recent?: string;
  average?: string;
  minimum?: string;
  maximum?: string;
  node_id?: string;
}

export interface LocationCustomProps {
  [key: string]: CustomProps;
}

export enum INCLUDED_REPORT_SECTIONS {
  LOCATIONS_SUMMARY = 'locationSummaryTable',
  GRAPHS_BY_THRESHOLD = 'graphsByThreshold',
  STATISTICS_SUMMARY = 'statisticsSummary',
  ALARMS = 'alarms',
  DATA_TABLE = 'dataTables'
}

export enum REPORT_FORMATS {
  PDF = 'PDF',
  CSV = 'CSV',
  XLSX = 'XLSX'
}
