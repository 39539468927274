type ErrorWithMessage = {
  message: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  if (!error) return false;
  return typeof error === 'object' && 'message' in error;
}

function getErrorMessage(error: unknown): string {
  if (isErrorWithMessage(error)) return error.message;
  return String(error);
}

export const reportError = (error: unknown, message = 'Unexpected error') => {
  // connect this function with our error logging service.
  // Additional connect this with redux to return pretty printed error messages
  if (process.env.NODE_ENV !== 'test') {
    console.error(message, getErrorMessage(error));
  }
  if (isErrorWithMessage(error)) return new Error(error.message);
  return new Error(String(error));
};
