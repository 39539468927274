export const TEST_IDS = {
  concat: (...args: string[]) => args.join('-'),
  close: 'close',
  button: 'button',
  link: 'link',
  timespan: 'timespan',
  custom_row: 'custom-row',
  events: 'events',
  event_detail: 'event-detail',
  source: 'source',
  object: 'object',
  action: 'action',
  location: 'location',
  no_data: 'no-data',
  graph: 'graph',
  vai_menu_button_list: 'vai-menu-button-list',
  alarm_type: 'alarm-type',
  alarm_type_icon: 'alarm-type-icon',
  tabs_container: 'tabs-container',
  navigation: {
    value: 'navigation',
    make: (...args: string[]) => TEST_IDS.concat(`${TEST_IDS.navigation.value}-`, ...args),
    sites: 'sites',
    devices: 'devices',
    reports: 'reports',
    events: 'events',
    profile: 'profile'
  },
  col: 'col',
  text: 'text',
  status: 'status',
  priority: 'priority',
  select: 'select',
  option: 'option',
  table: 'table',
  arrow_left: 'arrow-left',
  arrow_right: 'arrow-right',
  date_time: 'date-time',
  heading: 'heading',
  header: 'header',
  category: 'category',
  container: 'container',
  subheading: 'subheading',
  alarm_count: 'alarm-count',
  show_current: 'show-current',
  system_wide_rights: 'system-wide-rights',
  edit_button: 'edit-button',
  apply_button: 'apply-button',
  from_pickers_wrapper: 'from-pickers-wrapper',
  to_pickers_wrapper: 'to-pickers-wrapper',
  start_date: 'start-date',
  end_date: 'end-date',
  filter_bar: 'filter-bar',
  from_date_picker: 'from-date-picker',
  from_date_picker_label: 'from-date-picker-label',
  from_time_picker: 'from-time-picker',
  to_date_picker: 'to-date-picker',
  to_date_picker_label: 'to-date-picker-label',
  to_time_picker: 'to-time-picker',
  num_results: 'num-results',
  label: 'label',
  user_delete_button: 'user-delete-button',
  edit_zone_and_location_dialog_save_button: 'edit_zone_and_location_dialog_save_button',
  edit_zone_and_location_dialog_name_field: 'edit_zone_and_location_dialog_name_field',
  edit_zone_and_location_dialog_unit_field: 'edit_zone_and_location_dialog_unit_field',
  edit_zone_and_location_dialog_decimal_places_field: 'edit_zone_and_location_dialog_decimal_places_field',
  edit_zone_and_location_dialog_description_field: 'edit_zone_and_location_dialog_description_field',
  edit_zone_and_location_dialog_measurement_type_field: 'edit_zone_and_location_dialog_measurement_type_field',
  edit_zone_and_location_dialog_delete_button: 'edit_zone_and_location_dialog_delete_button',
  device_manager: { current_devices_datatable: 'current_devices_datatable' },
  device_manager_device_list: 'device-list',
  device_manager_sort_by: 'device-manager-sort-by',
  device_manager_filter_by_device: 'device-manager-filter-by-device',
  device_manager_filter_by_status: 'device-manager-filter-by-status',
  device_manager_device_list_search_container: 'device-manager-device-list-search-container',
  device_manager_device_list_search_input: 'device-manager-device-list-search-input',
  device_manager_device_list_item: 'device-list-item',
  device_manager_device_list_item_nickname: 'device-list-item-nickname',
  device_manager_device_list_item_img_wrapper: 'device-list-item-img-wrapper',
  device_manager_device_list_item_img: 'device-list-item-img',
  device_manager_device_list_item_device_status_icon: 'device-list-item-status-icon',
  device_manager_device_list_item_device_status_icon_wrapper: 'device-list-item-status-icon-wrapper',
  device_manager_device_list_item_device_or_host_text: 'device-list-item-device-or-host-text',
  device_manager_device_list_item_status_label: 'device-list-item-status-label',
  device_manager_container_right: 'device-manager-container-right',
  device_properties_restart_device_button: 'device-properties-restart-device-button',
  device_properties_restart_device_icon: 'device-properties-restart-device-icon',
  device_properties_device_id_heading: 'device-properties-device-id-heading',
  device_properties_heading: 'device-properties-heading',
  device_properties_hostname_value: 'device-properties-hostname-value',
  device_properties_channel_value: 'device-properties-channel-value',
  device_properties_display_enabled_value: 'device-properties-display-enabled-value',
  device_properties_display_percent_value: 'device-properties-display-percent-value',
  device_properties_led_enabled_value: 'device-properties-led-enabled-value',
  device_properties_led_percent_value: 'device-properties-led-percent-value',
  device_properties_current_host_value: 'device-properties-current-host-value',
  device_properties_device_class_value: 'device-properties-device-class-value',
  device_properties_hardware_model_value: 'device-properties-hardware-model-value',
  device_properties_serial_number_value: 'device-properties-serial-number-value',
  device_properties_firmware_version_value: 'device-properties-firmware-version-value',
  device_properties_rfl_led_value: 'device-properties-rfl-led-value',
  device_properties_rfl_display_value: 'device-properties-rfl-display-value',
  device_properties_unit_value: 'device-properties-unit-value',
  device_properties_calibration_date_value: 'device-properties-calibration-date-value',
  device_properties_calibration_interval_value: 'device-properties-calibration-interval-value',
  data_source_card_container: 'data_source_card_container',
  data_source_card_interval: 'data_source_card_interval',
  data_source_card_value: 'data_source_card_value',
  data_source_card_last_measurement: 'data_source_card_last_measurement',
  data_source_card_location_link: 'data_source_card_location_link',
  data_source_card_location_spinner: 'data_source_card_location_spinner',
  device_status_heading: 'device-status-heading',
  device_manager_fetch_error: 'device-manager-fetch-error',
  device_manager_refresh_device: 'device-manager-refresh-device',
  device_manager_tab_spinner: 'device-manager-tab-spinner',
  location_deactivated: 'location-deactivated',
  location_deactivated_label: 'location-deactivated-label',
  location_deactivated_status: 'location-deactivated-status',
  location_deactivated_alarm_blurb: 'location-deactivated-alarm-blurb',
  location_will_not_alarm: 'location-will-not-alarm',
  location_display: 'location-display',
  location_toggle: 'location-toggle',
  location_status_value: 'status-value',
  location_icon: 'location-icon',
  location_status_icon: 'location_status_icon',
  location_icon_unlink: 'location-icon-unlink',
  location_icon_link: 'location-icon-link',
  location_value: 'location_value',
  location_timestamp: 'location_timestamp',
  location_device_status: 'location-device-status',
  location_device_status_tooltip: 'location-device-status-tooltip',
  location_threshold: 'location-threshold',
  location_measurement_symbol: 'location_measurement_symbol',
  location_header: 'location-header',
  location_header_title: 'location-header-title',
  location_header_desc: 'location-header-desc',
  go_to_settings_link: 'go-to-site-settings',
  site_settings_accordion_list: 'site-settings-accordion-list',
  site_settings_threshold_alarms_accordion_item: 'site-settings-threshold-alarms-accordion-item',
  site_settings_device_alarms_accordion_item: 'site-settings-device-alarms-accordion-item',
  site_settings_threshold_settings_blurb: 'site-settings-threshold-settings-blurb',
  site_settings_threshold_alarms_not_set: 'site-settings-threshold-alarms-not-set',
  site_settings_threshold_alarms_no_thresholds_infotip: 'site-settings-threshold-alarms-no-thresholds-infotip',
  site_settings_threshold_alarms_no_thresholds_will_not_alarm:
    'site-settings-threshold-alarms-no-thresholds-will-not-alarm',
  site_settings_link_button: 'site-settings-link-button',
  site_setting_device_status_value: 'site_setting_device_status_value',
  site_tabs_container: 'site-tabs-container',
  site_tree_container: 'site-tree-container',
  site_tree_search: 'site-tree-search',
  site_tree_accordion_container: 'site-tree-accordion-container',
  site_tree_form_dialog: 'site_tree_form_dialog',
  site_tree_form_dialog_name_field: 'site_tree_form_dialog_name_field',
  site_tree_form_dialog_description_field: 'site_tree_form_dialog_description_field',
  site_tree_form_dialog_unitType_field: 'site_tree_form_dialog_unitType_field',
  site_tree_form_dialog_measurementType_field: 'site_tree_form_dialog_measurementType_field',
  site_tree_form_dialog_decimal_places_field: 'site_tree_form_dialog_decimal_places_field',
  site_tree_form_dialog_save_button: 'site_tree_form_dialog_save_button',
  site_tree_form_dialog_delete_button: 'site_tree_form_dialog_delete_button',
  site_tree_form_dialog_parent_zone: 'site_tree_form_dialog_parent_zone',
  site_tree_form_dialog_parent_zone_name: 'site_tree_form_dialog_parent_zone_name',
  site_tree_show_inactive_menu_button: 'site_tree_show_inactive_menu_button',
  site_tree_show_inactive_menu_button_show_toggle: 'site_tree_show_inactive_menu_button_show_toggle',
  site_tree_show_inactive_menu_button_hide_all: 'site_tree_show_inactive_menu_button_hide_all',
  site_tree_show_inactive_menu_button_show_all: 'site_tree_show_inactive_menu_button_show_all',
  site_tree_show_inactive_menu_button_checkbox: 'site_tree_show_inactive_menu_button_checkbox',
  site_tree_show_inactive_menu_button_checkbox_container: 'site_tree_show_inactive_menu_button_checkbox_container',
  events_filter_bar_clear_filters_button: 'events_filter_bar_clear_filters_button',
  events_filter_bar_apply_filters_button: 'events_filter_bar_apply_filters_button',
  events_filter_bar_search_field_input: 'events_filter_bar_search_field_input',
  events_filter_bar_category_field_select: 'events_filter_bar_category_field_select',
  events_filter_bar_action_field_select: 'events_filter_bar_action_field_select',
  events_filter_bar_source_field_select: 'events_filter_bar_source_field_select',
  events_filter_bar_from_time: 'events_filter_bar_from_time',
  events_filter_bar_to_time: 'events_filter_bar_to_time',
  events_filter_bar_filterByZone_modal: 'events_filter_bar_filterByZone_modal',
  events_filter_bar_filterByZone_button: 'events_filter_bar_filterByZone_button',
  events_filter_bar_filterByZone_input: 'events_filter_bar_filterByZone_input',
  events_filter_bar_filterByZone_apply: 'events_filter_bar_filterByZone_apply',
  events_filter_bar_filterByZone_cancel: 'events_filter_bar_filterByZone_cancel',
  events_filter_bar_filterByZone_selected_count: 'events_filter_bar_filterByZone_selected_count',
  events_filter_bar_filterByZone_clear_filters: 'events_filter_bar_filterByZone_clear_filters',
  events_filter_bar_filterByZone_error_message: 'events_filter_bar_filterByZone_error_message',
  events_table: 'events-table',
  events_not_found: 'events-not-found',
  events_not_found_icon: 'events-not-found-icon',
  event_details_table: 'event_details_table',
  link_data_source_heading: 'link-data-source-heading',
  link_data_source_status: 'link-data-source-status',
  link_data_source_status_icon: 'link-data-source-status-icon',
  link_data_source_device_name: 'link-data-source-device-name',
  link_data_source_device_img: 'link-data-source-device-img',
  link_data_source_probe_name: 'link-data-source-probe-name',
  link_data_source_no_linked_source_msg: 'link-data-source-no-linked-source-msg',
  link_data_source_no_linked_source_blurb: 'link-data-source-no-linked-source-blurb',
  link_data_source_dialog_location_title: 'link-data-source-dialog-location-title',
  link_data_source_dialog_blurb: 'link-data-source-dialog-blurb',
  link_data_source_dialog_infotip: 'link-data-source-dialog-infotip',
  link_data_source_dialog_search_input: 'link-data-source-dialog-search-input',
  link_data_source_dialog_search_input_icon: 'link-data-source-dialog-search-input-icon',
  data_source_list_container: 'link-data-source-dialog-data-source-list-container',
  data_source_list: 'data-source-list',
  data_source_list_item_container: 'data-source-list-item-container',
  data_source_list_item_radio: 'data-source-list-item-radio',
  data_source_list_item_latest_meas_container: 'data-source-list-item-latest-meas-container',
  data_source_list_item_latest_meas: 'data-source-list-item-latest-meas',
  data_source_list_item_symbol: 'data-source-list-item-symbol',
  device_icon: 'device-icon',
  device_id: 'device-id',
  probe_id: 'probe-id',
  status_msg_icon: 'status-msg-icon',
  list_error_container: 'list-error-container',
  list_error_text_container: 'list-error-text-container',
  list_error_icon: 'list-error-icon',
  list_error_text: 'list-error-text',
  list_error_refresh_button_container: 'list-error-refresh-button-container',
  list_error_refresh_button: 'list-error-refresh-button',
  infotip_popover: 'infotip-popover',
  infotip_icon: 'infotip-icon',
  measurement: 'measurement',
  measurement_icon: 'measurement-icon',
  measurement_value: 'measurement-value',
  measurement_symbol: 'measurement-symbol',
  link_data_source_confirmation_modal_location_title: 'link-data-source-confirmation-modal-location-title',
  link_data_source_confirmation_modal_data_source_title: 'link-data-source-confirmation-modal-data-source-title',
  link_data_source_confirmation_modal_data_source_old_title:
    'link-data-source-confirmation-modal-data-source-old-title',
  link_data_source_confirmation_modal_probe_id: 'link-data-source-confirmation-modal-probe-id',
  link_data_source_confirmation_modal_device_id: 'link-data-source-confirmation-modal-device-id',
  link_data_source_confirmation_modal_old_probe_id: 'link-data-source-confirmation-modal-old-probe-id',
  link_data_source_confirmation_modal_old_device_id: 'link-data-source-confirmation-modal-old-device-id',
  link_data_source_confirmation_modal_message: 'link-data-source-confirmation-modal-message',
  sidebar_help: 'sidebar-help',
  sidebar_help_icon: 'sidebar-help-icon',
  sidebar_help_drawer: 'sidebar-help-drawer',
  sidebar_help_content_wrapper: 'sidebar-help-content-wrapper',
  sidebar_help_empty: 'sidebar-help-empty',
  sidebar_current_route: 'sidebar-current-route',
  user_menu: 'user-menu',
  user_menu_item: 'user-menu-item',
  tours_dropdown: 'tours-dropdown',
  software_assurance_sidebar_button: 'software_assurance_sidebar_button',
  full_page_spinner: 'full-page-spinner',
  groups_edit_rights_button: 'groups_edit_rights_button',
  groups_rights_container: 'groups-rights-container',
  groups_rights_accordion_$NAME: `groups-rights-accordion-`,
  groups_rights_accordion_title_$NAME: `groups-rights-accordion-title-`,
  groups_rights_accordion_checkbox: 'groups_rights_accordion_checkbox',
  groups_add_group_member_button: 'groups_add_group_member_button',
  groups_add_group_member_dialog: 'groups_add_group_member_dialog',
  groups_add_group_member_dialog_group_name: 'groups_add_group_member_dialog_group_name',
  tag_input: 'tag_input',
  tag_input_field: 'tag_input_field',
  tag_input_list_item: 'tag_input_list_item',
  groups_add_group_member_dialog_search_field: 'groups_add_group_member_dialog_search_field',
  groups_add_group_member_dialog_save_button: 'groups_add_group_member_dialog_save_button',
  groups_add_group_member_dialog_cancel_button: 'groups_add_group_member_dialog_cancel_button',
  groups_add_group_member_dialog_users_list_item: 'groups_add_group_member_dialog_users_list_item',
  groups_add_group_member_dialog_header: 'groups_add_group_member_dialog_header',
  user_effective_rights_name: 'user_effective_rights_name',
  user_effective_rights_description: 'user_effective_rights_description',
  users_add_to_group: 'users_add_to_group',
  users_add_to_group_dialog_save: 'users_add_to_group_dialog_save',
  users_add_to_group_dialog_cancel: 'users_add_to_group_dialog_cancel',
  users_add_to_group_dialog_search_field: 'users_add_to_group_dialog_search_field',
  users_add_to_group_dialog_users_list_item: 'users_add_to_group_dialog_users_list_item',
  users_add_to_group_dialog_user_name: 'users_add_to_group_dialog_user_name',
  users_add_to_group_dialog_users_list_item_group_name: 'users_add_to_group_dialog_users_list_item_group_name',
  users_edit_profile_dialog_heading: 'users_edit_profile_dialog_heading',
  users_edit_profile_dialog_save_button: 'users_edit_profile_dialog_save_button',
  users_edit_profile_dialog_cancel_button: 'users_edit_profile_dialog_cancel_button',
  users_edit_profile_dialog_first_name_field: 'users_edit_profile_dialog_first_name_field',
  users_edit_profile_dialog_last_name_field: 'users_edit_profile_dialog_last_name_field',
  users_edit_profile_dialog_language_field: 'users_edit_profile_dialog_language_field',
  users_edit_profile_dialog_timezone_field: 'users_edit_profile_dialog_timezone_field',
  users_edit_profile_dialog_phone_field: 'users_edit_profile_dialog_phone_field',
  users_edit_profile_button: 'users_edit_profile_button',
  users_search_user: 'users_search_user',
  groups_search_group: 'groups_search_group',
  group_list_item_edit_button: 'group_list_item_edit_button',
  group_list_row: 'group_list_row',
  group_delete_dialog: 'group_delete_dialog',
  group_delete_dialog_confirm: 'group_delete_dialog_confirm',
  group_edit_dialog: 'group_edit_dialog',
  groups_edit_group_button: 'groups_edit_group_button',
  group_edit_dialog_save_buton: 'group_edit_dialog_save_buton',
  group_edit_dialog_delete_button: 'group_edit_dialog_delete_button',
  groups_edit_group_description_field: 'groups_edit_group_description_field',
  groups_edit_group_name_field: 'groups_edit_group_name_field',
  users_and_groups_new_button: 'users_and_groups_new_button',
  users_and_groups_new_group: 'users_and_groups_new_group',
  user_activation_toggle: 'user_activation_toggle',
  users_and_groups_active_toggle: 'users_and_groups_active_toggle',
  users_and_groups_active_toggle_confirm_button: 'users_and_groups_active_toggle_confirm_button',
  users_and_groups_sort_button: 'users_and_groups_sort_button',
  users_and_groups_sort_by_name: 'users_and_groups_sort_by_name',
  users_and_groups_sort_by_most_recent_changes: 'users_and_groups_sort_by_most_recent_changes',
  users_and_groups_sort_by_number_of_users_asc: 'users_and_groups_sort_by_number_of_users_asc',
  users_and_groups_sort_by_number_of_users_desc: 'users_and_groups_sort_by_number_of_users_desc',
  users_and_groups_invite_user: 'users_and_groups_invite_user',
  users_and_groups_invite_user_dialog: 'users_and_groups_invite_user_dialog',
  users_and_groups_invite_user_dialog_invite_button: 'users_and_groups_invite_user_dialog_invite_button',
  users_and_groups_invite_user_dialog_groups_list: 'users_and_groups_invite_user_dialog_groups_list',
  users_and_groups_invite_user_dialog_groups_list_search_field:
    'users_and_groups_invite_user_dialog_groups_list_search_field',
  toggle_location_active_dialog_blurb: 'toggle-location-active-dialog-blurb',
  group_create_dialog_create_group_button: 'group_create_dialog_create_group_button',
  group_create_dialog_name_field: 'group_create_dialog_name_field',
  group_create_dialog_description_field: 'group_create_dialog_description_field',
  group_users_list: 'group_users_list',
  group_users_list_item_checkbox: 'group_users_list_item_checkbox',
  group_users_list_all_checkbox: 'group_users_list_all_checkbox',
  user_group_list_heading_icon: 'user_group_list_heading_icon',
  user_group_list_infotip: 'user_group_list_infotip',
  user_group_list_group_name: 'user_group_list_group_name',
  user_group_list_user_count: 'user_group_list_user_count',
  user_group_list_table: 'user_group_list_table',
  user_group_list_table_row: 'user_group_list_table_row',
  user_group_list_checkbox: 'user_group_list_checkbox',
  remove_user_from_groups_button: 'remove_user_from_groups_button',
  group_remove_user_button: 'group_remove_user_button',
  group_remove_user_confirm_button: 'group_remove_user_confirm_button',
  inactive_row_location_text: 'inactive-row-location-text',
  active_row_location_text: 'active-row-location-text',
  hours_minutes_fields: { hours: 'hours_minutes_field.hours', minutes: 'hours_minutes_field.minutes' },
  alarm_setting_form_row: 'alarm_setting_form_row',
  alarm_setting_form_error_display: 'alarm_setting_form_error_display',
  alarm_settings_threshold_form_ack_toggle: 'alarm_settings_threshold_form_ack_toggle',
  alarm_settings_device_form_ack_toggle: 'alarm_settings_device_form_ack_toggle',
  alarm_settings_threshold_form_values: {
    input: 'alarm_settings_threshold_form_values.input',
    icon: 'alarm_settings_threshold_form_values.icon',
    symbol: 'alarm_settings_threshold_form_values.symbol'
  },
  alarm_settings_threshold_display_row: 'alarm_settings_threshold_display_row',
  alarm_settings_display_row: 'alarm_settings_display_row',
  alarm_settings_threshold_display_group_list_heading: 'alarm_settings_threshold_display_group_list_heading',
  steps: {
    next: 'steps.next',
    back: 'steps.back'
  },
  alarm_settings_threshold_modal: 'alarm_settings_threshold_modal',
  alarm_settings_threshold_accordion_title: 'alarm_settings_threshold_accordion_title',
  alarm_settings_device_accordion_title: 'alarm_settings_device_accordion_title',
  alarm_settings_threshold_notifications_form: 'alarm_settings_threshold_notifications_form',
  alarm_settings_threshold_notifications_form_row: 'alarm_settings_threshold_notifications_form_row',
  alarm_settings_threshold_notifications_form_group_select: {
    search_field: 'alarm_settings_threshold_notifications_form_group_select_search_field',
    selector: 'alarm_settings_threshold_notifications_form_group_select_selector',
    item: 'alarm_settings_threshold_notifications_form_group_select_item'
  },
  alarm_display: 'alarm_display',
  alarm_row_timesince: 'alarm-row-timesince',
  alarm_row_priority_icon: 'alarm-row-priority-icon',
  current_alarms: { search_all_alarms: 'search-all-alarms' },
  save_button: 'save_button',
  alarm_priority_select: 'alarm_priority_select',
  empty_state_title: 'empty-state-title',
  empty_state_desc: 'empty-state-description',
  empty_state_link: 'empty-state-link',
  confirm_cancel: 'confirm-cancel',
  go_back: 'go-back',
  cancel_button: 'cancel_buton',
  alarm_threshold_waring_message: 'alarm_threshold_waring_message',
  alarm_threshold_step_count_message: 'alarm_threshold_step_count_message',
  cancel_icon: 'cancel_icon',
  close_modal_icon_button: 'vai-modal-close',
  help_link_button: 'help-link-button',
  hide_contextual_help_button: 'hide-contextual-help-button',
  spinner: 'vai-spinner',
  access_restricted_dialog: 'access_restricted_dialog',
  empty_state_wrapper: 'empty_state_wrapper',
  reports: {
    locations_number_wrapper: 'locations_number_wrapper',
    locations_list_wrapper: 'locations_list_wrapper',
    select_button: 'reports_select_button',
    locations_dispaly_limit_message: 'locations_dispaly_limit_message'
  },
  resports_estimated_page_count: 'estimated-page-count',
  generate_report_dialog_id: 'generate-report-dialog-id',
  generate_report_confirm: 'generate-report-confirm',
  generate_report_error_message: 'generate-report-error-message',
  generate_report_error_message_icon: 'generate-report-error-message-icon',
  generate_report_format_select: 'generate-report-format-select',
  populated_chart_instance: 'populated_chart_instance',
  chart_instance: 'chart-instance',
  icon: 'icon',
  language_header: 'language-header',
  language_header_menu: 'language-header-menu',
  language_header_menu_avatar: 'language-header-menu-avatar',
  language_header_menu_item: 'language-header-menu-item',
  language_header_menu_item_avatar: 'language-header-menu-item-avatar',
  selected_locations_list_wrapper: 'selected-locations-list-wrapper',
  selected_location_node: 'selected-location-node',
  paper: 'paper',
  locations_filter_bar: 'locations-filter-bar',
  statistics_wrapper: 'statistics-wrapper',
  edit_ap10_properties_modal_container: 'edit_ap10_properties_modal_container',
  edit_ap10_properties_vaiNet_channel_select: 'edit_ap10_properties_vaiNet_channel_select',
  edit_ap10_properties_ap_display_toggle: 'edit_ap10_properties_ap_display_toggle',
  edit_ap10_properties_display_brightness_slider: 'edit_ap10_properties_display_brightness_slider',
  edit_ap10_properties_display_brightness: 'edit_ap10_properties_display_brightness',
  edit_ap10_properties_ap_led_toggle: 'edit_ap10_properties_ap_led_toggle',
  edit_ap10_properties_led_brightness: 'edit_ap10_properties_led_brightness',
  edit_ap10_properties_led_brightness_slider: 'edit_ap10_properties_led_brightness_slider',
  edit_ap10_properties_message: 'edit_ap10_properties_message',
  edit_ap10_properties_save_button: 'edit_ap10_properties_save_button',
  edit_ap10_properties_cancel_button: 'edit_ap10_properties_cancel_button',
  edit_rfl_properties_modal_container: 'edit_rfl_properties_modal_container',
  edit_rfl_properties_rfl_display_toggle: 'edit_rfl_properties_rfl_display_toggle',
  edit_rfl_properties_rfl_led_toggle: 'edit_rfl_properties_rfl_led_toggle',
  edit_rfl_properties_rfl_unit_toggle: 'edit_rfl_properties_rfl_unit_toggle',
  edit_rfl_properties_message: 'edit_rfl_properties_message',
  edit_rfl_properties_save_button: 'edit_rfl_properties_save_button',
  edit_rfl_properties_cancel_button: 'edit_ap10_properties_cancel_button',
  time_picker_toggle: 'time-picker-toggle',
  alarms_thresholdAlarmDetails_modalContainer: 'alarms-thresholdAlarmDetails-modalContainer',
  alarms_thresholdAlarmDetails_alarmDetails: 'alarms-thresholdAlarmDetails-alarmDetails',
  alarms_thresholdAlarmDetails_location_label: 'alarms-thresholdAlarmDetails-location-label',
  alarms_thresholdAlarmDetails_location_icon: 'alarms-thresholdAlarmDetails-location-icon',
  alarms_thresholdAlarmDetails_location_value: 'alarms-thresholdAlarmDetails-location-value',
  alarms_thresholdAlarmDetails_value_label: 'alarms-thresholdAlarmDetails-value-label',
  alarms_thresholdAlarmDetails_value_value: 'alarms-thresholdAlarmDetails-value-value',
  alarms_thresholdAlarmDetails_value_measurementtype: 'alarms-thresholdAlarmDetails-value-measurementtype',
  alarms_thresholdAlarmDetails_alarmType_label: 'alarms-thresholdAlarmDetails-alarmType-label',
  alarms_thresholdAlarmDetails_alarmType_value: 'alarms-thresholdAlarmDetails-alarmType-value',
  alarms_thresholdAlarmDetails_startTime_label: 'alarms-thresholdAlarmDetails-startTime-label',
  alarms_thresholdAlarmDetails_startTime_value: 'alarms-thresholdAlarmDetails-startTime-value',
  alarms_thresholdAlarmDetails_endTime_label: 'alarms-thresholdAlarmDetails-endTime-label',
  alarms_thresholdAlarmDetails_endTime_value: 'alarms-thresholdAlarmDetails-endTime-value',
  alarms_thresholdAlarmDetails_duration_label: 'alarms-thresholdAlarmDetails-duration-label',
  alarms_thresholdAlarmDetails_duration_value: 'alarms-thresholdAlarmDetails-duration-value',
  alarms_thresholdAlarmDetails_alarmSettings_title: 'alarms-thresholdAlarmDetails-alarmSettings-title',
  alarms_thresholdAlarmDetails_priority_label: 'alarms-thresholdAlarmDetails-priority-label',
  alarms_thresholdAlarmDetails_priority_icon: 'alarms-thresholdAlarmDetails-priority-icon',
  alarms_thresholdAlarmDetails_priority_value: 'alarms-thresholdAlarmDetails-priority-value',
  alarms_thresholdAlarmDetails_thresholdValue_label: 'alarms-thresholdAlarmDetails-thresholdValue-label',
  alarms_thresholdAlarmDetails_thresholdValue_sign: 'alarms-thresholdAlarmDetails-thresholdValue-sign',
  alarms_thresholdAlarmDetails_thresholdValue_value: 'alarms-thresholdAlarmDetails-thresholdValue-value',
  alarms_thresholdAlarmDetails_thresholdValue_measurementtype:
    'alarms-thresholdAlarmDetails-thresholdValue-measurementtype',
  alarms_thresholdAlarmDetails_alarmDelay_label: 'alarms-thresholdAlarmDetails-alarmDelay-label',
  alarms_thresholdAlarmDetails_alarmDelay_value: 'alarms-thresholdAlarmDetails-alarmDelay-value',
  alarms_thresholdAlarmDetails_dataSource_label: 'alarms-thresholdAlarmDetails-dataSource-label',
  alarms_thresholdAlarmDetails_dataSource_value: 'alarms-thresholdAlarmDetails-dataSource-value',
  alarms_thresholdAlarmDetails_acknowledge_button: 'alarms-thresholdAlarmDetails-acknowledge-button',
  alarms_thresholdAlarmDetails_copy_link_button: 'alarms-thresholdAlarmDetails-copy-link-button',
  alarms_thresholdAlarmDetails_spinner: 'alarms-thresholdAlarmDetails-spinner',
  alarms_deviceAlarmDetails_copy_link_button: 'alarms-deviceAlarmDetails-copy-link-button',
  alarms_deviceAlarmDetails_location_label: 'alarms-deviceAlarmDetails-location-label',
  alarms_deviceAlarmDetails_location_icon: 'alarms-deviceAlarmDetails-location-icon',
  alarms_deviceAlarmDetails_location_value: 'alarms-deviceAlarmDetails-location-value',
  alarms_deviceAlarmDetails_path: 'alarms-deviceAlarmDetails-path',
  alarms_deviceAlarmDetails_alarm_type_label: 'alarms-deviceAlarmDetails-alarm-type-label',
  alarms_deviceAlarmDetails_alarm_type_value: 'alarms-deviceAlarmDetails-alarm-type-value',
  alarms_deviceAlarmDetails_alarm_subtype_label: 'alarms-deviceAlarmDetails-alarm-subtype-label',
  alarms_deviceAlarmDetails_alarm_subtype_value: 'alarms-deviceAlarmDetails-alarm-subtype-value',
  alarms_deviceAlarmDetails_alarm_subtype_message: 'alarms-deviceAlarmDetails-alarm-subtype-message',
  alarms_deviceAlarmDetails_dataSource_label: 'alarms-deviceAlarmDetails-dataSource-label',
  alarms_deviceAlarmDetails_dataSource_value: 'alarms-deviceAlarmDetails-dataSource-value',
  alarms_deviceAlarmDetails_alarmType_label: 'alarms-deviceAlarmDetails-alarmType-label',
  alarms_deviceAlarmDetails_alarmType_value: 'alarms-deviceAlarmDetails-alarmType-value',
  alarms_deviceAlarmDetails_startTime_label: 'alarms-deviceAlarmDetails-startTime-label',
  alarms_deviceAlarmDetails_startTime_value: 'alarms-deviceAlarmDetails-startTime-value',
  alarms_deviceAlarmDetails_endTime_label: 'alarms-deviceAlarmDetails-endTime-label',
  alarms_deviceAlarmDetails_endTime_value: 'alarms-deviceAlarmDetails-endTime-value',
  alarms_deviceAlarmDetails_duration_label: 'alarms-deviceAlarmDetails-duration-label',
  alarms_deviceAlarmDetails_duration_value: 'alarms-deviceAlarmDetails-duration-value',
  alarms_deviceAlarmDetails_alarmSettings_title: 'alarms-deviceAlarmDetails-alarmSettings-title',
  alarms_deviceAlarmDetails_priority_label: 'alarms-deviceAlarmDetails-priority-label',
  alarms_deviceAlarmDetails_priority_icon: 'alarms-deviceAlarmDetails-priority-icon',
  alarms_deviceAlarmDetails_priority_value: 'alarms-deviceAlarmDetails-priority-value',
  alarms_deviceAlarmDetails_alarmDelay_label: 'alarms-deviceAlarmDetails-alarmDelay-label',
  alarms_deviceAlarmDetails_alarmDelay_value: 'alarms-deviceAlarmDetails-alarmDelay-value',
  delete_confirm_button: 'delete-confirm-button',
  error_message_container: 'error-message-container',
  error_message_exclamation: 'error-message-exclamation',
  error_message_ack_required: 'error-message-ack-required',
  error_message_exclamation_icon: 'error-message-exclamation-icon',
  no_ack_container: 'no-ack-container',
  cross_icon_container: 'cross-icon-container',
  cross_icon: 'cross-icon',
  no_ack_text: 'no-ack-text',
  ack_required_container: 'ack-required-container',
  ack_message_container: 'ack-message-container',
  ack_message_system_container: 'ack-message-system-container',
  ack_message_system_icon_container: 'ack-message-system-icon-container',
  ack_message_system_icon: 'ack-message-system-icon',
  ack_message_system_comment_title: 'ack-message-system-comment-title',
  ack_message_system_time_stamp: 'ack-message-system-time-stamp',
  ack_message_user_container: 'ack-message-user-container',
  ack_message_user_icon_container: 'ack-message-user-icon-container',
  ack_message_user_icon_bell: 'ack-message-user-icon-bell',
  ack_message_user_icon_ok: 'ack-message-user-icon-ok',
  ack_message_user_icon_profile: 'ack-message-user-icon-profile',
  ack_message_user_name: 'ack-message-user-name',
  ack_message_user_time_stamp: 'ack-message-user-time-stamp',
  ack_message_user_text_container: 'ack-message-user-text-container',
  ack_message_user_text: 'ack-message-user-text',
  form_container: 'form-container',
  form_message: 'form-message',
  ack_form_heading: 'acK-form-heading',
  error_message: 'error-message',
  accordion_tree_checkbox_$id: 'accordion_tree_checkbox_',
  accordion_tree_checkbox_title_$id: 'accordion_tree_checkbox_title_',
  accordion_tree_checkbox_wrapper_$id: 'accordion_tree_checkbox_wrapper_',
  accordion_tree_accordion_$id: 'accordion_tree_accordion_',
  rights_checkboxes_$id: 'rights_checkboxes_',
  rights_accordion_summary_$id: 'rights_accordion_summary_',
  edit_group_rights_modal: 'edit_group_rights_modal',
  forgot_password_company_name: 'forgot-password-company-name',
  forgot_password_email: 'forgot-password-email',
  sidebar_drawer: 'sidebar-drawer',
  sidebar_hide_button: 'sidebar_hide_button',
  sidebar_profile_tab: 'sidebar_profile_tab',
  sidebar_profile_icon: 'sidebar-profile-icon',
  sidebar_tour_icon: 'sidebar-tour-icon',
  sidebar_tour_tab: 'sidebar-tour-tab',
  sidebar_tour_header: 'sidebar-tour-header',
  sidebar_tour_description: 'sidebar-tour-description',
  sidebar_tour_list: 'sidebar-tour-list',
  sidebar_tour_group: 'sidebar-tour-groups',
  sidebar_tour_flows: 'sidebar-tour-flows',
  sidebar_tour_flow_icon: 'sidebar-tour-flow-icon',
  sidebar_software_assurance_tab: 'sidebar_software_assurance_tab',
  sidebar_software_assurance_spinner: 'sidebar_software_assurance_spinner',
  sidebar_help_tab: 'sidebar_help_tab',
  sidebar_profile_stack: 'sidebar_profile_stack',
  sidebar_profile_stack_heading: 'sidebar_profile_stack_heading',
  sidebar_profile_stack_view_profile_button: 'sidebar_profile_stack_view_profile_button',
  sidebar_profile_stack_change_password_button: 'sidebar_profile_stack_change_password_button',
  sidebar_profile_stack_logout_button: 'sidebar_profile_stack_logout_button',
  sidebar_profile_stack_contact_support_button: 'sidebar_profile_stack_contact_support_button',
  sidebar_profile_table: 'sidebar_profile_table',
  sidebar_profile_table_username: 'sidebar_profile_table_username',
  sidebar_profile_table_company_id: 'sidebar_profile_table_company_id',
  sidebar_profile_stack_current_login: 'sidebar_profile_stack_current_login',
  new_password_input: 'new_password_input',
  confirm_password_input: 'confirm_password_input',
  sidebar_csa_icon: 'sidebar_csa_icon',
  sidebar_csa_stack: 'sidebar_csa_stack',
  sidebar_csa_stack_heading: 'sidebar_csa_stack_heading',
  sidebar_csa_result_title: 'sidebar_csa_result_title',
  sidebar_csa_result_name: 'sidebar_csa_result_name',
  sidebar_csa_update_time: 'sidebar_csa_update_time',
  sidebar_csa_view_result_button: 'sidebar_csa_view_result_button',
  sidebar_csa_details_grid: 'sidebar_csa_details_grid',
  sidebar_csa_view_dashboard_button: 'sidebar_csa_view_dashboard_button',
  sidebar_csa_version_notes_heading: 'sidebar_csa_version_notes_heading',
  sidebar_csa_software_version_grid: 'sidebar_csa_software_version_grid',
  sidebar_csa_view_release_notes_button: 'sidebar_csa_view_release_notes_button',
  sidebar_csa_contract_id: 'sidebar_csa_contract_id',
  sidebar_csa_released: 'sidebar_csa_released',
  sidebar_csa_version: 'sidebar_csa_version',
  sidebar_csa_renewal_date: 'sidebar_csa_renewal_date',
  sidebar_csa_infotip_data_aquisation_and_integrity: 'sidebar_csa_infotip_data_aquisation_and_integrity',
  sidebar_csa_infotip_alarms: 'sidebar_csa_infotip_alarms',
  sidebar_csa_infotip_notifications: 'sidebar_csa_infotip_notifications',
  sidebar_csa_infotip_reports: 'sidebar_csa_infotip_reports',
  sidebar_csa_infotip_access_control: 'sidebar_csa_infotip_access_control',
  sidebar_csa_infotip_data_aquisation_and_integrity_content:
    'sidebar_csa_infotip_data_aquisation_and_integrity_content',
  sidebar_csa_infotip_alarms_content: 'sidebar_csa_infotip_alarms_content',
  sidebar_csa_infotip_notifications_content: 'sidebar_csa_infotip_notifications_content',
  sidebar_csa_infotip_reports_content: 'sidebar_csa_infotip_reports_content',
  sidebar_csa_infotip_access_control_content: 'sidebar_csa_infotip_access_control_content'
};
