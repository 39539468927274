import * as React from 'react';
import { Buffer } from 'buffer';
import { Form, Heading, Button } from '@vaisala/rockhopper-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../../utils/routes';
import { PasswordResetError } from '../../store/actions/auth';

import { decodeURIPrime, IPasswordPolicy } from '../../utils/common';
import { useTranslation } from 'react-i18next';
import { PasswordHelpText } from './PasswordHelpText';

import usePasswordValidators from '../../hooks/usePasswordValidators';
import CenteredSpinner from '../BaseComponents/CenteredSpinner';

import './reset-password.scss';
import { LS_COMPANY_ALIAS, LS_LOCALE } from '../../constants';
import { Languages } from '../../i18n';

interface ResetPasswordProps {
  resettingPassword: boolean;
  isPasswordReset: boolean;
  resettingPasswordError?: PasswordResetError;
  language: Languages;
  passwordPolicyLength: number;
  passwordPolicyMaxLength: number;
  passwordPolicyNumberRequired: boolean;
  passwordPolicySpecialCharRequired: boolean;
  passwordPolicyUpperCaseRequired: boolean;
  passwordPolicyLowerCaseRequired: boolean;
  setUpPasswordPolicy: () => void;
  resetPassword: (userName: string, resetCode: string, newPassword: string) => void;
  setResettingPasswordError: (error: PasswordResetError | null) => void;
  changeLanguage: (language: Languages) => void;
}

const ResetPassword = ({
  isPasswordReset,
  resetPassword,
  setUpPasswordPolicy,
  setResettingPasswordError,
  resettingPassword,
  language,
  passwordPolicyLength,
  passwordPolicyMaxLength,
  passwordPolicyNumberRequired,
  passwordPolicySpecialCharRequired,
  passwordPolicyUpperCaseRequired,
  passwordPolicyLowerCaseRequired,
  resettingPasswordError,
  changeLanguage
}: ResetPasswordProps) => {
  const { t, ready } = useTranslation('english', { useSuspense: false });
  const navigate = useNavigate();
  const location = useLocation();
  const [resetCode, setResetCode] = React.useState('');
  const [userName, setUserName] = React.useState('');
  const [isLinkConfigured, setIsLinkConfigured] = React.useState<boolean>(false);

  const [passwordBlackList, setPasswordBlackList] = React.useState<string[]>([]);
  const passwordPolicy: IPasswordPolicy = {
    minimumLength: passwordPolicyLength,
    maximumLength: passwordPolicyMaxLength,
    isNumberRequired: passwordPolicyNumberRequired,
    isSpecialCharRequired: passwordPolicySpecialCharRequired,
    isUpperCaseRequired: passwordPolicyUpperCaseRequired,
    isLowerCaseRequired: passwordPolicyLowerCaseRequired
  };

  React.useEffect(() => {
    if (resettingPasswordError) {
      if (resettingPasswordError === PasswordResetError.InvalidPasswordException) {
        setNewPasswordError(t('auth.doNotMeetCriteria'));
      } else {
        setResettingPasswordError(resettingPasswordError);
      }
    }
    if (isPasswordReset) {
      navigate(routes.login.url);
    }
  }, [resettingPasswordError, isPasswordReset]);

  React.useEffect(() => {
    // This used to use history.location.search
    const decodedString = Buffer.from(location.search, 'base64').toString('ascii');
    const decodedArray = decodedString.split('&');
    let userParameters: string[] = [];
    let hasCompanyAlias = false;
    let hasUsername = false;
    let hasResetCode = false;
    const blacklist: string[] = [];
    decodedArray.map(param => {
      userParameters = param.split('=');
      const urlKey = userParameters[0];
      const urlValue = decodeURIComponent(userParameters[1]);
      if (typeof urlValue !== 'undefined') {
        switch (urlKey) {
          case 'username':
            setUserName(urlValue);
            hasUsername = Boolean(urlValue);
            if (hasUsername) {
              blacklist.push(urlValue);
            }
            break;
          case LS_LOCALE:
            changeLanguage(decodeURIPrime(userParameters[1]) as Languages);
            break;
          case 'code':
            setResetCode(urlValue);
            hasResetCode = Boolean(urlValue);
            break;
          case LS_COMPANY_ALIAS:
            hasCompanyAlias = Boolean(urlValue);
            if (hasCompanyAlias) {
              localStorage.setItem(LS_COMPANY_ALIAS, urlValue);
              blacklist.push(urlValue);
              setUpPasswordPolicy();
            }
            break;
        }
      }
    });
    const hasAllRequiredParams = hasCompanyAlias && hasUsername && hasResetCode;
    setIsLinkConfigured(hasAllRequiredParams);
    setPasswordBlackList([...passwordBlackList, ...blacklist]);
    return () => setResettingPasswordError(null);
  }, []);

  React.useEffect(() => {
    // Need to wait for translations to load.
    if (!ready) {
      return;
    }
    if (!isLinkConfigured) {
      setResettingPasswordError(PasswordResetError.CodeMismatchException);
    }
  }, [ready, isLinkConfigured]);

  const {
    isButtonDisabled,
    renderConfirmPassword,
    renderNewPassword,
    setNewPasswordError,
    newPassword
  } = usePasswordValidators({
    loading: resettingPassword,
    // TODO: This may not be needed, but was something that was inherited from past password component code. It may be useful
    // when we start targeting more screen sizes.
    // displaySize: displaySize,
    t,
    language,
    passwordPolicy,
    passwordBlackList
  });

  const canSend = isLinkConfigured && !isButtonDisabled;

  const onSubmit = React.useCallback(() => {
    if (canSend) {
      resetPassword(userName, resetCode, newPassword);
    }
  }, [resetPassword, canSend]);

  return (
    <Form htmlId="reset-password-form" preventDefault={true} onSubmit={onSubmit} className="login-form">
      <Heading htmlId="reset-password-heading" level={2} className="reset-password-title">
        {t('auth.createNewPasswordTitle')}
      </Heading>
      <PasswordHelpText passwordPolicy={passwordPolicy} />
      {renderNewPassword()}
      {renderConfirmPassword()}
      <div id="buttons-container" className="app-login-submit vai-margin-top-m">
        <Link id="return-to-login-link" to={routes.login.url}>
          {t('general.back')}
        </Link>
        <Button htmlId="reset-password" id="reset-password" disabled={!canSend} type={'submit'}>
          {resettingPassword ? <CenteredSpinner htmlId="reset-password-spinner" className="" /> : t('general.save')}
        </Button>
      </div>
    </Form>
  );
};

export default ResetPassword;
