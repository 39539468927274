import React, { useRef } from 'react';
import { LocationNode } from '../../siteTree/site';
import { DataTable, DataTableColumn, TableSortDirection } from '@vaisala/rockhopper-data-table';
import './siteTable.scss';
import {
  ActionFormatter,
  DeviceAlarmFormatter,
  DeviceFormatter,
  formatDataForTable,
  FormattedSiteTableData,
  IconFormatter,
  LocationFormatter,
  ThresholdAlarmFormatter,
  ValueFormatter,
  ValueWithUnitFormatter
} from './SiteTableFormatters';
import { useResizeObserver } from '../../hooks/useResizeObserver';
import { useTranslation } from 'react-i18next';
import { sortRows } from '../../utils';
import { LocationStatusFormatter } from './SiteTableFormatters';
import { setSelectedNodeId, selectShowInactiveNodes, setSelectedNode } from '../../store/siteTree';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import useUserSettings from '../../hooks/useUserSettings';

interface SiteTableProps {
  data: LocationNode[];
  type: 'status' | 'settings';
  toggleHighlight?: (node_id: string) => void;
  descTextHeight?: number;
}

export const SiteTable: React.FC<SiteTableProps> = ({ data, type, toggleHighlight, descTextHeight }) => {
  const { showInactiveLocations } = useUserSettings();
  const showInactiveLocationsState = useAppSelector(selectShowInactiveNodes);
  const [initialData, setInitialData] = React.useState<FormattedSiteTableData[]>(
    formatDataForTable(data, showInactiveLocations)
  );
  const [rows, setRows] = React.useState<FormattedSiteTableData[]>(formatDataForTable(data, showInactiveLocations));
  const ref = useRef<HTMLDivElement>(null);
  const [, height] = useResizeObserver(ref, 400);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    setInitialData(formatDataForTable(data, showInactiveLocations));
    setRows(formatDataForTable(data, showInactiveLocations));
  }, [data, showInactiveLocations, showInactiveLocationsState]);

  // DataTable function for generating rows from data.
  const getRows = (rowIdx: number) => {
    return rows[rowIdx];
  };

  const setSelectedNodeInTree = (nodeId: string) => {
    dispatch(setSelectedNodeId(nodeId));
    dispatch(setSelectedNode(nodeId));
  };

  // Register columns for DataTable
  const StatusColumns: DataTableColumn[] = [
    {
      key: 'status',
      name: t('site.status'),
      width: 56,
      formatter: details => {
        return <LocationStatusFormatter status={details.value} />;
      }
    },
    {
      key: 'type',
      name: t('site.type'),
      width: 56,
      formatter: IconFormatter
    },
    {
      key: 'location',
      name: t('general.location'),
      sortable: true,
      formatter: details => {
        return (
          <LocationFormatter
            value={details.value}
            id={details.row.node_id}
            selectNode={id => {
              setSelectedNodeInTree(id);
              dispatch(setSelectedNode(details.row));
            }}
          />
        );
      }
    },
    {
      key: 'value',
      name: t('general.value'),
      formatter: ValueWithUnitFormatter
    },
    {
      key: 'time',
      name: t('general.time'),
      formatter: ValueFormatter
    },
    {
      key: 'action',
      name: t('general.action'),
      formatter: details => (
        <ActionFormatter
          rowId={details.row.node_id}
          onClick={() => toggleHighlight?.(details.row.node_id)}
          value={details.value}
        />
      ),
      width: 86
    }
  ];

  const SettingsColumns: DataTableColumn[] = [
    {
      key: 'type',
      name: t('site.type'),
      width: 56,
      formatter: IconFormatter
    },
    {
      key: 'location',
      name: t('general.location'),
      sortable: true,
      formatter: details => {
        return (
          <LocationFormatter
            value={details.value}
            id={details.row.node_id}
            selectNode={id => {
              setSelectedNodeInTree(id);
              dispatch(setSelectedNode(details.row));
            }}
          />
        );
      }
    },
    {
      // data source
      key: 'device',
      name: t('general.dataSource'),
      formatter: DeviceFormatter
    },
    {
      // threshold alarm
      key: 'thres_alarm_set',
      name: t('site.thresholdAlarm'),
      formatter: ThresholdAlarmFormatter
    },
    {
      // device alarm
      key: 'device_alarm_set',
      name: t('site.deviceAlarm'),
      formatter: DeviceAlarmFormatter
    },
    {
      key: 'action',
      name: t('general.action'),
      formatter: details => (
        <ActionFormatter
          rowId={details.row.node_id}
          onClick={() => toggleHighlight?.(details.row.node_id)}
          value={details.value}
        />
      ),
      width: 86
    }
  ];

  const onGridSort = (sortColumn: string, sortDirection: TableSortDirection) => {
    setRows(sortRows(initialData, sortColumn, sortDirection));
  };

  return (
    <div className="site-table__container" ref={ref}>
      {rows && (
        <DataTable
          className="site-table"
          columns={type === 'status' ? StatusColumns : SettingsColumns}
          rowGetter={getRows}
          rowsCount={rows.length}
          onGridSort={onGridSort}
          minColumnWidth={150}
          sortColumn="location"
          minHeight={descTextHeight ? (height ?? 0) - descTextHeight : height ?? 0}
          hasFocusBorder={false}
        />
      )}
    </div>
  );
};
