import React from 'react';
import { Grid, Icon, Size } from '@vaisala/rockhopper-components';
import { TEST_IDS } from '../../../../../tests/testids';
import { getIcon } from '../../../../../utils/site';
import { useTranslation } from 'react-i18next';
import { LocationNode } from '../../../../../siteTree/site';

interface Props {
  currentStepNumber: number;
  stepsCount: number;
  stepNumberTitles: { [key: number]: string };
  location: LocationNode;
}

export const StepsDisplay: React.FC<Props> = ({ currentStepNumber, stepNumberTitles, stepsCount, location }) => {
  const { t } = useTranslation();
  return (
    <Grid>
      <Grid.Row className="display-row">
        <Grid.Col md={2} lg={2} xl={2}>
          <h3>{t('general.location')}</h3>
        </Grid.Col>
        <Grid.Col md={10} lg={10} xl={10}>
          <h3>
            <Icon size={Size.M} name={getIcon(location.meas_id).name} /> {location.name}
          </h3>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="display-row">
        <Grid.Col md={2} lg={2} xl={2}>
          <h3>
            {t('site.alarms.settings.threshold.steps.display', {
              stepNumber: currentStepNumber,
              stepsCount: stepsCount
            })}
          </h3>
        </Grid.Col>
        <Grid.Col md={10} lg={10} xl={10}>
          <h3 data-ta={TEST_IDS.alarm_threshold_step_count_message}>{stepNumberTitles[currentStepNumber]}</h3>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};
