import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { parse } from 'querystring';
import { useLazyDownloadReportQuery, useLazyGetDownloadUrlQuery } from '../../../store/services/reportsApi';
import { LS_COMPANY_CUSTOMER_ID } from '../../../constants';
import { getConstantFromLocalStorage } from '../../../utils';
import CenteredSpinner from '../../BaseComponents/CenteredSpinner';
import { NotificationType } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../store/hooks';
import { addNotification } from '../../../store/notifications';
import './download-report.scss';

const DownloadReport: React.FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
  const dispatch = useAppDispatch();

  const [
    getDownloadUrl,
    { isFetching: isFetchingUrl, data: reportUrl, error: urlError, isUninitialized: isUrlUninitiated }
  ] = useLazyGetDownloadUrlQuery();
  const [
    downloadReport,
    { isFetching: isFetchingFile, data: reportData, error: fileError, isUninitialized: isFileUninitiated }
  ] = useLazyDownloadReportQuery();

  const [downloadUrl, setDownloadUrl] = React.useState('');
  const [filename, setFilename] = React.useState('');

  const anchorRef = React.useRef<HTMLAnchorElement | null>(null);

  const showErrorNotification = () => {
    dispatch(
      addNotification({
        type: NotificationType.Error,
        content: t('reports.generalDownloadingError')
      })
    );
  };

  React.useEffect(() => {
    const { reportInstanceId, title } = parse(location.search.substring(1)) as { [key: string]: string };

    if (title) {
      setFilename(title);
    }

    if (reportInstanceId) {
      // Call get download url endpoint
      customerId && getDownloadUrl({ customerId, reportInstanceId });
    } else {
      navigate('/reports/graph', { replace: true });
    }
  }, []);

  React.useEffect(() => {
    if (reportUrl && reportUrl.success && reportUrl.message && !urlError) {
      downloadReport({ url: reportUrl.message });
    } else if (!isUrlUninitiated && ((reportUrl && !reportUrl.success) || urlError)) {
      showErrorNotification();
      navigate('/reports/graph', { replace: true });
    }
  }, [reportUrl, urlError]);

  React.useEffect(() => {
    if (reportData) {
      const url = window.URL.createObjectURL(reportData);
      setDownloadUrl(url);
    } else if (!isFileUninitiated && (!reportData || fileError)) {
      showErrorNotification();
      navigate('/reports/graph', { replace: true });
    }
  }, [reportData, fileError]);

  React.useEffect(() => {
    if (downloadUrl && anchorRef.current) {
      let fileNameDirectories, title;

      if (!filename) {
        const anchor = document.createElement('a');
        if (reportUrl && reportUrl.message) {
          anchor.href = reportUrl.message; // This element to be able to get the file name from the reportUrl
        }

        fileNameDirectories = anchor.pathname.split('/'); // Filename
        title = fileNameDirectories[fileNameDirectories.length - 1];
      } else {
        title = filename;
      }

      // This is the only way to make sure that all browsers will download the file (Some browsers the default would be to open the file in a new tab only)
      anchorRef.current.setAttribute('download', title);
      anchorRef.current.setAttribute('type', reportData.type); // Add mime type for file
      anchorRef.current.click();

      navigate('/reports/graph', { replace: true });
    }
  }, [downloadUrl]);

  return (
    <>
      {isFetchingUrl || isFetchingFile ? <CenteredSpinner /> : <></>}
      <a id="download-link" href={downloadUrl} ref={anchorRef}></a>
    </>
  );
};

export default DownloadReport;
