import { Icon, RequiredAsterisk, Size } from '@vaisala/rockhopper-components';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import React from 'react';
import i18n from '../../../../i18n';
import { TEST_IDS } from '../../../../tests/testids';

interface Props {
  iconName: string;
  parentZoneName: string;
}
export const ParentZone: React.FC<Props> = ({ iconName, parentZoneName }) => {
  return (
    <div
      data-ta={TEST_IDS.site_tree_form_dialog_parent_zone}
      id="zone-and-location-dialog-parent-name-container"
      className="zone-and-location-dialog-parent-name-container"
    >
      <span id="zone-and-location-dialog-astrisk" className="zone-and-location-dialog-astrisk">
        <RequiredAsterisk />
      </span>
      <span id="zone-and-location-dialog-parent-zone" className="zone-and-location-dialog-parent-zone">
        {i18n.t('site.parentZone')}
      </span>
      <Icon
        className="zone-and-location-dialog-icon"
        id="zone-and-location-dialog-icon"
        name={iconName || VaiIcon.FolderOpen}
        size={Size.M}
      />
      <span
        data-ta={TEST_IDS.site_tree_form_dialog_parent_zone_name}
        id="zone-and-location-dialog-parent-zone-name"
        className="zone-and-location-dialog-parent-zone-name"
      >
        {parentZoneName}
      </span>
    </div>
  );
};
