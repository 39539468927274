import { NotificationType } from '@vaisala/rockhopper-components';
import { useEffect } from 'react';
import i18n from '../i18n';
import { useAppDispatch } from '../store/hooks';
import { addNotification } from '../store/notifications';
import { useActivateGroupMutation } from '../store/services/userApi';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import * as ActionType from '../store/actions/actionTypes';

const useGroupsActions = () => {
  const dispatch = useAppDispatch();

  const [toggleGroupActivation, groupActivationResultDetails] = useActivateGroupMutation();

  useEffect(() => {
    const groupName = groupActivationResultDetails.originalArgs?.name;
    const active = groupActivationResultDetails.originalArgs?.active;

    if (groupActivationResultDetails.status === QueryStatus.rejected) {
      if (groupActivationResultDetails.isError) {
        dispatch(
          addNotification({
            type: NotificationType.Alarm,
            content: i18n.t('profile.NotifyGroupActivationFailed')
          })
        );
      }
    }
    if (groupActivationResultDetails.status === QueryStatus.fulfilled) {
      if (groupActivationResultDetails.isSuccess) {
        dispatch({ type: ActionType.TOGGLE_GROUP_ACTIVATION_SUCCESS });
        if (active) {
          dispatch(
            addNotification({
              type: NotificationType.Ok,
              content: i18n.t('profile.NotifyGroupActivated', { groupName })
            })
          );
        } else {
          dispatch(
            addNotification({
              type: NotificationType.Ok,
              content: i18n.t('profile.NotifyGroupDeactivated', { groupName })
            })
          );
        }
      }
    }
  }, [
    groupActivationResultDetails.status,
    groupActivationResultDetails.isError,
    groupActivationResultDetails.originalArgs
  ]);

  return {
    toggleGroupActivation,
    groupActivationResultDetails: {
      ...groupActivationResultDetails,
      isQueryEnded:
        [QueryStatus.fulfilled, QueryStatus.rejected].includes(groupActivationResultDetails.status) &&
        !groupActivationResultDetails.isUninitialized
    }
  };
};

export default useGroupsActions;
