import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';

export enum SidebarContent {
  HELP = 'HELP',
  PROFILE = 'PROFILE',
  TOURS = 'TOURS',
  SOFTWARE_ASSURANCE = 'SOFTWARE_ASSURANCE'
}
export interface SidebarState {
  isOpen: boolean;
  content: SidebarContent;
}
const initialState = {
  isOpen: false,
  content: SidebarContent['HELP']
} as SidebarState;

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setIsOpen(state, action) {
      state.isOpen = action.payload;
    },
    setSideBarContent(state, action) {
      state.content = action.payload;
    }
  }
});
export const selectIsOpen = (state: RootState) => state.sidebar.isOpen;
export const selectContent = (state: RootState) => state.sidebar.content;
export const { setIsOpen, setSideBarContent } = sidebarSlice.actions;
export default sidebarSlice.reducer;
