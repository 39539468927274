import * as React from 'react';
import { Size, Button, ButtonType, BodyText } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import { ZONE_KEY, LOCATION_KEY } from '../../../constants';
import { ModalContainer } from '../../Modals/ModalContainer';
import { ButtonWithLoader } from '../../ButtonWithLoader/ButtonWithLoader';
import { TEST_IDS } from '../../../tests/testids';
import './delete-zone-and-location.scss';
import { TranslationKey } from '../../../react-i18next';

interface ZoneAndLocationDeletionDialogProps {
  node_id: string;
  name: string;
  deleteTreeNode: any;
  type: string;
  parent_id: string | null;
  loading?: boolean;
  onDismiss: () => void;
}

const DeleteZoneAndLocationDialog = ({
  node_id,
  name,
  type,
  parent_id,
  deleteTreeNode,
  loading,
  onDismiss
}: ZoneAndLocationDeletionDialogProps): JSX.Element => {
  const { t } = useTranslation();

  const deleteNodes = () => {
    deleteTreeNode({ node_id, type, parent_id, name });
  };

  let modalHeader: TranslationKey | undefined = undefined;
  if (type === ZONE_KEY) {
    modalHeader = 'site.deleteZone';
  } else if (type === LOCATION_KEY) {
    modalHeader = 'site.deleteLocation';
  }

  const modalButtons = (
    <>
      <ButtonWithLoader
        id="delete-zones-and-locations-confirm"
        htmlId="delete-zones-and-locations-confirm"
        onClick={deleteNodes}
        buttonType={ButtonType.Secondary}
        loading={loading}
        dataTa={TEST_IDS.delete_confirm_button}
      >
        {t('general.delete')}
      </ButtonWithLoader>
      <Button id="delete-zones-and-locations-cancel" htmlId="delete-zones-and-locations-cancel" onClick={onDismiss}>
        {t('general.cancel')}
      </Button>
    </>
  );

  return (
    <ModalContainer
      id="delete-zones-and-locations-dialog"
      isOpen
      htmlId="delete-zones-and-locations-dialog"
      onDismiss={onDismiss}
      width={Size.M}
      showCloseIcon={true}
      dismissOnOverlayClick={false}
      title={modalHeader}
      role="alert-dialog"
      buttons={modalButtons}
    >
      <BodyText id="delete-zones-and-locations-body" className="delete-zones-and-locations-body">
        {type === ZONE_KEY
          ? t('site.deleteZonesAlert', { nodeName: name })
          : t('site.deleteLocationsAlert', { nodeName: name })}
      </BodyText>
    </ModalContainer>
  );
};

export default DeleteZoneAndLocationDialog;
