import React, { useState } from 'react';
import { InputField, InputFieldProps } from '@vaisala/rockhopper-components';

interface CustomProps {
  handleChange: (change: { value: number | null }) => void;
  initialValue: string;
  regExp: RegExp;
}

type Props = CustomProps & InputFieldProps;

/**
 * @param handleChange - will be called if input value matches the pattern and is not NAN. If value isNAN it will return value as null.
 * @param initialValue - will be loaded as initial value and then it will keep it is own local state for displaying to the user
 * @param pattern - input regex pattern. User won't be able to type non matching chars
 * @param dataTa - optional
 * @returns
 */

const NumberField: React.FC<Props> = ({ handleChange, initialValue, regExp, dataTa, onBlur, ...rest }) => {
  const [value, setValue] = useState<string>(initialValue);

  const _handleChange = (value: string) => {
    const isMatch = regExp.test(value);
    if (isMatch) {
      setValue(value);
      const parsedValue = parseFloat(value);
      handleChange({ value: isNaN(parsedValue) ? null : parsedValue });
    }
  };

  return (
    <InputField
      value={value}
      onChange={event => {
        _handleChange(event.target.value);
      }}
      onBlur={onBlur}
      {...(dataTa !== undefined ? { 'data-ta': dataTa } : {})}
      {...rest}
    />
  );
};

export default NumberField;
