import React from 'react';
import { DeviceAlarmSettings } from '../../../../store/services/alarmsApi';
import { DisplayRow } from '../../Alarms/shared/DisplayRow';
import { DEVICE_ALARM_RULE_NAMES } from '../../../../constants';
import { Group } from '../../../../store/services/userApi';
import { AlarmSettingsDisplayContainer } from '../../Alarms/shared/AlarmSettingsDisplayContainer';
import { useTranslation } from 'react-i18next';
import { Heading } from '@vaisala/rockhopper-components';
import { ALARM_RULE_NAME_HEADING_TRANSLATION_KEYS, DeviceAlarmRuleIcons } from './shared';
import { AlarmPriorityIcons } from '../../Alarms/shared/AlarmIcons';

export interface Props {
  settingsDetails: { settings: DeviceAlarmSettings | undefined; isFetching: boolean };
  notificationGroupsDetails: { groups: Group[] | undefined; isFetching: boolean };
}

export const DeviceAlarmSettingsDisplay: React.FC<Props> = ({ settingsDetails, notificationGroupsDetails }) => {
  const { settings, isFetching } = settingsDetails;
  const { groups } = notificationGroupsDetails;
  const { t } = useTranslation();

  const groupDetails =
    groups?.map(group => {
      return { name: group.group_name, numberOfUsers: group.users.length, id: group.group_id };
    }) || [];

  const getLevelGroupDetails = (list: string[] | undefined) => {
    return list ? groupDetails.filter(group => list.includes(group.id)) : [];
  };

  const isLoading = !settings || isFetching || notificationGroupsDetails.isFetching;

  return (
    <AlarmSettingsDisplayContainer isLoading={isLoading}>
      {settings &&
        Object.values(DEVICE_ALARM_RULE_NAMES).map((ruleName: DEVICE_ALARM_RULE_NAMES) => {
          const AlarmIcon = DeviceAlarmRuleIcons[ruleName];
          const PriorityIcon = AlarmPriorityIcons[settings.template.rules[ruleName].priority];
          return (
            <DisplayRow
              key={ruleName}
              alarmValueContent={
                <>
                  <Heading className="alarm-level-heading" level={4}>
                    {t(ALARM_RULE_NAME_HEADING_TRANSLATION_KEYS[ruleName])}
                  </Heading>
                  {settings.template.rules[ruleName].enabled ? (
                    <div className="alarm-level-icons">
                      <AlarmIcon />
                      <PriorityIcon />
                    </div>
                  ) : (
                    t('general.alarmDisabled')
                  )}
                </>
              }
              groupsDetails={getLevelGroupDetails(settings.settings.notifications[ruleName].EMAIL)}
              level={ruleName}
            />
          );
        })}
    </AlarmSettingsDisplayContainer>
  );
};
