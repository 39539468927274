import { LOCATION_STATUS, SiteNodeTypes } from '../constants';
import { LocationNode, ZoneNode, SiteNode } from '../siteTree/site';
import { isLocationNode, isSiteNode, isZoneNode } from './common';

/**
 * Defines the structure of an OutputSite node which can have multiple children of different types.
 */
export interface OutputSiteNode extends SiteNode {
  type: SiteNodeTypes.SITE;
  alarms_count: number;
  status: LOCATION_STATUS;
}

/**
 * Defines the structure of an OutputZoneNode.
 * An output zone has associated alarm counts, a status, and can have multiple children.
 */
export interface OutputZoneNode extends ZoneNode {
  type: SiteNodeTypes.ZONE;
  alarms_count: number;
  status: LOCATION_STATUS;
}

/**
 * A utility mapping that assigns a priority level to each location status.
 * Lower numbers indicate higher priority.
 */
const statusOrder: { [key in LOCATION_STATUS]: number } = {
  [LOCATION_STATUS.CRITICAL]: 0,
  [LOCATION_STATUS.MODERATE]: 1,
  [LOCATION_STATUS.INFO]: 2,
  [LOCATION_STATUS.OK]: 3,
  [LOCATION_STATUS.UNLINK]: 4
};

/**
 * Utility function to determine the most critical status from a list.
 *
 * @param {LOCATION_STATUS[]} statuses - Array of statuses to evaluate.
 * @returns {LOCATION_STATUS} The status with the highest priority.
 */
export const getHighestStatus = (statuses: LOCATION_STATUS[]): LOCATION_STATUS => {
  return [...statuses].sort((a, b) => statusOrder[a] - statusOrder[b])[0];
};

export type InputTreeNode = SiteNode | ZoneNode | LocationNode;
export type OutputTreeNode = (
  | Omit<OutputSiteNode, 'type'>
  | Omit<OutputZoneNode, 'type'>
  | Omit<LocationNode, 'type'>
) & { type: SiteNodeTypes };

/**
 * Function to transform an input tree node into its corresponding output form.
 * For zones and sites, it aggregates alarm counts and determines the highest status.
 *
 * @param {InputTreeNode} inputNode - The tree node to be transformed.
 * @returns {OutputTreeNode} The transformed node.
 */
export const addAlarmDetails = (inputNode: InputTreeNode): OutputTreeNode => {
  // If it's a location, just return it. Though I added the spread operator to ensure we capture any extra properties.
  if (isLocationNode(inputNode)) {
    return { ...inputNode } as LocationNode; // This will ensure that all other properties are captured.
  } else {
    const transformedChildren = inputNode.children?.map(child => addAlarmDetails(child)) || [];

    const totalAlarms = transformedChildren.reduce((acc, child) => {
      if (isLocationNode(child) || isZoneNode(child)) {
        return acc + child.alarms_count;
      }
      return acc;
    }, 0);

    const childStatuses: LOCATION_STATUS[] = transformedChildren
      .filter(child => isLocationNode(child) || isZoneNode(child))
      .map(child => {
        if (isLocationNode(child) || isZoneNode(child)) {
          return child.status;
        }
        throw new Error('Unexpected node type');
      });

    const highestStatus = childStatuses.length > 0 ? getHighestStatus(childStatuses) : LOCATION_STATUS.OK;

    if (isZoneNode(inputNode)) {
      return {
        ...inputNode, // Spread to capture any extra properties
        alarms_count: totalAlarms,
        status: highestStatus,
        children: transformedChildren
      } as OutputZoneNode;
    } else if (isSiteNode(inputNode)) {
      return {
        ...inputNode, // Spread to capture any extra properties
        children: transformedChildren
      } as OutputSiteNode;
    } else {
      throw new Error('Unsupported node type');
    }
  }
};
