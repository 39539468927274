import React from 'react';

/**
 * Renders content provided as children if
 * the current step index order matches the order of Steps.Step
 */

export const Step: React.FC = ({ children }) => {
  return <>{children}</>;
};

Step.displayName = 'Step';
