import * as React from 'react';
import { InvitedUsersInterface } from '../../../profile/user';
import './user-rejection-dialog.scss';
import { Size, Button, ButtonType, BodyText } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../store/hooks';
import { hideDialog } from '../../../store/dialog';
import { ModalContainer } from '../../Modals/ModalContainer';
import useEmails from '../../../hooks/useEmails';
import { INVITE_USER_FROM_USER } from '../../../constants';

interface UserRejectionDialogProps {
  rejectedUsers: string[];
  selectedGroupIds: string[];
  inviteUsers: (users: InvitedUsersInterface, dispatcher: string, name?: string) => string[];
  selectedLanguage: string;
}

const UserRejectionDialog = (props: UserRejectionDialogProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [saving, setSaving] = React.useState<boolean>(false);
  const { setEmails, validEmails, setValidEmails, renderTagInput } = useEmails({ emails: props.rejectedUsers });

  const handleInviteUsers = async () => {
    setSaving(true);
    const invitedUsers: InvitedUsersInterface['users'] = [];
    props.rejectedUsers.map(email => {
      const invitedUser = {
        user_name: email,
        locale: props.selectedLanguage,
        // TODO: Use timezone from the user's profile. It won't necessarily match that of the browser.
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        group_ids: props.selectedGroupIds
      };
      invitedUsers.push(invitedUser);
    });
    const users = {
      users: invitedUsers
    };

    const rejectedUsers = await props.inviteUsers(users, INVITE_USER_FROM_USER);
    if (!rejectedUsers || rejectedUsers.length === 0) {
      onDismiss();
    } else {
      // Remove the emails that sent successfully. Retain those that did not while removing any invalid ones entered by the user.
      const emailsToKeep = validEmails.filter(a => rejectedUsers.includes(a));
      setEmails(emailsToKeep);
      setValidEmails(emailsToKeep);
    }
    setSaving(false);
  };

  const onDismiss = () => dispatch(hideDialog());

  const modalButtons = (
    <div id="user-rejection-dialog-buttons" className="vai-margin-top-m reject-user-button-container">
      <Button
        id="user-rejection-confirm"
        htmlId="user-rejection-confirm"
        onClick={handleInviteUsers}
        disabled={validEmails.length === 0 || saving}
      >
        {t('inviteUsers.invite')}
      </Button>
      <Button
        id="user-rejection-cancel"
        htmlId="user-rejection-cancel"
        onClick={onDismiss}
        buttonType={ButtonType.Secondary}
      >
        {t('inviteUsers.cancel')}
      </Button>
    </div>
  );

  return (
    <ModalContainer
      id="user-rejection-dialog"
      isOpen
      htmlId="user-rejection-dialog"
      showCloseIcon={true}
      onDismiss={onDismiss}
      width={Size.S}
      dismissOnOverlayClick={false}
      title="inviteUsers.userRejectionTitle"
      buttons={modalButtons}
      role="alert-dialog"
    >
      <BodyText id="reject-user-body-text" className="reject-user-body">
        {t('inviteUsers.userRejectionMsg')}
      </BodyText>
      {renderTagInput({ htmlId: 'user-rejection-tag-input', placeholder: '' })}
    </ModalContainer>
  );
};

export default UserRejectionDialog;
