import React from 'react';
import { LS_COMPANY_CUSTOMER_ID } from '../../../constants';
import { SiteNode } from '../../../siteTree/site';
import { getConstantFromLocalStorage } from '../../../utils';
import { RightsDialogAPI } from './RightsDialogAPI';

interface RightsDialogProps {
  site: SiteNode;
  groupDetails: { name: string; id: string };
  onDismiss: () => void;
  onSuccess: () => void;
}

export const RightsDialog: React.FC<RightsDialogProps> = ({ site, onDismiss, groupDetails, onSuccess }) => {
  const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID) ?? '';
  return (
    <RightsDialogAPI
      onSuccess={onSuccess}
      groupDetails={groupDetails}
      onDismiss={onDismiss}
      customerId={customerId}
      site={site}
    />
  );
};
