import React, { useContext, useState } from 'react';
import { Size, Button, BodyText, ButtonType } from '@vaisala/rockhopper-components';
import { DisplaySizeProps, withDisplaySize } from '../../../withDisplaySize';
import { ModalContainer } from '../../../Modals/ModalContainer';
import { useTranslation } from 'react-i18next';
import { TEST_IDS } from '../../../../tests/testids';
import { TranslationKey } from '../../../../react-i18next';
import './styles.scss';

interface Props extends DisplaySizeProps {
  closeModal: () => void;
  fallbackContent: React.ReactNode;
  isOpen: boolean;
  title: TranslationKey;
}

const AlarmSettingsDialogContext = React.createContext<{ showWarning: () => void; closeModal: () => void }>({
  showWarning: () => {
    return;
  },
  closeModal: () => {
    return;
  }
});

export const useAlarmSettingsDialogContext = () => useContext(AlarmSettingsDialogContext);

const AlarmSettingsDialog: React.FC<Props> = ({
  children,
  closeModal,
  fallbackContent,
  isOpen,
  title
}): JSX.Element => {
  const { t } = useTranslation();
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const hideWarning = () => setIsWarningVisible(false);
  const showWarning = () => setIsWarningVisible(true);

  return (
    <ModalContainer
      className={isWarningVisible ? 'hidden' : ''}
      id="alarm-threshold-modal"
      htmlId={'alarm-threshold-modal'}
      isOpen
      width={Size.L}
      onDismiss={() => {
        showWarning();
      }}
      showCloseIcon
      dismissOnOverlayClick={false}
      title={title}
    >
      {fallbackContent && fallbackContent}
      <AlarmSettingsDialogContext.Provider value={{ showWarning, closeModal }}>
        {!fallbackContent && children}
      </AlarmSettingsDialogContext.Provider>
      <ModalContainer
        id="warning"
        htmlId="warning"
        isOpen={isWarningVisible}
        showCloseIcon
        width={Size.M}
        onDismiss={() => hideWarning()}
        dismissOnOverlayClick={false}
        role="alert-dialog"
        footerChildren={
          <>
            <Button
              dataTa={TEST_IDS.confirm_cancel}
              buttonType={ButtonType.Secondary}
              id="button"
              onClick={() => closeModal()}
            >
              {t('site.alarms.settings.threshold.warning.confirmCancel')}
            </Button>
            <Button dataTa={TEST_IDS.go_back} id="button" onClick={() => hideWarning()}>
              {t('site.alarms.settings.threshold.warning.goBack')}
            </Button>
          </>
        }
        title="site.alarms.settings.threshold.warning.header"
      >
        <BodyText id="alarm-threshold-warning-message">{t('site.alarms.settings.threshold.warning.message')}</BodyText>
      </ModalContainer>
    </ModalContainer>
  );
};

export default withDisplaySize(AlarmSettingsDialog);
