import React from 'react';
import { Icon, IconProps, Size } from '@vaisala/rockhopper-components';
import { SOFTWARE_ASSURANCE_STATUS } from '../../constants';
import { VaiColor, VaiIcon } from '@vaisala/rockhopper-design-tokens';

export const StatusIcons: Record<SOFTWARE_ASSURANCE_STATUS, React.FC<IconProps>> = {
  [SOFTWARE_ASSURANCE_STATUS.ISSUECONFIRMED]: (props: IconProps) => (
    <Icon name={VaiIcon.AlertAlarm} size={Size.M} color={VaiColor.Red} {...props} />
  ),
  [SOFTWARE_ASSURANCE_STATUS.OK]: (props: IconProps) => (
    <Icon name={VaiIcon.AlertOk} size={Size.M} color={VaiColor.LeafGreen} {...props} />
  ),
  [SOFTWARE_ASSURANCE_STATUS.ISSUEDETECTED]: (props: IconProps) => (
    <Icon name={VaiIcon.AlertInfo} size={Size.M} color={VaiColor.AlertInfo} {...props} />
  )
};
