import { Buffer } from 'buffer';
import { Auth } from 'aws-amplify';
import * as ActionType from '../actions/actionTypes';
import { Api, Apis, UserApiUris } from '../../utils/Api';
import { UserInterface, InvitedUsersInterface } from '../../profile/user';
import { createGroupsInterface, EditGroupPayload, EditGroupsInterface } from '../../profile/group';
import i18n, { Languages } from '../../i18n';
import { changeLanguage } from '../general';
import {
  NETWORK_ERROR,
  LS_COMPANY_CUSTOMER_ID,
  API_PROFILE_INCLUDE_GROUP_PARAM,
  LS_COMPANY_ALIAS,
  INVITE_USER_FROM_GROUP,
  INVITE_USER_FROM_USER,
  ADD_GROUP_TO_USER,
  API_INCLUDE_RIGHT_PARAM,
  LS_LOCALE,
  LS_USERNAME,
  LS_TIME_ZONE
} from '../../constants';
export enum ProfileError {
  'networkError',
  'usersNotFound',
  'groupNotFound',
  'customerIdError'
}
import { getConstantFromLocalStorage, setAdditionalUserFields } from '../../utils/common';
import { reportError } from '../../utils/errorHandler';
import { sortUsersByName } from '../../../src/utils/common';
import { addNotification } from '../notifications';
import { NotificationType } from '@vaisala/rockhopper-components';
/**
 * Get list of users
 */
const getUsers = () => async (dispatch: any) => {
  dispatch({ type: ActionType.GET_USER_LIST_START });
  try {
    const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
    if (customerId) {
      const uri = UserApiUris.userList.replace('{customer_id}', customerId);
      const response = await Api.get(Apis.user, uri);
      const userList = await response.json();
      dispatch({ type: ActionType.GET_USER_LIST_SUCCESS, users: sortUsersByName(userList.users) });
    } else {
      dispatch({ type: ActionType.GET_USER_LIST_FAIL, error: ProfileError.customerIdError });
    }
  } catch (error) {
    reportError(error, 'Error getting users:');
    if ((error as any).code === NETWORK_ERROR) {
      // Distinquish network error from other erros.
      dispatch({ type: ActionType.GET_USER_LIST_FAIL, error: ProfileError.networkError });
    } else {
      //  All the other error cases.
      dispatch({ type: ActionType.GET_USER_LIST_FAIL, error: ProfileError.usersNotFound });
    }
  }
};

const getUserProfile = (userName: string, includeRights = false) => async (dispatch: any) => {
  dispatch({ type: ActionType.GET_USER_PROFILE_START });
  try {
    const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
    if (customerId) {
      let uri = '';
      const params = [API_PROFILE_INCLUDE_GROUP_PARAM];
      if (includeRights) {
        params.push(API_INCLUDE_RIGHT_PARAM);
      }
      uri =
        UserApiUris.userProfile.replace('{customer_id}', customerId).replace('{user_name}', btoa(userName)) +
        '?' +
        params.join('&');
      const response = await Api.get(Apis.user, uri);
      if (response.ok) {
        const userProfile = await response.json();
        userProfile.company_alias = localStorage.getItem(LS_COMPANY_ALIAS);
        dispatch({ type: ActionType.GET_USER_PROFILE_SUCCESS, userProfile: userProfile });
      } else {
        // TODO: This should eventually move over to redux toolkit, but for now we need some information that the request was not successful.
        //  MAYBE: set an error with status code and possibly message in the state to determine what to show in the Group/User components
        throw new Error('USER_NOT_FOUND');
      }
    } else {
      dispatch({ type: ActionType.GET_USER_PROFILE_FAIL, error: ProfileError.customerIdError });
    }
  } catch (error) {
    reportError(error, 'Error getting user profile:');
    if ((error as any).code === NETWORK_ERROR) {
      // Distinquish network error from other erros.
      dispatch({ type: ActionType.GET_USER_PROFILE_FAIL, error: ProfileError.networkError });
    } else {
      //  All the other error cases.
      dispatch({ type: ActionType.GET_USER_PROFILE_FAIL, error: ProfileError.usersNotFound });
    }
  }
};

export const initProfile = () => async (dispatch: any) => {
  const currentCredentials = await Auth.currentSession();
  const userName = currentCredentials.getIdToken().payload.email;
  dispatch({ type: ActionType.GET_MY_USER_PROFILE_ON_INIT });
  try {
    const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
    const params = [API_PROFILE_INCLUDE_GROUP_PARAM, API_INCLUDE_RIGHT_PARAM];
    if (customerId) {
      const uri =
        UserApiUris.userProfile.replace('{customer_id}', customerId).replace('{user_name}', btoa(userName)) +
        '?' +
        params.join('&');
      const response = await Api.get(Apis.user, uri);
      const currentUser: UserInterface = await response.json();
      setAdditionalUserFields(currentUser);
      const type = ActionType.GET_MY_USER_PROFILE_ON_INIT_SUCCESS;
      localStorage.setItem(LS_TIME_ZONE, currentUser.tz ?? '');
      dispatch({ type, currentUser });
    } else {
      dispatch({ type: ActionType.GET_MY_USER_PROFILE_ON_INIT_FAIL, error: ProfileError.customerIdError });
    }
  } catch (error) {
    reportError(error, "Error getting current user's profile:");
    if ((error as any).code === NETWORK_ERROR) {
      // Distinguish network error from other erros.
      dispatch({ type: ActionType.GET_MY_USER_PROFILE_ON_INIT_FAIL, error: ProfileError.networkError });
    } else {
      // All the other error cases.
      dispatch({ type: ActionType.GET_MY_USER_PROFILE_ON_INIT_FAIL, error: ProfileError.usersNotFound });
    }
  }
};

export const getMyProfile = (includeRights = false) => async (dispatch: any) => {
  const currentCredentials = await Auth.currentSession();
  const userName = currentCredentials.getIdToken().payload.email;
  dispatch({ type: ActionType.GET_MY_USER_PROFILE_START });
  try {
    const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
    const params = [API_PROFILE_INCLUDE_GROUP_PARAM];
    if (customerId) {
      if (includeRights) {
        params.push(API_INCLUDE_RIGHT_PARAM);
      }
      const uri =
        UserApiUris.userProfile.replace('{customer_id}', customerId).replace('{user_name}', btoa(userName)) +
        '?' +
        params.join('&');
      const response = await Api.get(Apis.user, uri);
      const myProfile: UserInterface = await response.json();
      setAdditionalUserFields(myProfile);
      const type = ActionType.GET_MY_USER_PROFILE_SUCCESS;
      dispatch({ type, myProfile });
    } else {
      dispatch({ type: ActionType.GET_MY_USER_PROFILE_FAIL, error: ProfileError.customerIdError });
    }
  } catch (error) {
    reportError(error, 'Error getting my profile:');
    if ((error as any).code === NETWORK_ERROR) {
      // Distinguish network error from other erros.
      dispatch({ type: ActionType.GET_MY_USER_PROFILE_FAIL, error: ProfileError.networkError });
    } else {
      // All the other error cases.
      dispatch({ type: ActionType.GET_MY_USER_PROFILE_FAIL, error: ProfileError.usersNotFound });
    }
  }
};

const toggleGroupActivation = (isActive: boolean, groupId: string, groupName: string) => async (dispatch: any) => {
  try {
    const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
    if (customerId) {
      const uri = UserApiUris.groupActivation.replace('{group_id}', groupId).replace('{customer_id}', customerId);
      const body = { active: isActive };
      const response = await Api.patch(Apis.user, uri, body);
      if (response.ok) {
        dispatch({ type: ActionType.TOGGLE_GROUP_ACTIVATION_SUCCESS });
        if (isActive) {
          dispatch(
            addNotification({
              type: NotificationType.Ok,
              content: i18n.t('profile.NotifyGroupActivated', { groupName: groupName })
            })
          );
        } else {
          dispatch(
            addNotification({
              type: NotificationType.Ok,
              content: i18n.t('profile.NotifyGroupDeactivated', { groupName: groupName })
            })
          );
        }
      } else {
        dispatch({ type: ActionType.TOGGLE_GROUP_ACTIVATION_FAIL, error: ProfileError.customerIdError });
        dispatch(
          addNotification({
            type: NotificationType.Alarm,
            content: i18n.t('profile.NotifyGroupActivationFailed')
          })
        );
      }
    } else {
      dispatch({ type: ActionType.TOGGLE_GROUP_ACTIVATION_FAIL, error: ProfileError.customerIdError });
      dispatch(
        addNotification({
          type: NotificationType.Alarm,
          content: i18n.t('profile.NotifyGroupActivationFailed')
        })
      );
    }
  } catch (error) {
    reportError(error, 'Error toggling group activation(deavtivation):');
    dispatch({ type: ActionType.TOGGLE_GROUP_ACTIVATION_FAIL, error: (error as any).code ?? '' });
    dispatch(
      addNotification({
        type: NotificationType.Alarm,
        content: i18n.t('profile.NotifyGroupActivationFailed')
      })
    );
  }
};
export interface UserProfileUpdate {
  pin?: string | null;
  phone?: string;
  tz?: string;
  picture?: string | null;
  last_name?: string;
  locale?: string;
  first_name?: string;
  user_name?: string;
  email: string;
}

/**
 * Edit user profile.
 * @param userProfile
 */
const editProfile = (userProfile: UserInterface) => async (dispatch: any) => {
  const body: UserProfileUpdate = {
    pin: userProfile.pin,
    phone: userProfile.phone,
    tz: userProfile.tz,
    picture: userProfile.picture,
    last_name: userProfile.last_name,
    locale: userProfile.locale,
    first_name: userProfile.first_name,
    email: userProfile.user_name
  };
  try {
    const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
    if (customerId) {
      const uri = UserApiUris.setUpProfile
        .replace('{user_name}', Buffer.from(userProfile.user_name).toString('base64'))
        .replace('{customer_id}', customerId);

      const response = await Api.put(Apis.user, uri, body).then(res => res.json());

      if (response.success) {
        // Update language if it is changed
        if (localStorage.getItem(LS_USERNAME) == body.email && localStorage.getItem(LS_LOCALE) != body.locale) {
          dispatch(changeLanguage(body.locale as Languages));
        }
        localStorage.setItem(LS_TIME_ZONE, userProfile.tz ?? '');
        dispatch({ type: ActionType.EDIT_PROFILE_SUCCESS, payload: { ...body, user_name: body.email } });
        dispatch(
          addNotification({
            type: NotificationType.Ok,
            content: i18n.t('profile.notifyProfileEdited', {
              userName:
                userProfile.first_name || userProfile.last_name
                  ? userProfile.first_name + ' ' + userProfile.last_name
                  : userProfile.user_name
            })
          })
        );
      } else {
        dispatch(
          addNotification({
            type: NotificationType.Error,
            content: i18n.t('profile.notifyEditProfileFailed', {
              userName:
                userProfile.first_name || userProfile.last_name
                  ? userProfile.first_name + ' ' + userProfile.last_name
                  : userProfile.user_name
            })
          })
        );
      }
    } else {
      dispatch(
        addNotification({
          type: NotificationType.Error,
          content: i18n.t('profile.notifyEditProfileFailed', {
            userName:
              userProfile.first_name || userProfile.last_name
                ? userProfile.first_name + ' ' + userProfile.last_name
                : userProfile.user_name
          })
        })
      );
      dispatch({ type: ActionType.EDIT_PROFILE_FAIL, error: ProfileError.customerIdError });
    }
  } catch (error) {
    reportError(error, 'Error editing profile:');
    dispatch({ type: ActionType.EDIT_PROFILE_FAIL, error: (error as any).code ?? '' });
    dispatch(
      addNotification({
        type: NotificationType.Error,
        content: i18n.t('profile.notifyEditProfileFailed', {
          userName:
            userProfile.first_name || userProfile.last_name
              ? userProfile.first_name + ' ' + userProfile.last_name
              : userProfile.user_name
        })
      })
    );
  }
};

/**
 * Get company group list.
 */
const getGroups = () => async (dispatch: any) => {
  dispatch({ type: ActionType.GET_GROUP_LIST_START });
  try {
    const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
    if (customerId) {
      const uri = UserApiUris.companyGroups.replace('{customer_id}', customerId) + '?' + API_INCLUDE_RIGHT_PARAM;
      const response = await Api.get(Apis.user, uri);
      const groupList = await response.json();
      dispatch({ type: ActionType.GET_GROUP_LIST_SUCCESS, groups: groupList.groups });
    } else {
      dispatch({ type: ActionType.GET_GROUP_LIST_FAIL, error: ProfileError.customerIdError });
    }
  } catch (error) {
    reportError(error, 'Error getting groups:');
    dispatch({ type: ActionType.GET_GROUP_LIST_FAIL, error: (error as any).code ?? '' });
  }
};

/**
 * Invite new users.
 * @param invitedUsers
 * @param dispatcher
 */
const inviteUsers = (invitedUsers: InvitedUsersInterface, dispatcher: string, name?: string) => async (
  dispatch: any
) => {
  try {
    const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
    if (customerId) {
      const uri = UserApiUris.inviteUsers.replace('{customer_id}', customerId);
      const response = await Api.post(Apis.user, uri, invitedUsers);
      const invitationResult = await response.json();
      if (dispatcher === INVITE_USER_FROM_USER || dispatcher === INVITE_USER_FROM_GROUP) {
        if (invitationResult?.rejected.length > 0) {
          dispatch(
            addNotification({
              type: NotificationType.Alarm,
              content: i18n.t('profile.NotifyInvitationsRejected', { count: invitationResult.rejected.length })
            })
          );
          return invitationResult.rejected;
        } else {
          if (dispatcher === INVITE_USER_FROM_GROUP) {
            dispatch({ type: ActionType.ADD_GROUP_MEMBERS_SUCCESS });
          } else {
            dispatch({ type: ActionType.INVITE_USERS_SUCCESS });
          }
          dispatch(
            addNotification({
              type: NotificationType.Ok,
              content: i18n.t('profile.NotifyInvitationsSent', { count: invitationResult.accepted.length })
            })
          );
          return [];
        }
      } else if (dispatcher === ADD_GROUP_TO_USER) {
        if (invitationResult?.rejected.length > 0) {
          dispatch(
            addNotification({
              type: NotificationType.Alarm,
              content: i18n.t('profile.NotifyInvitationsRejected', { count: invitationResult.rejected.length })
            })
          );
          return invitationResult.rejected;
        } else {
          dispatch({ type: ActionType.INVITE_USERS_SUCCESS });
          dispatch(
            addNotification({
              type: NotificationType.Ok,
              content: i18n.t('addGroupsToUser.NotifyInvitationSent', { name: name })
            })
          );
          return [];
        }
      }
    } else {
      dispatch({ type: ActionType.INVITE_USERS_FAIL, error: ProfileError.customerIdError });
      dispatch(
        addNotification({
          type: NotificationType.Alarm,
          content: i18n.t('profile.NotifyInvitationsFailed')
        })
      );
    }
  } catch (error) {
    reportError(error, 'Error inviting groups:');
    dispatch({ type: ActionType.INVITE_USERS_FAIL, error: (error as any).code ?? '' });
    dispatch(
      addNotification({
        type: NotificationType.Alarm,
        content: i18n.t('profile.NotifyInvitationsFailed')
      })
    );
  }
};

/**
 * Delete a user.
 * @param userName
 */
const deleteUser = (userName: string) => async (dispatch: any) => {
  try {
    const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
    if (customerId) {
      const uri = UserApiUris.deleteUser
        .replace('{user_name}', Buffer.from(userName).toString('base64'))
        .replace('{customer_id}', customerId);
      const response = await Api.delete(Apis.user, uri);
      if (response.ok) {
        dispatch({ type: ActionType.DELETE_USER_SUCCESS });
        dispatch(
          addNotification({
            type: NotificationType.Ok,
            content: i18n.t('profile.notifyUserDeleted')
          })
        );
      } else {
        dispatch({ type: ActionType.DELETE_USER_FAIL, error: ProfileError.customerIdError });
        dispatch(
          addNotification({
            type: NotificationType.Error,
            content: i18n.t('profile.notifyDeleteUserFailed')
          })
        );
      }
    } else {
      dispatch({ type: ActionType.DELETE_USER_FAIL, error: ProfileError.customerIdError });
      dispatch(
        addNotification({
          type: NotificationType.Error,
          content: i18n.t('profile.notifyDeleteUserFailed')
        })
      );
    }
  } catch (error) {
    reportError(error, 'Error deleting users:');
    dispatch({ type: ActionType.DELETE_USER_FAIL, error: (error as any).code ?? '' });
    dispatch(
      addNotification({
        type: NotificationType.Error,
        content: i18n.t('profile.notifyDeleteUserFailed')
      })
    );
  }
};

/**
 * Invite new users.
 * @param createGroups
 */
const createGroups = (group: createGroupsInterface) => async (dispatch: any) => {
  const body = {
    group_name: group.group_name,
    description: group.description
  };
  try {
    const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
    if (customerId) {
      const uri = UserApiUris.companyGroups.replace('{customer_id}', customerId);
      const response = await Api.post(Apis.user, uri, body);
      if (response.ok) {
        dispatch({ type: ActionType.CREATE_GROUPS_SUCCESS });
        dispatch(
          addNotification({
            type: NotificationType.Ok,
            content: i18n.t('profile.notifynotifyGroupCreated', { groupName: group.group_name })
          })
        );
      } else {
        dispatch({ type: ActionType.CREATE_GROUPS_FAIL, error: ProfileError.customerIdError });
        dispatch(
          addNotification({
            type: NotificationType.Error,
            content: i18n.t('profile.notifyGroupCreationFailed')
          })
        );
      }
    } else {
      dispatch({ type: ActionType.CREATE_GROUPS_FAIL, error: ProfileError.customerIdError });
      dispatch(
        addNotification({
          type: NotificationType.Error,
          content: i18n.t('profile.notifyGroupCreationFailed')
        })
      );
    }
  } catch (error) {
    reportError(error, 'Error creating groups:');
    dispatch({ type: ActionType.CREATE_GROUPS_FAIL, error: (error as any).code ?? '' });
    dispatch(
      addNotification({
        type: NotificationType.Error,
        content: i18n.t('profile.notifyGroupCreationFailed')
      })
    );
  }
};

/**
 * Get group details.
 * @param groupId
 */

const getGroupsDetails = (groupId: string, includeRights?: boolean) => async (dispatch: any) => {
  dispatch({ type: ActionType.GET_GROUP_DETAILS_START });
  if (typeof includeRights === 'undefined') {
    includeRights = false;
  }
  try {
    const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
    if (customerId) {
      let uri = '';
      if (includeRights) {
        uri =
          UserApiUris.groupsDetails.replace('{customer_id}', customerId).replace('{group_id}', groupId) +
          '?' +
          API_INCLUDE_RIGHT_PARAM;
      } else {
        uri = UserApiUris.groupsDetails.replace('{customer_id}', customerId).replace('{group_id}', groupId);
      }

      const response = await Api.get(Apis.user, uri);
      const { ok } = response;
      if (!ok) {
        throw new Error(`Could not fetch group details @ ${uri}`);
      }
      const groupDetails = await response.json();
      dispatch({ type: ActionType.GET_GROUP_DETAILS_SUCCESS, groupDetails: groupDetails });
    } else {
      dispatch({ type: ActionType.GET_GROUP_DETAILS_FAIL, error: ProfileError.customerIdError });
    }
  } catch (error) {
    reportError(error, 'Error getting group details:');
    if ((error as any).code === NETWORK_ERROR) {
      // Distinquish network error from other erros.
      dispatch({ type: ActionType.GET_GROUP_DETAILS_FAIL, error: ProfileError.networkError });
    } else {
      //  All the other error cases.
      dispatch({ type: ActionType.GET_GROUP_DETAILS_FAIL, error: ProfileError.groupNotFound });
    }
  }
};

/**
 * Edit group.
 * @param group
 */

const editGroup = (group: EditGroupsInterface) => async (dispatch: any) => {
  // NOTE: don't pass up arguments that aren't defined on the EditGroupsInterface. This is to avoid calls to multiple microservices.
  //  eg. when group name and/or description is passed up it triggers updating the group. When we are updating rights we don't necessarily
  //      need to update the name and description. This is to avoid unnecessary calls to the microservice.
  const { group_id, ...rest } = group;
  const body: EditGroupPayload = { ...rest };

  try {
    const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
    if (customerId) {
      const uri = UserApiUris.editGroup.replace('{customer_id}', customerId).replace('{group_id}', group_id);
      const response = await Api.patch(Apis.user, uri, body);
      if (response.ok) {
        dispatch(
          addNotification({
            type: NotificationType.Ok,
            content: i18n.t('profile.notifyGroupEdited')
          })
        );
      } else {
        dispatch(
          addNotification({
            type: NotificationType.Error,
            content: i18n.t('profile.notifyEditGroupFailed')
          })
        );
      }
      dispatch({ type: ActionType.EDIT_GROUP_SUCCESS });
    } else {
      dispatch({ type: ActionType.EDIT_GROUP_FAIL, error: ProfileError.customerIdError });
      dispatch(
        addNotification({
          type: NotificationType.Error,
          content: i18n.t('profile.notifyEditGroupFailed')
        })
      );
    }
  } catch (error) {
    reportError(error, 'Error editing group:');
    dispatch({ type: ActionType.EDIT_GROUP_FAIL, error: (error as any).code ?? '' });
    dispatch(
      addNotification({
        type: NotificationType.Error,
        content: i18n.t('profile.notifyEditGroupFailed')
      })
    );
  }
};

/**
 * Delete a user.
 * @param groupId
 */
const deleteGroup = (groupId: string, groupName: string) => async (dispatch: any) => {
  try {
    const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
    if (customerId) {
      const uri = UserApiUris.deleteGroup.replace('{customer_id}', customerId).replace('{group_id}', groupId);
      const response = await Api.delete(Apis.user, uri);
      if (response.ok) {
        dispatch({ type: ActionType.DELETE_GROUP_SUCCESS });
        dispatch(
          addNotification({
            type: NotificationType.Ok,
            content: i18n.t('profile.notifyGroupDeleted', { groupName: groupName })
          })
        );
      } else {
        dispatch(
          addNotification({
            type: NotificationType.Error,
            content: i18n.t('profile.notifyDeleteGroupFailed', { groupName: groupName })
          })
        );
      }
    } else {
      dispatch({ type: ActionType.DELETE_GROUP_FAIL, error: ProfileError.customerIdError });
      dispatch(
        addNotification({
          type: NotificationType.Error,
          content: i18n.t('profile.notifyDeleteGroupFailed', { groupName: groupName })
        })
      );
    }
  } catch (error) {
    reportError(error, 'Error deleting a group:');
    dispatch({ type: ActionType.DELETE_GROUP_FAIL, error: (error as any).code ?? '' });
    dispatch(
      addNotification({
        type: NotificationType.Error,
        content: i18n.t('profile.notifyDeleteGroupFailed', { groupName: groupName })
      })
    );
  }
};

const deleteGroupUsers = (users: UserInterface[], groupId: string, groupName: string) => async (dispatch: any) => {
  try {
    const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
    const usernames = users.map(user => user.user_name);
    if (customerId) {
      const uri = UserApiUris.deleteUsersFromGroup.replace('{customer_id}', customerId).replace('{group_id}', groupId);
      const response = await Api.post(Apis.user, uri, { users: usernames });
      const deleteGroupUsersResponse = await response.json();
      if (deleteGroupUsersResponse?.rejected.length > 0) {
        dispatch(
          addNotification({
            type: NotificationType.Error,
            content: i18n.t('profile.notifyRemoveUserFailed', { groupName })
          })
        );
      } else {
        dispatch({ type: ActionType.DELETE_GROUP_USERS_SUCCESS });
        const userIdentifier =
          users.length > 1
            ? users.length
            : users[0]?.first_name && users[0]?.last_name
            ? `${users[0].first_name} ${users[0].last_name}`
            : users[0]?.email || '';

        dispatch(
          addNotification({
            type: NotificationType.Ok,
            content:
              users.length > 1
                ? i18n.t('profile.notifyUsersRemoved', { numberOfUsers: userIdentifier, groupName })
                : i18n.t('profile.notifyUserRemoved', { user: userIdentifier, groupName })
          })
        );
      }
    } else {
      dispatch({ type: ActionType.DELETE_GROUP_USERS_FAIL, error: ProfileError.customerIdError });
      dispatch(
        addNotification({
          type: NotificationType.Error,
          content: i18n.t('profile.notifyRemoveUserFailed', { groupName: groupName })
        })
      );
    }
  } catch (error) {
    reportError(error, 'Error deleting users of a group:');
    dispatch({ type: ActionType.DELETE_GROUP_USERS_FAIL, error: (error as any).code ?? '' });
    dispatch(
      addNotification({
        type: NotificationType.Error,
        content: i18n.t('profile.notifyRemoveUserFailed', { groupName: groupName })
      })
    );
  }
};

export const refreshGroups = () => async (dispatch: any) => {
  dispatch({ type: ActionType.REFRESH_GROUPS });
};

export const profileDispatchActions = {
  getUsers,
  getUserProfile,
  createGroups,
  editProfile,
  getGroups,
  inviteUsers,
  getMyProfile,
  deleteUser,
  getGroupsDetails,
  editGroup,
  deleteGroup,
  toggleGroupActivation,
  deleteGroupUsers
};
