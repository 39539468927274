import React from 'react';
import {
  CHECKBOX_STATE_COMBINATIONS,
  ACCORDION_SUMMARY_ICON,
  ACCORDION_SUMMARY_CHECKBOX_STATE_TRANSLATION_KEYS,
  PropsWithRightsTreeNode
} from './constants';
import { useTranslation } from 'react-i18next';
import { CheckboxState } from '../MultiStateCheckbox/constants';
import { TEST_IDS } from '../../tests/testids';

export const RightsSummary: React.FC<PropsWithRightsTreeNode> = ({ node }) => {
  const { t } = useTranslation();
  const state = Object.values<CheckboxState>(node.checkboxRightsStates).reduce((acc, curr) => {
    return CHECKBOX_STATE_COMBINATIONS[acc + curr];
  }, CheckboxState.UNCHECKED);

  const icon = ACCORDION_SUMMARY_ICON[state];
  const text = t(ACCORDION_SUMMARY_CHECKBOX_STATE_TRANSLATION_KEYS[state]);

  return (
    <div data-ta={TEST_IDS.rights_accordion_summary_$id + node.node_id}>
      <span>{icon}</span>
      {text}
    </div>
  );
};
