import * as React from 'react';
import { Tooltip } from '@vaisala/rockhopper-components';

interface TruncatedTextProps {
  text: string;
  className?: string;
  dataTa?: string;
}

const TruncatedText: React.FunctionComponent<TruncatedTextProps> = ({ text, className, dataTa }) => {
  const textNode = React.useRef<HTMLSpanElement>(null);
  const [showTooltip, setShowTooltip] = React.useState<boolean>(false);

  React.useEffect(() => {
    const handleMouseOver = () => {
      if (textNode.current && textNode.current.scrollHeight > textNode.current.offsetHeight && !showTooltip) {
        setShowTooltip(true);
      } else if (textNode.current && textNode.current.scrollHeight <= textNode.current.offsetHeight && showTooltip) {
        setShowTooltip(false);
      }
    };

    const currentNode = textNode.current;
    currentNode?.addEventListener('mouseover', handleMouseOver);

    return () => {
      currentNode?.removeEventListener('mouseover', handleMouseOver);
    };
  }, [textNode.current]);

  const dataTaProp = dataTa ? { ['data-ta']: dataTa } : {};
  return (
    <>
      <span {...dataTaProp} ref={textNode} className={`truncated-text ${className}`}>
        {text}
      </span>
      {showTooltip && <Tooltip triggerRef={textNode}>{text}</Tooltip>}
    </>
  );
};

export default TruncatedText;
