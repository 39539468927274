import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex } from '@vaisala/rockhopper-components';
import { Illustration, IllustrationType } from '../Utils/EmptyState/Illustration';
import { useNavigate } from 'react-router-dom';
import { TranslationKey } from '../../react-i18next';
import useHtmlId, { IUseHtmlIDProps } from '../../hooks/useHtmlId';
import { routes } from '../../utils';

export type Error404Props = {
  pathToNavigateTo?: string;
  heading?: { key: TranslationKey; params?: Record<any, any> };
  description?: { key: TranslationKey; params?: Record<any, any> } | undefined;
  buttonText?: { key: TranslationKey; params?: Record<any, any> };
  onReload?: () => void;
} & IUseHtmlIDProps;

const HTML_ID = 'error-404';

const Error404 = ({
  pathToNavigateTo,
  heading,
  description,
  buttonText,
  onReload,
  htmlId = HTML_ID
}: Error404Props) => {
  const { t } = useTranslation();
  const { getId } = useHtmlId({ htmlId });
  const navigate = useNavigate();
  const { key: headingKey = 'general.somethingWentWrong', params: headingParams = {} } = heading || {};
  const { key: descriptionKey = undefined, params: descriptionParams = {} } = description || {};
  const { key: buttonKey = 'general.backToSites', params: buttonParams = {} } = buttonText || {};

  const reload = () => {
    onReload?.();
    navigate(pathToNavigateTo || `${routes.site.url}/${routes.site.status.url}`);
  };

  const descriptionId = getId('description');
  const headingId = getId('heading');
  const reloadButtonId = getId('reload-button');

  return (
    <Flex flexWrap="wrap" flexDirection="column" justifyContent="space-around" className="h-100">
      <Flex justifyContent="center" flexWrap="wrap-reverse">
        <Flex flexDirection="column" justifyContent="space-between">
          <Flex.Item id={headingId} className="vai-margin-bottom-m">
            <h2>{t(headingKey || 'general.somethingWentWrong', headingParams)}</h2>
          </Flex.Item>
          {descriptionKey && (
            <Flex.Item id={descriptionId} className="vai-margin-bottom-l">
              {t(descriptionKey, descriptionParams)}
            </Flex.Item>
          )}
          <Flex.Item>
            <Button id={reloadButtonId} onClick={reload}>
              {t(buttonKey, buttonParams)}
            </Button>
          </Flex.Item>
        </Flex>
        <Flex.Item className="vai-margin-left-xl vai-margin-right-xl">
          <Illustration illustration={IllustrationType.genericError} />
        </Flex.Item>
      </Flex>
    </Flex>
  );
};

export default Error404;
