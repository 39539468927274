import React from 'react';
import { HasRights, RightCheckScope } from './HasRights';
import { RightNames } from '../RightsAccordionTree/constants';
import { useAppSelector } from '../../store/hooks';
import { selectCurrentUser } from '../../store/profile';
import AccessRestricted from '../AccessRestricted/AccessRestricted';
import { isRightsNonNullable } from '../../utils/ts-utils';

export interface Props {
  nodeId?: string;
}

/**
 * Props definition for HasRights.
 * @property {nodeId} nodeId - node id to apply the rights.
 * * **If not provided, all node ids in user rights will be use to find at least one view right.**
 */

export const HasViewZone: React.FC<Props> = ({ children, nodeId }) => {
  const currentUser = useAppSelector(selectCurrentUser);

  if (!isRightsNonNullable(currentUser)) {
    return null;
  }

  const nodeRights = nodeId
    ? [{ nodeId, rightName: RightNames.VIEW }]
    : Object.keys(currentUser.rights).map(nodeId => ({ nodeId, rightName: RightNames.VIEW }));

  return (
    <HasRights
      fallback={<AccessRestricted />}
      rightCheckScope={nodeId ? RightCheckScope.All : RightCheckScope.Any}
      nodeRights={nodeRights}
    >
      {children}
    </HasRights>
  );
};

HasViewZone.displayName = 'HasViewZone';
