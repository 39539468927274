import { connect } from 'react-redux';
import { StoreState, reportsDispatchActions } from '../../../store';
import Graph from '../../Reports/Graph/Graph';
import React from 'react';
import { LocationNode } from '../../../siteTree/site';
import { useLazyGetMeasurementsQuery, useLazyDownloadJsonResponseQuery } from '../../../store/services/reportsApi';
import {
  convertLocalTimeToUTC,
  getConstantFromLocalStorage,
  getInitialAssignedChartColors,
  routes
} from '../../../utils';
import { LS_COMPANY_CUSTOMER_ID } from '../../../constants';
import { doGetMeasurementsQuery, processData } from '../../../utils/reports';
import { VaiColor } from '@vaisala/rockhopper-design-tokens';
import { differenceInMinutes, getTime, getUnixTime, subHours, subMinutes } from 'date-fns';
import CenteredSpinner from '../../BaseComponents/CenteredSpinner';
import { CustomProps, LocationCustomProps } from '../../Reports/Locations';
import makeTestId, { IMakeTestIdProps } from '../../../utils/makeTestId';
import { EmptyState } from '../../Utils/EmptyState/EmptyState';
import { IllustrationType } from '../../Utils/EmptyState/Illustration';
import { TEST_IDS } from '../../../tests/testids';
import Timespan, { TimespanOptions, numHoursForTimespan } from './Timespan';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';
import { selectTimeZone } from '../../../store/profile';
import { ReportState } from '../../../store/reducers/reports';

// Convert the current local date and time to the given timezone and return the converted date and time
export const convertLocalTimeToTimezone = (timezone: string, now: Date) => {
  let convertedNow: Date | number = convertLocalTimeToUTC(now, timezone) * 1000;
  const timezoneDiff = differenceInMinutes(convertedNow, now);
  convertedNow = subMinutes(now, timezoneDiff);

  return convertedNow;
};

type Props = {
  location: LocationNode;
} & ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  IMakeTestIdProps;

export const DEFAULT_TIMESPAN = TimespanOptions.THREE_HOURS;

function getStartFromTimespan(timespan: TimespanOptions, now?: Date): Date {
  now = now ?? new Date();
  const numHours = numHoursForTimespan[timespan] || numHoursForTimespan[DEFAULT_TIMESPAN];
  return subHours(now, numHours);
}

const StatusGraph: React.FC<Props> = ({ location, dataTa, ...props }) => {
  const { getTestId } = makeTestId({ dataTa });
  const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID) ?? '';
  const timezone = useAppSelector(selectTimeZone);
  const [getMeasurements, { isLoading, isFetching: isFetchingMeasurements, isSuccess }] = useLazyGetMeasurementsQuery();
  const [
    downloadJsonData,
    { isFetching: isFetchingJsonData, isSuccess: isSuccessJsonData }
  ] = useLazyDownloadJsonResponseQuery();

  const [data, setData] = React.useState<LocationCustomProps | null>(null);
  const [timespan, setTimespan] = React.useState<TimespanOptions>(TimespanOptions.THREE_HOURS);

  const locationHasDataFromLast24hours = location.timestamp && location.timestamp > getTime(subHours(new Date(), 24));
  // Check the fetching prop so it shows loader instead of empty table when switching between locations
  const isLoadingData = isLoading || isFetchingJsonData || isFetchingMeasurements;

  const fetchMeasurements = async (timespan: TimespanOptions) => {
    // Short circuit if the data source is not set on the location
    if (!location.device || !location.active) {
      return;
    }
    const now = new Date();
    const request = {
      from: getStartFromTimespan(timespan),
      to: now
    };
    const convertedNow = convertLocalTimeToTimezone(timezone, now);

    // set the interval date to the users settings timezone date
    props.setIntervalDate({
      from: getStartFromTimespan(timespan, convertedNow),
      to: convertedNow
    });

    // request data with the local date and convert to unix timestamps (seconds)
    const queryProps = {
      customerId: customerId,
      from: getUnixTime(request.from), // unix time in seconds
      to: getUnixTime(request.to), // unix time in seconds
      locationId: location.node_id,
      symbolId: location.symbol_id
    };

    const data = await doGetMeasurementsQuery(getMeasurements, downloadJsonData, queryProps);
    if (data) {
      const mergedData: CustomProps = {
        ...data,
        ...location,
        // These are merely to satisfy the type checker and provide some default values for it
        showThreshold: true,
        visibleOnGraph: true,
        selected: false,
        hierarchy: [],
        average: `${data.average}`
      };
      const processedData: LocationCustomProps = processData(
        data,
        { [location.node_id]: mergedData },
        location.node_id
      );
      props.setReportsAssignedChartColors({
        ...getInitialAssignedChartColors(),
        [VaiColor.AquaVaisala]: location.node_id
      });
      props.setReportsVisibleLocations({ [location.node_id]: { visible: true, color: VaiColor.AquaVaisala } });
      props.setReportSelectedLocationsCustomProps({
        [location.node_id]: {
          ...processedData[location.node_id],
          visibleOnGraph: true,
          showThreshold: true
        }
      });
      setData(processedData);
    } else {
      props.setReportSelectedLocationsIDs([]);
      props.setReportSelectedLocationsNum(0);
      props.setReportsVisibleLocations({});
      props.setReportSelectedLocationsCustomProps({});
    }
  };
  React.useEffect(() => {
    setData(null);
    const resetTimespan = DEFAULT_TIMESPAN;
    setTimespan(resetTimespan);
    fetchMeasurements(resetTimespan);
  }, [location.node_id]);

  React.useEffect(() => {
    fetchMeasurements(timespan);
  }, [timespan]);

  React.useEffect(() => {
    if (isSuccess || isSuccessJsonData) {
      props.setReportSelectedLocationsIDs([location.node_id]);
      props.setReportSelectedLocationsNum(1);
    }
  }, [isSuccess, isSuccessJsonData]);

  React.useEffect(() => {
    return () => {
      /** Reset report state on unmount as state is shared with reports.
       * SelectedLocationsNum doesn't otherwise get reset and can show as 1 on reports page when coming from a location page
       */
      props.resetReportsState();
    };
  }, []);

  // When the site changes, the component is not unmounted, there is a split second where the node_id of the current location is not
  // the same as the data we have. To avoid the NoData component flickering, we need to check.
  const dataIsSameAsCurrentLocation = data ? Object.keys(data)[0] === location.node_id : true;
  const showNoData =
    !isLoadingData &&
    dataIsSameAsCurrentLocation &&
    (data === null || !data[location.node_id] || data[location.node_id].measurement_points?.length === 0);

  const handleTimespanClick = (timespan: TimespanOptions) => setTimespan(timespan);

  return (
    <div className="vai-margin-top-xl">
      {!location.active ? (
        <LocationDeactivated dataTa={getTestId(TEST_IDS.location_deactivated)} />
      ) : (
        <>
          <div className="vai-margin-bottom-xxl">
            {locationHasDataFromLast24hours && (
              <Timespan
                dataTa={getTestId(TEST_IDS.timespan)}
                selectedTimespan={timespan}
                handleOnClick={handleTimespanClick}
                isLoading={isLoadingData}
              />
            )}
          </div>
          {showNoData ? (
            <NoData dataTa={getTestId(TEST_IDS.no_data)} />
          ) : isLoadingData ? (
            <CenteredSpinner dataTa={getTestId(TEST_IDS.spinner)} />
          ) : (
            <Graph dataTa={getTestId(TEST_IDS.graph)} hideTimeNav={true} />
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ reports }: StoreState) => ({
  selectedFormattedLocations: reports.selectedFormattedLocations,
  selectedFormattedLocationsNum: reports.selectedLocationsNum,
  intervalDate: reports.intervalDate,
  selectedLocationsCustomProps: reports.selectedLocationsCustomProps
});

const mapDispatchToProps = (dispatch: any) => ({
  setIntervalDate: (interval: ReportState['intervalDate']) =>
    dispatch(reportsDispatchActions.setReportIntervalDate(interval)),
  setReportsVisibleLocations: (locations: ReportState['visibleLocations']) =>
    dispatch(reportsDispatchActions.setReportVisibleLocations(locations)),
  setReportsAssignedChartColors: (colors: ReportState['assignedChartColors']) =>
    dispatch(reportsDispatchActions.setReportAssignedChartColors(colors)),
  setReportSelectedFormattedLocations: (locations: ReportState['selectedFormattedLocations']) =>
    dispatch(reportsDispatchActions.setReportSelectedFormattedLocations(locations)),
  setReportSelectedLocationsCustomProps: (locations: ReportState['selectedLocationsCustomProps']) =>
    dispatch(reportsDispatchActions.setReportSelectedLocationsCustomProps(locations)),
  setReportSelectedLocationsNum: (number: ReportState['selectedLocationsNum']) =>
    dispatch(reportsDispatchActions.setReportSelectedLocationsNum(number)),
  setReportSelectedLocationsIDs: (IDs: ReportState['selectedLocationsIDs']) =>
    dispatch(reportsDispatchActions.setReportSelectedLocationsIDs(IDs)),
  resetReportsState: () => dispatch(reportsDispatchActions.resetReportsState())
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusGraph);

export const NoData: React.FC<IMakeTestIdProps> = ({ dataTa }) => {
  return (
    <EmptyState
      dataTa={dataTa}
      heading={'site.statusThresholdNoData'}
      description={'site.noDataDescription'}
      illustration={IllustrationType.emptyTable}
    />
  );
};

export const LocationDeactivated: React.FC<IMakeTestIdProps> = ({ dataTa }) => {
  const { t } = useTranslation();
  return (
    <EmptyState
      dataTa={dataTa}
      heading={'site.inactiveLocationGraphHeading'}
      descriptionObj={
        <Trans
          i18nKey={'site.inactiveLocationGraphDesc'}
          values={{ linkText: t('commonTitles.reports') }}
          components={[
            <Link key="reports-url" target="_blank" to={`${routes.reports.url}/${routes.reports.graph.url}`}>
              Reports
            </Link>
          ]}
        />
      }
      illustration={IllustrationType.emptyTable}
    />
  );
};
