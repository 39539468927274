import { matchPath } from 'react-router';

export const routes = {
  url: '/',
  login: {
    url: '/login',
    forgotPassword: {
      url: 'forgot'
    },
    resetPassword: {
      url: 'forgotpassword'
    },
    resetPasswordConfirmation: {
      url: 'resetPasswordConfirmation'
    },
    passwordReset: {
      url: 'passwordreset'
    },
    setUpPassword: {
      url: 'setuppassword'
    },
    setUpProfile: {
      url: 'setupprofile'
    },
    setUpMfa: {
      url: 'setupmfa'
    }
  },
  logout: {
    url: '/logout'
  },
  info: {
    url: '/info'
  },
  site: {
    url: '/site',
    alarms: { url: 'alarms', historical: { url: 'historical' } },
    status: { url: 'status' },
    settings: { url: 'settings' },
    rights: { url: 'rights' },
    ticket: { url: 'ticket' }
  },
  events: {
    url: '/events'
  },
  profile: {
    url: '/profile',
    users: {
      url: 'users'
    },
    groups: {
      url: 'groups'
    }
  },
  reports: {
    url: '/reports',
    graph: {
      url: 'graph'
    },
    statistics: {
      url: 'statistics'
    }
  },
  downloadReport: {
    url: '/download-report'
  },
  deviceManager: {
    url: '/device-manager',
    status: { url: 'status' },
    // TODO: Release 2
    // links: { url: 'links' },
    properties: { url: 'properties' }
  }
};

/*
 * Utility function to compare current URL to Path object above.
 * This uses React Router Matchpath to find first matching path in the object.
 */
export function getMatchingPath(pathname: string, pathOptions: string[]) {
  const matches = pathOptions
    .map(path => matchPath({ path: path }, pathname))
    .filter(Boolean)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .map(m => m!.pattern.path);
  // select last match because '/' (Application) matches all
  return matches[0] || '/site';
}
