import * as React from 'react';
import validator from 'validator';
import './edit-groups-dialog.scss';
import {
  GROUP_NAME_MIN_CHARACHTERS_LENGTH,
  GROUP_NAME_MAX_CHARACHTERS_LENGTH,
  GROUP_DESCRIPTION_MAX_CHARACHTERS_LENGTH
} from '../../../constants';
import {
  Icon,
  Size,
  InputField,
  Form,
  Label,
  Button,
  ButtonType,
  Separator,
  TextArea
} from '@vaisala/rockhopper-components';
import { GroupInterface } from '../../../profile/user';
import { EditGroupsInterface } from '../../../profile/group';
import GroupDeletionDialog from '../GroupDeletionDialog';
import { useTranslation } from 'react-i18next';
import { getErrorClass } from '../../../utils';
import { useAppDispatch } from '../../../store/hooks';
import { hideDialog, showDialog } from '../../../store/dialog';
import { ModalContainer } from '../../Modals/ModalContainer';
import { TEST_IDS } from '../../../tests/testids';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';

interface EditGroupsDialogProps {
  groupDetails: GroupInterface;
  editGroup: (group: EditGroupsInterface) => void;
  deleteGroup: (groupId: string, groupName: string) => void;
  groups: GroupInterface[];
}

const EditGroupsDialog = (props: EditGroupsDialogProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [groupName, setGroupName] = React.useState<string>(props.groupDetails.group_name ?? '');
  const [groupNameError, setGroupNameError] = React.useState<string>('');
  const [description, setDescription] = React.useState<string>(props.groupDetails.description ?? '');
  const [descriptionError, setDescriptionError] = React.useState<string>('');

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { value, name } = e.currentTarget;
    if (name === 'groupName') {
      setGroupName(value);
      value.length > GROUP_NAME_MAX_CHARACHTERS_LENGTH
        ? setGroupNameError(t('profile.editGroupsMaxLengthError', { charLen: GROUP_NAME_MAX_CHARACHTERS_LENGTH }))
        : setGroupNameError('');
    } else if (name === 'description') {
      setDescription(value);
      value.length > GROUP_DESCRIPTION_MAX_CHARACHTERS_LENGTH
        ? setDescriptionError(
            t('profile.editGroupsMaxLengthError', { charLen: GROUP_DESCRIPTION_MAX_CHARACHTERS_LENGTH })
          )
        : setDescriptionError('');
    }
  };

  const onDismiss = () => dispatch(hideDialog());
  const onEditGroup = () => {
    let errorExist = false;
    const trimmedGroupName = validator.trim(groupName);
    const trimmedDescription = validator.trim(description);
    setGroupNameError('');
    setDescriptionError('');
    if (!trimmedGroupName) {
      errorExist = true;
      setGroupNameError(t('profile.groupNameEmptyError'));
    } else if (trimmedGroupName.length > GROUP_NAME_MAX_CHARACHTERS_LENGTH) {
      errorExist = true;
      setGroupNameError(t('profile.createGroupsMaxLengthError'));
    } else if (
      props.groups.map(a => a.group_name).includes(trimmedGroupName) &&
      trimmedGroupName !== props.groupDetails.group_name
    ) {
      errorExist = true;
      setGroupNameError(t('profile.groupNameDuplicateError'));
    } else if (trimmedGroupName.length < GROUP_NAME_MIN_CHARACHTERS_LENGTH) {
      errorExist = true;
      setGroupNameError(t('profile.editGroupsMinLengthError', { charLen: GROUP_NAME_MIN_CHARACHTERS_LENGTH }));
    } else if (trimmedDescription && trimmedDescription.length > GROUP_DESCRIPTION_MAX_CHARACHTERS_LENGTH) {
      errorExist = true;
    }
    if (!errorExist) {
      const newGroup: EditGroupsInterface = {
        group_name: trimmedGroupName,
        description: trimmedDescription ?? '',
        group_id: props.groupDetails.group_id
      };
      props.editGroup(newGroup);
      dispatch(hideDialog());
    }
  };

  const onDeleteGroup = () => {
    onDismiss();
    dispatch(
      showDialog(
        <GroupDeletionDialog
          groupId={props.groupDetails.group_id}
          groupName={props.groupDetails.group_name ?? ''}
          deleteGroup={props.deleteGroup}
        ></GroupDeletionDialog>
      )
    );
  };

  const isNameInvalid = !groupName || groupNameError.length > 0;
  const isDescriptionInvalid = descriptionError.length > 0;

  const hasformChanged = props.groupDetails.group_name !== groupName || props.groupDetails?.description !== description;

  const isConfirmDisabled = !hasformChanged || isNameInvalid || isDescriptionInvalid;

  const modalButtons = (
    <div className="edit-groups-button-container">
      <Button
        id="edit-groups-confirm"
        dataTa={TEST_IDS.group_edit_dialog_save_buton}
        htmlId="edit-groups-confirm"
        disabled={isConfirmDisabled}
        onClick={onEditGroup}
      >
        {t('general.save')}
      </Button>
      <Button id="edit-groups-cancel" htmlId="edit-groups-cancel" onClick={onDismiss} buttonType={ButtonType.Secondary}>
        {t('profile.cancel')}
      </Button>
    </div>
  );

  const modalFooterExtras = (
    <div id="edit-groups-button-container" className="edit-groups-button-container">
      <Separator />
      <Button
        id="edit-groups-dialog"
        buttonType={ButtonType.Link}
        startIcon={<Icon name={VaiIcon.Trash} />}
        onClick={onDeleteGroup}
        dataTa={TEST_IDS.group_edit_dialog_delete_button}
      >
        {t('profile.deleteGroup')}
      </Button>
    </div>
  );

  return (
    <ModalContainer
      id="edit-groups-dialog"
      data-ta={TEST_IDS.group_edit_dialog}
      isOpen
      htmlId="edit-groups-dialog"
      showCloseIcon={true}
      onDismiss={onDismiss}
      width={Size.S}
      dismissOnOverlayClick={false}
      buttons={modalButtons}
      footerChildren={modalFooterExtras}
      iconName={VaiIcon.UserGroup}
      title="profile.editGroupsTitle"
    >
      <Form>
        <Label className="edit-groups-name">
          {t('profile.creaGroupsName')}
          <InputField
            required={true}
            htmlId="edit-groups-name"
            width={Size.Container}
            value={groupName}
            name="groupName"
            onChange={onChange}
            className={getErrorClass(groupNameError)}
            data-ta={TEST_IDS.groups_edit_group_name_field}
          />
          <span className="input-error">{groupNameError}</span>
        </Label>
        <Label className="edit-groups-description">
          {t('profile.editGroupsDescription')}
          <TextArea
            htmlId="edit-groups-description"
            width={Size.Container}
            value={description}
            name="description"
            onChange={onChange}
            rows={2}
            className={getErrorClass(descriptionError)}
            data-ta={TEST_IDS.groups_edit_group_description_field}
          />
          <span className="input-error">{descriptionError}</span>
        </Label>
      </Form>
    </ModalContainer>
  );
};

export default EditGroupsDialog;
