import React from 'react';
import { Size, Flex, Icon } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import { AlarmMessage, AlarmMessageType } from '../../../../constants';
import { VaiColor, VaiIcon } from '@vaisala/rockhopper-design-tokens';
import './acknowledge-message.scss';
import { timestampToTimeString } from '../../../../utils';
import makeTestId, { IMakeTestIdProps } from '../../../../utils/makeTestId';
import { TEST_IDS } from '../../../../tests/testids';
interface Props extends IMakeTestIdProps {
  alarmMessage: AlarmMessage;
}
const AcknowledgeMessage: React.FC<Props> = ({ alarmMessage, dataTa }) => {
  const { getTestId } = makeTestId({ dataTa });
  const { t } = useTranslation();
  const isSystemAlarm = alarmMessage.message_type === AlarmMessageType.SYSTEM;
  return (
    <div
      data-ta={getTestId(TEST_IDS.ack_message_container)}
      className={isSystemAlarm ? 'acknowledge-message-system-container' : 'acknowledge-message-user-container'}
    >
      {isSystemAlarm ? (
        <Flex dataTa={getTestId(TEST_IDS.ack_message_system_container)} flexWrap="wrap" flexDirection="row">
          <Flex.Item dataTa={getTestId(TEST_IDS.ack_message_system_icon_container)} flexBasis="10%">
            <Icon dataTa={getTestId(TEST_IDS.ack_message_system_icon)} name={VaiIcon.SmsO} size={Size.M} />
          </Flex.Item>
          <Flex.Item dataTa={getTestId(TEST_IDS.ack_message_system_comment_title)} flexBasis="40%">
            <b>{t('general.systemComment')}</b>
          </Flex.Item>
          <Flex.Item
            dataTa={getTestId(TEST_IDS.ack_message_system_time_stamp)}
            className="acknowledge-message-ok-time"
            flexBasis="50%"
          >
            {timestampToTimeString(alarmMessage.timestamp)}
          </Flex.Item>
        </Flex>
      ) : (
        <Flex dataTa={getTestId(TEST_IDS.ack_message_user_container)} flexWrap="wrap" flexDirection="row">
          <Flex.Item dataTa={getTestId(TEST_IDS.ack_message_user_icon_container)} flexBasis="10%">
            <Icon dataTa={getTestId(TEST_IDS.ack_message_user_icon_bell)} name={VaiIcon.AlertBell} size={Size.M} />
            <Icon
              dataTa={getTestId(TEST_IDS.ack_message_user_icon_ok)}
              className="acknowledge-message-ok-icon"
              name={VaiIcon.AlertOk}
              size={Size.M}
              color={VaiColor.LeafGreen}
            />
          </Flex.Item>
          <Flex.Item dataTa={getTestId(TEST_IDS.ack_message_user_icon_profile)} flexBasis="10%">
            <Icon name={VaiIcon.User} size={Size.M} />
          </Flex.Item>
          <Flex.Item dataTa={getTestId(TEST_IDS.ack_message_user_name)} flexBasis="35%">
            <b>{alarmMessage.user}</b>
          </Flex.Item>
          <Flex.Item
            dataTa={getTestId(TEST_IDS.ack_message_user_time_stamp)}
            className="acknowledge-message-ok-time"
            flexBasis="45%"
          >
            {timestampToTimeString(alarmMessage.timestamp)}
          </Flex.Item>
        </Flex>
      )}
      <Flex dataTa={getTestId(TEST_IDS.ack_message_user_text_container)} flexWrap="wrap" flexDirection="row">
        <Flex.Item flexBasis="20%"></Flex.Item>
        <Flex.Item dataTa={getTestId(TEST_IDS.ack_message_user_text)} flexBasis="75%">
          {alarmMessage.message}
        </Flex.Item>
      </Flex>
    </div>
  );
};
export default AcknowledgeMessage;
