import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

export interface DialogState {
  dialog?: JSX.Element;
}

const initialState = {
  dialog: undefined
} as DialogState;

export const dialogSlice = createSlice({
  name: 'Dialog',
  initialState,
  reducers: {
    showDialog(state, action: PayloadAction<DialogState['dialog']>) {
      state.dialog = action.payload;
    },
    hideDialog(state) {
      state.dialog = undefined;
    }
  }
});

export const selectDialog = (state: RootState) => state.dialog.dialog;

export const { showDialog, hideDialog } = dialogSlice.actions;
export default dialogSlice.reducer;
