import React from 'react';
import { connect } from 'react-redux';
import { authDispatchActions } from '../../../store';
import ChangePasswordContainer from '../../../containers/ChangePassword';
import ViewMyProfileContainer from '../../../containers/ViewMyProfile';
import { useAppDispatch } from '../../../store/hooks';
import { showDialog } from '../../../store/dialog';
import { Button, ButtonType, Stack, Heading, Separator, Grid, Size } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import { getConstantFromLocalStorage } from '../../../utils';
import { ContactSupportLink, LS_COMPANY_ALIAS, LS_USERNAME } from '../../../constants';
import { TEST_IDS } from '../../../tests/testids';

const mapDispatchToProps = (dispatch: any) => ({
  logout: (globalSignout: boolean) => dispatch(authDispatchActions.logout(globalSignout)),
  startChangePassword: () => dispatch(authDispatchActions.startChangePassword())
});

type ProfileProps = {
  logout: (globalSingout: boolean) => void;
  startChangePassword: () => void;
} & ReturnType<typeof mapDispatchToProps>;

const Profile: React.FC<ProfileProps> = ({ startChangePassword, logout }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const companyName = getConstantFromLocalStorage(LS_COMPANY_ALIAS) ?? '';
  const username = getConstantFromLocalStorage(LS_USERNAME) ?? '';
  const onChangePassword = (): void => {
    startChangePassword();
    dispatch(showDialog(<ChangePasswordContainer />));
  };
  const onViewMyProfile = (): void => {
    dispatch(showDialog(<ViewMyProfileContainer />));
  };
  const onLogout = (): void => {
    // NOTE: the LoginRedirect component will take care of redirecting when the isAuthenticated state changes.
    logout(false);
  };
  const goToSupportPortal = () => {
    window.open(ContactSupportLink, '_blank');
  };

  const buttons = [
    {
      onClick: onViewMyProfile,
      text: t('menu.viewMyProfile'),
      dataTa: TEST_IDS.sidebar_profile_stack_view_profile_button
    },
    {
      onClick: onChangePassword,
      text: t('menu.changePassword'),
      dataTa: TEST_IDS.sidebar_profile_stack_change_password_button
    },
    {
      onClick: onLogout,
      text: t('auth.logout'),
      dataTa: TEST_IDS.sidebar_profile_stack_logout_button
    },
    {
      onClick: goToSupportPortal,
      text: t('menu.contactSupport'),
      dataTa: TEST_IDS.sidebar_profile_stack_contact_support_button
    }
  ];

  const rows = [
    {
      label: t('auth.username'),
      value: username,
      dataTa: TEST_IDS.sidebar_profile_table_username
    },
    {
      label: t('auth.companyID'),
      value: companyName,
      dataTa: TEST_IDS.sidebar_profile_table_company_id
    }
  ];

  return (
    <>
      <Stack spacing={Size.S} dataTa={TEST_IDS.sidebar_profile_stack} className="profile-stack" align="start">
        <Heading dataTa={TEST_IDS.sidebar_profile_stack_heading} level={2}>
          {t('general.userMenu')}
        </Heading>
        {buttons.map((button, index) => (
          <Button
            dataTa={button.dataTa}
            key={index}
            buttonType={ButtonType.Link}
            onClick={button.onClick}
            className="profile-button"
          >
            {button.text}
          </Button>
        ))}
      </Stack>
      <Separator />
      <Heading dataTa={TEST_IDS.sidebar_profile_stack_current_login} level={2}>
        {t('header.currentLogin')}
      </Heading>
      <Grid padding={Size.S} dataTa={TEST_IDS.sidebar_profile_table} className="profile-grid">
        {rows.map((row, index) => (
          <Grid.Row key={index}>
            <Grid.Col xl={4} lg={4} md={4} sm={4} xs={4}>
              {row.label}
            </Grid.Col>
            <Grid.Col xl={8} lg={8} md={8} sm={8} xs={8}>
              {row.value}
            </Grid.Col>
          </Grid.Row>
        ))}
      </Grid>
      <Separator />
    </>
  );
};

export default connect(null, mapDispatchToProps)(Profile);
