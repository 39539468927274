import React from 'react';
import { BodyText, Button, ButtonType } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import useHtmlId from '../../../hooks/useHtmlId';
import { hideDialog } from '../../../store/dialog';
import { useAppDispatch } from '../../../store/hooks';
import { ModalContainer } from '../../Modals/ModalContainer';
import { QA_TEST_IDS } from '../../../tests/qaTestIds';
import { useRestartMutation } from '../../../store/services/devicesApi';
import { getConstantFromLocalStorage } from '../../../utils';
import { LS_COMPANY_CUSTOMER_ID, PARENT_SITE_KEY } from '../../../constants';
import CenteredSpinner from '../../BaseComponents/CenteredSpinner';

const htmlId = 'restart-device-modal';

interface Props {
  deviceId: string;
  deviceModel: string;
}

const RestartDeviceModal: React.FC<Props> = ({ deviceId, deviceModel }) => {
  const { t } = useTranslation();
  const { getId } = useHtmlId({ htmlId });
  const customer_id = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID) ?? '';
  const site_id = PARENT_SITE_KEY;
  const dispatch = useAppDispatch();
  const [restart, { isLoading, isSuccess, isError }] = useRestartMutation();
  const onDismiss = () => {
    dispatch(hideDialog());
  };

  const onConfirm = () => {
    restart({ customer_id, device_id: deviceId, site_id });
  };

  React.useEffect(() => {
    if (isError) {
      // For now, we are just closing the modal when there is an error. This may be revisited in the future.
      onDismiss();
    }
  }, [isError]);

  React.useEffect(() => {
    if (isSuccess) {
      onDismiss();
    }
  }, [isSuccess]);

  const buttonId = getId('restart-button');
  const cancelId = getId('cancel-button');

  const ModalButtons = (
    <>
      <Button id={buttonId} className="vai-space-inline-s" disabled={isLoading} onClick={onConfirm}>
        {isLoading ? <CenteredSpinner className="" /> : t('general.restart')}
      </Button>
      <Button id={cancelId} dataTa={QA_TEST_IDS.vai_modal_cancel} buttonType={ButtonType.Secondary} onClick={onDismiss}>
        {t('general.cancel')}
      </Button>
    </>
  );

  return (
    <ModalContainer
      id={getId('container')}
      isOpen
      showCloseIcon={true}
      dismissOnOverlayClick={false}
      title={'deviceManager.restartDeviceHeading'}
      titleParams={{ deviceModel }}
      onDismiss={onDismiss}
      buttons={ModalButtons}
    >
      <BodyText htmlId={getId('inner')}>{t('deviceManager.restartDeviceBlurb', { deviceModel })}</BodyText>
    </ModalContainer>
  );
};

export default RestartDeviceModal;
