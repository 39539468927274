import * as Action from '../actions/actionTypes';
import { ReportState } from '../reducers/reports';

/**
 * Sets the loading of reports apis
 * @param isLoading is loading indicator
 */
const setReportLoadingState = (isLoading: ReportState['isApiLoading']) => (dispatch: any) =>
  dispatch({ type: Action.SET_REPORT_API_LOADING, loading: isLoading });

/**
 * Sets the interval of reports filters
 * @param interval The interval object
 */
const setReportIntervalDate = (interval: ReportState['intervalDate']) => (dispatch: any) =>
  dispatch({ type: Action.SET_REPORT_INTERVAL_DATE, interval });

/**
 * Sets the locations for the report
 * @param locations The fetched locations
 */
const setReportLocations = (locations: ReportState['locations']) => (dispatch: any) =>
  dispatch({ type: Action.SET_REPORT_LOCATIONS, locations: locations });

/**
 * Sets the selected locations for the report
 * @param locations The selected formatted locations
 */
const setReportSelectedFormattedLocations = (locations: ReportState['selectedFormattedLocations']) => (dispatch: any) =>
  dispatch({ type: Action.SET_REPORT_SELECTED_FORMATTED_LOCATIONS, selectedFormattedLocations: locations });

/**
 * Sets the selected locations custom props for the report
 * @param locations The selected locations custom props
 */
const setReportSelectedLocationsCustomProps = (locations: ReportState['selectedLocationsCustomProps']) => (
  dispatch: any
) => dispatch({ type: Action.SET_REPORT_SELECTED_LOCATIONS_CUSTOM_PROPS, locationsProps: locations });

/**
 * Sets the selected locations number for the report
 * @param number The selected formatted locations number
 */
const setReportSelectedLocationsNum = (number: ReportState['selectedLocationsNum']) => (dispatch: any) =>
  dispatch({ type: Action.SET_REPORT_SELECTED_LOCATIONS_NUM, selectedLocationsNum: number });

/**
 * Sets the selected locations ids for the report
 * @param Ids Selected locations Ids
 */
const setReportSelectedLocationsIDs = (Ids: ReportState['selectedLocationsIDs']) => (dispatch: any) =>
  dispatch({ type: Action.SET_REPORT_SELECTED_LOCATIONS_IDS, selectedLocationsIDs: Ids });
/**
 * Sets the selected locations SK for the report
 * @param SK Selected locations sk
 */
const setReportSelectedLocationsSK = (SKs: ReportState['selectedLocationsSKs']) => (dispatch: any) =>
  dispatch({ type: Action.SET_REPORT_SELECTED_LOCATIONS_SK, selectedLocationsSKs: SKs });

/**
 * Sets the graphs number for the report
 * @param num Graphs number
 */
const setReportGraphsNum = (num: ReportState['graphsNum']) => (dispatch: any) =>
  dispatch({ type: Action.SET_REPORT_GRAPHS_NUM, graphsNum: num });

/**
 * Sets the visible locations visibility and color
 * @param locations Visible locations
 */
const setReportVisibleLocations = (locations: ReportState['visibleLocations']) => (dispatch: any) =>
  dispatch({ type: Action.SET_REPORT_VISIBLE_LOCATIONS, visibleLocations: locations });

/**
 * Sets assigned chart colors
 * @param colors Assigned colors
 */
const setReportAssignedChartColors = (colors: ReportState['assignedChartColors']) => (dispatch: any) =>
  dispatch({ type: Action.SET_REPORT_ASSIGNED_CHART_COLORS, assignedChartColors: colors });

/**
 * Resets Reports state to initial state
 */
const resetReportsState = () => (dispatch: any) => dispatch({ type: Action.RESET_REPORTS_STATE });

export const reportsDispatchActions = {
  setReportLoadingState,
  setReportIntervalDate,
  setReportLocations,
  setReportSelectedFormattedLocations,
  setReportSelectedLocationsCustomProps,
  setReportSelectedLocationsNum,
  setReportSelectedLocationsIDs,
  setReportSelectedLocationsSK,
  setReportGraphsNum,
  setReportVisibleLocations,
  setReportAssignedChartColors,
  resetReportsState
};
