import * as React from 'react';
import useGroupsActions from '../../../hooks/useGroupActions';
import GroupActivationDialog from '.';
import { GroupInterface } from '../../../profile/user';

interface Props {
  groupDetails: GroupInterface;
  onDismiss: () => void;
}

export const GroupActivation: React.FC<Props> = ({ groupDetails, onDismiss }): JSX.Element => {
  const {
    groupActivationResultDetails: { isLoading, isQueryEnded, reset },
    toggleGroupActivation
  } = useGroupsActions();

  return (
    <GroupActivationDialog
      groupDetails={groupDetails}
      groupActivateApiDetails={{ result: { isLoading, isQueryEnded }, toggleGroupActivation }}
      onDismiss={() => {
        onDismiss();
        reset();
      }}
    />
  );
};

export default GroupActivationDialog;
