export const addRightToBit = (bit: number, mask: number) => {
  return bit | mask;
};
export const removeRightFromBit = (bit: number, mask: number) => {
  return bit & ~mask;
};

export const thingHasRights = (rightsToCheck: number[], thingRights: number): boolean => {
  for (let i = 0; i < rightsToCheck.length; i++) {
    const right = rightsToCheck[i];
    if ((right & thingRights) == right) {
      continue;
    }
    return false;
  }
  return true;
};
