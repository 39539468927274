import React from 'react';
import { Select, SelectProps } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import { ALARM_PRIORITY } from '../../../../constants';
import { AlarmPriorityIcons } from './AlarmIcons';
import { TranslationKey } from '../../../../react-i18next';
import { TEST_IDS } from '../../../../tests/testids';
import './alarmPrioritySelector.scss';

export const PriorityTranslationKeyMap: Record<ALARM_PRIORITY, TranslationKey> = {
  [ALARM_PRIORITY.CRITICAL]: 'site.alarms.settings.threshold.priority.label.high',
  [ALARM_PRIORITY.MODERATE]: 'site.alarms.settings.threshold.priority.label.medium',
  [ALARM_PRIORITY.INFO]: 'site.alarms.settings.threshold.priority.label.low'
};

const PrioritySelectorOption = ({ priority }: PrioritySelectorOptionProps): JSX.Element => {
  const { t } = useTranslation();

  const Icon = AlarmPriorityIcons[priority];
  const label = t(PriorityTranslationKeyMap[priority]);

  return (
    <div className="alarm-priority-selector-option">
      <Icon />
      <span>{label}</span>
    </div>
  );
};

interface Props extends SelectProps<ALARM_PRIORITY> {
  onSelect: (option: ALARM_PRIORITY) => void;
  value: ALARM_PRIORITY;
  disabled?: boolean;
}

export const PrioritySelector: React.FC<Props> = ({ id, htmlId, ...rest }) => {
  return (
    <Select<ALARM_PRIORITY>
      {...rest}
      id={id}
      htmlId={htmlId}
      className="alarm-priority-select"
      dropdownClassName="alarm-priority-selector-list"
      dataTa={TEST_IDS.alarm_priority_select}
    >
      <Select.Option value={ALARM_PRIORITY.CRITICAL}>
        <PrioritySelectorOption priority={ALARM_PRIORITY.CRITICAL} />
      </Select.Option>
      <Select.Option value={ALARM_PRIORITY.MODERATE}>
        <PrioritySelectorOption priority={ALARM_PRIORITY.MODERATE} />
      </Select.Option>
      <Select.Option value={ALARM_PRIORITY.INFO}>
        <PrioritySelectorOption priority={ALARM_PRIORITY.INFO} />
      </Select.Option>
    </Select>
  );
};

interface PrioritySelectorOptionProps {
  priority: ALARM_PRIORITY;
}
