import * as React from 'react';
import {
  BodyText,
  Button,
  ButtonType,
  Flex,
  Heading,
  Icon,
  InputField,
  MenuButton,
  MenuButtonList,
  MenuButtonListItem,
  MenuButtonToggle,
  Paper,
  Size
} from '@vaisala/rockhopper-components';
import { VaiColor, VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { useTranslation } from 'react-i18next';

import './locations-list.scss';
import SelectLocationsDialog from '../SelectLocationsDialog/SelectLocationsDialog';
import { EmptyState } from '../../Utils/EmptyState/EmptyState';
import { StoreState } from '../../../store';
import { connect } from 'react-redux';
import SelectedLocationsList from '../SelectedLocationsList/SelectedLocationsList';
import { LocationTreeFormattedNode } from '../Locations';
import { LOCATION_KEY, MAX_LOCATIONS_SELECTED } from '../../../constants';
import { useDebounce } from 'use-debounce';
import { filterLocationsTree } from '../../../utils/common';
import { IllustrationType } from '../../Utils/EmptyState/Illustration';
import { TEST_IDS } from '../../../tests/testids';

type LocationsListProps = ReturnType<typeof mapStateToProps>;

const LocationsList: React.FunctionComponent<LocationsListProps> = props => {
  const { t } = useTranslation();
  const [reportsFilter, setReportsFilter] = React.useState({
    search: '',
    sortBy: '',
    filterBy: ''
  });

  const [filteredLocations, setFilteredLocations] = React.useState(props.selectedFormattedLocations);
  const [filteredLocationsCount, setFilteredLocationsCount] = React.useState(0);
  const [value] = useDebounce(reportsFilter.search, 600);

  const [showDialogs, setShowDialogs] = React.useState({
    select: false
  });

  React.useEffect(() => {
    const newFilteredLocations = filterLocationsTree(
      value.toLowerCase(),
      {} as LocationTreeFormattedNode,
      props.selectedFormattedLocations,
      true
    ) as typeof filteredLocations;

    const visibleLocations = calculateFilteredLocationsCount({} as LocationTreeFormattedNode, newFilteredLocations);

    setFilteredLocationsCount(visibleLocations);
    setFilteredLocations(newFilteredLocations);
  }, [value, props.selectedFormattedLocations]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setReportsFilter(prevDateSelector => ({ ...prevDateSelector, [name]: value }));
  };

  const calculateFilteredLocationsCount = (
    node: LocationTreeFormattedNode = {} as LocationTreeFormattedNode,
    parent_node: {
      [key: string]: LocationTreeFormattedNode;
    } = {},
    visible_locations = 0
  ) => {
    const locationsClone =
      Object.keys(node).length > 0 ? node : (JSON.parse(JSON.stringify(parent_node)) as typeof parent_node);

    let visibleLocations = visible_locations;

    Object.values(locationsClone).forEach(node => {
      if (node.type === LOCATION_KEY && node.show && node.selected) {
        visibleLocations++;
      }

      if (Object.keys(node.children).length > 0) {
        visibleLocations = calculateFilteredLocationsCount(
          node.children as LocationTreeFormattedNode,
          parent_node,
          visibleLocations
        );
      }
    });

    return visibleLocations;
  };

  const toggleSelectModal = () => setShowDialogs(prev => ({ ...prev, select: !prev.select }));

  return (
    <Paper className="h-100 locations-list-wrapper" dataTa={TEST_IDS.reports.locations_list_wrapper}>
      <Heading level={2} className="vai-margin-vertical-none">
        {t('reports.reportLocations')}
      </Heading>

      <span style={{ color: VaiColor.GreyMedium }} data-ta={TEST_IDS.reports.locations_number_wrapper}>
        {props.selectedLocationsNum} {t('reports.locations')}
      </span>
      {props.selectedLocationsNum > MAX_LOCATIONS_SELECTED && (
        <BodyText
          className="vai-margin-top-m"
          style={{ color: VaiColor.GreyMedium }}
          data-ta={TEST_IDS.reports.locations_dispaly_limit_message}
        >
          {t('reports.locationDisplayLimitNote', { max: MAX_LOCATIONS_SELECTED })}
        </BodyText>
      )}

      <InputField
        className="vai-margin-vertical-s"
        value={reportsFilter.search}
        onChange={handleInputChange}
        name="search"
        placeholder={t('events.filterBar.search')}
        endIcon={<Icon name={VaiIcon.Search} size={Size.M} />}
        width={Size.Container}
      />

      <Flex justifyContent="flex-end" className="locations-filters">
        <Button data-ta={TEST_IDS.reports.select_button} id="locations-select-button" onClick={toggleSelectModal}>
          {t('reports.select')}
        </Button>

        {showDialogs.select && <SelectLocationsDialog isVisible={showDialogs.select} onDismiss={toggleSelectModal} />}
      </Flex>

      {props.selectedLocationsNum === 0 ? (
        <EmptyState
          illustration={IllustrationType.nothingSelected}
          heading="reports.nothingSelected"
          description="reports.emptyLocationsNote"
        />
      ) : filteredLocationsCount === 0 ? (
        <span className="no-results-text">{t('noResults')}</span>
      ) : (
        <SelectedLocationsList selectedLocations={filteredLocations} />
      )}
    </Paper>
  );
};

const mapStateToProps = ({ profile, reports }: StoreState) => ({
  currentUser: profile?.currentUser,
  selectedLocationsNum: reports.selectedLocationsNum,
  selectedFormattedLocations: reports.selectedFormattedLocations,
  loading: reports.isApiLoading
});

export default connect(mapStateToProps)(LocationsList);
