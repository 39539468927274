import React from 'react';
import { ReactComponent as Positive } from '../../../assets/images/Positive.svg';
import { ReactComponent as EmptyFolder } from '../../../assets/images/EmptyFolder.svg';
import { ReactComponent as EmptyTable } from '../../../assets/images/EmptyTable.svg';
import { ReactComponent as GenericError } from '../../../assets/images/GenericError.svg';
import { ReactComponent as NothingFound } from '../../../assets/images/NothingFound.svg';
import { ReactComponent as NothingSelected } from '../../../assets/images/NothingSelected.svg';
import { ReactComponent as ConnectionLost } from '../../../assets/images/ConnectionLost.svg';

export enum IllustrationType {
  positive = 'positive',
  emptyFolder = 'emptyFolder',
  connectionLost = 'connectionLost',
  emptyTable = 'emptyTable',
  nothingFound = 'nothingFound',
  nothingSelected = 'nothingSelected',
  genericError = 'genericError'
}

export type Illustrations = keyof typeof IllustrationType;

export interface IllustrationProps {
  illustration?: Illustrations;
  dataTa?: string;
  id?: string;
}

export const Illustration: React.FC<IllustrationProps> = ({ illustration, dataTa, id, children }) => {
  let component: React.ReactElement<any, any> | null = null;
  switch (illustration) {
    case 'positive':
      component = <Positive />;
      break;
    case 'emptyFolder':
      component = <EmptyFolder />;
      break;
    case 'connectionLost':
      component = <ConnectionLost />;
      break;
    case 'emptyTable':
      component = <EmptyTable />;
      break;
    case 'nothingFound':
      component = <NothingFound />;
      break;
    case 'nothingSelected':
      component = <NothingSelected />;
      break;
    case 'genericError':
      component = <GenericError />;
      break;
    default:
      return null;
  }
  return (
    <>
      {React.cloneElement(component, { 'data-ta': dataTa, id })}
      {children}
    </>
  );
};
