import React from 'react';
import { Flex, FlexItem, Button, ButtonType, Spinner } from '@vaisala/rockhopper-components';
import { TEST_IDS } from '../../../../../../tests/testids';
import { QA_TEST_IDS } from '../../../../../../tests/qaTestIds';
import { useThresholdModalContext } from '../../ThresholdModalProvider';
import { useTranslation } from 'react-i18next';

interface Props {
  onCancel: () => void;
}

export const FormControls: React.FC<Props> = ({ onCancel }) => {
  const { handleSave, errors, isSaving, settingsHasChanged } = useThresholdModalContext();
  const { t } = useTranslation();

  const id = QA_TEST_IDS.alarms_settings_threshold_form_save_button;

  return (
    <Flex justifyContent="center">
      <FlexItem>
        <Button
          dataTa={TEST_IDS.save_button}
          htmlId={id}
          id={id}
          buttonType={isSaving ? ButtonType.Secondary : ButtonType.Primary}
          className="save-button"
          disabled={errors.collectedErrors.length > 0 || isSaving || !settingsHasChanged}
          onClick={handleSave}
        >
          {isSaving ? <Spinner /> : t('general.save')}
        </Button>
      </FlexItem>
      <FlexItem>
        <Button
          dataTa={TEST_IDS.cancel_button}
          buttonType={ButtonType.Secondary}
          id="steps-controls-cancel-button"
          onClick={onCancel}
        >
          {t('general.cancel')}
        </Button>
      </FlexItem>
    </Flex>
  );
};
