import React from 'react';
import { Checkbox, CheckboxProps } from '@vaisala/rockhopper-components';
import { CHECKBOX_STATE_CHECKED, CHECKBOX_STATE_CLASSNAMES, CheckboxState } from './constants';
import { TEST_IDS } from '../../tests/testids';
import './multi-state-checkbox.scss';

interface Props extends CheckboxProps {
  state: CheckboxState;
  dataTa?: string;
}

export const MultiStateCheckbox: React.FC<Props> = ({ onChange, state, readOnly, dataTa, ...rest }) => {
  const checkBoxClasses = `multi-state-checkbox ${CHECKBOX_STATE_CLASSNAMES[state]}`;

  return (
    <span data-readonly={!!readOnly} data-ta={TEST_IDS.groups_rights_accordion_checkbox} className={checkBoxClasses}>
      <Checkbox
        {...rest}
        dataTa={dataTa}
        checked={CHECKBOX_STATE_CHECKED[state]}
        onChange={onChange}
        data-readonly={!!readOnly}
        disabled={!!readOnly || rest.disabled}
      />
    </span>
  );
};
