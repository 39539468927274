import * as React from 'react';
import { useEffect } from 'react';
import { Paper } from '@vaisala/rockhopper-components';
import EventFilterBar from './EventFilterBar';
import EventsTable from './EventsTable';
import { EventDetail } from './EventDetail';
import { EventInterface, EventsProps, GetEventsProp } from '../../events/events';
import { EVENTS_TABLE_ROWS_COUNT } from '../../constants';
import { useResizeObserver } from '../../hooks/useResizeObserver';

import makeTestId from '../../utils/makeTestId';
import { TEST_IDS } from '../../tests/testids';

import './event.scss';

const Events = ({
  events,
  start,
  eventsLoading,
  getEvents,
  deviceType,
  language,
  totalCount,
  dataTa
}: EventsProps): JSX.Element => {
  const { getTestId } = makeTestId({ dataTa: dataTa ?? TEST_IDS.events });
  const [rows, setRows] = React.useState<EventInterface[]>([]);
  const [showDetailView, setShowDetailView] = React.useState(false);
  const [eventDetails, setEventDetails] = React.useState<EventInterface | null>(null);
  const [activeRowIndex, setActiveRowIndex] = React.useState<number | null>(null);
  const [pagination, setPagination] = React.useState({
    rowIndex: 0,
    rowCount: EVENTS_TABLE_ROWS_COUNT
  });
  const PAGE_DEFAULT_HEIGHT = 1000;
  const ref = React.useRef(null);
  const [, height] = useResizeObserver(ref, PAGE_DEFAULT_HEIGHT);

  useEffect(() => {
    // TODO: would need to remove sorting on rows if the user had already sorted
    setRows(events ?? []);
  }, [events]);

  const updateRowVisibleEndIdx = (rowIndex: number) => {
    if (totalCount != null && rowIndex < totalCount && start !== rowIndex) {
      setPagination({
        rowIndex,
        rowCount: EVENTS_TABLE_ROWS_COUNT
      });
    }
  };

  React.useEffect(() => {
    let eventDetails: EventInterface | null = null;
    if (activeRowIndex !== null) {
      eventDetails = rows[activeRowIndex];
    }
    if (eventDetails) {
      setShowDetailView(true);
    } else {
      setShowDetailView(false);
    }
    setEventDetails(eventDetails);
  }, [activeRowIndex]);

  const handleClose = (flag: boolean) => {
    setShowDetailView(false);
    setActiveRowIndex(null);
  };

  const handleOnRowClick = (rowIdx: number, row: any) => {
    if (rowIdx === activeRowIndex) {
      return;
    }
    setActiveRowIndex(rowIdx);
  };

  const handleNavBtn = (value: number) => {
    if (activeRowIndex !== null) {
      setActiveRowIndex(activeRowIndex + value);
    }
  };

  const handleApply = () => {
    setActiveRowIndex(null);
    setShowDetailView(false);
    setRows([]);
    setPagination({
      rowIndex: 0,
      rowCount: EVENTS_TABLE_ROWS_COUNT
    });
  };

  const getEventsData = (query: GetEventsProp) => {
    if (!eventsLoading) getEvents(query);
  };

  return (
    <div id="events" data-ta={getTestId()}>
      <EventFilterBar
        deviceType={deviceType}
        rowsCount={totalCount}
        pagination={pagination}
        getEvents={getEventsData}
        language={language}
        isLoading={eventsLoading}
        handleApply={handleApply}
      />
      <div id="events-content" className="content" ref={ref}>
        <div className={showDetailView ? 'table-calc-width' : 'table-full-width'}>
          <Paper>
            <EventsTable
              deviceType={deviceType}
              rows={rows}
              setRows={setRows}
              isLoading={eventsLoading}
              activeRowIndex={activeRowIndex}
              setActiveRowIndex={setActiveRowIndex}
              updateRowVisibleEndIdx={updateRowVisibleEndIdx}
              showDetailView={showDetailView}
              handleOnRowClick={handleOnRowClick}
              minHeight={height !== undefined ? height : PAGE_DEFAULT_HEIGHT}
            />
          </Paper>
        </div>
        {showDetailView && eventDetails && (
          <div id="events-detail-view" className="detail-view">
            <Paper>
              <EventDetail
                key={activeRowIndex}
                data={eventDetails}
                activeIndex={activeRowIndex}
                lastIndex={rows.length - 1}
                onClose={handleClose}
                handleNavBtn={handleNavBtn}
                dataTa={getTestId(TEST_IDS.event_detail)}
                maxHeight={height}
              ></EventDetail>
            </Paper>
          </div>
        )}
      </div>
    </div>
  );
};

export default Events;
