import * as React from 'react';
import { subDays, format, set } from 'date-fns';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { Form, Size, InputField, Button, ButtonType, Select, Flex, Icon } from '@vaisala/rockhopper-components';
import { GetEventsProp } from '../../events/events';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import CenteredSpinner from '../BaseComponents/CenteredSpinner';
import { TEST_IDS } from '../../tests/testids';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { convertLocalTimeToUTC, convertToMiliSeconds, getConstantFromLocalStorage, userSettings } from '../../utils';
import {
  EVENTS_SEARCH_INPUT_MAX_CHARACHTERS_LENGTH,
  LS_COMPANY_CUSTOMER_ID,
  LS_USERNAME,
  PARENT_SITE_KEY,
  UserSettings
} from '../../constants';
import { useAppSelector } from '../../store/hooks';
import { selectCurrentUser, selectTimeZone } from '../../store/profile';
import { Languages } from '../../i18n';
import { useGetSiteQuery } from '../../store/services/siteApi';
import useGetUserName from '../../hooks/useGetUsername';
import { TranslationKey } from '../../react-i18next';
import { EventFilterByZoneModal } from '../EventFilterByZoneModal/EventFilterByZoneModal';
import './event-filter-bar.scss';
import TimeContext from '../../context/TimeContext';
import useDateFilterOptions from '../../hooks/useDateFilterOptions';

export const SELECT_ALL = 'Select all';

export enum EVENT_FILTERS_CATEGORIES {
  Site = 'Site',
  Zone = 'Zone',
  Location = 'Location',
  User = 'User',
  Group = 'Group',
  Notification = 'Notification',
  Alarm = 'Alarm',
  AlarmSettings = 'Alarm settings',
  Device = 'Device',
  Report = 'Report'
}

export const EventCategoryI18nKeyMap: Record<EVENT_FILTERS_CATEGORIES, TranslationKey> = {
  [EVENT_FILTERS_CATEGORIES.Site]: 'general.site',
  [EVENT_FILTERS_CATEGORIES.Zone]: 'general.zone',
  [EVENT_FILTERS_CATEGORIES.Location]: 'general.location',
  [EVENT_FILTERS_CATEGORIES.User]: 'profile.user',
  [EVENT_FILTERS_CATEGORIES.Group]: 'profile.group',
  [EVENT_FILTERS_CATEGORIES.Notification]: 'general.notification',
  [EVENT_FILTERS_CATEGORIES.Alarm]: 'general.alarm',
  [EVENT_FILTERS_CATEGORIES.AlarmSettings]: 'site.alarmSettingsHeading',
  [EVENT_FILTERS_CATEGORIES.Device]: 'general.device',
  [EVENT_FILTERS_CATEGORIES.Report]: 'profile.report'
};

export enum EVENT_FILTERS_ACTIONS {
  Created = 'Created',
  Modified = 'Modified',
  Removed = 'Removed',
  Deactivated = 'Deactivated',
  Activated = 'Activated',
  Downloaded = 'Downloaded',
  LoggedIn = 'Logged in',
  RightsModified = 'Rights modified',
  AlarmOn = 'Alarm on',
  AlarmOff = 'Alarm off',
  AlarmAck = 'Alarm acknowledged',
  AlarmPaused = 'Alarm paused',
  Sent = 'Sent',
  RemovedFromGroup = 'User removed',
  AddedToGroup = 'User added',
  Linked = 'Linked',
  Unlinked = 'Unlinked'
}

export const EventActionI18nKeyMap: Record<EVENT_FILTERS_ACTIONS, TranslationKey> = {
  [EVENT_FILTERS_ACTIONS.Created]: 'general.created',
  [EVENT_FILTERS_ACTIONS.Modified]: 'general.modified',
  [EVENT_FILTERS_ACTIONS.Removed]: 'general.removed',
  [EVENT_FILTERS_ACTIONS.Deactivated]: 'general.deactivated',
  [EVENT_FILTERS_ACTIONS.Activated]: 'general.activated',
  [EVENT_FILTERS_ACTIONS.Downloaded]: 'general.downloaded',
  [EVENT_FILTERS_ACTIONS.LoggedIn]: 'general.loggedIn',
  [EVENT_FILTERS_ACTIONS.RightsModified]: 'general.rightsModified',
  [EVENT_FILTERS_ACTIONS.AlarmOn]: 'general.alarmOn',
  [EVENT_FILTERS_ACTIONS.AlarmAck]: 'general.alarmAcknowledged',
  [EVENT_FILTERS_ACTIONS.AlarmOff]: 'general.alarmOff',
  [EVENT_FILTERS_ACTIONS.AlarmPaused]: 'general.alarmPaused',
  [EVENT_FILTERS_ACTIONS.Sent]: 'general.sent',
  [EVENT_FILTERS_ACTIONS.RemovedFromGroup]: 'general.userRemoved',
  [EVENT_FILTERS_ACTIONS.AddedToGroup]: 'general.userAdded',
  [EVENT_FILTERS_ACTIONS.Linked]: 'general.linked',
  [EVENT_FILTERS_ACTIONS.Unlinked]: 'site.statusDeviceUnlinked'
};

export enum EVENT_FILTERS_SOURCES {
  User = 'User',
  Automated = 'Automated'
}

export const SourceI18nKeyMap: Record<EVENT_FILTERS_SOURCES, TranslationKey> = {
  [EVENT_FILTERS_SOURCES.User]: 'profile.user',
  [EVENT_FILTERS_SOURCES.Automated]: 'general.automated'
};

export const CATEGORIES_LIST = Object.values(EVENT_FILTERS_CATEGORIES);

export const ACTIONS_LIST = Object.values(EVENT_FILTERS_ACTIONS);

const SOURCES_LIST = [EVENT_FILTERS_SOURCES.Automated, EVENT_FILTERS_SOURCES.User];

const initialTime = format(new Date(), 'HH:mm');

enum FilterNameEnum {
  SEARCH = 'search',
  ACTION = 'action',
  CATEGORY = 'category',
  SOURCE = 'source'
}

const EMPTY_STRING = '';

const DEFAULT_FILTERS = {
  fromDate: subDays(new Date(), 1),
  toDate: new Date(),
  [FilterNameEnum.SEARCH]: EMPTY_STRING,
  [FilterNameEnum.CATEGORY]: [],
  [FilterNameEnum.ACTION]: [],
  [FilterNameEnum.SOURCE]: [],
  selectedNodeIds: []
};

export const getTZDateTimeDetails = (date: Date, locale = Languages.en, tz?: string) => {
  const timezoneDate = set(date, { seconds: 0, milliseconds: 0 });

  return {
    fromDate: subDays(timezoneDate, 1),
    toDate: set(timezoneDate, { seconds: 59, milliseconds: 999 })
  };
};

const getDefaultEventFilters = (date: Date, locale?: Languages, tz?: string) => {
  return {
    ...DEFAULT_FILTERS,
    ...getTZDateTimeDetails(date, locale, tz)
  } as EventFilters;
};

type EventFilters = {
  fromDate: Date;
  toDate: Date;
  search: string;
  category: EVENT_FILTERS_CATEGORIES[];
  action: EVENT_FILTERS_ACTIONS[];
  source: EVENT_FILTERS_SOURCES[];
  selectedNodeIds: string[];
};

type SavedEventFilters = Omit<EventFilters, 'toDate' | 'fromDate'> & { toDate: string; fromDate: string };

export interface EventFilterBarProps {
  deviceType?: string;
  rowsCount?: number;
  language: string;
  pagination: { rowIndex: number; rowCount: number };
  getEvents: (query: GetEventsProp) => void;
  isLoading?: boolean;
  handleApply: () => void;
}

const getInitialFilterOptions = (username: string): EventFilters | undefined => {
  let filters: EventFilters | undefined = undefined;
  const savedFiltersStr = userSettings.get(username, UserSettings.EVENTS_FILTERS);
  if (savedFiltersStr) {
    const savedFiltersObj: SavedEventFilters = JSON.parse(savedFiltersStr);
    filters = {
      ...savedFiltersObj,
      fromDate: new Date(savedFiltersObj.fromDate),
      toDate: new Date(savedFiltersObj.toDate)
    };
  }
  return filters;
};

const EventFilterBar = ({
  deviceType,
  rowsCount,
  pagination,
  language,
  getEvents,
  isLoading,
  handleApply
}: EventFilterBarProps): JSX.Element => {
  const { t } = useTranslation();
  const timeContext = React.useContext(TimeContext);
  const timeDetails = timeContext?.timeDetails;
  const today = timeContext?.today;
  const timezone = useAppSelector(selectTimeZone);
  const [showExtraFilters, setShowExtraFilters] = React.useState(false);
  const user = useAppSelector(selectCurrentUser);
  const username = getConstantFromLocalStorage(LS_USERNAME) ?? '';
  const initial = getInitialFilterOptions(username);
  const { filterOptions, renderFrom, renderTo } = useDateFilterOptions({ today, initial });
  const defaultFilters = getDefaultEventFilters(today || new Date(), user?.locale as Languages, user?.tz);
  const [eventFilters, setEventFilters] = React.useState<EventFilters>({
    ...defaultFilters,
    ...initial,
    ...filterOptions
  });
  const [appliedFilters, setAppliedFilters] = React.useState(eventFilters);
  const [showFilterByZone, setShowFilterByZone] = React.useState(false);
  const extraFiltersclassName = cx('events-event-filter-extras', {
    show: showExtraFilters
  });
  const [dirtyBit, setDirtyBit] = React.useState<boolean>(false);

  React.useEffect(() => {
    setDirtyBit(!dirtyBit);
  }, [timeDetails?.current?.date]);

  const saveEventFilters = () => {
    const eventFiltersCopy: SavedEventFilters = {
      ...eventFilters,
      ...filterOptions,
      fromDate: filterOptions.fromDate.toISOString(),
      toDate: filterOptions.toDate.toISOString()
    };
    userSettings.set(username, UserSettings.EVENTS_FILTERS, JSON.stringify(eventFiltersCopy));
  };

  React.useEffect(() => {
    doEventsQuery(eventFilters);
    setAppliedFilters(eventFilters);
  }, [pagination, language]);

  React.useEffect(() => {
    setEventFilters(filters => ({ ...filters, ...filterOptions }));
  }, [filterOptions]);

  const toggleExtraFilters = () => {
    setShowExtraFilters(value => !value);
  };

  const doEventsQuery = (eventFilters: EventFilters) => {
    const from = convertToMiliSeconds(convertLocalTimeToUTC(eventFilters.fromDate, timezone || 'UTC'));
    const to = set(convertToMiliSeconds(convertLocalTimeToUTC(eventFilters.toDate, timezone || 'UTC')), {
      seconds: 59,
      milliseconds: 999
    }).getTime();

    const query: GetEventsProp = {
      from,
      to,
      start: pagination.rowIndex,
      size: pagination.rowCount,
      locale: language
    };

    if (eventFilters.search && eventFilters.search.trim()) {
      query[FilterNameEnum.SEARCH] = eventFilters.search.trim();
    }

    if (eventFilters.category && eventFilters.category.length > 0) {
      query[FilterNameEnum.CATEGORY] = eventFilters.category.join(',');
    }

    if (eventFilters.action && eventFilters.action.length > 0) {
      query[FilterNameEnum.ACTION] = eventFilters.action.join(',');
    }

    if (eventFilters.source && eventFilters.source.length > 0) {
      query.source_type = eventFilters.source.join(',');
    }

    if (eventFilters.selectedNodeIds?.length > 0) {
      query.location_ids = eventFilters.selectedNodeIds.join(',');
    }
    getEvents(query);
  };

  const onChange = (
    value: (EVENT_FILTERS_CATEGORIES | EVENT_FILTERS_ACTIONS | EVENT_FILTERS_SOURCES)[],
    name: keyof typeof eventFilters
  ) => {
    setEventFilters(prevFilters => ({ ...prevFilters, [name]: value }));
  };

  const handleCategoryChange = (options: EVENT_FILTERS_CATEGORIES[]) => {
    onChange(options, FilterNameEnum.CATEGORY);
  };

  const handleActionChange = (options: EVENT_FILTERS_ACTIONS[]) => {
    onChange(options, FilterNameEnum.ACTION);
  };

  const handleSourceChange = (options: EVENT_FILTERS_SOURCES[]) => {
    onChange(options, FilterNameEnum.SOURCE);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const limitedValue = name === 'search' ? value.substring(0, EVENTS_SEARCH_INPUT_MAX_CHARACHTERS_LENGTH) : value;
    setEventFilters(prevFilters => ({ ...prevFilters, [name]: limitedValue }));
  };

  const handleZoneSelection = (selectedIds: string[]) => {
    setEventFilters(filters => {
      const clonedFilters = { ...filters, selectedNodeIds: selectedIds };
      return clonedFilters;
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      saveEventFilters();
      handleApply();
    }
  };

  const searchLabel = (rowsCount: number): JSX.Element => (
    <Flex justifyContent="space-between" className="text-wrap--nowrap">
      <Flex.Item id="events-event-search-title">{t('events.filterBar.search')}</Flex.Item>
      <Flex.Item id="events-event-count" className="grey-medium normal">
        {rowsCount} {t('events.filterBar.events')}
      </Flex.Item>
    </Flex>
  );
  const isExtraFiltersEmpty =
    isEmpty(eventFilters.action) &&
    isEmpty(eventFilters.category) &&
    isEmpty(eventFilters.source) &&
    isEmpty(eventFilters.search) &&
    isEmpty(eventFilters.selectedNodeIds);

  const onClearFilters = () => {
    setEventFilters(prevState => ({
      ...prevState,
      search: '',
      category: [],
      action: [],
      source: [],
      selectedNodeIds: []
    }));
    userSettings.clear(username, UserSettings.EVENTS_FILTERS);
    handleApply();
  };

  const hasFiltersChanged = () => {
    const original = cloneDeep(appliedFilters);

    sortStringArray(original.action);
    sortStringArray(original.category);
    sortStringArray(original.source);

    const current = cloneDeep(eventFilters);

    sortStringArray(current.action);
    sortStringArray(current.category);
    sortStringArray(current.source);

    return !isEqual(current, original);
  };

  const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID) ?? '';
  const { username: usernameEncoded } = useGetUserName();

  const { data: site } = useGetSiteQuery(
    { customerId, username: usernameEncoded ?? '', siteId: PARENT_SITE_KEY },
    { skip: usernameEncoded === undefined || usernameEncoded === 'undefined' || usernameEncoded === null }
  );

  const closeModal = () => setShowFilterByZone(false);

  return (
    <div id="events-event-filter-bar">
      <EventFilterByZoneModal
        onNodeSelect={handleZoneSelection}
        onApply={() => {
          handleApply();
          closeModal();
        }}
        selectedNodes={eventFilters.selectedNodeIds}
        onDismiss={closeModal}
        site={site}
        isOpen={showFilterByZone}
      />
      <div id="events-event-filter-bar-search" className="events-event-filter-search">
        <Form.Item label={searchLabel(rowsCount ?? 0)} className="events-event-filter-bar-search">
          <InputField
            id="events-event-filter-bar-search"
            data-ta={TEST_IDS.events_filter_bar_search_field_input}
            value={eventFilters.search}
            onChange={handleInputChange}
            name="search"
            placeholder={t('events.filterBar.search')}
            width={Size.Container}
            onKeyDown={handleKeyDown}
          />
        </Form.Item>
      </div>
      <div id="events-event-filter-bar-date" className="events-event-filter-date">
        <Form.Item
          label={<div data-ta={TEST_IDS.from_date_picker_label}>{t('events.filterBar.from')}</div>}
          labelWidth={Size.S}
          className="form-item-datepicker"
        >
          <Flex>
            {renderFrom({
              datePickerProps: {
                onKeyDown: handleKeyDown
              }
            })}
          </Flex>
        </Form.Item>
        <Form.Item
          label={<div data-ta={TEST_IDS.to_date_picker_label}>{t('events.filterBar.to')}</div>}
          className="form-item-datepicker"
        >
          <Flex>{renderTo()}</Flex>
        </Form.Item>
      </div>
      <div className="events-event-filter-filter">
        <Button
          id="events-filter-button"
          buttonSize={Size.S}
          buttonType={ButtonType.Secondary}
          startIcon={<Icon name={VaiIcon.ChevronDown} />}
          onClick={toggleExtraFilters}
          className={showExtraFilters ? 'active' : ''}
        >
          {t('events.filterBar.filter')}
        </Button>
      </div>
      <div className={extraFiltersclassName}>
        <Form.Item
          label={
            <div data-ta={TEST_IDS.concat(TEST_IDS.label, TEST_IDS.category)}>{t('events.filterBar.category')}</div>
          }
          className="form-item-filter-category"
        >
          <Select<EVENT_FILTERS_CATEGORIES[]>
            placeholder={`${t('events.filterBar.filterCategory')}`}
            showSearch
            allowClear
            id="events-event-category"
            virtual={process.env.NODE_ENV === 'test' ? false : true}
            onChange={handleCategoryChange}
            maxTagCount={1}
            mode={'tags'}
            onKeyDown={handleKeyDown}
            value={eventFilters.category}
            data-ta={TEST_IDS.events_filter_bar_category_field_select}
          >
            {CATEGORIES_LIST.map((category, index) => (
              <Select.Option
                id={`event-category-option-${index}`}
                key={`event-category-option-${index}`}
                value={category}
              >
                {t(EventCategoryI18nKeyMap[category] || 'general.missingTranslation')}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={<div data-ta={TEST_IDS.concat(TEST_IDS.label, TEST_IDS.action)}>{t('events.filterBar.action')}</div>}
        >
          <Select<EVENT_FILTERS_ACTIONS[]>
            placeholder={`${t('events.filterBar.filterAction')}`}
            showSearch
            allowClear
            id="events-event-action"
            virtual={process.env.NODE_ENV === 'test' ? false : true}
            maxTagCount={1}
            onChange={handleActionChange}
            mode={'tags'}
            onKeyDown={handleKeyDown}
            value={eventFilters.action}
            data-ta={TEST_IDS.events_filter_bar_action_field_select}
          >
            {ACTIONS_LIST.map((action, index) => (
              <Select.Option id={`event-action-option-${index}`} key={`event-action-option-${index}`} value={action}>
                {t(EventActionI18nKeyMap[action] || 'general.missingTranslation')}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={<div data-ta={TEST_IDS.concat(TEST_IDS.label, TEST_IDS.source)}>{t('events.filterBar.source')}</div>}
        >
          <Select<EVENT_FILTERS_SOURCES[]>
            placeholder={`${t('events.filterBar.filterSource')}`}
            showSearch
            allowClear
            id="events-event-source"
            virtual={process.env.NODE_ENV === 'test' ? false : true}
            maxTagCount={1}
            onChange={handleSourceChange}
            mode={'tags'}
            onKeyDown={handleKeyDown}
            value={eventFilters.source}
            data-ta={TEST_IDS.events_filter_bar_source_field_select}
          >
            {SOURCES_LIST.map((source, index) => (
              <Select.Option id={`event-source-option-${index}`} key={`event-source-option-${index}`} value={source}>
                {t(SourceI18nKeyMap[source] || 'general.missingTranslation')}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button
          id="events-filter-zone-button"
          buttonSize={Size.S}
          buttonType={ButtonType.Secondary}
          startIcon={<Icon name={VaiIcon.TreeControl} />}
          onClick={() => setShowFilterByZone(true)}
          dataTa={TEST_IDS.events_filter_bar_filterByZone_button}
        >
          {t('events.filterBar.filterByZone')}
          {eventFilters.selectedNodeIds?.length > 0 ? ` (${eventFilters.selectedNodeIds.length})` : ''}
        </Button>
        <Button
          onClick={onClearFilters}
          id="events-filter-clear-button"
          buttonSize={Size.S}
          buttonType={ButtonType.Link}
          dataTa={TEST_IDS.events_filter_bar_clear_filters_button}
          disabled={isExtraFiltersEmpty}
        >
          {t('events.filterBar.clearFiltres')}
        </Button>
      </div>
      <div className="events-event-filter-apply">
        <Button
          id="events-apply-button"
          buttonSize={deviceType === 'MOBILE_PHONE' ? Size.S : Size.M}
          type="button"
          onClick={() => {
            saveEventFilters();
            setAppliedFilters(eventFilters);
            handleApply();
          }}
          disabled={isLoading || !hasFiltersChanged()}
          dataTa={TEST_IDS.events_filter_bar_apply_filters_button}
        >
          {isLoading ? <CenteredSpinner htmlId="save-button-spinner" className="" /> : t('events.filterBar.apply')}
        </Button>
      </div>
    </div>
  );
};

export default EventFilterBar;

function sortStringArray(arr: string[]) {
  return arr.sort((a, b) => (a > b ? 1 : -1));
}
