import React, { useMemo } from 'react';
import { Button, ButtonType, Flex, FlexItem, Icon, Size } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import useHtmlId from '../../../hooks/useHtmlId';
import { LocationNode } from '../../../siteTree/site';
import { hideDialog } from '../../../store/dialog';
import { useAppDispatch } from '../../../store/hooks';
import { TEST_IDS } from '../../../tests/testids';
import { LinkNodeArgs, UnlinkNodeArgs } from '../../../store/services/siteApi';
import { DataSourceListItemModel } from '../../../types';
import { ModalContainer } from '../../Modals/ModalContainer';
import MeasurementTypeIcon from '../../Utils/MeasurementTypeIcon';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { buildDeviceId } from '../../../utils';
import { DEVICE_LINKAGE_ACTION } from '../../../constants';

const htmlId = 'link-data-source-confirmation-modal';

import './index.scss';

interface DataSourceConfirmationModalProps {
  location: LocationNode;
  selectedItem: DataSourceListItemModel | null;
  linkTreeNode: (node: LinkNodeArgs) => void;
  unlinkTreeNode: (node: UnlinkNodeArgs) => void;
}

const DataSourceConfirmationModal: React.FC<DataSourceConfirmationModalProps> = ({
  location,
  selectedItem,
  linkTreeNode,
  unlinkTreeNode
}) => {
  const { t } = useTranslation();
  const [saving, setSaving] = React.useState<boolean>(false);
  const { getId } = useHtmlId({ htmlId });
  const deviceId = selectedItem ? buildDeviceId(selectedItem.device_model, selectedItem.device_sn) : '';
  const probeId = selectedItem ? buildDeviceId(selectedItem.probe_model, selectedItem.probe_sn) : '';
  const oldDeviceId = location.device ? buildDeviceId(location.device.device_model, location.device.device_sn) : '';
  const oldProbeId = location.device ? buildDeviceId(location.device.probe_model, location.device.probe_sn) : '';
  const dispatch = useAppDispatch();
  const onDismiss = () => {
    dispatch(hideDialog());
  };

  const modalAction: DEVICE_LINKAGE_ACTION = useMemo(() => {
    if (!location.device) {
      return DEVICE_LINKAGE_ACTION.LINK;
    } else if (probeId !== oldProbeId) {
      return DEVICE_LINKAGE_ACTION.SWITCH;
    } else {
      return DEVICE_LINKAGE_ACTION.UNLINK;
    }
  }, [location, selectedItem]);

  const onConfirm = async () => {
    setSaving(true);
    let nodeLink: LinkNodeArgs | undefined;
    if (selectedItem) {
      nodeLink = {
        linkedNode: {
          device_model: selectedItem.device_model,
          device_sn: selectedItem.device_sn,
          probe_model: selectedItem.probe_model,
          probe_sn: selectedItem.probe_sn,
          meas_group: selectedItem.meas_group,
          meas_id: selectedItem.meas_id
        },
        node_id: location.node_id,
        name: location.name,
        node_type: location.type
      };
    }
    let nodeUnlink: UnlinkNodeArgs | undefined;
    if (location.device) {
      nodeUnlink = {
        node_id: location.node_id,
        name: location.name,
        dataSource: location.device
      };
    }
    if (modalAction === DEVICE_LINKAGE_ACTION.LINK && nodeLink) {
      linkTreeNode(nodeLink);
    } else if (modalAction === DEVICE_LINKAGE_ACTION.UNLINK && nodeUnlink) {
      unlinkTreeNode(nodeUnlink);
    } else if (modalAction === DEVICE_LINKAGE_ACTION.SWITCH && nodeLink && nodeUnlink) {
      try {
        await unlinkTreeNode(nodeUnlink);
        linkTreeNode(nodeLink);
      } catch (error) {
        console.error('Error while unlinking:', error);
      }
    }
    onDismiss();
  };
  const cancelId = getId('cancel-button');
  const confirmId = getId('confirm-button');
  const ModalButtons = (
    <>
      <Button id={confirmId} className="vai-space-inline-s" disabled={saving} onClick={onConfirm}>
        {t('general.confirm')}
      </Button>
      <Button id={cancelId} htmlId={cancelId} buttonType={ButtonType.Secondary} onClick={onDismiss}>
        {t('general.cancel')}
      </Button>
    </>
  );

  return (
    <ModalContainer
      id={getId() as string}
      isOpen
      showCloseIcon={false}
      dismissOnOverlayClick={false}
      title={
        modalAction === DEVICE_LINKAGE_ACTION.UNLINK
          ? 'unlinkDataSourceConfirmationModal.title'
          : 'linkDataSourceConfirmationModal.title'
      }
      onDismiss={onDismiss}
      buttons={ModalButtons}
    >
      <Flex>
        <FlexItem className="vai-margin-left-l vai-margin-top-m" flexBasis={'35%'} alignSelf={'flex-start'}>
          {t('general.location')}
        </FlexItem>
        <FlexItem flexBasis={'65%'} alignSelf={'flex-end'}>
          <h3 id={getId('location-title')} data-ta={TEST_IDS.link_data_source_confirmation_modal_location_title}>
            <MeasurementTypeIcon
              type={location.meas_id ?? null}
              className="confirmation-modal-meas-icon"
              htmlId={getId('meas-icon')}
            />
            {location.name}
          </h3>
        </FlexItem>
      </Flex>
      {modalAction === DEVICE_LINKAGE_ACTION.SWITCH && (
        <Flex>
          <FlexItem className="vai-margin-left-l vai-margin-top-m" flexBasis={'35%'} alignSelf={'flex-start'}>
            {t('general.dataSource')}
          </FlexItem>
          <FlexItem className="vai-margin-top-s" flexBasis={'65%'} alignSelf={'flex-end'}>
            <Flex
              id={getId('data-source-old-title')}
              flexDirection="row"
              dataTa={TEST_IDS.link_data_source_confirmation_modal_data_source_old_title}
            >
              <FlexItem alignSelf="center">
                <Icon className="vai-margin-right-s" size={Size.L} name={VaiIcon.Unlink} />
              </FlexItem>
              <FlexItem flexBasis={'100%'}>
                <b id={getId('old-device-id')} data-ta={TEST_IDS.link_data_source_confirmation_modal_old_device_id}>
                  {oldDeviceId}
                </b>
                <br />
                <span
                  id={getId('old-probe-id')}
                  className="grey-medium"
                  data-ta={TEST_IDS.link_data_source_confirmation_modal_old_probe_id}
                >
                  {oldProbeId}
                </span>
              </FlexItem>
            </Flex>
          </FlexItem>
        </Flex>
      )}
      <Flex>
        <FlexItem className="vai-margin-left-l vai-margin-top-m" flexBasis={'35%'} alignSelf={'flex-start'}>
          {modalAction === DEVICE_LINKAGE_ACTION.UNLINK
            ? t('unlinkDataSourceConfirmationModal.toBeLinked')
            : t('general.dataSource')}
        </FlexItem>
        <FlexItem className="vai-margin-top-s" flexBasis={'65%'} alignSelf={'flex-end'}>
          <Flex
            id={getId('data-source-title')}
            flexDirection="row"
            dataTa={TEST_IDS.link_data_source_confirmation_modal_data_source_title}
          >
            <FlexItem alignSelf="center">
              <Icon
                className="vai-margin-right-s"
                size={Size.L}
                name={modalAction === DEVICE_LINKAGE_ACTION.UNLINK ? VaiIcon.Unlink : VaiIcon.Link}
              />
            </FlexItem>
            <FlexItem flexBasis={'100%'}>
              <b id={getId('device-id')} data-ta={TEST_IDS.link_data_source_confirmation_modal_device_id}>
                {deviceId}
              </b>
              <br />
              <span
                id={getId('probe-id')}
                className="grey-medium"
                data-ta={TEST_IDS.link_data_source_confirmation_modal_probe_id}
              >
                {probeId}
              </span>
            </FlexItem>
          </Flex>
        </FlexItem>
      </Flex>
      <Flex>
        <FlexItem className="vai-margin-left-s vai-margin-top-m" flexBasis={'100%'}>
          <p id={getId('message')} data-ta={TEST_IDS.link_data_source_confirmation_modal_message}>
            {modalAction === DEVICE_LINKAGE_ACTION.UNLINK
              ? t('unlinkDataSourceConfirmationModal.message')
              : t('linkDataSourceConfirmationModal.message')}
          </p>
        </FlexItem>
      </Flex>
    </ModalContainer>
  );
};

export default DataSourceConfirmationModal;
