import * as React from 'react';
import { Size, Modal, Heading } from '@vaisala/rockhopper-components';
import { UserInterface } from '../../../profile/user';
import { SiteNode } from '../../../siteTree/site';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../store/hooks';
import { hideDialog } from '../../../store/dialog';
import { RightsAccordionTree } from '../../RightsAccordionTree/RightsAccordionTree';
import { TEST_IDS } from '../../../tests/testids';
import './user-effective-rights.scss';
import { NO_RIGHTS } from '../../RightsAccordionTree/constants';

interface UserEffectiveRightsDialogProps {
  site: SiteNode;
  userProfile: UserInterface;
}

const UserEffectiveRightsDialog = (props: UserEffectiveRightsDialogProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const onDismiss = () => dispatch(hideDialog());
  const firstName = props.userProfile.first_name?.trim() ?? '';
  const lastName = props.userProfile.last_name?.trim() ?? '';
  const email = props.userProfile.email ?? '';
  const userName = firstName.length > 0 ? firstName + ' ' + lastName : email;
  const { rights, rights_global: rightsGlobal } = props.userProfile;

  return (
    <Modal
      id="user-effective-rights-dialog"
      className="user-effective-rights-dialog"
      isOpen
      showCloseIcon={true}
      onDismiss={onDismiss}
      width={Size.L}
      dismissOnOverlayClick={false}
    >
      <Heading id="user-effective-rights-header" className="user-effective-rights-header vai-margin-top-none">
        <span id="user-effective-rights-summary">{t('profile.rightSummary', { userName })}</span>
      </Heading>
      <p
        id="user-effective-rights-name"
        data-ta={TEST_IDS.user_effective_rights_name}
        className="user-effective-rights-name"
      >
        {userName}
      </p>
      <p
        id="user-effective-rights-description"
        data-ta={TEST_IDS.user_effective_rights_description}
        className="user-effective-rights-description"
      >
        {t('profile.rightSummaryMessage')}
      </p>
      <br />
      <RightsAccordionTree
        readonly={true}
        site={props.site}
        expandedNodes={[props.site.node_id]}
        rightDetails={{ rights: rights ?? {}, rightsGlobal: rightsGlobal ?? NO_RIGHTS }}
      />
    </Modal>
  );
};

export default UserEffectiveRightsDialog;
