import { Flex, Icon, Paper, Size } from '@vaisala/rockhopper-components';
import { VaiColor, VaiIcon } from '@vaisala/rockhopper-design-tokens';
import React from 'react';
import { connect } from 'react-redux';
import {
  CHART_SERIES_COLORS,
  DEVICES_MEAS_ID,
  LOCATION_KEY,
  LS_COMPANY_CUSTOMER_ID,
  MAX_LOCATIONS_SELECTED,
  SiteNodeTypes
} from '../../../constants';
import { reportsDispatchActions, StoreState } from '../../../store';
import {
  useLazyGetMeasurementsQuery,
  useLazyDownloadJsonResponseQuery,
  GetMeasurementLazyQuery,
  DownloadJsonResponseLazyQuery
} from '../../../store/services/reportsApi';
import {
  convertLocalTimeToUTC,
  getAssignedChartColor,
  getConstantFromLocalStorage,
  getInitialAssignedChartColors,
  getVisibleLocationsNumber,
  modifyResponseData,
  sortLocationsOnTopOfZones
} from '../../../utils';
import CenteredSpinner from '../../BaseComponents/CenteredSpinner';
import TruncatedText from '../../Utils/TruncatedText/TruncatedText';
import { CustomProps, LocationCustomProps, LocationTreeFormattedNode } from '../Locations';

import './selected-locations-list.scss';
import { TEST_IDS } from '../../../tests/testids';
import { doGetMeasurementsQuery, findTreeNode, processData } from '../../../utils/reports';
import { cloneDeep } from 'lodash';
import { useAppSelector } from '../../../store/hooks';
import { selectTimeZone } from '../../../store/profile';
import { ReportState } from '../../../store/reducers/reports';

type SelectedLocationsListProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    selectedLocations: { [key: string]: LocationTreeFormattedNode };
  };

let requests: Record<string, ReturnType<GetMeasurementLazyQuery | DownloadJsonResponseLazyQuery>> = {};

const SelectedLocationsList: React.FunctionComponent<SelectedLocationsListProps> = (
  props: SelectedLocationsListProps
) => {
  const [prevSelectedNode, setPrevSelectedNode] = React.useState<CustomProps | null>(null);
  const [removingNode, setRemovingNode] = React.useState(false);
  const [zonesNames, setZonesNames] = React.useState<{ [key: string]: string }>({});
  const timezone = useAppSelector(selectTimeZone);

  const [getMeasurements, { isLoading }] = useLazyGetMeasurementsQuery();
  const [downloadJsonData] = useLazyDownloadJsonResponseQuery();
  const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID) ?? '';

  const getMeasurementsPoints = async (
    selectedLocationAndSymbolIDs: { locationId: string; symbolId: string }[],
    prevData = props.selectedLocationsCustomProps
  ) => {
    let locationsClone: LocationCustomProps | undefined;
    try {
      props.setReportLoadingState(true);

      await Promise.all(
        selectedLocationAndSymbolIDs.map(async locAndSymbolId => {
          const data = await doGetMeasurementsQuery(
            getMeasurements,
            downloadJsonData,
            {
              ...locAndSymbolId,
              customerId,
              from: convertLocalTimeToUTC(props.intervalDate.from, timezone),
              to: convertLocalTimeToUTC(props.intervalDate.to, timezone)
            },
            requests
          );
          locationsClone = processData(data, locationsClone ?? prevData, locAndSymbolId.locationId);
        })
      );

      requests = {};
      props.setReportLoadingState(false);
      updateLocationsState(locationsClone); // Update store after all requests are fulfilled
    } catch (e) {
      locationsClone = undefined;
      console.error((e as any).response ?? e);
      throw e;
    }
  };

  const updateLocationsState = (data = props.selectedLocationsCustomProps) => {
    const [newLocations, visibleLocations, assignedColors] = hideAdditionalLocations(data);
    props.setReportsAssignedChartColors(assignedColors);
    props.setReportsVisibleLocations(visibleLocations);
    props.setReportSelectedLocationsCustomProps(newLocations);
  };

  React.useEffect(() => {
    setPrevSelectedNode(null);
    hideAdditionalLocations();
  }, [props.selectedFormattedLocations]);

  React.useEffect(() => {
    if (props.selectedLocationsIDs.length > 0 && !removingNode) {
      // Call measurements endpoint
      abortCurrentRequests();

      const selectedLocationAndSymbolIDs: { locationId: string; symbolId: string }[] = [];
      props.selectedLocationsIDs.map(locID => {
        const loc = findTreeNode(props.selectedLocations, locID);
        selectedLocationAndSymbolIDs.push({ locationId: locID, symbolId: loc?.symbol_id ?? '' });
      });
      getMeasurementsPoints(selectedLocationAndSymbolIDs);
    }

    if (removingNode) {
      setRemovingNode(false);
    }
  }, [props.selectedLocationsIDs, props.intervalDate]);

  React.useEffect(() => {
    return () => {
      abortCurrentRequests();
    };
  }, []);

  const abortCurrentRequests = () => {
    if (requests && Object.keys(requests).length > 0 && !props.isTesting) {
      for (const key in requests) {
        requests[key].abort();
        delete requests[key];
      }
    }

    requests = {};
  };

  const hideAdditionalLocations = (
    data = props.selectedLocationsCustomProps,
    visibleLocations = props.visibleLocations,
    assignedChartColors = props.assignedChartColors,
    visibleLocationsNumIndicator = 0
  ): [LocationCustomProps, ReportState['visibleLocations'], ReportState['assignedChartColors'], number] => {
    const dataClone: LocationCustomProps =
      data === props.selectedLocationsCustomProps ? JSON.parse(JSON.stringify(data)) : data;
    const visibleLocationsClone: ReportState['visibleLocations'] =
      visibleLocations === props.visibleLocations ? JSON.parse(JSON.stringify(visibleLocations)) : visibleLocations;
    const assignedColorsClone: ReportState['assignedChartColors'] =
      assignedChartColors === props.assignedChartColors ? getInitialAssignedChartColors() : assignedChartColors;

    Object.keys(dataClone).forEach((id, locationIndex) => {
      if (
        Object.keys(props.visibleLocations).length > 0 &&
        getVisibleLocationsNumber(props.visibleLocations) <= MAX_LOCATIONS_SELECTED
      ) {
        if (locationIndex >= MAX_LOCATIONS_SELECTED) {
          dataClone[id].visibleOnGraph = false;
        }

        // Adjust when selecting locations and some are hidden
        visibleLocationsNumIndicator = dataClone[id].visibleOnGraph
          ? visibleLocationsNumIndicator + 1
          : visibleLocationsNumIndicator;

        const color = Object.keys(props.assignedChartColors)[visibleLocationsNumIndicator - 1] as VaiColor;

        visibleLocationsClone[id] = {
          visible: dataClone[id].visibleOnGraph,
          color: dataClone[id].visibleOnGraph ? color : null
        };

        if (dataClone[id].visibleOnGraph) {
          assignedColorsClone[color] = id;
        }
      } else {
        if (locationIndex >= MAX_LOCATIONS_SELECTED) {
          dataClone[id].visibleOnGraph = false;
          visibleLocationsClone[id] = {
            visible: false,
            color: null
          };
        } else {
          visibleLocationsClone[id] = {
            visible: dataClone[id].visibleOnGraph || true,
            color: CHART_SERIES_COLORS[locationIndex]
          };

          assignedColorsClone[CHART_SERIES_COLORS[locationIndex]] = id;
        }
      }

      if (dataClone[id].showThreshold) {
        setPrevSelectedNode({ ...dataClone[id], node_id: id });
      }
    });

    return [dataClone, visibleLocationsClone, assignedColorsClone, visibleLocationsNumIndicator];
  };

  const onNodeRemove = (node: LocationTreeFormattedNode) => (event: React.SyntheticEvent) => {
    event.stopPropagation();
    if (props.selectedLocationsNum - 1 === 0) {
      // Unselect all zones (have no locations) and locations
      const formattedData = modifyResponseData(props.locations);
      props.setReportsSelectedFormattedLocations(formattedData);
      props.setReportSelectedLocationsIDs([]);
      props.setReportSelectedLocationsSKs([]);
      props.setReportSelectedLocationsCustomProps({});
    } else {
      const selectedLocationsClone = cloneDeep(props.selectedFormattedLocations);

      setRemovingNode(true);

      const removedNode = node.hierarchy.reduce<LocationTreeFormattedNode | null>((acc, curr) => {
        return acc ? acc.children[curr] : selectedLocationsClone[curr];
      }, null);

      if (!removedNode) {
        return;
      }

      const newLocationsIDs = props.selectedLocationsIDs.filter(id => id !== removedNode.node_id);
      const newLocationsSKs = props.selectedLocationsSKs.filter(sk => sk !== removedNode.sk);
      const newLocationsCustomProps = { ...props.selectedLocationsCustomProps };

      delete newLocationsCustomProps[removedNode.node_id];

      removedNode.selected = false;

      const assignedColor = getAssignedChartColor(removedNode.node_id, props.assignedChartColors);
      if (assignedColor) {
        props.setReportsAssignedChartColors({ ...props.assignedChartColors, [assignedColor]: null });
      }

      props.setReportsVisibleLocations({
        ...props.visibleLocations,
        [removedNode.node_id]: { visible: false, color: null }
      });

      props.setReportSelectedLocationsIDs(newLocationsIDs);
      props.setReportSelectedLocationsSKs(newLocationsSKs);
      props.setReportsSelectedFormattedLocations(selectedLocationsClone);
      props.setReportSelectedLocationsCustomProps(newLocationsCustomProps);
    }

    props.setReportsSelectedLocationsNum(props.selectedLocationsNum - 1);
  };

  const onNodeVisibilityToggle = (node: LocationTreeFormattedNode) => (event: React.SyntheticEvent) => {
    event.stopPropagation();

    const selectedLocationsClone = JSON.parse(JSON.stringify(props.selectedLocationsCustomProps));

    if (
      getVisibleLocationsNumber(props.visibleLocations) < MAX_LOCATIONS_SELECTED ||
      selectedLocationsClone[node.node_id].visibleOnGraph
    ) {
      selectedLocationsClone[node.node_id].visibleOnGraph = !selectedLocationsClone[node.node_id].visibleOnGraph;
      selectedLocationsClone[node.node_id].showThreshold = false; // If hiding it, remove threshold

      let assignedColor: VaiColor | undefined;

      if (selectedLocationsClone[node.node_id].visibleOnGraph) {
        // assign new color to it
        assignedColor = getFirstAvailableColor();
        if (assignedColor) {
          props.setReportsAssignedChartColors({ ...props.assignedChartColors, [assignedColor]: node.node_id });
        }
        props.setReportsVisibleLocations({
          ...props.visibleLocations,
          [node.node_id]: { visible: true, color: assignedColor }
        });
      } else {
        // remove already assigned color
        assignedColor = getAssignedChartColor(node.node_id, props.assignedChartColors);
        if (assignedColor) {
          props.setReportsAssignedChartColors({ ...props.assignedChartColors, [assignedColor]: null });
        }
        props.setReportsVisibleLocations({
          ...props.visibleLocations,
          [node.node_id]: { visible: false, color: null }
        });
      }

      props.setReportSelectedLocationsCustomProps(selectedLocationsClone);
    }
  };

  const getFirstAvailableColor = (): VaiColor | undefined => {
    let availableColor;

    for (const [key, value] of Object.entries(props.assignedChartColors)) {
      if (!value) {
        // This is not ideal, but typescript doesn't have a way to pass types for key and value so we need to assert the type here
        availableColor = key as VaiColor;
        break;
      }
    }

    return availableColor;
  };

  const onNodeClick = (node: LocationTreeFormattedNode) => (e: React.SyntheticEvent) => {
    const selectedLocationsClone = JSON.parse(JSON.stringify(props.selectedLocationsCustomProps));

    if (prevSelectedNode && prevSelectedNode.node_id && prevSelectedNode.node_id !== node.node_id) {
      selectedLocationsClone[prevSelectedNode.node_id].showThreshold = false;
    }

    selectedLocationsClone[node.node_id].showThreshold = !selectedLocationsClone[node.node_id].showThreshold;
    setPrevSelectedNode({ ...selectedLocationsClone[node.node_id], node_id: node.node_id });

    props.setReportSelectedLocationsCustomProps(selectedLocationsClone);
  };

  const renderIcon = (node: LocationTreeFormattedNode): JSX.Element => {
    switch (node.meas_id) {
      case DEVICES_MEAS_ID.TEMPERATURE:
        return <Icon name={VaiIcon.Thermometer} color={VaiColor.BlueDark} size={Size.M} />;
      case DEVICES_MEAS_ID.HUMIDITY:
        return <Icon name={VaiIcon.Humidity} color={VaiColor.BlueDark} size={Size.M} />;
      case DEVICES_MEAS_ID.CO2:
        return <Icon name={VaiIcon.Dust} color={VaiColor.BlueDark} size={Size.M} />;
      default:
        return <></>;
    }
  };

  const renderZoneNamesByHierarchy = (node: LocationTreeFormattedNode) => {
    let selectedNode: LocationTreeFormattedNode;
    const selectedLocationsClone = JSON.parse(JSON.stringify(props.selectedLocations));

    let zoneName = '';

    node.hierarchy.forEach(id => {
      selectedNode = selectedNode ? selectedNode.children[id] : selectedLocationsClone[id];
      zoneName +=
        selectedNode.type === SiteNodeTypes.SITE
          ? ''
          : zoneName.length > 0
          ? ` / ${selectedNode.name}`
          : selectedNode.name;
    });

    setZonesNames(names => ({ ...names, [node.node_id]: zoneName }));

    return zoneName;
  };

  const renderTree = (data: { [key: string]: LocationTreeFormattedNode } = props.selectedLocations) => {
    return sortLocationsOnTopOfZones(data).map((node: LocationTreeFormattedNode) => {
      if (Object.keys(node.children).length > 0) {
        return (
          <div key={node.node_id}>
            {(node.selected || node.partialSelected) && node.type === SiteNodeTypes.SITE ? (
              <Flex className="site-node node vai-font-size-l" alignItems="center">
                <Icon name={VaiIcon.MapMarkerHexagon} color={VaiColor.BlueDark} />
                <span key={node.node_id}>{node.name}</span>
              </Flex>
            ) : (
              node.show &&
              Object.values(node.children).find(n => n.type === LOCATION_KEY && n.selected) && (
                <p key={node.node_id} className="node">
                  {zonesNames[node.node_id] ? zonesNames[node.node_id] : renderZoneNamesByHierarchy(node)}
                </p>
              )
            )}
            {renderTree(node.children)}
          </div>
        );
      } else {
        const customProps = props.selectedLocationsCustomProps[node.node_id];

        return (
          node.type === LOCATION_KEY &&
          node.selected &&
          customProps &&
          node.show && (
            <Paper
              className={`location-node node ${!customProps.visibleOnGraph &&
                'invisible-node'} ${customProps.showThreshold && customProps?.visibleOnGraph && 'show-threshold'}`}
              dataTa={TEST_IDS.selected_location_node}
              key={node.node_id}
              onClick={onNodeClick(node)}
            >
              <span
                className="colored-line"
                style={{ backgroundColor: getAssignedChartColor(node.node_id, props.assignedChartColors) }}
              ></span>
              <Flex alignItems="center" justifyContent="space-between">
                <Flex alignItems="center">
                  <Icon
                    name={customProps.visibleOnGraph ? VaiIcon.VisibilityOn : VaiIcon.VisibilityOff}
                    color={customProps.visibleOnGraph ? VaiColor.AquaVaisala : VaiColor.GreyMedium2}
                    size={Size.M}
                    className="visibility-icon"
                    onClick={onNodeVisibilityToggle(node)}
                  />
                  {renderIcon(node)}
                  <TruncatedText text={node.name} className="vai-margin-left-s" />
                </Flex>

                <Icon
                  className="remove-icon"
                  name={VaiIcon.Cross}
                  color={VaiColor.AquaVaisala}
                  size={Size.M}
                  onClick={onNodeRemove(node)}
                />
              </Flex>
            </Paper>
          )
        );
      }
    });
  };

  return (
    <div className="selected-locations-wrapper" data-ta={TEST_IDS.selected_locations_list_wrapper}>
      {(isLoading || props.loadingAPI) && !props.isTesting ? (
        <CenteredSpinner />
      ) : Object.keys(props.visibleLocations).length > 0 ? (
        <div data-ta="selected-locations-list-inner-wrapper">{renderTree()}</div>
      ) : null}
    </div>
  );
};

const mapStateToProps = ({ reports }: StoreState) => ({
  locations: reports.locations,
  selectedFormattedLocations: reports.selectedFormattedLocations,
  selectedLocationsNum: reports.selectedLocationsNum,
  selectedLocationsIDs: reports.selectedLocationsIDs,
  selectedLocationsSKs: reports.selectedLocationsSKs,
  visibleLocations: reports.visibleLocations,
  assignedChartColors: reports.assignedChartColors,
  intervalDate: reports.intervalDate,
  isTesting: reports.isTesting,
  loadingAPI: reports.isApiLoading,
  selectedLocationsCustomProps: reports.selectedLocationsCustomProps
});

const mapDispatchToProps = (dispatch: any) => ({
  setReportsSelectedFormattedLocations: (locations: ReportState['selectedFormattedLocations']) =>
    dispatch(reportsDispatchActions.setReportSelectedFormattedLocations(locations)),
  setReportSelectedLocationsCustomProps: (locations: ReportState['selectedLocationsCustomProps']) =>
    dispatch(reportsDispatchActions.setReportSelectedLocationsCustomProps(locations)),
  setReportsVisibleLocations: (locations: ReportState['visibleLocations']) =>
    dispatch(reportsDispatchActions.setReportVisibleLocations(locations)),
  setReportsSelectedLocationsNum: (number: ReportState['selectedLocationsNum']) =>
    dispatch(reportsDispatchActions.setReportSelectedLocationsNum(number)),
  setReportSelectedLocationsIDs: (IDs: ReportState['selectedLocationsIDs']) =>
    dispatch(reportsDispatchActions.setReportSelectedLocationsIDs(IDs)),
  setReportSelectedLocationsSKs: (SKs: ReportState['selectedLocationsSKs']) =>
    dispatch(reportsDispatchActions.setReportSelectedLocationsSK(SKs)),
  setReportsAssignedChartColors: (colors: ReportState['assignedChartColors']) =>
    dispatch(reportsDispatchActions.setReportAssignedChartColors(colors)),
  setReportLoadingState: (loading: ReportState['isApiLoading']) =>
    dispatch(reportsDispatchActions.setReportLoadingState(loading))
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectedLocationsList);
