import { isString } from 'lodash';

export const validateCannotBeEmpty = (value: string | undefined) => {
  return isString(value) && value !== '';
};

export const validateCharacterMaxLength = (value: string, maxLength: number, optional?: boolean) => {
  if (optional && !value) {
    return true;
  }
  return value.length <= maxLength ? true : false;
};

export const validateCannotMatch = (value: string, match: string) => {
  return value !== match ? true : false;
};
