/**
 * Individual checkbox states
 */

export enum CheckboxState {
  UNCHECKED = 'a',
  UNCHECKED_INVERTED = 'b',
  CHECKED_INVERTED = 'c',
  CHECKED = 'd'
}

export const CHECKBOX_STATE_CLASSNAMES = {
  [CheckboxState.CHECKED_INVERTED]: 'checked-inverted',
  [CheckboxState.CHECKED]: 'checked',
  [CheckboxState.UNCHECKED]: 'unchecked',
  [CheckboxState.UNCHECKED_INVERTED]: 'unchecked-inverted'
};

export const CHECKBOX_STATE_CHECKED = {
  [CheckboxState.CHECKED_INVERTED]: true,
  [CheckboxState.CHECKED]: true,
  [CheckboxState.UNCHECKED]: false,
  [CheckboxState.UNCHECKED_INVERTED]: false
};
