import React from 'react';
import { DeviceState } from '../../../constants';
import { DeviceType, routes } from '../../../utils';
import { EmptyState } from '../../Utils/EmptyState/EmptyState';
import { IllustrationType } from '../../Utils/EmptyState/Illustration';
import { DeviceModel } from '../../../types';
import withDevice from '../../../hoc/withDevice';
import { QA_TEST_IDS } from '../../../tests/qaTestIds';
import DeviceManagerDataSources from './DeviceManagerDataSources';
import DeviceManagerStatusTitlebar from './DeviceManagerStatusTitlebar';
import GatewayStatus from './GatewayStatus';

interface DeviceManagerStatusProps {
  hasDevices: boolean;
  device: DeviceModel;
  onHighlight: (deviceId: string) => boolean;
  highlighted: string;
}

const DeviceManagerStatus: React.FC<DeviceManagerStatusProps> = ({ hasDevices, device, onHighlight, highlighted }) => {
  const isNewDevice: boolean = device.status.state === DeviceState.NewDevice;

  // Device specific status pages
  if (device.properties.attributes.productCode === DeviceType.AP10) {
    return (
      <>
        <DeviceManagerStatusTitlebar device={device} />
        <GatewayStatus device={device} onHighlight={onHighlight} highlighted={highlighted} />
      </>
    );
  }

  if (device.properties.attributes.productCode === DeviceType.RFL100) {
    return (
      <div className="device-manager-status">
        <DeviceManagerStatusTitlebar device={device} />
        {isNewDevice ? (
          <EmptyState
            htmlId="device-manager-empty-state"
            heading="deviceManager.noDataSource.heading"
            description="deviceManager.noDataSource.description"
            illustration={IllustrationType.emptyTable}
          />
        ) : (
          <DeviceManagerDataSources device={device} />
        )}
      </div>
    );
  }
  return <></>;
};

export default withDevice({
  htmlId: QA_TEST_IDS.device_status,
  route: routes.deviceManager.status.url,
  displayName: 'DeviceManagerStatus'
})(DeviceManagerStatus);
