import * as React from 'react';
import { Form, Heading, InputField, Label, Size, Button, ButtonType, Flex } from '@vaisala/rockhopper-components';
import { routes } from '../../utils/routes';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MFA_LENGTH } from '../../constants';
import { AuthError, MFAError } from '../../store/actions/auth';
import CenteredSpinner from '../BaseComponents/CenteredSpinner';

import './setup-mfa.scss';

interface SetUpMFAProps {
  setUpMFA: (mfa: string) => void;
  resendMFA: () => void;
  setAuthError: (error: MFAError | AuthError | undefined) => void;
  MFABackToLogin: () => void;
  error: string | number;
  loading: boolean;
}

const SetUpMFA = ({
  setUpMFA,
  resendMFA,
  setAuthError,
  MFABackToLogin,
  error,
  loading
}: SetUpMFAProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [mfa, setMfa] = React.useState('');

  React.useEffect(() => {
    const browserBackCb = () => MFABackToLogin();
    window.addEventListener('popstate', browserBackCb);
    // Remove auth error when component dismounts.
    return () => {
      window.removeEventListener('popstate', browserBackCb);
    };
  }, []);

  const onSubmitMFA = () => {
    // Guard against DOM manipulation of the disabled attribute
    if (canSend()) {
      setUpMFA(mfa);
    }
  };

  const goBack = () => {
    navigate(routes.login.url);
    MFABackToLogin();
  };

  const canSend = React.useCallback(() => {
    return mfa.length === MFA_LENGTH;
  }, [mfa]);

  const onMFAChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    error != null && setAuthError(undefined);
    const re = /^[0-9\b]+$/;
    if (value === '' || re.test(value)) {
      setMfa(value);
    }
  };

  const onMFAResend = () => {
    resendMFA();
  };

  return (
    <div id="mfa-wrapper">
      <Form htmlId="mfa-form">
        <Heading htmlId="mfa-title" level={2}>
          {t('mfa.title')}
        </Heading>
        <p id="mfa-explanation" className="mfa-explanation">
          {t('mfa.explanation')}
        </p>
        <Label id="mfa-code-label">{t('mfa.inputLabel')}</Label>
        <InputField required={true} htmlId="mfa-input-code" onChange={onMFAChange} width={Size.Container} value={mfa} />
        <Flex className="vai-margin-top-m">
          <Flex.Item alignSelf={'flex-start'} flexBasis={'70%'}>
            <Button
              id="mfa-button-resend"
              buttonType={ButtonType.Link}
              htmlId="mfa-button-resend"
              onClick={onMFAResend}
            >
              {t('mfa.resendButton')}
            </Button>
            <br />
            <Button id="mfa-button-back" buttonType={ButtonType.Link} htmlId="mfa-button-back" onClick={goBack}>
              {t('general.back')}
            </Button>
          </Flex.Item>
          <Flex.Item alignSelf={'flex-start'} flexBasis={'30%'}>
            <Button
              id="mfa-enter-button"
              className="vai-margin-top-l"
              htmlId="mfa-enter-button"
              onClick={onSubmitMFA}
              disabled={!canSend() || loading}
            >
              {loading ? <CenteredSpinner htmlId="save-button-spinner" className="" /> : t('mfa.enter')}
            </Button>
          </Flex.Item>
        </Flex>
      </Form>
    </div>
  );
};

export default SetUpMFA;
