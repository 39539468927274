import React from 'react';
import { isEqual } from 'lodash';
import { Button, ButtonType, Flex, Heading, Size } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import { SiteNode } from '../../../siteTree/site';
import { RightDetails } from '../../../store/services/userApi';
import CenteredSpinner from '../../BaseComponents/CenteredSpinner';
import { ModalContainer } from '../../Modals/ModalContainer';
import { RightsAccordionTree } from '../../RightsAccordionTree/RightsAccordionTree';
import { NO_RIGHTS } from '../../RightsAccordionTree/constants';
import { GroupRightsInfotip } from '../RightsCheckboxInfoTip/Infotip';
import { TEST_IDS } from '../../../tests/testids';
import './rights-dialog.scss';
import { RightDetailsNonNullable } from './common';

export interface Props {
  site: SiteNode;
  groupDetails: { name: string; id: string };
  rightDetails: RightDetailsNonNullable;
  isLoading?: boolean;
  savingDetails: {
    isSaving: boolean;
    isSaved: boolean;
    isSaveFailed: boolean;
  };
  onDismiss: () => void;
  onSave: (rightDetails: RightDetails) => void;
}

export const RightsDialogView: React.FC<Props> = ({
  onDismiss,
  onSave,
  savingDetails,
  groupDetails,
  site,
  rightDetails,
  isLoading
}) => {
  const { t } = useTranslation();

  const [updatedRightDetails, setUpdatedRightDetails] = React.useState<RightDetails>(rightDetails);

  React.useEffect(() => {
    setUpdatedRightDetails(rightDetails);
  }, [rightDetails]);

  React.useEffect(() => {
    if (savingDetails.isSaved) {
      onDismiss();
    }
  }, [savingDetails.isSaved]);

  const registerChanges = (rightDetails: RightDetails) => {
    const updatedRightDetails: RightDetails = { rights: {}, rightsGlobal: rightDetails.rightsGlobal };
    // Remove node ids with NO_RIGHTS mask
    for (const key in rightDetails.rights) {
      if (rightDetails.rights[key] !== NO_RIGHTS && updatedRightDetails.rights) {
        updatedRightDetails.rights[key] = rightDetails.rights[key];
      }
    }

    setUpdatedRightDetails(updatedRightDetails);
  };

  const modalButtons = (
    <>
      <Button
        id="groups-rights-edit-save-button"
        className="vai-space-inline-m"
        htmlId="groups-rights-edit-save-button"
        onClick={() => onSave(updatedRightDetails)}
        disabled={savingDetails.isSaving || isEqual(updatedRightDetails, rightDetails)}
      >
        {savingDetails.isSaving ? <CenteredSpinner htmlId="groups-rights-save-spinner" /> : t('general.save')}
      </Button>
      <Button
        className="vai-space-inline-m"
        id="groups-rights-edit-cancel-button"
        htmlId="groups-rights-edit-cancel-button"
        buttonType={ButtonType.Secondary}
        onClick={onDismiss}
      >
        {t('general.cancel')}
      </Button>
    </>
  );

  return (
    <ModalContainer
      id="edit-grouprights-modal"
      htmlId="edit-grouprights-modal"
      dataTa={TEST_IDS.edit_group_rights_modal}
      isOpen
      showCloseIcon
      width={Size.L}
      onDismiss={onDismiss}
      dismissOnOverlayClick={false}
      title="groupsRightsDialog.header"
      footerChildren={modalButtons}
    >
      <Flex id="groups-rights-flex-container" justifyContent="space-between">
        <Flex flexDirection="column">
          <Heading level={3} id="groups-rights-container-name" className="vai-font-size-m groups-rights-title">
            {groupDetails.name}
          </Heading>
          <Flex.Item id="groups-rights-flex-first">
            <div id="groups-rights-note" className="vai-font-size-xs vai-margin-bottom-m groups-rights-subtitle">
              {t('groupsRightsDialog.note')}
            </div>
          </Flex.Item>
        </Flex>
        <Flex.Item id="groups-rights-flex-second" alignSelf="center">
          <GroupRightsInfotip
            htmlId="groups-rights-help-icon"
            popoverProps={{ className: 'group-rights-infotip' }}
            iconProps={{ className: 'vai-margin-vertical-s vai-margin-left-m' }}
          />
        </Flex.Item>
      </Flex>
      <div className="scrollable-content">
        {isLoading ? (
          <CenteredSpinner htmlId="groups-rights-spinner" className="small" />
        ) : (
          <RightsAccordionTree
            expandedNodes={[site.node_id]}
            onChange={registerChanges}
            site={site}
            rightDetails={rightDetails ?? { rights: {}, rightsGlobal: NO_RIGHTS }}
          />
        )}
      </div>
    </ModalContainer>
  );
};
