import * as React from 'react';
import { DataTable, DataTableColumn, TableSortDirection } from '@vaisala/rockhopper-data-table';
import { Flex } from '@vaisala/rockhopper-components';
import { CHART_AXES_LABELS, DEVICES_SYMBOL_ID, LOCATION_KEY } from '../../../constants';
import './statistics-table.scss';
import { useTranslation } from 'react-i18next';
import { StoreState } from '../../../store';
import { connect } from 'react-redux';
import { getAssignedChartColor, sortLocationsOnTopOfZones } from '../../../utils';
import { LocationTreeFormattedNode } from '../Locations';
import { TEST_IDS } from '../../../tests/testids';
import CenteredSpinner from '../../BaseComponents/CenteredSpinner';
import { useResizeObserver } from '../../../hooks/useResizeObserver';

type StatisticsProps = ReturnType<typeof mapStateToProps>;

const NullOrValueFormatter: DataTableColumn['formatter'] = ({ value }: { value: number | null }) => {
  return value === null ? <span title="-">-</span> : <span title={`${value}`}>{value}</span>;
};

/**
 * Used to match cell.key as Union of specific strings instead of just string so translation types won't break.
 *
 * Update this list of keys to match headerCells.
 *
 * Const assertion would be better on the headerCells to not need this manual Union. But the datagrid library makes the headerCells mutable and that causes issues with as const as it's read-only.
 */
interface DataTableColumnStrictKey extends DataTableColumn {
  key: 'location' | 'unit' | 'mostRecent' | 'average' | 'minimum' | 'maximum';
}

type DataRow = {
  location: React.ReactNode;
  name: string;
  unit: string;
  mostRecent: string | null | undefined;
  average: string | null | undefined;
  minimum: string | null | undefined;
  maximum: string | null | undefined;
};

type DataRowKey = keyof DataRow;

const headerCells: DataTableColumnStrictKey[] = [
  { key: 'location', sortable: true, name: 'Location', width: 200 },
  { key: 'unit', sortable: true, name: 'Unit' },
  { key: 'mostRecent', sortable: true, name: 'Most recent', formatter: <NullOrValueFormatter /> },
  { key: 'average', sortable: true, name: 'Average', formatter: <NullOrValueFormatter /> },
  { key: 'minimum', sortable: true, name: 'Minimum', formatter: <NullOrValueFormatter /> },
  { key: 'maximum', sortable: true, name: 'Maximum', formatter: <NullOrValueFormatter /> }
];

const Statistics = (props: StatisticsProps): JSX.Element => {
  const { selectedFormattedLocations, assignedColors, selectedLocationsCustomProps, isLoading } = props;
  const { t } = useTranslation();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [, containerHeight] = useResizeObserver(containerRef, 400);

  const createRows = (data = selectedFormattedLocations, locations: DataRow[] = []) => {
    let selectedLocations = [...locations];

    sortLocationsOnTopOfZones(data).forEach((node: LocationTreeFormattedNode) => {
      if (Object.keys(node.children).length > 0) {
        selectedLocations = createRows(node.children, selectedLocations);
      } else if (node.type === LOCATION_KEY && node.selected) {
        selectedLocations.push({
          name: node.name,
          location: (
            <Flex className="location-wrapper" alignItems="center">
              <span
                className="colored-dot"
                style={{ backgroundColor: getAssignedChartColor(node.node_id, assignedColors) }}
              ></span>
              <span title={node.name} className="truncated-text">
                {node.name}
              </span>
            </Flex>
          ),
          unit: CHART_AXES_LABELS[node.symbol_id as DEVICES_SYMBOL_ID],
          ...(selectedLocationsCustomProps[node.node_id]
            ? {
                mostRecent:
                  typeof selectedLocationsCustomProps[node.node_id].most_recent === 'string'
                    ? selectedLocationsCustomProps[node.node_id].most_recent
                    : null,
                average:
                  typeof selectedLocationsCustomProps[node.node_id].average === 'string'
                    ? selectedLocationsCustomProps[node.node_id].average
                    : null,
                minimum:
                  typeof selectedLocationsCustomProps[node.node_id].minimum === 'string'
                    ? selectedLocationsCustomProps[node.node_id].minimum
                    : null,
                maximum:
                  typeof selectedLocationsCustomProps[node.node_id].maximum === 'string'
                    ? selectedLocationsCustomProps[node.node_id].maximum
                    : null
              }
            : {
                mostRecent: null,
                average: null,
                maximum: null,
                minimum: null
              })
        });
      }
    });

    return selectedLocations;
  };

  const [data, setData] = React.useState({ rows: createRows() });
  const [tableHeaders, setTableHeaders] = React.useState(headerCells);

  React.useEffect(() => {
    setData({ rows: createRows() });
    setTableHeaders(headerCells);
  }, [selectedLocationsCustomProps]);

  function rowGetter(rowIdx: number) {
    return data.rows[rowIdx];
  }

  const onSort = (column: string, direction: TableSortDirection) => {
    const typedColumn = column as DataRowKey;
    const multiplier = direction === TableSortDirection.Descending ? 1 : -1;
    const rows = data.rows.slice().sort((a, b) => {
      if (column === 'location') {
        return (a.name == b.name ? 0 : a.name < b.name ? 1 : -1) * multiplier;
      }
      const aVal = a[typedColumn];
      const bVal = b[typedColumn];
      if (aVal == null) {
        return 1;
      } else if (bVal == null) {
        return -1;
      }
      const ABAreEqual = aVal == bVal;
      const aLessThanBOperation = aVal < bVal ? 1 : -1;
      return (ABAreEqual ? 0 : aLessThanBOperation) * multiplier;
    });

    setData({ rows });
  };

  return (
    <Flex ref={containerRef} flexDirection="column" className="h-100" data-ta={TEST_IDS.statistics_wrapper}>
      {isLoading || !containerHeight ? (
        <CenteredSpinner />
      ) : (
        <DataTable
          columns={
            tableHeaders.map(cell => {
              return { ...cell, name: t(`reports.table.header.${cell.key}`) };
            }) as DataTableColumn[] // Assertion to please external library types, our names are always strings so this is safe
          }
          htmlId="statistics-table"
          rowGetter={rowGetter}
          rowsCount={data.rows.length}
          onSort={onSort}
          minColumnWidth={100}
          minHeight={containerHeight}
        />
      )}
    </Flex>
  );
};

const mapStateToProps = ({ reports }: StoreState) => ({
  selectedFormattedLocations: reports.selectedFormattedLocations,
  selectedLocationsCustomProps: reports.selectedLocationsCustomProps,
  assignedColors: reports.assignedChartColors,
  isLoading: reports.isApiLoading
});

export default connect(mapStateToProps)(Statistics);
