import { useEffect, useRef, RefObject } from 'react';

export function useOutsideClick<T extends HTMLElement>(callback: () => void): RefObject<T> {
  const ref = useRef<T>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.body.addEventListener('click', handleClick, { capture: true });

    return () => {
      document.body.removeEventListener('click', handleClick);
    };
  }, [ref]);

  return ref;
}
