import * as React from 'react';
import { connect } from 'react-redux';
import uPlot, { AlignedData } from 'uplot';
import { DEVICES_SYMBOL_ID } from '../../../constants';
import { reportsDispatchActions, StoreState } from '../../../store';
import Chart from '../Chart/Chart';
import { CustomProps, LocationCustomProps, LocationTreeFormattedNode } from '../Locations';
import { TEST_IDS } from '../../../tests/testids';
import makeTestId, { IMakeTestIdProps } from '../../../utils/makeTestId';

type GraphProps = { hideTimeNav?: boolean } & ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IMakeTestIdProps;

const Graph: React.FunctionComponent<GraphProps> = ({ dataTa, ...props }) => {
  const { getTestId } = makeTestId({ dataTa });
  const initialChartsData: {
    units: string[];
    locations: LocationTreeFormattedNode[];
  }[] = [
    {
      units: [],
      locations: []
    }
  ];

  const [chartsData, setChartsData] = React.useState<AlignedData[]>([]); // Defaults to 1 empty chart
  const [chartsUnits, setChartsUnits] = React.useState<string[][]>([]);
  const [chartsLocations, setChartsLocations] = React.useState<
    (LocationTreeFormattedNode | (CustomProps & { node_id: string }))[][]
  >([]);

  const [allLocations, setAllLocations] = React.useState<(CustomProps & { node_id: string })[]>([]);
  const [allUnits, setAllUnits] = React.useState<string[]>([]);
  const [syncKey, setSyncKey] = React.useState<uPlot.SyncPubSub | undefined>(undefined);

  const getAllLocationsAndUnits = (data: LocationCustomProps): [(CustomProps & { node_id: string })[], string[]] => {
    const locations: (CustomProps & { node_id: string })[] = [];
    const units: string[] = [];

    for (const key in data) {
      const location = data[key];
      const hasMeasurements = location.measurement_points && location.measurement_points.length;
      if (data[key].visibleOnGraph && hasMeasurements) {
        locations.push({ ...data[key], node_id: key });

        if (!units.includes(data[key].symbol_id)) {
          units.push(data[key].symbol_id);
        }
      }
    }

    return [locations, units];
  };

  const getDataForEachChart = (moreThanOne = true) => {
    // Get all timestamps from all locations for xAxis data
    const timestamps = new Set();
    allLocations.forEach(loc => {
      loc.measurement_points?.forEach(point => timestamps.add(point.t));
    });

    if (moreThanOne) {
      const chartsData: {
        units: string[];
        locations: (LocationTreeFormattedNode | (CustomProps & { node_id: string }))[];
      }[] = [...JSON.parse(JSON.stringify(initialChartsData)), ...JSON.parse(JSON.stringify(initialChartsData))];

      allUnits.map(unit => {
        const locations = allLocations.filter(loc => loc.symbol_id === unit);

        switch (unit) {
          case DEVICES_SYMBOL_ID.HUMIDITY:
            chartsData[0].locations = [...chartsData[0].locations, ...locations];
            chartsData[0].units = [...chartsData[0].units, unit];
            break;
          case DEVICES_SYMBOL_ID.CELSIUS:
            if (chartsData[0].units.length < 2 && !chartsData[0].units.includes(DEVICES_SYMBOL_ID.FAHRENHEIT)) {
              chartsData[0].locations = [...chartsData[0].locations, ...locations];
              chartsData[0].units = [...chartsData[0].units, unit];
            } else {
              chartsData[1].locations = [...chartsData[1].locations, ...locations];
              chartsData[1].units = [...chartsData[1].units, unit];
            }
            break;
          case DEVICES_SYMBOL_ID.FAHRENHEIT:
            if (chartsData[0].units.length < 2 && !chartsData[0].units.includes(DEVICES_SYMBOL_ID.CELSIUS)) {
              chartsData[0].locations = [...chartsData[0].locations, ...locations];
              chartsData[0].units = [...chartsData[0].units, unit];
            } else {
              chartsData[1].locations = [...chartsData[1].locations, ...locations];
              chartsData[1].units = [...chartsData[1].units, unit];
            }
            break;
          case DEVICES_SYMBOL_ID.CO2_PERCENT:
          case DEVICES_SYMBOL_ID.CO2_PPM:
            chartsData[1].locations = [...chartsData[1].locations, ...locations];
            chartsData[1].units = [...chartsData[1].units, unit];
            break;
          default:
            break;
        }
      });

      return chartsData;
    }
  };

  const determineChartsCount = () => {
    let chartsData: {
      units: string[];
      locations: (LocationTreeFormattedNode | (CustomProps & { node_id: string }))[];
    }[];

    if (
      allUnits.length > 2 ||
      (allUnits.includes(DEVICES_SYMBOL_ID.CELSIUS) && allUnits.includes(DEVICES_SYMBOL_ID.FAHRENHEIT))
    ) {
      props.setReportGraphsNum(2);
      chartsData = getDataForEachChart() || [];
      setChartsUnits([chartsData[0]?.units, chartsData[1]?.units]);
      setChartsLocations([chartsData[0].locations, chartsData[1].locations]);
    } else {
      props.setReportGraphsNum(1);
      getDataForEachChart(false);
      setChartsUnits([allUnits]);
      setChartsLocations([allLocations]);
    }
  };

  React.useEffect(() => {
    if (props.selectedLocationsNum > 0) {
      const [locations, units] = getAllLocationsAndUnits(props.selectedLocationsCustomProps);
      setAllLocations(locations);
      setAllUnits(units);
    }
  }, [props.selectedLocationsNum, props.selectedLocationsCustomProps]);

  React.useEffect(() => {
    // If the intervals (from, to, etc.) are changed, or the user selects different locations, reset chart data
    setChartsData([]);
    setChartsLocations([]);
    setChartsUnits([]);
  }, [props.intervalDate, props.selectedLocationsIDs]);

  React.useEffect(() => {
    if (allUnits.length > 0) {
      determineChartsCount();
    } else {
      setChartsData([]);
    }
  }, [allUnits]);

  React.useEffect(() => {
    if (chartsUnits.length > 0) {
      const newChartsData: AlignedData[] = [];

      chartsLocations.forEach(chart => {
        const tables: AlignedData[] = chart.map((location: any) => {
          const data: number[][] = [[], []];
          location.measurement_points.forEach((meas: { t: number; v: number }) => {
            data[0].push(meas.t);
            data[1].push(meas.v);
          });
          return data as AlignedData;
        });

        const nullModes: uPlot.JoinNullMode[][] = tables.map(t => t.map(s => 2));

        newChartsData.push(uPlot.join(tables, nullModes));
      });

      if (newChartsData.length > 1) {
        setSyncKey(uPlot.sync('syncKey'));
      } else {
        setSyncKey(undefined);
      }

      setChartsData(newChartsData);
    }
  }, [chartsUnits]);
  return (
    <>
      {chartsData.length > 0 ? (
        chartsData.map((data, ind) => (
          <div id={`chart-${ind}`} key={ind} data-ta={getTestId(TEST_IDS.populated_chart_instance)}>
            <Chart
              id={`chart-${ind}`}
              data={data}
              syncKey={syncKey}
              locations={chartsLocations[ind]}
              allLocations={chartsLocations.flat(1)}
              units={chartsUnits[ind]}
              hideTimeNav={props.hideTimeNav}
            />
          </div>
        ))
      ) : (
        <div id={`chart-empty`} data-ta={getTestId(TEST_IDS.chart_instance)}>
          <Chart
            id={`chart-empty`}
            hideTimeNav={props.hideTimeNav}
            data={[]}
            locations={[]}
            allLocations={[]}
            units={[]}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ reports }: StoreState) => ({
  selectedFormattedLocations: reports.selectedFormattedLocations,
  selectedLocationsNum: reports.selectedLocationsNum,
  intervalDate: reports.intervalDate,
  selectedLocationsIDs: reports.selectedLocationsIDs,
  selectedLocationsCustomProps: reports.selectedLocationsCustomProps
});

const mapDispatchToProps = (dispatch: any) => ({
  setReportGraphsNum: (num: number) => dispatch(reportsDispatchActions.setReportGraphsNum(num))
});

export default connect(mapStateToProps, mapDispatchToProps)(Graph);
