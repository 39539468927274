import { Button, ButtonType, Drawer, Icon, NavTabs, Stack } from '@vaisala/rockhopper-components';
import React, { useEffect, useState } from 'react';
import { SIDEBAR_ANCHOR, SIDEBAR_MOBILE_WIDTH, SIDEBAR_WIDTH, SOFTWARE_ASSURANCE_STATUS } from '../../constants';
import { Languages } from '../../i18n';
import { selectContent, SidebarContent, setSideBarContent, setIsOpen } from '../../store/sidebar';
import { TEST_IDS } from '../../tests/testids';
import Help from './content/Help';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import Profile from './content/Profile';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { useTranslation } from 'react-i18next';
import SideBarTab from './SideBarTab';
import './SideBar.scss';
import Tours from './content/Tours';
import SoftwareAssurance from './content/SoftwareAssurance';
import { AtlassianStatusApiResponse } from '../../store/services/atlassianStatusApi';
import { StatusIcons } from './StatusIcons';

type SidebarProps = {
  isOpen?: boolean;
  activeTab?: 'string';
  language?: Languages;
  statusResult: SOFTWARE_ASSURANCE_STATUS;
  status: AtlassianStatusApiResponse | undefined;
};

const Sidebar: React.FC<SidebarProps> = ({ isOpen = false, language = Languages.en, statusResult, status }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const ResultIcon = StatusIcons[statusResult];

  useEffect(() => {
    const handleResize = () => {
      // Check if the current state matches the new condition
      // Only call setIsMobile if the condition has changed
      const shouldSetMobile = window.innerWidth < 768;
      if (isMobile !== shouldSetMobile) {
        setIsMobile(shouldSetMobile);
      }
    };

    window.addEventListener('resize', handleResize);
    // Call handleResize initially to set the correct state
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]); // Add isMobile to the dependency array
  const selectedContent = useAppSelector(selectContent);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const tabs = [
    {
      key: SidebarContent.SOFTWARE_ASSURANCE,
      title: t('general.csa'),
      content: <SoftwareAssurance statusResult={statusResult} status={status} />,
      buttonTestId: TEST_IDS.sidebar_hide_button,
      tabTestId: TEST_IDS.sidebar_software_assurance_tab,
      icon: VaiIcon.Monitor
    },
    {
      key: SidebarContent.HELP,
      title: t('general.help'),
      content: <Help language={language} />,
      buttonTestId: TEST_IDS.sidebar_hide_button,
      tabTestId: TEST_IDS.sidebar_help_tab,
      icon: VaiIcon.HelpO
    },
    {
      key: SidebarContent.TOURS,
      title: t('header.tours', { count: 0 }),
      content: <Tours />,
      buttonTestId: TEST_IDS.sidebar_hide_button,
      tabTestId: TEST_IDS.sidebar_tour_tab,
      icon: VaiIcon.Tour
    },
    {
      key: SidebarContent.PROFILE,
      title: t('general.profile'),
      content: <Profile />,
      buttonTestId: TEST_IDS.sidebar_hide_button,
      tabTestId: TEST_IDS.sidebar_profile_tab,
      icon: VaiIcon.User
    }
  ];

  return (
    <Drawer
      dataTa={TEST_IDS.sidebar_drawer}
      isOpen={isOpen}
      anchor={SIDEBAR_ANCHOR}
      width={isMobile ? SIDEBAR_MOBILE_WIDTH : SIDEBAR_WIDTH}
      animated={true}
    >
      <NavTabs
        selectedTabKey={selectedContent}
        onSelectedTabChanged={selectedTabKey => dispatch(setSideBarContent(selectedTabKey))}
        htmlId="drawer-tabs"
      >
        {tabs.map(tab => (
          <NavTabs.Tab
            dataTa={tab.tabTestId}
            key={tab.key}
            tabKey={tab.key}
            title={
              <>
                <SideBarTab icon={tab.icon} title={tab.title} />
                {tab.icon === VaiIcon.Monitor && <ResultIcon className="sidebar-status-icon" />}
              </>
            }
          >
            <Stack>
              <Button
                buttonType={ButtonType.Link}
                endIcon={<Icon name={VaiIcon.DoubleChevronRight} />}
                onClick={() => dispatch(setIsOpen(false))}
                dataTa={tab.buttonTestId}
                className="sidebar__hide-button"
              >
                {t('general.hide')}
              </Button>
              <Stack className="vai-space-inset-m sidebar__tab-content">{tab.content}</Stack>
            </Stack>
          </NavTabs.Tab>
        ))}
      </NavTabs>
    </Drawer>
  );
};

export default Sidebar;
