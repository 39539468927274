import { API_USER_MANAGEMENT_BASE_URI } from '../../constants';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { UserApiUris, replaceTemplateValues } from '../../utils';
import { updateHeaders } from './utils';
import { Buffer } from 'buffer';
import { Right } from '../../profile/group';
import { RightsInterface } from '../../profile/user';

type GetGroupsApiArgs = {
  customerId: string;
  parent_id: string;
  include_rights: boolean;
};

type GetGroupApiArgs = {
  customer_id: string;
  group_id: string;
  include_rights: boolean;
};

type ResponseWithSuccess = {
  success: boolean;
  accepted?: string[];
  rejected?: string[];
};

type DeleteGroupUsersResponse = ResponseWithSuccess;
type UpdateGroupRightsResponse = ResponseWithSuccess;
type AcivateGroupResponse = ResponseWithSuccess;
type ActivateUserResponse = ResponseWithSuccess;
type DeleteUserResponse = ResponseWithSuccess;

type DeleteUserArgs = {
  customerId: string;
  username: string;
};

type DeleteGroupUsersArgs = {
  customerId: string;
  groupId: string;
  email: string;
};

export type UpdateGroupRightsArgs = {
  customerId: string;
  groupId: string;
  rights: Right;
  rights_global: number;
};

export type ActivateGroupArgs = {
  customerId: string;
  groupId: string;
  active: boolean;
  name: string;
};

export type ActivateUserArgs = {
  customerId: string;
  active: boolean;
  userName: string;
  fullName?: string;
};

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_USER_MANAGEMENT_BASE_URI,
    prepareHeaders: async headers => {
      headers = await updateHeaders(headers);
      return headers;
    }
  }),
  endpoints: builder => ({
    getGroups: builder.query<GetGroupsResponse, GetGroupsApiArgs>({
      query: ({ customerId, parent_id, include_rights }) => {
        return {
          url: `${UserApiUris.companyGroups.replace('{customer_id}', customerId)}`,
          params: {
            node_id: parent_id,
            include_rights
          }
        };
      }
    }),
    getGroup: builder.query<GetGroupResponse, GetGroupApiArgs>({
      query: ({ customer_id, group_id, include_rights }) => {
        return {
          url: replaceTemplateValues(UserApiUris.groupsDetails, { group_id, customer_id }),
          params: {
            include_rights
          }
        };
      }
    }),
    updateGroupRights: builder.mutation<UpdateGroupRightsResponse, UpdateGroupRightsArgs>({
      query: ({ customerId, groupId, rights, rights_global }) => {
        return {
          url: replaceTemplateValues(UserApiUris.editGroup, { customer_id: customerId, group_id: groupId }),
          method: 'PATCH',
          body: { rights, rights_global }
        };
      }
    }),
    deleteUserGroups: builder.mutation<DeleteGroupUsersResponse, DeleteGroupUsersArgs>({
      query: ({ customerId, groupId, email }) => {
        // TODO: When BE refactors to allow multiple groupIds to be sent, this endpoint and groupId parameter will need updated.
        return {
          url: replaceTemplateValues(UserApiUris.deleteUsersFromGroup, { customer_id: customerId, group_id: groupId }),
          method: 'POST',
          body: { users: [email] }
        };
      }
    }),
    activateGroup: builder.mutation<AcivateGroupResponse, ActivateGroupArgs>({
      query: ({ customerId, groupId, active }) => {
        return {
          url: replaceTemplateValues(UserApiUris.groupActivation, { customer_id: customerId, group_id: groupId }),
          method: 'PATCH',
          body: { active }
        };
      }
    }),
    activateUser: builder.mutation<ActivateUserResponse, ActivateUserArgs>({
      query: ({ customerId, active, userName }) => {
        const encodedEmail = btoa(userName);
        return {
          url: replaceTemplateValues(UserApiUris.userActivation, { customer_id: customerId, user_name: encodedEmail }),
          method: 'PATCH',
          body: { active }
        };
      }
    }),
    deleteUser: builder.mutation<DeleteUserResponse, DeleteUserArgs>({
      query: ({ customerId, username }) => {
        return {
          url: replaceTemplateValues(UserApiUris.deleteUser, {
            user_name: Buffer.from(username).toString('base64'),
            customer_id: customerId
          }),
          method: 'DELETE'
        };
      }
    })
  })
});

export interface Group {
  group_id: string;
  group_name: string;
  created: number;
  rights: RightsInterface | null;
  description: string;
  active: boolean;
  rights_global: number | null;
  users: {
    user_name: string;
    active: boolean;
  }[];
  changed: number;
}

export interface GetGroupsResponse {
  groups: Group[];
}
export type GetGroupResponse = Group;

export type RightDetails = {
  rights: GetGroupResponse['rights'] | undefined;
  rightsGlobal: GetGroupResponse['rights_global'] | undefined;
};

export const {
  useGetGroupsQuery,
  useGetGroupQuery,
  useDeleteUserMutation,
  useDeleteUserGroupsMutation,
  useActivateGroupMutation,
  useActivateUserMutation,
  useUpdateGroupRightsMutation
} = userApi;
