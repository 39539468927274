import React from 'react';
import { Icon, IconProps, Size } from '@vaisala/rockhopper-components';
import { VaiColor, VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { ALARM_PRIORITY, ALARM_THRESHOLD_TYPE } from '../../../../constants';
import cx from 'classnames';
import './alarmIcons.scss';

export const AlarmPriorityIcons: Record<ALARM_PRIORITY, React.FC<IconProps>> = {
  [ALARM_PRIORITY.CRITICAL]: (props: IconProps) => (
    <Icon name={VaiIcon.AlertAlarm} size={Size.M} color={VaiColor.Red} {...props} />
  ),
  [ALARM_PRIORITY.MODERATE]: (props: IconProps) => (
    <Icon name={VaiIcon.Warning} size={Size.M} color={VaiColor.Orange} {...props} />
  ),
  [ALARM_PRIORITY.INFO]: (props: IconProps) => (
    <Icon name={VaiIcon.AlertInfo} size={Size.M} color={VaiColor.AlertInfo} {...props} />
  )
};

export const AlarmThresholdIcons: Record<ALARM_THRESHOLD_TYPE, React.FC<IconProps>> = {
  [ALARM_THRESHOLD_TYPE.HIHI]: (props: IconProps) => {
    const { className, name, ...rest } = props;
    const classes = cx('rotate-270', className);
    return <Icon className={classes} name={VaiIcon.FastForward} size={Size.XS} {...rest} />;
  },
  [ALARM_THRESHOLD_TYPE.HI]: (props: IconProps) => <Icon name={VaiIcon.TriangleUp} size={Size.M} {...props} />,
  [ALARM_THRESHOLD_TYPE.LO]: (props: IconProps) => <Icon name={VaiIcon.TriangleDown} size={Size.M} {...props} />,
  [ALARM_THRESHOLD_TYPE.LOLO]: (props: IconProps) => {
    const { className, name, ...rest } = props;
    const classes = cx('rotate-90', className);
    return <Icon className={classes} name={VaiIcon.FastForward} size={Size.XS} {...rest} />;
  }
};
