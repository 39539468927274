import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { AppcuesFlow, AppcuesTag } from '../store/services/appcuesApi';

export type GroupedFlows = {
  group: string;
  flows: AppcuesFlow[];
};

export const filterPublishedFlows = (flows: AppcuesFlow[]) => {
  return flows.filter(flow => flow.published === true);
};

export const filterTagsByGroup = (tags: AppcuesTag[]) => {
  return tags.filter(tag => tag.name.startsWith('g_'));
};
export const filterTagsByOrder = (tags: AppcuesTag[]) => {
  return tags.filter(tag => tag.name.startsWith('o_'));
};

/**
 * Return a list of groups with flows grouped by the group tags
 *
 * @param flows: List of flows
 * @param tags: List of tags that start with "g_"
 */
export const groupFlowsByTags = (flows: AppcuesFlow[], tags: AppcuesTag[]) => {
  return tags.map(tag => {
    return {
      group: tag.name,
      flows: flows.filter(flow => flow.tag_ids.includes(tag.id))
    };
  });
};

/**
 * Sort the groups by the last character of the group name (e.g. "g_location_1, g_devices_2")
 */
export const orderGroupTags = (groupTags: GroupedFlows[]) => {
  return groupTags.sort((a, b) => a.group.slice(-1).localeCompare(b.group.slice(-1)));
};

/**
 * Sort the flows by the order
 *
 */
export const orderFlowsByOrderTag = (flows: AppcuesFlow[], tags: AppcuesTag[]) => {
  // Create a mapping of tag ID to its order value, but only for tags that start with "o_"
  const tagOrderMap: { [key: string]: string } = {};

  const orderTags = filterTagsByOrder(tags);

  orderTags.forEach(tag => {
    const orderTag = tag.name.split('_')[1]; // Extract the order part after "o_"
    tagOrderMap[tag.id] = orderTag;
  });

  // Filter the flows that have at least one order tag, and sort them by the order value.
  return flows
    .filter(flow => flow.tag_ids.some(tagId => tagId in tagOrderMap))
    .sort((a, b) => {
      const orderA = a.tag_ids.map(tagId => tagOrderMap[tagId]).find(order => order) ?? '';
      const orderB = b.tag_ids.map(tagId => tagOrderMap[tagId]).find(order => order) ?? '';
      return orderA.localeCompare(orderB);
    });
};

export const filterTagsByIcon = (tags: AppcuesTag[]) => {
  return tags.filter(tag => tag.name.startsWith('i_'));
};

export const createIconMap = (tags: AppcuesTag[]) => {
  const iconMap: { [key: string]: string } = {};
  const iconTags = filterTagsByIcon(tags);

  iconTags.forEach(tag => {
    iconMap[tag.id] = tag.name;
  });

  return iconMap;
};
export const getFlowIconName = (flow: AppcuesFlow, icons: { [key: string]: string }): keyof typeof VaiIcon => {
  const tagId = flow.tag_ids.find(tagId => tagId in icons);
  const icon = tagId ? icons[tagId] : 'i_Tour';

  return icon.split('_')[1] as keyof typeof VaiIcon;
};
