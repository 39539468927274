import React from 'react';
import { Form, Select, SelectProps, Size } from '@vaisala/rockhopper-components';
import { TEST_IDS } from '../../../../tests/testids';
import { getErrorClass } from '../../../../utils';
import { unitTypes } from '../../../../data/unitTypes';
import { FormActionType, UnitTypeLabels } from '../../shared';
import { useTranslation } from 'react-i18next';

interface Props {
  onChange: SelectProps<UnitTypeLabels>['onChange'];
  label: string;
  value: string | undefined;
  errorMessage: string;
  measurementType: keyof typeof unitTypes;
  action: FormActionType;
}

export const UnitTypes: React.FC<Props> = ({ label, onChange, value, errorMessage, measurementType, action }) => {
  const { t } = useTranslation();
  return (
    <Form.Item
      label={label}
      component={Select}
      required={true}
      id={`${action}-zone-and-location-dialog-unit-type`}
      defaultValue={t('form.selectPlaceholder')}
      value={value}
      width={Size.M}
      onChange={onChange}
      className={getErrorClass(errorMessage)}
      data-ta={TEST_IDS.site_tree_form_dialog_unitType_field}
    >
      {unitTypes[measurementType] &&
        unitTypes[measurementType].map(option => (
          <Select.Option
            key={option.value}
            title={option.label}
            value={option.value}
            id={`${action}-zone-and-location-dialog-${option.value}`}
          >
            {t(`site.${option.label as UnitTypeLabels}`)}
          </Select.Option>
        ))}
    </Form.Item>
  );
};
