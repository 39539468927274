// TODO: Create enums for unti types

export type UnitTypeLabels = 'celsius' | 'fahrenheit' | 'rh' | 'percent' | 'partsPerMillion';

export enum MeasurementTypesEnum {
  relativeHumidity = 'humidity',
  temperature = 'temperature',
  co2 = 'co2'
}

export enum FormActionType {
  EDIT = 'edit',
  CREATE = 'create'
}
