import React from 'react';
import { BodyText, Heading, Button, ButtonType, Separator } from '@vaisala/rockhopper-components';
import { AlarmThresholdIcons } from '../../../../Alarms/shared/AlarmIcons';
import { ALARM_THRESHOLD_TYPE } from '../../../../../../constants';
import { useTranslation } from 'react-i18next';
import { QA_TEST_IDS } from '../../../../../../tests/qaTestIds';
import { TEST_IDS } from '../../../../../../tests/testids';
import './threshold.scss';

interface ContextualHelpProps {
  showHelpContext: () => void;
}

export const ContextualHelp: React.FC<ContextualHelpProps> = ({ showHelpContext }) => {
  const { t } = useTranslation();
  const HiHiThresholdIcon = AlarmThresholdIcons[ALARM_THRESHOLD_TYPE.HIHI];
  const HiThresholdIcon = AlarmThresholdIcons[ALARM_THRESHOLD_TYPE.HI];
  const LowLowThresholdIcon = AlarmThresholdIcons[ALARM_THRESHOLD_TYPE.LOLO];
  const LowThresholdIcon = AlarmThresholdIcons[ALARM_THRESHOLD_TYPE.LO];

  return (
    <div className="contextual-help">
      <Separator />
      {/* eslint-disable-next-line enforce-ids-in-jsx/missing-ids */}
      <Button
        className="hide-contextual-help-button"
        id={QA_TEST_IDS.hide_contextual_help_button}
        dataTa={TEST_IDS.hide_contextual_help_button}
        onClick={showHelpContext}
        buttonType={ButtonType.Secondary}
      >
        {t('general.hide')}
      </Button>
      <Heading level={3}>{t('site.alarms.settings.threshold.form.contextualHelp.title')}</Heading>
      <BodyText>
        <b>{t('site.alarms.settings.threshold.form.contextualHelp.activate.title')}: </b>
        {t('site.alarms.settings.threshold.form.contextualHelp.activate.text')}
      </BodyText>
      <BodyText>
        <b>{t('site.alarms.settings.threshold.form.contextualHelp.value.title')}: </b>
        {t('site.alarms.settings.threshold.form.contextualHelp.value.text')} <HiHiThresholdIcon />(
        {t('site.alarms.threshold.level.HighHigh')}), <HiThresholdIcon />({t('site.alarms.threshold.level.High')}),{' '}
        <LowLowThresholdIcon />({t('site.alarms.threshold.level.LowLow')}), <LowThresholdIcon />(
        {t('site.alarms.threshold.level.Low')}).
      </BodyText>
      <BodyText>
        <b>{t('site.alarms.settings.threshold.form.contextualHelp.acknowledgement.title')}: </b>
        {t('site.alarms.settings.threshold.form.contextualHelp.acknowledgement.text')}
      </BodyText>
      <BodyText>
        <b>{t('site.alarms.settings.threshold.form.contextualHelp.delay.title')}: </b>
        {t('site.alarms.settings.threshold.form.contextualHelp.delay.text')}
      </BodyText>
    </div>
  );
};
