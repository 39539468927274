import { MeasurementTypesEnum } from '../components/SiteTree/shared';

export const unitTypes = {
  [MeasurementTypesEnum.temperature]: [
    { value: 'celsius', label: 'celsius' },
    { value: 'fahrenheit', label: 'fahrenheit' }
  ],
  [MeasurementTypesEnum.relativeHumidity]: [{ value: 'rh', label: 'rh' }],
  [MeasurementTypesEnum.co2]: [
    { value: 'percent', label: 'percent' },
    { value: 'ppm', label: 'partsPerMillion' }
  ]
};
