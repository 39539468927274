import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition
} from '@reduxjs/toolkit/dist/query';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { Auth } from 'aws-amplify';

export const updateHeaders = async (headers: Headers): Promise<Headers> => {
  headers.set('Accept', 'application/json');
  headers.set('Content-Type', 'application/json');
  headers.set('Accept-Encoding', 'gzip, deflate, br');

  const currentCredentials = await Auth.currentSession();

  if (currentCredentials) {
    headers.set('Authorization', currentCredentials.getIdToken().getJwtToken());
  }
  return headers;
};

export type GenericMutationTrigger<T, K extends string, P extends string> = MutationTrigger<
  MutationDefinition<
    T,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, never>, FetchBaseQueryMeta>,
    K,
    any,
    P
  >
>;
