import * as React from 'react';
import { Heading } from '@vaisala/rockhopper-components';
import { Link } from 'react-router-dom';
import { routes } from '../../utils/routes';
import { useTranslation } from 'react-i18next';

const PasswordReset = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div>
      <Heading id="password-rest-title" level={2} className="reset-password-title">
        {t('auth.passwordResetTitle')}
      </Heading>
      <p id="password-reset-help" className="vai-margin-left-none">
        {t('auth.passwordResetHelp')}
      </p>
      <div id="password-reset-back-link-container" className="app-login-submit vai-margin-top-m">
        <Link id="password-reset-back-link" to={routes.login.url}>
          {t('general.back')}
        </Link>
      </div>
    </div>
  );
};

export default PasswordReset;
