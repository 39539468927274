import React from 'react';
import { BodyText, Grid } from '@vaisala/rockhopper-components';
import { ALARM_THRESHOLD_TYPE, DEVICE_ALARM_RULE_NAMES } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import { QA_TEST_IDS } from '../../../../tests/qaTestIds';
import { TEST_IDS } from '../../../../tests/testids';
import './displayRow.scss';

interface Props {
  groupsDetails: { name: string; numberOfUsers: number }[];
  level: ALARM_THRESHOLD_TYPE | DEVICE_ALARM_RULE_NAMES;
  alarmValueContent: React.ReactNode;
}

export const DisplayRow: React.FC<Props> = ({ level, groupsDetails, alarmValueContent }) => {
  const { t } = useTranslation();
  const groupId = `${QA_TEST_IDS.alarms_settings_threshold_view_level_group_$level}${level}`;
  const groupNameId = QA_TEST_IDS.alarms_settings_threshold_view_level_group_name;
  const groupNumberOfUsersId = QA_TEST_IDS.alarms_settings_threshold_view_level_group_numberofUser;

  return (
    <Grid.Row dataTa={TEST_IDS.alarm_settings_display_row} className="alarm-display-row">
      <Grid.Col md={7} lg={6} xl={4}>
        {alarmValueContent}
      </Grid.Col>
      <Grid.Col md={6} lg={6} xl={8}>
        {groupsDetails.length > 0 && (
          <Grid.Row>
            <Grid.Col>
              <BodyText className="grey-medium alarm-notification-heading">
                {t('site.alarms.threshold.level.display.value.notificationMethod')}
              </BodyText>
              <BodyText>{t('general.email')}</BodyText>
            </Grid.Col>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Col>
            <BodyText className="grey-medium alarm-notification-heading">
              {t('site.alarms.threshold.level.display.value.groupsToBeNotifed', { number: groupsDetails.length })}
            </BodyText>
            {groupsDetails.length > 0
              ? groupsDetails.map(group => {
                  return (
                    <Grid.Row id={groupId} key={group.name}>
                      <Grid.Col className="text-ellipsis" id={groupNameId} xl={4}>
                        {group.name}
                      </Grid.Col>
                      <Grid.Col id={groupNumberOfUsersId} xl={4}>
                        {`${group.numberOfUsers} ${group.numberOfUsers > 1 ? t('profile.users') : t('profile.user')}`}
                      </Grid.Col>
                    </Grid.Row>
                  );
                })
              : t('site.alarms.threshold.level.display.value.noGroupToNotify')}
          </Grid.Col>
        </Grid.Row>
      </Grid.Col>
    </Grid.Row>
  );
};
