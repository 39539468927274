import * as React from 'react';
import {
  InputField,
  Size,
  Button,
  Select,
  ButtonType,
  Icon,
  Flex,
  Table,
  TableStyle,
  TableBody,
  TableRow,
  TableCell,
  Separator,
  Checkbox,
  FlexItem
} from '@vaisala/rockhopper-components';
import { Languages } from '../../../i18n';
import { InvitedUsersInterface, GroupInterface } from '../../../profile/user';
import UserRejectionDialog from '../UserRejectionDialog';
import { INVITE_USER_FROM_USER } from '../../../constants';
import { LANGUAGE_TEXT } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../store/hooks';
import { hideDialog, showDialog } from '../../../store/dialog';
import { ModalContainer } from '../../Modals/ModalContainer';
import useEmails from '../../../hooks/useEmails';
import Infotip from '../../Utils/Infotip';
import { TEST_IDS } from '../../../tests/testids';

import './invite-users.scss';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';

interface InviteUsersDialogProps {
  locale: string;
  inviteUsers: (users: InvitedUsersInterface, dispatcher: string, name?: string) => string[];
  groups: GroupInterface[];
  rejectedUsers: string[];
}

const InviteUsersDialog = (props: InviteUsersDialogProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchCriteria, setSearchCriteria] = React.useState<string>('');
  const [foundGroups, setFoundGroups] = React.useState<GroupInterface[]>([]);
  const [selectedLanguage, setSelectedLanguage] = React.useState<Languages>(props.locale as Languages);
  const [groupSelected, isGroupSelected] = React.useState<boolean>(false);
  const [saving, setSaving] = React.useState<boolean>(false);
  const { validEmails, renderTagInput } = useEmails();

  const onDismiss = () => dispatch(hideDialog());

  const initializeGroups = () => {
    const temp: GroupInterface[] = [];
    props.groups.map(group => {
      temp.push({ ...group, checked: false });
    });
    setFoundGroups(temp);
  };

  React.useEffect(() => {
    initializeGroups();
  }, [props.groups]);

  const onLanguageChange = (value: Languages): void => {
    setSelectedLanguage(value);
  };

  const filter = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    const keyword = e.currentTarget.value;
    if (keyword !== '') {
      const results = foundGroups.filter(group => {
        return group.group_name?.toLowerCase().includes(keyword.toLowerCase());
      });
      setFoundGroups(results);
    } else {
      initializeGroups();
    }
    setSearchCriteria(keyword);
  };

  const checkBoxChangeHandler = (index: number) => {
    const temp = [...foundGroups];
    temp[index].checked = !temp[index].checked;
    setFoundGroups(temp);
    isGroupSelected(false);
    temp.map(group => {
      if (group.checked) {
        isGroupSelected(true);
        return;
      }
    });
  };

  const inviteUsers = async () => {
    setSaving(true);
    const selectedGroupIds: string[] = [];
    foundGroups.map(group => {
      if (group.checked && group.group_id) {
        selectedGroupIds.push(group.group_id);
      }
    });
    const invitedUsers: InvitedUsersInterface['users'] = [];
    validEmails.map(email => {
      const invitedUser = {
        user_name: email,
        locale: selectedLanguage,
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        group_ids: selectedGroupIds
      };
      invitedUsers.push(invitedUser);
    });
    const users: InvitedUsersInterface = {
      users: invitedUsers
    };

    const rejectedUsers = await props.inviteUsers(users, INVITE_USER_FROM_USER);
    setSaving(false);
    if (!rejectedUsers || rejectedUsers.length > 0) {
      dispatch(
        showDialog(
          <UserRejectionDialog
            inviteUsers={props.inviteUsers}
            rejectedUsers={rejectedUsers}
            selectedGroupIds={selectedGroupIds}
            selectedLanguage={selectedLanguage}
          />
        )
      );
    } else {
      onDismiss();
    }
  };

  const modalButtons = (
    <>
      <Button
        id="invite-users-invite-button"
        className="vai-space-inline-s"
        htmlId="invite-users-invite-button"
        onClick={inviteUsers}
        disabled={!groupSelected || validEmails.length === 0 || saving}
        dataTa={TEST_IDS.users_and_groups_invite_user_dialog_invite_button}
      >
        {t('inviteUsers.invite')}
      </Button>
      <Button
        id="invite-users-cancel-button"
        htmlId="invite-users-cancel-button"
        buttonType={ButtonType.Secondary}
        onClick={onDismiss}
      >
        {t('inviteUsers.cancel')}
      </Button>
    </>
  );

  return (
    <ModalContainer
      id="invite-users-dialog"
      isOpen
      htmlId="invite-users-dialog"
      showCloseIcon={true}
      onDismiss={onDismiss}
      width={Size.L}
      dismissOnOverlayClick={false}
      title="inviteUsers.inviteUsersTitle"
      iconName={VaiIcon.User}
      buttons={modalButtons}
      dataTa={TEST_IDS.users_and_groups_invite_user_dialog}
    >
      <Flex id="invite-users-container" className="invite-users-container">
        <Flex flexDirection="column">
          <Flex.Item id="invite-users-left-container" alignSelf={'flex-start'} flexBasis={'100%'}>
            <span id="invite-users-email-field-help-text">{t('inviteUsers.enterEmail')}</span>
            <Flex>
              <FlexItem className="margin-auto" flexGrow={1}>
                <div id="invite-users-email-field-container" className="invite-users-email-invite-container">
                  {renderTagInput({ htmlId: 'invite-users-add-email-input', placeholder: t('inviteUsers.email') })}
                </div>
              </FlexItem>
              <FlexItem>
                <Infotip
                  htmlId="invite-users-email-infotip"
                  iconProps={{ size: Size.L, className: 'vai-margin-horizontal-xs', style: { marginTop: '16px' } }}
                >
                  {t('infotip.emailInput')}
                </Infotip>
              </FlexItem>
            </Flex>
            <div id="invite-users-language-field-container" style={{ marginTop: 'auto' }}>
              <span id="invite-users-language-field-help-text">{t('inviteUsers.invitationLanguage')}</span>
              <Select
                id="invite-users-language-select"
                htmlId="invite-users-language-select"
                placeholder={t('general.selectEllipsis')}
                showSearch
                filterOption={true}
                optionFilterProp="title"
                value={selectedLanguage}
                onChange={onLanguageChange}
                width={Size.Container}
              >
                {Object.entries(LANGUAGE_TEXT).map(([key, value]) => (
                  <Select.Option key={key} title={value} value={key} id={`invite-users-language-select-option-${key}`}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Flex.Item>
        </Flex>
        <Flex.Item
          id="invite-users-vertical-separator-container"
          alignSelf={'center'}
          flexBasis={'2%'}
          style={{ height: '100%' }}
        >
          <hr id="invite-users-vertical-separator" className="vertical-separator" />
        </Flex.Item>
        <Flex.Item
          id="invite-users-right-container"
          alignSelf={'flex-end'}
          flexBasis={'49%'}
          style={{ height: '100%', maxWidth: '50%' }}
        >
          <div id="invite-users-search-groups-container" className="invite-users-field-container">
            <span id="invite-users-search-groups-help-text">{t('inviteUsers.addUserToGroups')}</span>
            <br />
            <InputField
              htmlId="invite-users-search-groups-input"
              name="search"
              className="vai-margin-top-m"
              width={Size.Container}
              placeholder={t('general.search')}
              value={searchCriteria}
              onChange={filter}
              data-ta={TEST_IDS.users_and_groups_invite_user_dialog_groups_list_search_field}
              endIcon={<Icon htmlId="invite-users-search-groups-input-icon" name={VaiIcon.Search} size={Size.M} />}
            />
          </div>
          <br />
          <div
            data-ta={TEST_IDS.users_and_groups_invite_user_dialog_groups_list}
            id="invite-users-groups-container"
            className="invite-users-groups-container"
          >
            <Table htmlId="invite-users-groups-table" tableStyle={TableStyle.Plain} className="user-and-group-table">
              <TableBody>
                {foundGroups.map((row, index) => {
                  // NOTE: Linting error for missing ids if this is defined inline.
                  const cbId = `invite-users-group-checkbox__${row.group_id}`;
                  return (
                    <TableRow htmlId={`invite-users-group-tr__${row.group_id}`} key={`invite-user-group-tr__${index}`}>
                      <TableCell htmlId={`invite-users-group-td__${row.group_id}`} className="aqua-medium">
                        <Checkbox
                          id={cbId}
                          htmlId={cbId}
                          dataTa="checkbox"
                          checked={row.checked}
                          label={
                            <span title={row.group_name} className="invite-user-group-label">
                              {row.group_name}
                            </span>
                          }
                          onChange={() => checkBoxChangeHandler(index)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <Separator />
        </Flex.Item>
      </Flex>
    </ModalContainer>
  );
};

export default InviteUsersDialog;
