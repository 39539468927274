import uPlot from 'uplot';
import { convertLocalTimeToUTC, convertToMiliSeconds } from '../../../utils';

interface ReportsDates {
  intervalDate: {
    from: Date;
    to: Date;
  };
}

export function getDefaultOptions({
  reports,
  syncKey,
  xAxisOptions,
  yAxisOptions,
  timezone
}: {
  reports: ReportsDates;
  syncKey: any;
  xAxisOptions: any;
  yAxisOptions: any;
  timezone: string;
}): uPlot.Options {
  return {
    width: 700,
    height: 350,
    tzDate: ts => uPlot.tzDate(new Date(convertToMiliSeconds(ts)), timezone || 'UTC'),
    focus: {
      alpha: 1.1 // setting alpha: 1.1 bypasses the internal (alpha == 1) check that skips redraw() on focus changes
    },
    cursor: {
      show: true,
      focus: {
        prox: 5
      },
      lock: true,
      sync: {
        key: syncKey?.key ?? '',
        setSeries: true
      },
      y: false, // Show only the cursor dashed indicator for x axis
      drag: {
        x: false // No zooming
      },
      points: {
        size: 7
      },
      dataIdx: (self: uPlot, seriesIdx: number, _closestIdx: number, _xValue: number) => {
        return seriesIdx;
      }
    },
    legend: {
      show: false
    },
    scales: {
      x: {
        range: [
          convertLocalTimeToUTC(reports.intervalDate.from, timezone),
          convertLocalTimeToUTC(reports.intervalDate.to, timezone)
        ]
      },
      y: {
        range: [0, 100]
      }
    },
    axes: [{ ...xAxisOptions }, { ...yAxisOptions }],
    series: [{}, {}]
  };
}
