import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { APP_VERSION_BUCKET, APP_VERSION_ENV } from '../../constants';
import { replaceTemplateValues, VersionApiUris } from '../../utils';

export type VersionData = {
  version: string;
  timestamp: number;
};

export const versionApi = createApi({
  reducerPath: 'versionApi',
  baseQuery: fetchBaseQuery({
    baseUrl: APP_VERSION_BUCKET
  }),

  endpoints: builder => ({
    getVersion: builder.query<VersionData, void>({
      query: () => {
        return {
          url: replaceTemplateValues(VersionApiUris.version, { env: APP_VERSION_ENV })
        };
      }
    })
  })
});

export const { useGetVersionQuery } = versionApi;
