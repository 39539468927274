import React from 'react';
import { Button, ButtonType, Form, Toggle, BodyText, NotificationType, Spinner } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import { ModalContainer } from '../../Modals/ModalContainer';
import { hideDialog } from '../../../store/dialog';
import { useAppDispatch } from '../../../store/hooks';
import { DeviceConfigModel } from '../../../types';
import { QA_TEST_IDS } from '../../../tests/qaTestIds';
import { TEST_IDS } from '../../../tests/testids';
import { addNotification } from '../../../store/notifications';
import { useEditDevicePropertiesMutation } from '../../../store/services/devicesApi';
import { PARENT_SITE_KEY, PRODUCT_NAME } from '../../../constants';

interface Props {
  config: DeviceConfigModel;
  deviceId: string;
}

const EditRFLProperties: React.FC<Props> = ({ config, deviceId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [RFLDisplay, setRFLDisplay] = React.useState(
    config.reported?.display?.enabled ? config.reported.display.enabled : false
  );
  const [RFLLED, setRFLLED] = React.useState(config.reported?.led?.enabled ? config.reported.led.enabled : false);

  const [unit, setUnit] = React.useState(
    config.reported?.device?.metricUnitsEnabled ? config.reported.device.metricUnitsEnabled : false
  );

  const isDeviceModified =
    config.reported?.display?.enabled !== RFLDisplay ||
    config.reported?.led?.enabled !== RFLLED ||
    config.reported?.device?.metricUnitsEnabled !== unit;

  const [editDeviceProperties, editDevicePropertiesResult] = useEditDevicePropertiesMutation();

  const onDismiss = () => {
    dispatch(hideDialog());
  };

  const onSavingDeviceProperties = () => {
    editDeviceProperties({
      deviceId: deviceId,
      siteId: PARENT_SITE_KEY,
      config: {
        display: {
          enabled: RFLDisplay
        },
        led: {
          enabled: RFLLED
        }
      }
    });
  };

  React.useEffect(() => {
    if (editDevicePropertiesResult.isSuccess) {
      onDismiss();
      dispatch(
        addNotification({
          type: NotificationType.Ok,
          content: t('devicemanager.editProperties.toaster.success', { deviceId: deviceId, productName: PRODUCT_NAME })
        })
      );
    } else if (editDevicePropertiesResult.isError) {
      onDismiss();
      dispatch(
        addNotification({
          type: NotificationType.Error,
          content: t('devicemanager.editProperties.toaster.error', { deviceId: deviceId })
        })
      );
    }
  }, [editDevicePropertiesResult.isSuccess, editDevicePropertiesResult.isError]);

  const ModalButtons = (
    <>
      {/* eslint-disable-next-line enforce-ids-in-jsx/missing-ids */}
      <Button
        id={QA_TEST_IDS.edit_rfl_properties_save_button}
        dataTa={TEST_IDS.edit_rfl_properties_save_button}
        className="vai-space-inline-s"
        buttonType={editDevicePropertiesResult.isLoading ? ButtonType.Secondary : ButtonType.Primary}
        onClick={onSavingDeviceProperties}
        disabled={!isDeviceModified || editDevicePropertiesResult.isLoading}
      >
        {editDevicePropertiesResult.isLoading ? <Spinner /> : t('general.save')}
      </Button>
      {/* eslint-disable-next-line enforce-ids-in-jsx/missing-ids */}
      <Button
        id={QA_TEST_IDS.edit_rfl_properties_cancel_button}
        dataTa={TEST_IDS.edit_rfl_properties_cancel_button}
        buttonType={ButtonType.Secondary}
        onClick={onDismiss}
      >
        {t('general.cancel')}
      </Button>
    </>
  );

  return (
    <ModalContainer
      id={QA_TEST_IDS.edit_rfl_properties_modal_container}
      dataTa={TEST_IDS.edit_rfl_properties_modal_container}
      isOpen
      showCloseIcon={true}
      dismissOnOverlayClick={false}
      title={'devicemanager.editProperties.title'}
      onDismiss={onDismiss}
      buttons={ModalButtons}
    >
      <Form className="device-manages-edit-properties-form-container">
        <Form.Item label={t('deviceManager.LED')}>
          <Toggle
            htmlId={QA_TEST_IDS.edit_rfl_properties_rfl_led_toggle}
            dataTa={TEST_IDS.edit_rfl_properties_rfl_led_toggle}
            text={t('general.on')}
            uncheckedText={t('general.off')}
            checked={RFLLED}
            onChange={RFLLED => setRFLLED(RFLLED)}
          />
        </Form.Item>
        <Form.Item label={t('deviceManager.display')}>
          <Toggle
            htmlId={QA_TEST_IDS.edit_rfl_properties_rfl_display_toggle}
            dataTa={TEST_IDS.edit_rfl_properties_rfl_display_toggle}
            text={t('general.on')}
            uncheckedText={t('general.off')}
            checked={RFLDisplay}
            onChange={RFLDisplay => setRFLDisplay(RFLDisplay)}
          />
        </Form.Item>
        <Form.Item label={t('deviceManager.unit')}>
          <Toggle
            htmlId={QA_TEST_IDS.edit_rfl_properties_rfl_unit_toggle}
            dataTa={TEST_IDS.edit_rfl_properties_rfl_unit_toggle}
            text={t('deviceManager.imperial')}
            uncheckedText={t('deviceManager.metric')}
            checked={unit}
            onChange={unit => setUnit(unit)}
          />
        </Form.Item>
        <BodyText
          htmlId={QA_TEST_IDS.edit_rfl_properties_message}
          dataTa={TEST_IDS.edit_rfl_properties_message}
          className="grey-medium"
        >
          {t('devicemanager.editProperties.message')}
        </BodyText>
      </Form>
    </ModalContainer>
  );
};

export default EditRFLProperties;
