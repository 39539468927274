import React from 'react';
import { Icon, IconProps, Size } from '@vaisala/rockhopper-components';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { DEVICE_ALARM_RULE_NAMES } from '../../../../constants';
import { TranslationKey } from '../../../../react-i18next';

export const ALARM_RULE_NAME_HEADING_TRANSLATION_KEYS: Record<DEVICE_ALARM_RULE_NAMES, TranslationKey> = {
  [DEVICE_ALARM_RULE_NAMES.CALIBRATION]: 'site.alarms.device.rule.display.value.header.calibration',
  [DEVICE_ALARM_RULE_NAMES.COMMUNICATION]: 'site.alarms.device.rule.display.value.header.communication',
  [DEVICE_ALARM_RULE_NAMES.ERROR]: 'site.alarms.device.rule.display.value.header.error',
  [DEVICE_ALARM_RULE_NAMES.CONFIGURATION]: 'site.alarms.device.rule.display.value.header.configuration',
  [DEVICE_ALARM_RULE_NAMES.BATTERY]: 'site.alarms.device.rule.display.value.header.battery'
};

export const DeviceAlarmRuleIcons: Record<DEVICE_ALARM_RULE_NAMES, React.FC> = {
  [DEVICE_ALARM_RULE_NAMES.CALIBRATION]: (props: IconProps) => (
    <Icon size={Size.M} name={VaiIcon.DataLogger} {...props} />
  ),
  [DEVICE_ALARM_RULE_NAMES.COMMUNICATION]: (props: IconProps) => (
    <Icon size={Size.M} name={VaiIcon.CommunicationFailure} {...props} />
  ),
  [DEVICE_ALARM_RULE_NAMES.ERROR]: (props: IconProps) => <Icon size={Size.M} name={VaiIcon.Error} {...props} />,
  [DEVICE_ALARM_RULE_NAMES.CONFIGURATION]: (props: IconProps) => (
    <Icon size={Size.M} name={VaiIcon.SyncError} {...props} />
  ),
  [DEVICE_ALARM_RULE_NAMES.BATTERY]: (props: IconProps) => <Icon size={Size.M} name={VaiIcon.Battery1} {...props} />
};

const DEFAULT_WARNING = { hasWarning: false };

export type WarningStateType = Record<DEVICE_ALARM_RULE_NAMES, { hasWarning: boolean }>;

export const DEFAULT_WARNING_STATE: WarningStateType = {
  [DEVICE_ALARM_RULE_NAMES.CALIBRATION]: { ...DEFAULT_WARNING },
  [DEVICE_ALARM_RULE_NAMES.COMMUNICATION]: { ...DEFAULT_WARNING },
  [DEVICE_ALARM_RULE_NAMES.CONFIGURATION]: { ...DEFAULT_WARNING },
  [DEVICE_ALARM_RULE_NAMES.ERROR]: { ...DEFAULT_WARNING },
  [DEVICE_ALARM_RULE_NAMES.BATTERY]: { ...DEFAULT_WARNING }
};
