export const QA_TEST_IDS = {
  vai_modal_cancel: 'vai-modal-cancel',
  location_value: 'location-value',
  edit_button: 'edit-button',
  data_source_list_item_symbol: 'data-source-list-item-symbol',
  data_source_list_item_latest_meas: 'data-source-list-item-latest-meas',
  link_data_source_status: 'link-data-source-status',
  link_data_source_status_icon: 'link-data-source-icon',
  link_data_source_heading: 'link-data-source-heading',
  link_data_source_device_name: 'link-data-source-device-name',
  link_data_source_device_img: 'link-data-source-device-img',
  link_data_source_probe_name: 'link-data-source-probe-name',
  link_data_source_no_linked_source_msg: 'link-data-source-no-linked-source-msg',
  link_data_source_no_linked_source_blurb: 'link-data-source-no-linked-source-blurb',
  location_deactivated_alarm_blurb: 'location-deactivated-alarm-blurb',
  site_settings_alarm_settings_heading: 'site-settings-alarm-settings-heading',
  site_settings_pause_alarms: 'site-settings-pause-alarms',
  site_settings_link_button: 'site-settings-link-button',
  site_settings_accordion_list: 'site-settings-accordion-list',
  site_settings_threshold_alarms_accordion_item: 'site-settings-threshold-alarms-accordion-item',
  site_settings_device_alarms_accordion_item: 'site-settings-device-alarms-accordion-item',
  site_settings_threshold_settings_blurb: 'site-settings-threshold-settings-blurb',
  site_settings_threshold_alarms_not_set: 'site-settings-threshold-alarms-not-set',
  site_settings_threshold_alarms_no_thresholds_will_not_alarm:
    'site-settings-threshold-alarms-no-thresholds-will-not-alarm',
  site_settings_threshold_alarms_accordion_title: 'site-settings-threshold-alarms-accordion-title',
  site_settings_list_description: 'site-settings-list-description',
  site_status_list_description: 'site-status-list-description',
  device_manager_tab_spinner: 'device-manager-tab-spinner',
  device_manager_fetch_error: 'device-manager-fetch-error',
  device_manager_refresh_device: 'device-manager-refresh-device',
  device_manager_ap10_status_blurb: 'device_manager_ap10_status_blurb',
  device_manager_current_devices_heading: 'device_manager_current_devices_heading',
  device_properties: 'device-properties',
  device_properties_heading: 'device-properties-heading',
  device_properties_hostname_value: 'device-properties-hostname-value',
  device_properties_hostname_label: 'device-properties-hostname-label',
  device_properties_channel_value: 'device-properties-channel-value',
  device_properties_channel_label: 'device-properties-channel-label',
  device_properties_restart_device_button: 'device-properties-restart-device-button',
  device_properties_restart_device_icon: 'device-properties-restart-device-icon',
  device_properties_display_enabled_value: 'device-properties-display-enabled-value',
  device_properties_display_enabled_label: 'device-properties-display-enabled-label',
  device_properties_display_percent_value: 'device-properties-display-percent-value',
  device_properties_display_percent_label: 'device-properties-display-percent-label',
  device_properties_led_enabled_value: 'device-properties-led-enabled-value',
  device_properties_led_enabled_label: 'device-properties-led-enabled-label',
  device_properties_led_percent_value: 'device-properties-led-percent-value',
  device_properties_led_percent_label: 'device-properties-led-percent-label',
  device_status: 'device-status',
  device_status_no_hosted_devices: 'device_status_no_hosted_devices',
  alarms_settings_threshold_form_save_button: 'alarms_settings_threshold_form_save_button',
  alarms_settings_threshold_form_cancel_button: 'alarms_settings_threshold_form_cancel_button',
  alarms_settings_threshold_value_field_$level: 'alarms_settings_threshold_value_field_',
  alarms_settings_threshold_activate_checkbox_$level: 'alarms_settings_threshold_activate_checkbox_field_',
  alarms_settings_threshold_priority_select_$level: 'alarms_settings_threshold_priority_select_field_',
  alarms_settings_threshold_ack_toggle_$level: 'alarms_settings_threshold_ack_toggle_field_',
  alarms_settings_threshold_hours_field_$level: 'alarms_settings_threshold_hours_field_',
  alarms_settings_threshold_minutes_field_$level: 'alarms_settings_threshold_minutes_field_',
  alarms_settings_threshold_levels_error_display: 'alarms_settings_threshold_levels_error_display',
  alarms_settings_threshold_level_alarm_display_$level: 'alarms_settings_threshold_level_alarm_display_',
  alarms_settings_threshold_view_level_group_$level: 'alarms_settings_threshold_view_level_group_',
  alarms_settings_threshold_view_level_group_numberofUser: 'alarms_settings_threshold_view_level_group_numberofUser',
  alarms_settings_threshold_view_level_group_name: 'alarms_settings_threshold_view_level_group_name',

  alarms_settings_device_form_save_button: 'alarms_settings_device_form_save_button',
  alarms_settings_device_form_cancel_button: 'alarms_settings_device_form_cancel_button',
  alarms_settings_device_activate_checkbox_$ruleName: 'alarms_settings_device_activate_checkbox_field_',
  alarms_settings_device_priority_select_$ruleName: 'alarms_settings_device_priority_select_field_',
  alarms_settings_device_ack_toggle_$ruleName: 'alarms_settings_device_ack_toggle_field_',
  alarms_settings_device_hours_field_$ruleName: 'alarms_settings_device_hours_field_',
  alarms_settings_device_minutes_field_$ruleName: 'alarms_settings_device_minutes_field_',
  alarms_settings_device_levels_error_display: 'alarms_settings_device_levels_error_display',
  alarms_settings_device_level_alarm_display_$ruleName: 'alarms_settings_device_level_alarm_display_',
  alarms_settings_device_view_level_group_$ruleName: 'alarms_settings_device_view_level_group_',

  alarms_settings_threshold_notifications_group_select_selector_$level:
    'alarms_settings_threshold_notifications_group_select_selector_',
  alarms_settings_device_notifications_group_select_selector_$ruleName:
    'alarms_settings_device_notifications_group_select_selector_',
  alarms_settings_threshold_notifications_group_select_search_field:
    'alarms_settings_threshold_notifications_group_select_search_field',
  alarms_settings_threshold_notifications_group_select_checkbox_select_all:
    'alarms_settings_threshold_notifications_group_select_checkbox_select_all',
  alarms_settings_threshold_notifications_group_select_checkbox_$group_name:
    'alarms_settings_threshold_notifications_group_select_checkbox_',
  alarms_settings_device_notifications_group_select_checkbox_$group_name:
    'alarms_settings_device_notifications_group_select_checkbox_',
  alarms_settings_threshold_accordion_edit_button: 'alarms_settings_threshold_accordion_edit_button',
  alarms_settings_device_accordion_edit_button: 'alarms_settings_device_accordion_edit_button',
  steps: {
    next: 'steps_next',
    back: 'steps_back'
  },
  current_alarms_table: 'current-alarms-table',
  device_properties_current_host_label: 'device-properties-current-host-label',
  device_properties_current_host_value: 'device-properties-current-host-value',
  device_properties_device_class_label: 'device-properties-device-class-label',
  device_properties_device_class_value: 'device-properties-device-class-value',
  device_properties_hardware_model_label: 'device-properties-hardware-model-label',
  device_properties_hardware_model_value: 'device-properties-hardware-model-value',
  device_properties_serial_number_label: 'device-properties-serial-number-label',
  device_properties_serial_number_value: 'device-properties-serial-number-value',
  device_properties_firmware_version_label: 'device-properties-firmware-version-label',
  device_properties_firmware_version_value: 'device-properties-firmware-version-value',
  device_properties_rfl_led_label: 'device-properties-rfl-led-label',
  device_properties_rfl_led_value: 'device-properties-rfl-led-value',
  device_properties_rfl_display_label: 'device-properties-rfl-display-label',
  device_properties_rfl_display_value: 'device-properties-rfl-display-value',
  device_properties_unit_label: 'device-properties-unit-label',
  device_properties_unit_value: 'device-properties-unit-value',
  device_properties_probe_id_label: 'device-properties-probe-id-label',
  device_properties_calibration_date_label: 'device-properties-calibration-date-label',
  device_properties_calibration_date_value: 'device-properties-calibration-date-value',
  device_properties_calibration_interval_label: 'device-properties-calibration-interval-label',
  device_properties_calibration_interval_value: 'device-properties-calibration-interval-value',
  device_status_datasource_location_link: 'device-status-datasource-location-link',
  help_link_button: 'help-link-button',
  hide_contextual_help_button: 'hide-contextual-help-button',
  edit_ap10_properties_modal_container: 'edit_ap10_properties_modal_container',
  edit_ap10_properties_vaiNet_channel_select: 'edit_ap10_properties_vaiNet_channel_select',
  edit_ap10_properties_ap_display_toggle: 'edit_ap10_properties_ap_display_toggle',
  edit_ap10_properties_display_brightness_slider: 'edit_ap10_properties_display_brightness_slider',
  edit_ap10_properties_ap_led_toggle: 'edit_ap10_properties_ap_led_toggle',
  edit_ap10_properties_led_brightness_slider: 'edit_ap10_properties_led_brightness_slider',
  edit_ap10_properties_led_brightness: 'edit_ap10_properties_led_brightness',
  edit_ap10_properties_message: 'edit_ap10_properties_message',
  edit_ap10_properties_save_button: 'edit_ap10_properties_save_button',
  edit_ap10_properties_cancel_button: 'edit_ap10_properties_cancel_button',
  edit_rfl_properties_modal_container: 'edit_rfl_properties_modal_container',
  edit_rfl_properties_rfl_display_toggle: 'edit_rfl_properties_rfl_display_toggle',
  edit_rfl_properties_rfl_led_toggle: 'edit_rfl_properties_rfl_led_toggle',
  edit_rfl_properties_rfl_unit_toggle: 'edit_rfl_properties_rfl_unit_toggle',
  edit_rfl_properties_message: 'edit_rfl_properties_message',
  edit_rfl_properties_save_button: 'edit_rfl_properties_save_button',
  edit_rfl_properties_cancel_button: 'edit_ap10_properties_cancel_button',
  remove_user_from_groups_button: 'remove_user_from_groups_button',
  user_group_list_heading_icon: 'user_group_list_heading_icon',
  events_table: 'events-table',
  events_not_found_icon: 'events-not-found-icon',
  events_not_found_text: 'events-not-found-text',
  events_not_found_subtext: 'events-not-found-subtext',
  events_not_found: 'events-not-found',
  alarms_thresholdAlarmDetails_modalContainer: 'alarms-thresholdAlarmDetails-modalContainer',
  alarms_thresholdAlarmDetails_alarmDetails: 'alarms-thresholdAlarmDetails-alarmDetails',
  alarms_thresholdAlarmDetails_location_label: 'alarms-thresholdAlarmDetails-location-label',
  alarms_thresholdAlarmDetails_location_icon: 'alarms-thresholdAlarmDetails-location-icon',
  alarms_thresholdAlarmDetails_location_value: 'alarms-thresholdAlarmDetails-location-value',
  alarms_thresholdAlarmDetails_value_label: 'alarms-thresholdAlarmDetails-value-label',
  alarms_thresholdAlarmDetails_value_value: 'alarms-thresholdAlarmDetails-value-value',
  alarms_thresholdAlarmDetails_value_measurementtype: 'alarms-thresholdAlarmDetails-value-measurementtype',
  alarms_thresholdAlarmDetails_alarmType_label: 'alarms-thresholdAlarmDetails-alarmType-label',
  alarms_thresholdAlarmDetails_alarmType_value: 'alarms-thresholdAlarmDetails-alarmType-value',
  alarms_thresholdAlarmDetails_startTime_label: 'alarms-thresholdAlarmDetails-startTime-label',
  alarms_thresholdAlarmDetails_startTime_value: 'alarms-thresholdAlarmDetails-startTime-value',
  alarms_thresholdAlarmDetails_endTime_label: 'alarms-thresholdAlarmDetails-endTime-label',
  alarms_thresholdAlarmDetails_endTime_value: 'alarms-thresholdAlarmDetails-endTime-value',
  alarms_thresholdAlarmDetails_duration_label: 'alarms-thresholdAlarmDetails-duration-label',
  alarms_thresholdAlarmDetails_duration_value: 'alarms-thresholdAlarmDetails-duration-value',
  alarms_thresholdAlarmDetails_alarmSettings_title: 'alarms-thresholdAlarmDetails-alarmSettings-title',
  alarms_thresholdAlarmDetails_priority_label: 'alarms-thresholdAlarmDetails-priority-label',
  alarms_thresholdAlarmDetails_priority_icon: 'alarms-thresholdAlarmDetails-priority-icon',
  alarms_thresholdAlarmDetails_priority_value: 'alarms-thresholdAlarmDetails-priority-value',
  alarms_thresholdAlarmDetails_thresholdValue_label: 'alarms-thresholdAlarmDetails-thresholdValue-label',
  alarms_thresholdAlarmDetails_thresholdValue_sign: 'alarms-thresholdAlarmDetails-thresholdValue-sign',
  alarms_thresholdAlarmDetails_thresholdValue_value: 'alarms-thresholdAlarmDetails-thresholdValue-value',
  alarms_thresholdAlarmDetails_thresholdValue_measurementtype:
    'alarms-thresholdAlarmDetails-thresholdValue-measurementtype',
  alarms_thresholdAlarmDetails_alarmDelay_label: 'alarms-thresholdAlarmDetails-alarmDelay-label',
  alarms_thresholdAlarmDetails_alarmDelay_value: 'alarms-thresholdAlarmDetails-alarmDelay-value',
  alarms_thresholdAlarmDetails_dataSource_label: 'alarms-thresholdAlarmDetails-dataSource-label',
  alarms_thresholdAlarmDetails_dataSource_value: 'alarms-thresholdAlarmDetails-dataSource-value',
  alarms_thresholdAlarmDetails_acknowledge_button: 'alarms-thresholdAlarmDetails-acknowledge-button'
};
