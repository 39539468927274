import React, { useContext, useState } from 'react';

interface Props {
  stepsCount: number;
  currentStep?: number;
  currentStepNumber?: number;
  onStepChange?: (step: number) => void;
}

export interface StepsContextType {
  stepsCount: number;
  currentStepIndex: number;
  goNext: () => void;
  goBack: () => void;
}

const Context = React.createContext<StepsContextType>({
  currentStepIndex: 0,
  stepsCount: 0,
  goNext: () => {
    return;
  },
  goBack: () => {
    return;
  }
});

export const useStepsContext = () => useContext(Context);

export const StepsContextProvider: React.FC<Props> = ({ children, stepsCount, currentStepNumber }) => {
  const [currentStepIndex, setCurrentIndexStep] = useState(currentStepNumber !== undefined ? currentStepNumber - 1 : 0);

  const goNext = () => {
    setCurrentIndexStep(index => {
      const updatedIndex = index < stepsCount ? index + 1 : stepsCount - 1;
      return updatedIndex;
    });
  };
  const goBack = () => {
    setCurrentIndexStep(index => {
      const updatedIndex = index > 0 ? index - 1 : 0;
      return updatedIndex;
    });
  };

  return (
    <Context.Provider
      value={{
        currentStepIndex,
        stepsCount,
        goBack,
        goNext
      }}
    >
      {children}
    </Context.Provider>
  );
};
