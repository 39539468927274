import React, { useState } from 'react';
import validator from 'validator';
import { ZoneNode, ZoneNodeInterface } from '../../../siteTree/site';
import { useTranslation } from 'react-i18next';
import { SITE_NAME_MAX_LENGTH, SITE_DESCRIPTION_MAX_LENGTH } from '../../../constants';
import { validateCannotBeEmpty, validateCannotMatch, validateCharacterMaxLength } from '../../validator/validate';
import { Name } from '../Form/Fields/Name';
import { Description } from '../Form/Fields/Description';
import { SiteTreeFormDialog } from '../Form/FormDialog';
import { FormActionType } from '../shared';
import '../zone-and-location-dialog.scss';

interface Props {
  onSave: (node: ZoneNodeInterface) => void;
  formAction: FormActionType;
  childNames?: string[];
  siblingNames?: string[];
  icon?: string;
  parentName?: string;
  Footer?: JSX.Element;
  onDismiss?: () => void;
  selectedItemObject?: ZoneNode & { text: string; icon: string; id: string };
  loading?: boolean;
}

export const ZoneFormDialog = ({
  childNames,
  siblingNames,
  onSave,
  parentName,
  selectedItemObject,
  icon,
  Footer,
  formAction,
  onDismiss,
  loading
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [name, setName] = useState(selectedItemObject?.text ?? '');
  const [nameError, setNameError] = useState('');
  const [description, setDescription] = useState(selectedItemObject?.description ?? '');
  const [descriptionError, setDescriptionError] = useState('');

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameError('');
    setName(event.target.value);
    if (!validateCharacterMaxLength(event.target.value, SITE_NAME_MAX_LENGTH)) {
      setNameError(t('site.characterMaxLengthError', { charLen: SITE_NAME_MAX_LENGTH }));
    }
  };

  const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescriptionError('');
    setDescription(event.target.value);
    if (!validateCharacterMaxLength(event.target.value, SITE_DESCRIPTION_MAX_LENGTH)) {
      setDescriptionError(t('site.characterMaxLengthError', { charLen: SITE_DESCRIPTION_MAX_LENGTH }));
    }
  };

  const handleSave = () => {
    let errorExist = false;
    const trimmedName = validator.trim(name);
    const trimmedDescription = validator.trim(description);

    errorExist = !(
      validateCannotBeEmpty(trimmedName) &&
      validateCharacterMaxLength(trimmedName, SITE_NAME_MAX_LENGTH) &&
      validateCharacterMaxLength(trimmedDescription, SITE_DESCRIPTION_MAX_LENGTH, true)
    );

    if (siblingNames && siblingNames.indexOf(trimmedName.toLowerCase()) !== -1) {
      setNameError(t('site.nameConnotmatchSiblingName', { parentName }));
      errorExist = true;
    }

    if (childNames && childNames.indexOf(trimmedName.toLowerCase()) !== -1) {
      setNameError(t('site.nameConnotmatchChild', { parentName }));
      errorExist = true;
    }

    if (parentName && !validateCannotMatch(trimmedName.toLocaleLowerCase(), parentName?.toLocaleLowerCase())) {
      setNameError(t('site.nameCannotMatchParent'));
      errorExist = true;
    }

    if (!validateCannotBeEmpty(trimmedName)) {
      setNameError(t('site.nameCannotBeEmpty'));
    }

    if (!errorExist) {
      const newTreeNode: ZoneNodeInterface = {
        name: trimmedName,
        description: trimmedDescription
      };

      onSave(newTreeNode);
    }
  };
  const isNameInvalid = !name || nameError.length > 0;
  const isDescriptionInvalid = descriptionError.length > 0;

  const hasformChanged = selectedItemObject?.text !== name || selectedItemObject?.description !== description;

  return (
    <SiteTreeFormDialog
      action={formAction}
      iconName={icon ?? ''}
      parentZoneName={parentName}
      onDismiss={() => onDismiss?.()}
      onSave={handleSave}
      title={formAction === FormActionType.CREATE ? 'site.newZone' : 'site.editZone'}
      saveButtonDisabled={!hasformChanged || isNameInvalid || isDescriptionInvalid}
      Footer={Footer}
      loading={loading}
    >
      <Name
        action={formAction}
        label={t('general.name')}
        value={name}
        onChange={onNameChange}
        errorMessage={nameError}
      />
      <Description
        action={formAction}
        value={description}
        onChange={onDescriptionChange}
        errorMessage={descriptionError}
        label={t('general.description')}
      />
    </SiteTreeFormDialog>
  );
};
