import { DEVICE_ALARM_RULE_NAMES } from '../../../../../constants';
import { TranslationKey } from '../../../../../react-i18next';
import { DeviceAlarmSettings } from '../../../../../store/services/alarmsApi';

export const deviceRuleNameTranslations: Record<DEVICE_ALARM_RULE_NAMES, TranslationKey> = {
  [DEVICE_ALARM_RULE_NAMES.CALIBRATION]: 'site.alarms.device.rule.names.calibration',
  [DEVICE_ALARM_RULE_NAMES.COMMUNICATION]: 'site.alarms.device.rule.names.communication',
  [DEVICE_ALARM_RULE_NAMES.ERROR]: 'site.alarms.device.rule.names.error',
  [DEVICE_ALARM_RULE_NAMES.CONFIGURATION]: 'site.alarms.device.rule.names.configuration',
  [DEVICE_ALARM_RULE_NAMES.BATTERY]: 'site.alarms.device.rule.names.battery'
};

const DEAFULT_NOTIFICATION_RULE = {
  EMAIL: [],
  VOICE: [],
  SMS: []
};

export const DEFAULT_NOTIFICATION_SETTINGS: DeviceAlarmSettings['settings']['notifications'] = {
  [DEVICE_ALARM_RULE_NAMES.CALIBRATION]: { ...DEAFULT_NOTIFICATION_RULE },
  [DEVICE_ALARM_RULE_NAMES.COMMUNICATION]: { ...DEAFULT_NOTIFICATION_RULE },
  [DEVICE_ALARM_RULE_NAMES.ERROR]: { ...DEAFULT_NOTIFICATION_RULE },
  [DEVICE_ALARM_RULE_NAMES.CONFIGURATION]: { ...DEAFULT_NOTIFICATION_RULE },
  [DEVICE_ALARM_RULE_NAMES.BATTERY]: { ...DEAFULT_NOTIFICATION_RULE }
};
