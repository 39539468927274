import { NodeModel, NodeState } from '@vaisala/community-tree-menu';
import { ALARM_PRIORITY, CO2_ID, HUMIDITY_ID, LOCATION_STATUS, SiteNodeTypes, TEMP_ID } from '../constants';
import { DataSourceListItemModel } from '../types';

export interface SiteNode extends BaseNode {
  children: ChildrenNodes;
  type: SiteNodeTypes.SITE;
}

type ChildNode = ZoneNode | LocationNode;

export type ChildrenNodes = ChildNode[];

export type TreeMenuSiteNode = SiteNode &
  Omit<NodeModel, 'states'> & {
    states?: (NodeState | 'info')[];
    status: LOCATION_STATUS;
    active: boolean;
  };

export type MeasurementType = typeof CO2_ID | typeof TEMP_ID | typeof HUMIDITY_ID;

export enum AlarmLevel {
  HI = 'HI',
  HIHI = 'HIHI',
  LO = 'LO',
  LOLO = 'LOLO'
}

export enum AlarmStatus {
  OK = 'OK',
  LOW_PRIORITY = 'LOW',
  MED_PRIORITY = 'MEDIUM',
  HIGH_PRIORITY = 'HIGH'
}

export enum AlarmSetState {
  NOT_SET = 'NOT_SET',
  DEFAULT = 'DEFAULT',
  NONE = 'NONE',
  SET = 'SET',
  // TODO: It would appear that this is going to be removed from the BE, we need to refactor the places this is used
  PAUSED = 'PAUSED'
}

export enum DeviceAlarm {
  COMMUNICATION = 'COMMUNICATION',
  CONFIGURATION = 'CONFIGURATION',
  CALIBRATION = 'CALIBRATION',
  ERROR = 'ERROR',
  BATTERY = 'BATTERY'
}

// TODO: I would like to use the values defined for reports, but there is a typo in celsius and
//  I don't want them to be coupled too tightly
export enum SITES_SYMBOL_ID {
  CELSIUS = 'celsius',
  FAHRENHEIT = 'fahrenheit',
  HUMIDITY = 'rh',
  CO2_PERCENT = 'percent',
  CO2_PPM = 'ppm'
}

export interface CreateTreeNodeInterface extends BaseLocationTreeNodeInterface {
  parent_id: string;
  position: number;
  type: SiteNodeTypes;
}
export type EditTreeNodeInterface = BaseLocationTreeNodeInterface;

export interface BaseLocationTreeNodeInterface extends BaseNodeInterface {
  meas_id?: string;
  symbol_id?: string;
  decimal_places?: number;
}
export interface LinkTreeNodeInterface {
  device_model: string;
  device_sn: string;
  probe_model: string;
  probe_sn: string;
  meas_group: number;
  meas_id: number;
}
export interface BaseNodeInterface {
  name: string;
  description?: string;
}

export type ZoneNodeInterface = BaseNodeInterface;
export type SiteNodeInterface = BaseNodeInterface;
export interface DeleteTreeNodeInterface {
  node_id: string;
  parent_id: string;
  name: string;
  type: SiteNodeTypes;
}
export type LocationAlarms = {
  count: number;
  highest_priority: ALARM_PRIORITY | null;
};
export interface LocationNode extends BaseNode {
  type: SiteNodeTypes.LOCATION;
  active: boolean;
  timestamp: number | null;
  device_alarm_set: AlarmSetState;
  thres_alarm_set: AlarmSetState;
  threshold_alarms: LocationAlarms;
  device_alarms: LocationAlarms;
  alarms_count: number;
  symbol_id: SITES_SYMBOL_ID;
  is_stale: boolean;
  meas_id: MeasurementType;
  device: DataSourceListItemModel | null;
  node_id: string;
  status: LOCATION_STATUS;
  raw_value: number | null;
  decimal_places: number;
}
export interface ZoneNode extends BaseNode {
  children: ChildrenNodes;
  type: SiteNodeTypes.ZONE;
}
export interface BaseNode {
  name: string;
  description: string;
  parent_id: string;
  position: number;
  type: SiteNodeTypes;
  node_id: string;
  created: number;
  changed: number;
  sk: string;
}
