import React, { useEffect, useState } from 'react';
import { AccordionTree } from '../AccordionTree/AccordionTree';
import { cloneDeep } from 'lodash';
import { ZoneNode, SiteNode } from '../../siteTree/site';
import {
  applyRightsToSite,
  normalizeRights,
  toggleRightInBit,
  getNodeChildrenIds,
  convertSiteTreeToRightsTree,
  isGlobalRight,
  applyInheritence
} from './helpers';
import { RightsSummary } from './RightsSummary';
import { RightCheckboxes } from './RightCheckboxes';
import { RightDetails } from '../../store/services/userApi';
import { RightNames, SiteRightsTree } from './constants';
import { TEST_IDS } from '../../tests/testids';
import { SelectionOptions } from '../AccordionTree/helpers';
import { RightDetailsNonNullable } from '../profile/GroupRightsDialog/common';
import './rights-accordion-tree.scss';

interface Props {
  site?: SiteNode | ZoneNode;
  rightDetails: RightDetailsNonNullable;
  onChange?: (rightDetails: RightDetails) => void;
  expandedNodes?: SelectionOptions['expandedNodes'];
  readonly?: boolean;
}

export const RightsAccordionTree: React.FC<Props> = ({
  site,
  rightDetails: initialRightDetails,
  readonly,
  onChange,
  expandedNodes
}) => {
  const { rights: initialRights, rightsGlobal: initialGlobalRights } = initialRightDetails;
  const [rightsTree, setRightsTree] = useState<SiteRightsTree | undefined>();
  const [rightDetails, setRightDetails] = useState<RightDetailsNonNullable>();

  // Adds missing nodes ids to the rights object
  useEffect(() => {
    site &&
      setRightDetails(prev => ({
        ...prev,
        rights: normalizeRights(initialRights ?? {}, site),
        rightsGlobal: initialGlobalRights
      }));
  }, [initialRights, site, initialGlobalRights]);

  // Prepare the rights tree from the site tree. Adds rights and parent properties.
  useEffect(() => {
    site &&
      setRightsTree(() => {
        const clonedSite = cloneDeep(site);
        const rightsTree = convertSiteTreeToRightsTree(clonedSite);
        return rightsTree;
      });
  }, [site]);

  // Updates rights tree whenever rights are updated
  useEffect(() => {
    rightDetails &&
      setRightsTree(prev => {
        if (prev) {
          const clonedTree = cloneDeep(prev);
          applyRightsToSite(clonedTree, rightDetails.rights);
          return clonedTree;
        }
      });
  }, [rightDetails]);

  // Make sure data for the component is ready before rendering the tree
  if (!rightsTree || !rightDetails) {
    return null;
  }

  // Main function where bits get updated
  const onCheckboxChange = (checked: boolean, node: SiteRightsTree, rightName: RightNames) => {
    setRightDetails(prev => {
      if (!prev) {
        return prev;
      }
      const newRightDetails = cloneDeep(prev);
      const nodeRightsBit = newRightDetails.rights[node.node_id];

      // Handle right update for the current node
      const newBit = toggleRightInBit(checked, nodeRightsBit, rightName);
      newRightDetails.rights[node.node_id] = newBit;

      // Propogate the change to all children
      getNodeChildrenIds(node).forEach(childId => {
        const nodeRightsBit = rightDetails.rights[childId];
        newRightDetails.rights[childId] = toggleRightInBit(checked, nodeRightsBit, rightName);
      });

      // Handle inheritence
      const tree = cloneDeep(rightsTree);
      applyRightsToSite(tree, newRightDetails.rights);
      newRightDetails.rights = applyInheritence(tree, { ...newRightDetails.rights });

      // Handle global rights
      if (isGlobalRight(rightName)) {
        newRightDetails.rightsGlobal = toggleRightInBit(checked, rightDetails.rightsGlobal, rightName);
      }

      onChange && onChange(newRightDetails);
      return newRightDetails;
    });
  };

  return (
    <div id="groups-rights-container" className="rights-accordion-tree">
      <div id="groups-rights-accordion-container" className="rights-accordion-tree-container">
        <AccordionTree
          site={rightsTree}
          options={{ nonSelectableNodes: ['*'], showLocations: false, expandedNodes }}
          AccordionSummary={({ node }) => <RightsSummary node={node} />}
          AccordionContent={({ node }) => (
            <div key={node.node_id} data-ta={TEST_IDS.rights_checkboxes_$id + node.node_id}>
              <RightCheckboxes key={node.node_id} node={node} onChange={onCheckboxChange} readonly={readonly} />
            </div>
          )}
        />
      </div>
    </div>
  );
};
