/**
 * The viewLinc Rights are defined in Confluence
 * https://vaisala.atlassian.net/wiki/spaces/VLNCC/pages/2165023082/Rights+details+How+rights+work
 */

export const RIGHTS_MASK = {
  /**
   * ZONE/LOCATION Rights
   * These are similar to 'Permissions' in viewLinc 5.x
   */

  // This is a special VIEW right that allows viewing the site/zone but does not
  // propagate to newly created child zones.
  VIEW_NON_INHERITABLE: 0b0000000000000001,
  // System Wide = view all sites
  // Site Wide = view zones
  // Zone Wide = view zones
  VIEW: 0b0000000000000010,
  // System Wide = part of 'Manage All Sites' concept
  // Site Wide = acknowledge alarms
  // Zone Wide = acknowledge alarms
  ACKNOWLEDGE_ALARMS: 0b0000000000000100,
  // System Wide = part of 'Manage All Sites' concept
  // Site Wide = pause alarms
  // Zone Wide = pause alarms
  PAUSE_ALARMS: 0b0000000000001000,
  // System Wide = part of 'Manage All Sites' concept
  // Site Wide = link alarm templates
  // Zone Wide = link alarm templates
  // LINK_ALARMS: 0b0000000000010000, // Non standard release right. https://vaisala.atlassian.net/browse/VLNCC-9314
  // System Wide = part of 'Manage All Sites' concept
  // Site Wide = comment on alarm and data
  // Zone Wide = comment on alarm and data
  // COMMENT_ALARMS: 0b0000000000100000, // Non standard release right. https://vaisala.atlassian.net/browse/VLNCC-9314
  // System Wide = part of 'Manage All Sites' concept
  // Site Wide = comment on alarm and data
  // Zone Wide = comment on alarm and data
  // COMMENT_DATA: 0b0000000001000000, // Non standard release right. https://vaisala.atlassian.net/browse/VLNCC-9314
  /**
   * SYSTEM and SITE Rights
   * These are similar to 'Rights' in viewLinc 5.x
   */
  // System Wide = part of 'Manage All Sites' concept
  // Zone Wide = manage site properties, zones and locations
  MANAGE_SITES: 0b0000000010000000,
  // System Wide = manage all devices
  // Zone Wide = manage devices
  MANAGE_DEVICES: 0b0000000100000000,
  // System Wide = manage all alarm templates
  // Zone Wide = manage alarm templates
  MANAGE_ALARM_TEMPLATES: 0b0000001000000000,
  // System Wide = manage all users and groups
  // Zone Wide = manage users and groups
  MANAGE_USERS_GROUPS: 0b0000010000000000,
  // System Wide = view all events
  // Zone Wide = view events
  VIEW_EVENTS: 0b0000100000000000,
  // System Wide = manage system preferences
  // Zone Wide = manage system preferences
  // MANAGE_PREFERENCES: 0b0001000000000000, // Non standard release right. https://vaisala.atlassian.net/browse/VLNCC-9314
  // System Wide = manage all reports
  // Zone Wide = manage reports
  MANAGE_REPORTS: 0b0010000000000000
};

export const ADMIN_RIGHTS_MASK = 0b1111111111111110;
