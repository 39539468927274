import React, { useState } from 'react';
import validator from 'validator';
import { BaseLocationTreeNodeInterface } from '../../../siteTree/site';
import { useTranslation } from 'react-i18next';
import { SITE_NAME_MAX_LENGTH, SITE_DESCRIPTION_MAX_LENGTH } from '../../../constants';
import { validateCannotBeEmpty, validateCannotMatch, validateCharacterMaxLength } from '../../validator/validate';
import { DecimalPlaces } from '../Form/Fields/DecimalPlaces';
import { MeasurementTypes } from '../Form/Fields/MeasurementType';
import { Name } from '../Form/Fields/Name';
import { Description } from '../Form/Fields/Description';
import { UnitTypes } from '../Form/Fields/UnitType';
import { SiteTreeFormDialog } from '../Form/FormDialog';
import { FormActionType, MeasurementTypesEnum, UnitTypeLabels } from '../shared';
import measurementTypes from '../../../data/measurementTypes.json';
import decimals from '../../../data/decimalPlaces.json';
import { unitTypes } from '../../../data/unitTypes';
import '../zone-and-location-dialog.scss';

interface Props {
  parentName: string;
  onSave: (node: BaseLocationTreeNodeInterface) => void;
  onDismiss: () => void;
  icon: string;
  formAction: FormActionType;
  selectedItemObject?: BaseLocationTreeNodeInterface & { text: string; icon: string; id: string };
  siblingNames?: string[];
  Footer?: JSX.Element;
  loading?: boolean;
}

const [temperature] = measurementTypes;
const [{ value: defaultHumidityOptionValue }] = unitTypes.humidity;
const [{ value: defaultCelsiusOptionValue }] = unitTypes.temperature;
const [{ value: defaultCo2OptionValue }] = unitTypes.co2;
const [__, { value: defaultDecimalPlaceValue }] = decimals;

export const LocationFormDialog: React.FC<Props> = ({
  siblingNames,
  onSave,
  selectedItemObject,
  Footer,
  parentName,
  formAction,
  icon,
  loading,
  onDismiss
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState(selectedItemObject?.text ?? '');
  const [nameError, setNameError] = useState('');
  const [description, setDescription] = useState(selectedItemObject?.description ?? '');
  const [descriptionError, setDescriptionError] = useState<string>('');
  const [measurementType, setMeasurementType] = useState(
    (selectedItemObject?.meas_id || temperature.value) as MeasurementTypesEnum
  );
  const [measurementTypeError, setMeasurementTypeError] = useState<string>('');
  const [unitType, setUnitType] = useState(selectedItemObject?.symbol_id || defaultCelsiusOptionValue);
  const [unitTypeError, setUnitTypeError] = useState('');
  const [decimalPlace, setDecimalPlace] = useState(
    formAction === FormActionType.EDIT ? selectedItemObject?.decimal_places : defaultDecimalPlaceValue
  );

  const [decimalPlaceError, setDecimalPlaceError] = useState('');

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameError('');
    setName(event.target.value);

    if (!validateCharacterMaxLength(event.target.value, SITE_NAME_MAX_LENGTH)) {
      setNameError(t('site.characterMaxLengthError', { charLen: SITE_NAME_MAX_LENGTH }));
    }
  };

  const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescriptionError('');
    setDescription(event.target.value);
    if (!validateCharacterMaxLength(event.target.value, SITE_DESCRIPTION_MAX_LENGTH)) {
      setDescriptionError(t('site.characterMaxLengthError', { charLen: SITE_DESCRIPTION_MAX_LENGTH }));
    }
  };

  const onMeasurementTypeChange = (value: MeasurementTypesEnum) => {
    setMeasurementTypeError('');
    setMeasurementType(value);

    switch (value) {
      case MeasurementTypesEnum.temperature:
        setUnitType(defaultCelsiusOptionValue);
        break;
      case MeasurementTypesEnum.relativeHumidity:
        setUnitType(defaultHumidityOptionValue);
        break;
      case MeasurementTypesEnum.co2:
        setUnitType(defaultCo2OptionValue);
        break;
      default:
        setUnitType(defaultCelsiusOptionValue);
    }
  };

  const onUnitTypeChange = (value: UnitTypeLabels) => {
    setUnitTypeError('');
    setUnitType(value);
  };

  const onDecimalPlaceChange = (value: number) => {
    setDecimalPlaceError('');
    setDecimalPlace(value);
  };

  const onCreateNewNode = () => {
    let errorExist = false;
    const trimmedName = validator.trim(name);
    const trimmedDescription = validator.trim(description);

    errorExist = !(
      validateCannotBeEmpty(trimmedName) &&
      validateCannotBeEmpty(unitType) &&
      validateCannotBeEmpty(measurementType) &&
      validateCharacterMaxLength(trimmedName, SITE_NAME_MAX_LENGTH) &&
      validateCharacterMaxLength(trimmedDescription, SITE_DESCRIPTION_MAX_LENGTH, true)
    );

    if (!validateCannotBeEmpty((trimmedName as string).trim())) {
      setNameError(t('site.nameCannotBeEmpty'));
    }

    if (siblingNames && siblingNames?.indexOf(trimmedName.toLowerCase()) !== -1) {
      setNameError(t('site.nameConnotmatchSiblingName', { parentName }));
      errorExist = true;
    }

    if (!validateCannotMatch(trimmedName.toLowerCase(), parentName.toLowerCase())) {
      setNameError(t('site.nameCannotMatchParent'));
      errorExist = true;
    }

    if (!errorExist) {
      const newTreeNode: BaseLocationTreeNodeInterface = {
        meas_id: measurementType,
        symbol_id: unitType,
        decimal_places: decimalPlace,
        name: trimmedName,
        description: trimmedDescription
      };
      onSave(newTreeNode);
    }
  };
  const isNameInvalid = !name || nameError.length > 0;
  const isDescriptionInvalid = descriptionError.length > 0;

  const hasformChanged =
    selectedItemObject?.text !== name ||
    selectedItemObject?.description !== description ||
    selectedItemObject?.meas_id !== measurementType ||
    selectedItemObject?.symbol_id !== unitType ||
    selectedItemObject?.decimal_places !== decimalPlace;

  return (
    <SiteTreeFormDialog
      action={formAction}
      iconName={icon}
      parentZoneName={parentName}
      onDismiss={onDismiss}
      onSave={onCreateNewNode}
      title={formAction === FormActionType.CREATE ? 'site.newLocation' : 'site.editLocation'}
      saveButtonDisabled={!hasformChanged || isNameInvalid || isDescriptionInvalid}
      Footer={Footer}
      loading={loading}
    >
      <Name
        action={formAction}
        label={t('general.name')}
        value={name}
        onChange={onNameChange}
        errorMessage={nameError}
      />
      <MeasurementTypes
        action={formAction}
        label={t('site.measurementType')}
        value={measurementType}
        onChange={onMeasurementTypeChange}
        errorMessage={measurementTypeError}
      />
      <UnitTypes
        action={formAction}
        value={unitType}
        onChange={onUnitTypeChange}
        errorMessage={unitTypeError}
        label={t('site.unit')}
        measurementType={measurementType}
      />
      <DecimalPlaces
        action={formAction}
        label={t('site.decimalPlace')}
        errorMessage={decimalPlaceError}
        value={decimalPlace}
        onChange={onDecimalPlaceChange}
      />
      <Description
        action={formAction}
        value={description}
        onChange={onDescriptionChange}
        errorMessage={descriptionError}
        label={t('general.description')}
      />
    </SiteTreeFormDialog>
  );
};
