import React from 'react';
import {
  Flex,
  ButtonType,
  FlexItem,
  Button,
  Spinner,
  TextArea,
  Heading,
  RequiredAsterisk
} from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import './acknowledge-form.scss';
import makeTestId, { IMakeTestIdProps } from '../../../../utils/makeTestId';
import { TEST_IDS } from '../../../../tests/testids';
import { usePutAcknowledgeAlarmMutation } from '../../../../store/services/alarmsApi';
import { ACK_MESSAGE_MAX_CHARACHTERS_LENGTH } from '../../../../constants';
interface Props extends IMakeTestIdProps {
  ticketId: string;
  showAcknowledgeForm: (displayForm: boolean) => void;
}

const AcknowledgeForm: React.FC<Props> = ({ dataTa, ticketId, showAcknowledgeForm }) => {
  const { getTestId } = makeTestId({ dataTa });
  const { t } = useTranslation();
  const [saveAcknowledgment, saveAcknowledgmentResults] = usePutAcknowledgeAlarmMutation();
  const [message, setMessage] = React.useState('');
  const [messageError, setMessageError] = React.useState('');

  const onMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const { value } = e.currentTarget;
    setMessage(value);
    value.length > ACK_MESSAGE_MAX_CHARACHTERS_LENGTH
      ? setMessageError(t('site.characterMaxLengthError', { charLen: ACK_MESSAGE_MAX_CHARACHTERS_LENGTH }))
      : setMessageError('');
  };

  const onCancel = () => {
    showAcknowledgeForm(false);
  };

  const handleSave = async () => {
    await saveAcknowledgment({ ticket_id: ticketId, message: message, ack: true });
    onCancel();
  };

  return (
    <div className="acknowledge-form-container" data-ta={getTestId(TEST_IDS.form_container)}>
      <Heading dataTa={getTestId(TEST_IDS.ack_form_heading)} level={4}>
        {t('profile.acknowledgeAlarms')}
      </Heading>
      <span>
        <RequiredAsterisk />
      </span>
      <span className="vai-margin-left-s">{t('alarms.alarmDetails.actionTaken')}</span>
      <TextArea
        data-ta={getTestId(TEST_IDS.form_message)}
        className="acknowledge-form-message"
        placeholder={t('alarms.alarmDetails.messagePlaceHolder')}
        rows={4}
        value={message}
        onChange={onMessageChange}
      />
      <span data-ta={getTestId(TEST_IDS.error_message)} className="input-error">
        {messageError}
      </span>
      <Flex justifyContent="center">
        <FlexItem>
          <Button
            dataTa={getTestId(TEST_IDS.save_button)}
            buttonType={saveAcknowledgmentResults.isLoading ? ButtonType.Secondary : ButtonType.Primary}
            disabled={message.length === 0 || messageError.length > 0}
            onClick={handleSave}
            className="vai-margin-right-s acknowledge-form-button"
          >
            {saveAcknowledgmentResults.isLoading ? <Spinner /> : t('general.save')}
          </Button>
        </FlexItem>
        <FlexItem>
          <Button
            dataTa={getTestId(TEST_IDS.cancel_button)}
            buttonType={ButtonType.Secondary}
            onClick={onCancel}
            className="vai-margin-left-s acknowledge-form-button"
          >
            {t('general.cancel')}
          </Button>
        </FlexItem>
      </Flex>
    </div>
  );
};
export default AcknowledgeForm;
