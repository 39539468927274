import { BodyText, Icon, IconProps, Size } from '@vaisala/rockhopper-components';
import React from 'react';
import {
  ALARM_PRIORITY,
  ALARM_STATUS_PRIORITY_TRANSLATION_KEYS,
  DEVICE_ALARM_STATUS,
  DEVICE_ALARM_STATUS_TRANSLATION_KEYS
} from '../../../constants';
import { Status } from './StatusItem';
import { useTranslation } from 'react-i18next';
import { AlarmPriorityIcons } from '../Alarms/shared/AlarmIcons';
import { VaiIcon, VaiColor } from '@vaisala/rockhopper-design-tokens';
import { Link } from 'react-router-dom';
import { routes } from '../../../utils';
import { useAppSelector } from '../../../store/hooks';
import { selectSelectedNodeId } from '../../../store/siteTree';

interface Props {
  status: Status;
  alarmCount?: number;
}

const DeviceStatusIcons = {
  ...AlarmPriorityIcons,
  [DEVICE_ALARM_STATUS.OK]: (props: IconProps) => (
    <Icon name={VaiIcon.AlertOk} size={Size.M} color={VaiColor.LeafGreen} {...props} />
  )
};

export const DeviceStatus: React.FC<Props> = ({ status, alarmCount }) => {
  const { t } = useTranslation();
  const typedDeviceStatus = status as keyof typeof DeviceStatusIcons;
  const typedAlarmPriority = status as ALARM_PRIORITY;
  const DeviceStatusIcon = DeviceStatusIcons[typedDeviceStatus];
  const activeAlarmsTranslationKey =
    alarmCount == null || alarmCount === 0 || alarmCount > 1
      ? ALARM_STATUS_PRIORITY_TRANSLATION_KEYS[typedAlarmPriority]?.alarms
      : ALARM_STATUS_PRIORITY_TRANSLATION_KEYS[typedAlarmPriority]?.alarm;

  const isAlarmStatus = ALARM_STATUS_PRIORITY_TRANSLATION_KEYS[typedAlarmPriority];
  const selectedNodeId = useAppSelector(selectSelectedNodeId);
  const deviceAlarmStatusTranslation = DEVICE_ALARM_STATUS_TRANSLATION_KEYS[status as DEVICE_ALARM_STATUS];

  return (
    <BodyText className="status-item__value alarm-status-value">
      {DeviceStatusIcon && <DeviceStatusIcon />}
      {isAlarmStatus ? (
        <Link to={`${routes.site.url}/${routes.site.alarms.url}/${selectedNodeId}`}>
          {alarmCount} {t(activeAlarmsTranslationKey)}
        </Link>
      ) : (
        t(deviceAlarmStatusTranslation)
      )}
    </BodyText>
  );
};
