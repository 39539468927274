import * as React from 'react';
import { BodyText } from '@vaisala/rockhopper-components';
import './empty-tree.scss';

export interface EmptyTreeProps {
  message: React.ReactNode;
}

export function EmptyTree({ message }: EmptyTreeProps) {
  return (
    <div className="site-tree-empty-state">
      <BodyText>{message}</BodyText>
    </div>
  );
}
