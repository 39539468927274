import React, { ReactNode, SyntheticEvent } from 'react';
import { Button, ButtonType, Flex, FlexItem, Icon, InputField, Size } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import {
  APP_NAME,
  DEVICE_SYMBOL_ID_TO_UNIT_ID,
  LS_COMPANY_CUSTOMER_ID,
  MEAS_ID_TO_NIMBUS_R,
  PARENT_SITE_KEY
} from '../../../constants';
import useHtmlId from '../../../hooks/useHtmlId';
import { LocationNode } from '../../../siteTree/site';
import { hideDialog, showDialog } from '../../../store/dialog';
import { useAppDispatch } from '../../../store/hooks';
import { useGetDataSourcesPerSiteQuery } from '../../../store/services/devicesApi';
import { TEST_IDS } from '../../../tests/testids';
import { DataSourceListItemModel } from '../../../types';
import { buildDeviceId, DeviceType, getConstantFromLocalStorage } from '../../../utils';
import CenteredSpinner from '../../BaseComponents/CenteredSpinner';
import ListError from '../../Error/ListError';
import { ModalContainer } from '../../Modals/ModalContainer';
import Infotip from '../../Utils/Infotip';
import MeasurementTypeIcon from '../../Utils/MeasurementTypeIcon';
import { EmptyState } from '../../Utils/EmptyState/EmptyState';
import { IllustrationType } from '../../Utils/EmptyState/Illustration';
import DataSourceList from './DataSourceList';
import DataSourceConfirmationModal from './DataSourceConfirmationModal';
import { LinkNodeArgs, UnlinkNodeArgs } from '../../../store/services/siteApi';

import './index.scss';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { TranslationKey } from '../../../react-i18next';

interface Props {
  location: LocationNode;
  linkTreeNode: (node: LinkNodeArgs) => void;
  unlinkTreeNode: (node: UnlinkNodeArgs) => void;
}

const appName = APP_NAME;
const htmlId = 'link-data-source-dialog';

const LinkDataSourceDialog: React.FC<Props> = ({ location = null, linkTreeNode, unlinkTreeNode }) => {
  const { t } = useTranslation();
  const { getId } = useHtmlId({ htmlId });
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = React.useState<string>('');
  const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID) ?? '';
  // TODO: Release 2 will use multiple sites, this will need a hook/mechanism for storing/retrieving the relevant site.
  const siteId = PARENT_SITE_KEY;
  const { data: dataSources = undefined, error, isLoading, isFetching, refetch } = useGetDataSourcesPerSiteQuery(
    {
      customerId,
      siteId,
      meas_name: location?.meas_id,
      unit_name: location?.symbol_id,
      available: true
    },
    { refetchOnFocus: false, refetchOnMountOrArgChange: true }
  );

  const [initialItem, setInitialItem] = React.useState<DataSourceListItemModel | null>(
    location && location.device
      ? {
          ...location.device,
          latest_measurement: {
            value: location.raw_value ?? null,
            timestamp: location.timestamp ?? null,
            stale: location.is_stale ?? false
          }
        }
      : null
  );
  const [selectedItem, setSelectedItem] = React.useState<DataSourceListItemModel | null>(initialItem);
  const [filteredDataSources, setFilteredDataSources] = React.useState<DataSourceListItemModel[]>(dataSources ?? []);

  const onDismiss = () => {
    dispatch(hideDialog());
  };

  const showDataSourceConfirmationModal = (): void => {
    const newSelectedItem = initialItem && selectedItem == null ? initialItem : selectedItem;
    if (location && newSelectedItem) {
      dispatch(
        showDialog(
          <DataSourceConfirmationModal
            linkTreeNode={linkTreeNode}
            unlinkTreeNode={unlinkTreeNode}
            location={location}
            selectedItem={newSelectedItem}
          />
        )
      );
    }
  };

  const onChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setSearchValue(value);
  };

  const itemHasChanged = () => {
    if (initialItem) {
      // Value was set, and no longer is
      if (selectedItem == null) {
        return true;
      }
      // Validate that the data source selected is in fact different
      return (
        buildDeviceId(initialItem.probe_model, initialItem.probe_sn) !==
        buildDeviceId(selectedItem.probe_model, selectedItem.probe_sn)
      );
    }
    // No initial value and no item is selected
    return selectedItem != null;
  };

  const isSelectedItem = (dataSource: DataSourceListItemModel) => {
    return (
      selectedItem != null &&
      selectedItem.probe_model === dataSource.probe_model &&
      selectedItem.probe_sn === dataSource.probe_sn &&
      selectedItem.meas_group === dataSource.meas_group
    );
  };
  const isInitialItem = (dataSource: DataSourceListItemModel) => {
    return (
      initialItem != null &&
      initialItem.probe_model === dataSource.probe_model &&
      initialItem.probe_sn === dataSource.probe_sn &&
      initialItem.meas_group === dataSource.meas_group
    );
  };

  const filter = (): void => {
    const filterDataSource = (dataSource: DataSourceListItemModel) => {
      const valuesToCheck = [
        buildDeviceId(dataSource.device_model, dataSource.device_sn),
        buildDeviceId(dataSource.probe_model, dataSource.probe_sn)
      ];
      // We want to keep the initial item in the list of datasources after filtering
      const isInitial = isInitialItem(dataSource);
      let result = isInitial;
      if (!result) {
        for (let i = 0; i < valuesToCheck.length; i++) {
          if (valuesToCheck[i].includes(searchValue)) {
            result = true;
            break;
          }
        }
      }
      if (!result && !isInitial && isSelectedItem(dataSource)) {
        setSelectedItem(null);
      }
      return result;
    };
    setFilteredDataSources(dataSources == null ? [] : dataSources.filter(filterDataSource));
  };

  React.useEffect(() => {
    filter();
  }, [searchValue, dataSources]);

  React.useEffect(() => {
    if (location?.device) {
      setInitialItem({
        ...location.device,
        latest_measurement: {
          value: location.raw_value ?? null,
          timestamp: location.timestamp ?? null,
          stale: location.is_stale ?? false
        }
      });
    } else {
      setInitialItem(null);
    }
  }, [location]);

  const cancelId = getId('cancel-button');
  const saveId = getId('save-button');
  const ModalButtons = (
    <>
      <Button
        id={saveId}
        className="vai-space-inline-s"
        htmlId={saveId}
        onClick={showDataSourceConfirmationModal}
        disabled={!itemHasChanged()}
      >
        {t('general.save')}
      </Button>
      <Button id={cancelId} htmlId={cancelId} buttonType={ButtonType.Secondary} onClick={onDismiss}>
        {t('general.cancel')}
      </Button>
    </>
  );
  const renderDataSources = () => {
    const components: ReactNode[] = [];
    const symbolId = location?.symbol_id;
    if ((initialItem || filteredDataSources?.length > 0) && symbolId) {
      components.push(
        <DataSourceList
          symbolId={symbolId}
          key="data-source-list"
          htmlId={htmlId}
          onSelect={selectedItem => setSelectedItem(selectedItem)}
          initialItem={initialItem}
          selectedItem={selectedItem}
          decimalPlaces={location?.decimal_places}
          dataSources={filteredDataSources}
        />
      );
    }
    // If nothing is returned from the API, the text is different on the EmptyState container.
    // When there is a search value and nothing is returned other than the selected or initial item, show the
    // special message for there being nothing found from the current search.
    let descriptionKey: TranslationKey = 'linkDataSourceDialog.emptyDescription';
    let headingKey: TranslationKey = 'linkDataSourceDialog.emptyHeader';
    let showEmptyState = false;
    if (dataSources && dataSources.length > 0) {
      if (searchValue) {
        const filteredDataSourcesWithoutSelected = filteredDataSources.filter(dataSource =>
          isInitialItem(dataSource) ? false : true
        );
        if (filteredDataSourcesWithoutSelected.length === 0) {
          showEmptyState = true;
          descriptionKey = 'linkDataSourceDialog.emptyDescriptionSearch';
          headingKey = 'general.nothingFound';
        }
      }
    } else {
      showEmptyState = true;
    }
    showEmptyState &&
      components.push(
        <EmptyState
          key="empty-state"
          htmlId={getId('data-source-empty-state')}
          description={descriptionKey}
          heading={headingKey}
          illustration={IllustrationType.nothingFound}
        />
      );
    return components;
  };

  return (
    <ModalContainer
      id={getId() as string}
      isOpen
      showCloseIcon={true}
      dismissOnOverlayClick={false}
      title={'linkDataSourceDialog.title'}
      onDismiss={onDismiss}
      buttons={ModalButtons}
    >
      <h3
        id={getId('title')}
        data-ta={TEST_IDS.link_data_source_dialog_location_title}
        className="vai-margin-top-none link-data-source-dialog__title"
      >
        <MeasurementTypeIcon
          type={location?.meas_id ?? null}
          className="vai-margin-right-s link-data-source-dialog__icon"
          htmlId={getId('meas-icon')}
        />
        <span className="link-data-source-dialog__name">{location?.name}</span>
      </h3>
      <Flex alignItems="center">
        <FlexItem
          id={getId('blurb')}
          dataTa={TEST_IDS.link_data_source_dialog_blurb}
          flexGrow={1}
          className="grey-medium"
        >
          {t('linkDataSourceDialog.blurb')}
        </FlexItem>
        <FlexItem>
          <Infotip data-ta={TEST_IDS.link_data_source_dialog_infotip} htmlId={getId('infotip')}>
            {t('infotip.linkDataSource', { appName })}
          </Infotip>
        </FlexItem>
      </Flex>
      <InputField
        htmlId={getId('search-data-sources-input')}
        name="search"
        className="vai-margin-top-m"
        width={Size.Container}
        placeholder={t('general.search')}
        value={searchValue}
        onChange={onChange}
        data-ta={TEST_IDS.link_data_source_dialog_search_input}
        endIcon={
          <Icon
            htmlId={getId('search-data-sources-input-icon')}
            data-ta={TEST_IDS.link_data_source_dialog_search_input_icon}
            name={VaiIcon.Search}
            size={Size.M}
          />
        }
      />
      <div
        id={getId('data-source-list')}
        data-ta={TEST_IDS.data_source_list_container}
        className="data-source--list vai-margin-top-m"
      >
        {isLoading || isFetching ? (
          <CenteredSpinner htmlId={getId('spinner')} />
        ) : error ? (
          <Flex className="margin-auto">
            <ListError
              htmlId={htmlId}
              errorMsgTranslationKey="linkDataSourceDialog.errorLoadingResults"
              refreshResults={refetch}
            />
          </Flex>
        ) : (
          renderDataSources()
        )}
      </div>
    </ModalContainer>
  );
};

export default LinkDataSourceDialog;
