import React, { useEffect, useState } from 'react';
import { Button, ButtonType, Grid, Icon, Size } from '@vaisala/rockhopper-components';
import { ALARM_THRESHOLD_TYPE } from '../../../../../../constants';
import { useTranslation } from 'react-i18next';
import { ThresholdNotificationRow } from './ThresholdNotificationRow';
import { useThresholdModalContext } from '../../ThresholdModalProvider';
import { AlarmSettingFormHeaderRow } from '../../../../Alarms/shared/AlarmSettingFormHeaderRow';
import { VaiColor, VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { TEST_IDS } from '../../../../../../tests/testids';
import { useStepsContext } from '../../../Steps/StepsContextProvider';
import { FormControls } from './FormControls';
import { QA_TEST_IDS } from '../../../../../../tests/qaTestIds';
import './thresholdNotificationsForm.scss';

interface Props {
  onCancel: () => void;
}

export const ThresholdNotificationsForm: React.FC<Props> = ({ onCancel }) => {
  const { t } = useTranslation();
  const { rules, warnings } = useThresholdModalContext();
  const [showWarning, setShowWarning] = useState(false);
  const { goBack } = useStepsContext();

  useEffect(() => {
    let showWarning = false;
    for (const level of Object.keys(ALARM_THRESHOLD_TYPE)) {
      if (warnings[level as ALARM_THRESHOLD_TYPE].hasWarning) {
        showWarning = true;
      }
    }
    setShowWarning(showWarning);
  }, [warnings]);

  const id = QA_TEST_IDS.steps.back;

  return (
    <div className="threshold-notifications-form" data-ta={TEST_IDS.alarm_settings_threshold_notifications_form}>
      <Grid padding={Size.S}>
        <AlarmSettingFormHeaderRow>
          <Grid.Col lg={4} xl={4}>
            {t('site.alarms.settings.threshold.form.headers.values')}
          </Grid.Col>
          <Grid.Col lg={5} xl={5}>
            {t('site.alarms.settings.threshold.form.headers.notificationGroup')}
          </Grid.Col>
          <Grid.Col lg={3} xl={3}>
            {t('site.alarms.settings.threshold.form.headers.notificationMethod')}
          </Grid.Col>
        </AlarmSettingFormHeaderRow>
        <ThresholdNotificationRow
          value={rules.HIHI.value}
          priority={rules.HIHI.priority}
          threshold={ALARM_THRESHOLD_TYPE.HIHI}
        />
        <ThresholdNotificationRow
          value={rules.HI.value}
          priority={rules.HI.priority}
          threshold={ALARM_THRESHOLD_TYPE.HI}
        />
        <ThresholdNotificationRow
          value={rules.LO.value}
          priority={rules.LO.priority}
          threshold={ALARM_THRESHOLD_TYPE.LO}
        />
        <ThresholdNotificationRow
          value={rules.LOLO.value}
          priority={rules.LOLO.priority}
          threshold={ALARM_THRESHOLD_TYPE.LOLO}
        />
      </Grid>
      <div>
        {showWarning && (
          <p>
            <Icon name={VaiIcon.Warning} size={Size.M} color={VaiColor.OrangeDark} />{' '}
            <span>{t('site.alarms.settings.threshold.notifications.warning')}</span>
          </p>
        )}
      </div>
      <div>
        <Button
          className="controls-back-button"
          startIcon={<Icon name={VaiIcon.ArrowLeft} />}
          buttonType={ButtonType.Link}
          onClick={goBack}
          dataTa={TEST_IDS.steps.back}
          htmlId={id}
          id={id}
        >
          {t('general.back')}
        </Button>
        <FormControls onCancel={onCancel} />
      </div>
    </div>
  );
};
