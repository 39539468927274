import React from 'react';
import { useTranslation } from 'react-i18next';
import { TEST_IDS } from '../../../../../tests/testids';
import { Steps } from '../../Steps';
import { LocationNode } from '../../../../../siteTree/site';
import { DeviceAlarmSettings } from '../../../../../store/services/alarmsApi';
import { Group } from '../../../../../store/services/userApi';
import { StepsDisplay } from './StepsDisplay';
import { DeviceAlarmRuleForm } from './DeviceAlarmRulesForm/DeviceAlarmRulesForm';
import { DeviceAlarmModalProvider } from './DeviceAlarmModalProvider';
import { DeviceAlarmNotificationsForm } from './DeviceAlarmNotificationsForm/DeviceAlarmNotificationsForm';

interface Props {
  location: LocationNode | undefined;
  onSave: (settings: DeviceAlarmSettings) => void;
  settingsDetails: { settings: DeviceAlarmSettings | undefined; isFetching: boolean; refetch: () => void };
  notificationGroupsDetails: { groups: Group[] | undefined; isFetching: boolean };
  isSaving?: boolean;
}

export const DeviceAlarmDialogContent: React.FC<Props> = ({
  location,
  notificationGroupsDetails,
  onSave,
  settingsDetails,
  isSaving
}) => {
  const { t } = useTranslation();

  const stepNumberTitles = {
    1: t('site.alarms.settings.device.form.step1.label'),
    2: t('site.alarms.settings.threshold.form.step2.label')
  };

  if (!settingsDetails.settings || !location) {
    return null;
  }

  return (
    <DeviceAlarmModalProvider
      notificationGroupsDetails={notificationGroupsDetails}
      initialSettings={settingsDetails.settings}
      onSave={onSave}
      location={location}
      isSaving={isSaving ?? false}
    >
      <div data-ta={TEST_IDS.alarm_settings_threshold_modal}>
        <Steps>
          <Steps.Display>
            {({ currentStepNumber, stepsCount }) => (
              <StepsDisplay
                currentStepNumber={currentStepNumber}
                location={location}
                stepNumberTitles={stepNumberTitles}
                stepsCount={stepsCount}
              />
            )}
          </Steps.Display>
          <Steps.Step>
            <DeviceAlarmRuleForm />
          </Steps.Step>
          <Steps.Step>
            <DeviceAlarmNotificationsForm />
          </Steps.Step>
        </Steps>
      </div>
    </DeviceAlarmModalProvider>
  );
};
