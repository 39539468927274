import React from 'react';
import { Flex, Grid, Heading } from '@vaisala/rockhopper-components';
import { DEVICE_ALARM_RULE_NAMES } from '../../../../../../constants';
import { AlarmSettingFormRow } from '../../../../Alarms/shared/AlarmSettingFormRow';
import { useTranslation } from 'react-i18next';
import GroupsSelect from '../../../../Alarms/shared/GroupsSelect';
import { Group } from '../../../../../../store/services/userApi';
import { AlarmPriorityIcons } from '../../../../Alarms/shared/AlarmIcons';
import { deviceRuleNameTranslations } from '../shared';
import { useDeviceAlarmModalContext } from '../DeviceAlarmModalProvider';
import { QA_TEST_IDS } from '../../../../../../tests/qaTestIds';

interface Props {
  ruleName: DEVICE_ALARM_RULE_NAMES;
}

export const DeviceAlarmdNotificationRow: React.FC<Props> = ({ ruleName }) => {
  const {
    rules,
    isFetchingGroups,
    checkCanAcknowledgeAlarms,
    notificationSettings,
    notificationGroups,
    updateSettings,
    updateWarnings,
    warnings
  } = useDeviceAlarmModalContext();

  const { t } = useTranslation();

  const updateNotificationsSettings = (updatedList: Group['group_name'][]) => {
    updateSettings(settings => {
      return {
        ...settings,
        settings: {
          ...settings.settings,
          notifications: {
            ...settings.settings.notifications,
            [ruleName as DEVICE_ALARM_RULE_NAMES]: {
              ...settings.settings.notifications[ruleName as DEVICE_ALARM_RULE_NAMES],
              EMAIL: updatedList
            }
          }
        }
      };
    });
  };
  const AlarmPriorityIcon = AlarmPriorityIcons[rules[ruleName].priority];

  return (
    <AlarmSettingFormRow hasWarning={warnings[ruleName].hasWarning}>
      <Grid.Col md={3} lg={3} xl={3}>
        <Flex className="device-alarm-rule-display" justifyContent="space-between" alignContent="center">
          <Heading level={4}>{t(deviceRuleNameTranslations[ruleName])}</Heading>
          <AlarmPriorityIcon />
        </Flex>
      </Grid.Col>
      <Grid.Col md={6} lg={6} xl={6}>
        <GroupsSelect
          domIds={{
            checkboxId: QA_TEST_IDS.alarms_settings_device_notifications_group_select_checkbox_$group_name,
            selectorId: `${QA_TEST_IDS.alarms_settings_device_notifications_group_select_selector_$ruleName}${ruleName}`
          }}
          onBlur={groupsList => {
            updateWarnings(warnings => {
              const updatedWarnings = { ...warnings };
              const selectedGroups = groupsList.filter(group => group.selected);
              updatedWarnings[ruleName].hasWarning =
                selectedGroups.length > 0 ? selectedGroups.every(group => !group.canAcknowledgeAlarms) : false;
              return updatedWarnings;
            });
          }}
          groups={notificationGroups ?? []}
          isLoading={isFetchingGroups}
          onSelect={updateNotificationsSettings}
          selectedGroups={notificationSettings[ruleName].EMAIL}
          checkCanAcknowledgeAlarms={checkCanAcknowledgeAlarms}
        />
      </Grid.Col>
      <Grid.Col sm={2} md={3} lg={3} xl={3}>
        {notificationSettings[ruleName].EMAIL.length > 0 && t('general.email')}
      </Grid.Col>
    </AlarmSettingFormRow>
  );
};
