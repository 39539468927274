import { Size, Button, ButtonType } from '@vaisala/rockhopper-components';
import React from 'react';
import { ModalContainer } from '../../Modals/ModalContainer';
import i18n from '../../../i18n';
import { TranslationKey } from '../../../react-i18next';
import { ParentZone } from './Fields/ParentZone';
import { FormActionType } from '../shared';
import { TEST_IDS } from '../../../tests/testids';
import { ButtonWithLoader } from '../../ButtonWithLoader/ButtonWithLoader';

interface Props {
  title: TranslationKey;
  onDismiss: () => void;
  saveButtonDisabled: boolean;
  onSave: () => void;
  iconName: string;
  parentZoneName?: string;
  action: FormActionType;
  Footer?: JSX.Element;
  loading?: boolean;
}

export const SiteTreeFormDialog: React.FC<Props> = ({
  title,
  onDismiss,
  saveButtonDisabled,
  onSave,
  children,
  iconName,
  parentZoneName,
  Footer,
  action,
  loading
}) => {
  return (
    <ModalContainer
      id={`${action}-zone-and-location-dialog-id`}
      htmlId={`${action}-zone-and-location-dialog`}
      isOpen
      showCloseIcon={true}
      onDismiss={onDismiss}
      width={Size.S}
      dismissOnOverlayClick={false}
      title={title}
      buttons={
        <>
          <ButtonWithLoader
            id={`${action}-zone-and-location-confirm`}
            htmlId={`${action}-zone-and-location-confirm`}
            disabled={saveButtonDisabled}
            onClick={onSave}
            data-ta={TEST_IDS.site_tree_form_dialog_save_button}
            loading={loading}
          >
            {i18n.t('general.save')}
          </ButtonWithLoader>
          <Button
            id={`${action}-zone-and-location-cancel`}
            htmlId={`${action}-zone-and-location-cancel`}
            onClick={onDismiss}
            buttonType={ButtonType.Secondary}
          >
            {i18n.t('general.cancel')}
          </Button>
        </>
      }
      footerChildren={Footer}
    >
      {parentZoneName && <ParentZone iconName={iconName} parentZoneName={parentZoneName} />}
      {children}
    </ModalContainer>
  );
};
