import { Icon, IconProps, Size, Popover, PopoverProps } from '@vaisala/rockhopper-components';
import React, { useEffect } from 'react';
import cx from 'classnames';
import { VaiColor, VaiIcon } from '@vaisala/rockhopper-design-tokens';
import useHtmlId, { IUseHtmlIDProps } from '../../../hooks/useHtmlId';

import './infotip.scss';
import { TEST_IDS } from '../../../tests/testids';

export interface InfotipProps extends IUseHtmlIDProps {
  iconProps?: IconProps;
  popoverProps?: PopoverProps;
  children?: React.ReactNode;
}

const Infotip = ({ htmlId, iconProps = {}, popoverProps = {}, children }: InfotipProps) => {
  const { getId } = useHtmlId({ htmlId, separator: '__' });

  // control the visibility of the popover
  const [visible, setVisible] = React.useState(false);
  const triggerRef = React.useRef<HTMLDivElement | null>(null);
  const popoverContentRef = React.useRef<HTMLDivElement | null>(null);

  const togglePopover = (event?: React.MouseEvent<HTMLSpanElement>) => {
    // Prevent the event from bubbling up to the parent (when infotips are used inside buttons or similar)
    if (event) event.stopPropagation();
    setVisible(!visible);
  };

  useEffect(() => {
    // Close the popover when clicking outside of the popover or the trigger
    const handleClickOutside = (event: MouseEvent) => {
      if (
        triggerRef.current &&
        !triggerRef.current.contains(event.target as Node) &&
        popoverContentRef.current &&
        !popoverContentRef.current.contains(event.target as Node)
      ) {
        setVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    if (!visible) {
      // remove the event listener when the popover is hidden
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [visible, triggerRef]);

  // Set some sane defaults for the icon
  const {
    color = VaiColor.AquaVaisala,
    name = VaiIcon.Help,
    size = Size.M,
    className = null,
    dataTa = TEST_IDS.infotip_icon,
    ...otherIconProps
  } = iconProps;
  const classes = cx('infotip', className);
  const iconId = getId('icon');
  const icon: JSX.Element = (
    <Icon
      htmlId={iconId}
      className={classes}
      color={color}
      name={name}
      size={size}
      dataTa={dataTa}
      {...otherIconProps}
    />
  );
  const {
    dataTa: dataTaPopover = TEST_IDS.infotip_popover,
    showCloseIcon = false,
    ...otherPopoverProps
  } = popoverProps;
  const iconWrapperId = getId('icon-wrapper');
  const popoverId = getId('popover');

  return (
    <>
      <div onClick={togglePopover} id={iconWrapperId} ref={triggerRef} role="button">
        {icon}
      </div>
      <Popover
        id={popoverId}
        visible={visible}
        triggerRef={triggerRef}
        showCloseIcon={showCloseIcon}
        dataTa={dataTaPopover}
        {...otherPopoverProps}
        className={cx('infotip-popover', popoverProps.className)}
      >
        {/** NOTE: Use div instead of BodyText to avoid invalid dom nesting errors in console */}
        <div ref={popoverContentRef} className="vai-body-text">
          {children}
        </div>
      </Popover>
    </>
  );
};

export default Infotip;
