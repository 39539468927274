import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_APPCUES_ACCOUNT_ID, API_APPCUES_BASE_URI } from '../../constants';
import { AppcuesApiUris } from '../../utils';

export type AppcuesTag = {
  created_at: string;
  created_by: string;
  id: string;
  name: string;
  updated_at: string | null;
  updated_by: string | null;
  url: string;
};

export type AppcuesFlow = {
  id: string;
  name: string;
  published: boolean;
  frequency: string;
  tag_ids: string[];
  tags: { [key: string]: boolean };
  created_at: string;
  updated_at: string;
  published_at?: string;
  created_by: string;
  updated_by: string;
  url: string;
} & AppcuesOrder;

export type AppcuesOrder = {
  order?: string;
};

const getAppcuesHeaders = (headers: Headers): Headers => {
  headers.set('Accept', 'application/json');
  headers.set('Content-Type', 'application/json');
  headers.set('Accept-Encoding', 'gzip, deflate, br');
  headers.set('Authorization', `Basic ${process.env.REACT_APP_APPCUES_BASE64_API_KEY}`);
  return headers;
};

export const appcuesApi = createApi({
  reducerPath: 'appcuesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_APPCUES_BASE_URI}${API_APPCUES_ACCOUNT_ID}`,
    prepareHeaders: async headers => {
      headers = getAppcuesHeaders(headers);
      return headers;
    }
  }),

  endpoints: builder => ({
    getTours: builder.query<AppcuesFlow[], void>({
      query: () => {
        return AppcuesApiUris.flows;
      },
      keepUnusedDataFor: 600
    }),
    getTags: builder.query<AppcuesTag[], void>({
      query: () => {
        return AppcuesApiUris.tags;
      }
    })
  })
});

export const { useGetToursQuery, useGetTagsQuery } = appcuesApi;
