import React, { useRef } from 'react';
import { LS_COMPANY_CUSTOMER_ID, SITES_DEFAULT_TOUR_ID } from '../../../constants';
import { getConstantFromLocalStorage } from '../../../utils';
import { useGetNodeLocationsQuery } from '../../../store/services/siteApi';
import { SiteTable } from '../SiteTable';
import { EmptyState } from '../../Utils/EmptyState/EmptyState';
import { useParams } from 'react-router-dom';
import CenteredSpinner from '../../BaseComponents/CenteredSpinner';
import { IllustrationType } from '../../Utils/EmptyState/Illustration';
import useGetUserName from '../../../hooks/useGetUsername';
import useZoneorSiteDescription from '../../../hooks/useZoneorSiteDescription';
import { SiteNode } from '../../../siteTree/site';
import { useResizeObserver } from '../../../hooks/useResizeObserver';
import { QA_TEST_IDS } from '../../../tests/qaTestIds';
import './statuslist.scss';

interface Props {
  siteId: string;
  site: SiteNode | undefined;
  // An error is too difficult to predict what it could be from RTK so this must be handled by the consumer
  onError?: (error: any) => void;
  toggleHighlight?: (node_id: string) => void;
}

export const StatusList: React.FC<Props> = ({ siteId, site, onError, toggleHighlight }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [, descHeight] = useResizeObserver(ref, 0);

  const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID) ?? '';
  const { username } = useGetUserName();
  const id = useParams<{ id?: string }>().id ?? '';

  const { data: nodes, isFetching, error } = useGetNodeLocationsQuery(
    { customerId, username: username ?? '', nodeId: id || siteId },
    { skip: username === undefined || username === 'undefined' || username === null }
  );

  const { description } = useZoneorSiteDescription(site, id);

  React.useEffect(() => {
    if (onError && error) {
      onError(error);
    }
  }, [error]);

  if (!nodes || isFetching) {
    return <CenteredSpinner />;
  }

  if (nodes.length <= 0 && !id) {
    return (
      <EmptyState
        illustration={IllustrationType.positive}
        heading="site.emptyHeader"
        description="site.emptyBody"
        link={{
          onClick: () => window.Appcues.show(SITES_DEFAULT_TOUR_ID),
          desc: 'site.emptyTourLink'
        }}
      />
    );
  }

  if (nodes.length <= 0) {
    return <EmptyState illustration={IllustrationType.emptyFolder} heading="site.emptyZoneHeading" />;
  }
  return (
    <div className="statuslist__container">
      {description && (
        <div className="statuslist__description" data-ta={QA_TEST_IDS.site_status_list_description} ref={ref}>
          {description}
        </div>
      )}
      <SiteTable
        descTextHeight={description ? descHeight : undefined}
        toggleHighlight={toggleHighlight}
        key={id}
        data={nodes}
        type="status"
      />
    </div>
  );
};
