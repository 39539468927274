import { ReactElement } from 'react';
import { useStepsContext } from './StepsContextProvider';

interface Props {
  children: ({ currentStepNumber, stepsCount }: { currentStepNumber: number; stepsCount: number }) => ReactElement;
}

export const Display: React.FC<Props> = ({ children }) => {
  const { currentStepIndex, stepsCount } = useStepsContext();

  return children({ currentStepNumber: currentStepIndex + 1, stepsCount });
};

Display.displayName = 'Display';
