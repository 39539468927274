import { BodyText, Button, ButtonType, Flex, Heading } from '@vaisala/rockhopper-components';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useHtmlId, { IUseHtmlIDProps } from '../../../hooks/useHtmlId';
import { TranslationKey } from '../../../react-i18next';
import { Illustration, Illustrations } from './Illustration';

import './empty-state.scss';
import { TEST_IDS } from '../../../tests/testids';
import makeTestId from '../../../utils/makeTestId';

export interface EmptyStateProps extends IUseHtmlIDProps {
  heading: TranslationKey;
  headingParams?: Record<any, any>;
  descriptionObj?: JSX.Element;
  description?: TranslationKey;
  descriptionParams?: Record<any, any>;
  illustration: Illustrations;
  link?: {
    to?: string;
    desc: TranslationKey;
    onClick?: () => void;
  };
  dataTa?: string;
}

export const EmptyState: React.FC<EmptyStateProps> = ({
  heading,
  headingParams,
  descriptionObj,
  description,
  descriptionParams,
  illustration,
  link,
  htmlId,
  children,
  dataTa
}) => {
  const { t } = useTranslation();
  const { getId } = useHtmlId({ htmlId, separator: '__' });
  const { getTestId } = makeTestId({ dataTa });
  const hasDescription = Boolean(description || descriptionObj);

  return (
    <Flex
      dataTa={getTestId(TEST_IDS.empty_state_wrapper)}
      id={getId('container')}
      className="empty-state empty-state__container"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Illustration id={getId('illustration')} illustration={illustration} />

      <Heading htmlId={getId('heading')} className="empty-state__heading" dataTa={TEST_IDS.empty_state_title}>
        {t(heading, headingParams)}
      </Heading>
      {hasDescription && (
        <BodyText htmlId={getId('description')} className="empty-state__body-text" dataTa={TEST_IDS.empty_state_desc}>
          {descriptionObj ? descriptionObj : description && t(description, descriptionParams)}
        </BodyText>
      )}
      {link?.to && (
        <Link id={getId('link')} className="empty-state__link" to={link.to} data-ta={TEST_IDS.empty_state_link}>
          {t(link.desc)}
        </Link>
      )}
      {link?.onClick && (
        <Button
          buttonType={ButtonType.Link}
          id={getId('link')}
          onClick={link?.onClick}
          className="empty-state__link"
          data-ta={TEST_IDS.empty_state_link}
        >
          {t(link?.desc)}
        </Button>
      )}
      {children}
    </Flex>
  );
};
