/* eslint-disable react/prop-types */
import React, { NamedExoticComponent } from 'react';
import { StepsContextProvider } from './StepsContextProvider';
import { Display as _Display } from './Display';
import { Step } from './Step';
import { CurrentStep } from './CurrentStep';

interface Props {
  children: React.ReactElement<any, NamedExoticComponent>[];
  currentStepNumber?: number;
  onStepChange?: (step: number) => void;
}

interface StepsType<T> extends React.FC<T> {
  Step: typeof Step;
  Display: typeof _Display;
}

/**
 *
 * @param children
 *  Steps.Step as children each being a separate step in side the steps container
 *  Steps.Display as children will receive current step index and steps count from the context
 * @returns
 */

export const Steps: StepsType<Props> = ({ children, currentStepNumber }) => {
  const steps = children.filter(child => child.type.displayName === Step.displayName);
  const Display = () => children.find(child => child.type.displayName === _Display.displayName) ?? <></>;

  return (
    <StepsContextProvider currentStepNumber={currentStepNumber} stepsCount={steps.length}>
      <Display />
      <CurrentStep steps={steps} />
    </StepsContextProvider>
  );
};

Steps.Step = Step;
Steps.Display = _Display;
