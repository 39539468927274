import React from 'react';
import {
  addMinutes,
  addYears,
  differenceInMinutes,
  format,
  isAfter,
  isBefore,
  isSameDay,
  set,
  setHours,
  setMinutes,
  subDays,
  subMinutes,
  subYears
} from 'date-fns';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { DatePicker, DatePickerProps, Size } from '@vaisala/rockhopper-components';

import { MAX_TIME_DURATION_IN_YEARS, MIN_TIME_DURATION_IN_MINUTES } from '../constants';
import { convertLocalTimeToUTC, isTimeBeforeTime } from '../utils';
import makeTestId, { IMakeTestIdProps } from '../utils/makeTestId';
import TimePicker, { TimePickerProps } from '../components/BaseComponents/TimePicker/TimePicker';
import { useAppSelector } from '../store/hooks';
import { selectTimeZone } from '../store/profile';
import { TEST_IDS } from '../tests/testids';

interface SecondsMilliseconds {
  seconds: number;
  milliseconds: number;
}

export const MAX_SECONDS_MILLISECONDS: SecondsMilliseconds = { seconds: 59, milliseconds: 999 };
export const MIN_SECONDS_MILLISECONDS: SecondsMilliseconds = { seconds: 0, milliseconds: 0 };

export interface DateFilterOptions {
  fromDate: Date;
  toDate: Date;
}

export interface NullableDateFilterOptions {
  fromDate: Date | null;
  toDate: Date | null;
}

export interface Props extends IMakeTestIdProps {
  today: Date;
  initial?: NullableDateFilterOptions;
  onChangeFromDate?: (newFromDate: Date) => void;
  onChangeFromTime?: (newFromDate: Date) => void;
  onChangeToDate?: (newToDate: Date) => void;
  onChangeToTime?: (newToDate: Date) => void;
}

interface DateInputProps {
  datePickerProps?: Partial<DatePickerProps>;
  timePickerProps?: Partial<TimePickerProps>;
}

interface ReturnProps {
  filterOptions: DateFilterOptions;
  setFilterOptions: React.Dispatch<React.SetStateAction<DateFilterOptions>>;
  renderFrom: (props?: DateInputProps) => JSX.Element;
  renderTo: (props?: DateInputProps) => JSX.Element;
}

const useDateFilterOptions = ({
  today,
  initial,
  onChangeFromDate,
  onChangeFromTime,
  onChangeToDate,
  onChangeToTime,
  dataTa
}: Props): ReturnProps => {
  const { getTestId } = makeTestId({ dataTa });
  const { t } = useTranslation();
  const timezone = useAppSelector(selectTimeZone);
  let now: Date | number = convertLocalTimeToUTC(set(today, MAX_SECONDS_MILLISECONDS), timezone);
  const timezoneDiff = differenceInMinutes(now, set(today, MAX_SECONDS_MILLISECONDS));
  now = subMinutes(now, timezoneDiff);
  const initialDateTime = format(now, 'HH:mm');
  const initialToDate = set(now, MAX_SECONDS_MILLISECONDS);
  const initialFilterOptions: DateFilterOptions = {
    fromDate: initial?.fromDate || set(subDays(now, 1), MIN_SECONDS_MILLISECONDS),
    toDate: initial?.toDate || initialToDate
  };

  const [filterOptions, setFilterOptions] = React.useState(initialFilterOptions);

  React.useEffect(() => {
    // Wait for invalidTimes variable to change
    const [fromHours, fromMinutes] = format(filterOptions.fromDate, 'HH:mm').split(':');
    let fromDateWhole = set(filterOptions.fromDate, {
      hours: +fromHours,
      minutes: +fromMinutes,
      ...MIN_SECONDS_MILLISECONDS
    });

    const [toHours, toMinutes] = format(filterOptions.toDate, 'HH:mm').split(':');
    let toDateWhole = set(filterOptions.toDate, {
      hours: +toHours,
      minutes: +toMinutes,
      ...MIN_SECONDS_MILLISECONDS
    });
    const options: Record<any, any> = {};
    const earliestPossibleTime = set(
      subMinutes(subYears(now, 3), MIN_TIME_DURATION_IN_MINUTES),
      MIN_SECONDS_MILLISECONDS
    );
    const isFromBeforeEarliestAllowed = isBefore(fromDateWhole, earliestPossibleTime);
    // If the FROM is over 3 years in the past to the current hour/minute, set the date and time to today's date and time.
    if (isFromBeforeEarliestAllowed) {
      options.fromDate = earliestPossibleTime;
    } else if (toDateWhole.toString() === fromDateWhole.toString()) {
      options.toDate = set(addMinutes(fromDateWhole, MIN_TIME_DURATION_IN_MINUTES), MAX_SECONDS_MILLISECONDS);
    }
    fromDateWhole = options.fromDate || fromDateWhole;
    toDateWhole = options.toDate || toDateWhole;
    // If FROM is set to more than one year earlier than the current TO, TO will be reset to the MAX difference which is one year after FROM.
    if (isBefore(fromDateWhole, subYears(toDateWhole, MAX_TIME_DURATION_IN_YEARS))) {
      options.toDate = addYears(filterOptions.fromDate, MAX_TIME_DURATION_IN_YEARS);
      // If FROM is set to any date/time later than the current TO,  TO will be reset to the MIN difference which is 5 minutes after FROM.
    } else if (isAfter(fromDateWhole, toDateWhole) || fromDateWhole.toString() === earliestPossibleTime.toString()) {
      options.toDate = set(addMinutes(fromDateWhole, MIN_TIME_DURATION_IN_MINUTES), MAX_SECONDS_MILLISECONDS);
    }
    if (!_.isEmpty(options)) {
      setFilterOptions({ ...filterOptions, ...options });
      if (options.toDate) {
        onChangeToTime?.(options.toDate);
      }
      if (options.fromDate) {
        onChangeFromDate?.(options.fromDate);
      }
    }
  }, [filterOptions.fromDate, today]);

  React.useEffect(() => {
    // Wait for invalidTimes variable to change
    const [fromHours, fromMinutes] = format(filterOptions.fromDate, 'HH:mm').split(':');
    const fromDateWhole = set(filterOptions.fromDate, {
      hours: +fromHours,
      minutes: +fromMinutes,
      ...MIN_SECONDS_MILLISECONDS
    });
    const [toHours, toMinutes] = format(filterOptions.toDate, 'HH:mm').split(':');
    const toDateWhole = set(filterOptions.toDate, {
      hours: +toHours,
      minutes: +toMinutes,
      ...MIN_SECONDS_MILLISECONDS
    });

    const options: Record<any, any> = {};
    const earliestPossibleTime = set(
      subMinutes(subYears(now, 3), MIN_TIME_DURATION_IN_MINUTES),
      MIN_SECONDS_MILLISECONDS
    );
    // const isFromBeforeEarliestAllowed = isBefore(fromDateWhole, earliestPossibleTime);
    const isToFromSame = toDateWhole.toString() === fromDateWhole.toString();
    const isFromEqualEarliestAllowed = fromDateWhole.toString() === earliestPossibleTime.toString();
    // const isToEqualEarliestAllowed = toDateWhole.toString() === earliestPossibleTime.toString();
    // If TO is set to any date/time earlier than the current FROM, FROM will be reset to the MIN difference which is 5 minutes before TO
    if (isBefore(toDateWhole, fromDateWhole) || isToFromSame) {
      if (isFromEqualEarliestAllowed) {
        options.toDate = set(addMinutes(earliestPossibleTime, MIN_TIME_DURATION_IN_MINUTES), MAX_SECONDS_MILLISECONDS);
      } else {
        options.fromDate = set(subMinutes(toDateWhole, MIN_TIME_DURATION_IN_MINUTES), MIN_SECONDS_MILLISECONDS);
      }
      // If TO date is later than the current allowed to time, set to the current allowed date time.
    } else if (isAfter(toDateWhole, now)) {
      const [hours, minutes] = initialDateTime.split(':');
      options.toDate = set(now, { hours: +hours, minutes: +minutes, ...MAX_SECONDS_MILLISECONDS });
      // If TO is set to more than one year later than the current FROM, FROM will be reset to the MAX difference which is one year before TO.
    } else if (isAfter(toDateWhole, addYears(fromDateWhole, MAX_TIME_DURATION_IN_YEARS))) {
      options.fromDate = set(subYears(filterOptions.toDate, MAX_TIME_DURATION_IN_YEARS), MIN_SECONDS_MILLISECONDS);
    }

    if (!_.isEmpty(options)) {
      setFilterOptions({ ...filterOptions, ...options });
      if (options.fromDate) {
        onChangeFromDate?.(options.fromDate);
      }
      if (options.toDate) {
        onChangeToDate?.(options.toDate);
      }
    }
  }, [filterOptions.toDate]);

  const handleChangeFromDate = (value: Date) => {
    let fromDate: Date;
    if (value === null) {
      // When the user deletes all characters this will return null
      return;
    }
    const fromHoursMinutes = format(filterOptions.fromDate, 'HH:mm');
    const [hours, minutes] = fromHoursMinutes.split(':');
    // Change fromDateTime if today is selected and the time will be disabled
    if (
      isSameDay(value, now) &&
      !isTimeBeforeTime(fromHoursMinutes, format(subMinutes(now, MIN_TIME_DURATION_IN_MINUTES), 'HH:mm'))
    ) {
      fromDate = set(setHours(setMinutes(now, +minutes), +hours), MIN_SECONDS_MILLISECONDS);
      setFilterOptions(prevValue => ({
        ...prevValue,
        fromDate
      }));
    } else {
      fromDate = set(setHours(setMinutes(value, +minutes), +hours), MIN_SECONDS_MILLISECONDS);
      setFilterOptions(prevValue => ({ ...prevValue, fromDate }));
    }
    onChangeFromDate?.(fromDate);
  };

  const filterDateFunc = (value: Date) => {
    // Cannot choose dates before three year ago from the today or after today
    return !isBefore(value, subYears(subDays(now, 1), 3)) && !isAfter(value, now);
  };

  const handleChangeFromTime = (value: string) => {
    const [hours, minutes] = value.split(':');
    const newDate = setHours(setMinutes(filterOptions.fromDate, +minutes), +hours);
    onChangeFromTime?.(newDate);
    setFilterOptions(prevValue => ({ ...prevValue, fromDate: newDate }));
  };

  const renderFrom = (props?: DateInputProps): JSX.Element => {
    const { datePickerProps, timePickerProps } = props || {};
    const datePickerDataTa = getTestId(TEST_IDS.from_date_picker);
    const timePickerDataTa = getTestId(TEST_IDS.from_time_picker);
    return (
      <>
        <DatePicker
          dataTa={datePickerDataTa}
          config={{
            name: 'fromDate'
          }}
          dateFormat="yyyy-MM-dd"
          filterDate={filterDateFunc}
          value={filterOptions.fromDate}
          onChange={handleChangeFromDate}
          width={Size.S}
          placeholder={t('general.selectEllipsis')}
          {...(datePickerProps || {})}
        />
        <TimePicker
          id={timePickerDataTa}
          testId={timePickerDataTa}
          value={format(filterOptions.fromDate, 'HH:mm')}
          onChange={handleChangeFromTime}
          // Disable times only if less than one year before today's time
          {...(isSameDay(subYears(now, 3), filterOptions.fromDate)
            ? { minTime: format(subMinutes(now, MIN_TIME_DURATION_IN_MINUTES), 'HH:mm') }
            : {})}
          // Disable times if same day as today and subtract 5 minutes from today's time
          {...(isSameDay(filterOptions.fromDate, now)
            ? { maxTime: format(subMinutes(now, MIN_TIME_DURATION_IN_MINUTES), 'HH:mm') }
            : {})}
          {...(timePickerProps || {})}
        />
      </>
    );
  };

  const handleChangeToDate = (value: Date) => {
    if (value === null) {
      // When the user deletes all characters this will return null
      return;
    }
    onChangeToDate?.(value);
    setFilterOptions(prevValue => ({ ...prevValue, toDate: value }));
  };

  const handleChangeToTime = (value: string) => {
    const [hours, minutes] = value.split(':');
    const newDate = set(filterOptions.toDate, { minutes: +minutes, hours: +hours, ...MAX_SECONDS_MILLISECONDS });
    onChangeToTime?.(newDate);
    setFilterOptions(prevValue => ({ ...prevValue, toDate: newDate }));
  };

  const renderTo = (props?: DateInputProps): JSX.Element => {
    const { datePickerProps, timePickerProps } = props || {};
    const datePickerDataTa = getTestId(TEST_IDS.to_date_picker);
    const timePickerDataTa = getTestId(TEST_IDS.to_time_picker);
    return (
      <>
        <DatePicker
          dataTa={datePickerDataTa}
          value={filterOptions.toDate}
          dateFormat="yyyy-MM-dd"
          onChange={handleChangeToDate}
          filterDate={filterDateFunc}
          width={Size.S}
          config={{
            name: 'toDate'
          }}
          placeholder={t('general.selectEllipsis')}
          {...(datePickerProps || {})}
        />
        <TimePicker
          id={timePickerDataTa}
          testId={timePickerDataTa}
          value={format(filterOptions.toDate, 'HH:mm')}
          onChange={handleChangeToTime}
          // Disable times only if greater than current time
          {...(isSameDay(filterOptions.toDate, now) ? { maxTime: initialDateTime } : {})}
          {...(isSameDay(subYears(now, 3), filterOptions.toDate) ? { minTime: initialDateTime } : {})}
          {...(timePickerProps || {})}
        />
      </>
    );
  };

  return { filterOptions, setFilterOptions, renderFrom, renderTo };
};

export default useDateFilterOptions;
