import { NotificationType } from '@vaisala/rockhopper-components';
import { useEffect } from 'react';
import i18n from '../i18n';
import { useAppDispatch } from '../store/hooks';
import { addNotification } from '../store/notifications';
import { useActivateUserMutation } from '../store/services/userApi';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import * as ActionType from '../store/actions/actionTypes';
import { isQueryEnded } from '../utils';

const useUserActions = () => {
  const dispatch = useAppDispatch();

  const [toggleUserActivation, userActivationResultDetails] = useActivateUserMutation();

  useEffect(() => {
    if (userActivationResultDetails.status === QueryStatus.fulfilled && userActivationResultDetails.originalArgs) {
      if (userActivationResultDetails.isSuccess) {
        const { userName, fullName, active } = userActivationResultDetails.originalArgs;
        dispatch({ type: ActionType.TOGGLE_USER_ACTIVATION_SUCCESS });
        if (active) {
          dispatch(
            addNotification({
              type: NotificationType.Ok,
              content: i18n.t('profile.NotifyUserActivated', {
                userName: fullName || userName
              })
            })
          );
        } else {
          dispatch(
            addNotification({
              type: NotificationType.Ok,
              content: i18n.t('profile.NotifyUserDeactivated', {
                userName: fullName || userName
              })
            })
          );
        }
      }
    }
    if (userActivationResultDetails.status === QueryStatus.rejected && userActivationResultDetails.originalArgs) {
      if (userActivationResultDetails.isError) {
        const { userName, fullName } = userActivationResultDetails.originalArgs;
        dispatch({ type: ActionType.TOGGLE_USER_ACTIVATION_FAIL });
        dispatch(
          addNotification({
            type: NotificationType.Error,
            content: i18n.t('profile.NotifyUserActivationFailed', {
              userName: fullName || userName
            })
          })
        );
      }
    }
  }, [
    userActivationResultDetails.status,
    userActivationResultDetails.isSuccess,
    userActivationResultDetails.isError,
    userActivationResultDetails.originalArgs
  ]);

  return {
    toggleUserActivation,
    userActivationResultDetails: {
      ...userActivationResultDetails,
      isQueryEnded: isQueryEnded(userActivationResultDetails.status, userActivationResultDetails.isUninitialized)
    }
  };
};

export default useUserActions;
