import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import React, { useEffect } from 'react';
import { SiteNodeTypes } from '../../constants';
import { CreateTreeNodeInterface } from '../../siteTree/site';
import { LocationFormDialog } from './Location/LocationFormDialog';
import { ZoneFormDialog } from './Zone/ZoneFormDialog';
import { FormActionType } from './shared';
import { NodeActions, getChildList, getNodeObjectById } from './SiteTree';
import { useAppSelector } from '../../store/hooks';
import { selectSelectedNodeId } from '../../store/siteTree';
import { TreeMenuNode } from '../../utils';

interface Props {
  currentAction: NodeActions;
  treeData: TreeMenuNode[][];
  onDismiss: () => void;
  addNodeApiDetails: {
    createTreeNode: (args: CreateTreeNodeInterface) => void;
    addNodeResultDetails: { isLoading?: boolean; isQueryEnded?: boolean };
  };
}

export const CreateActionDialogs: React.FC<Props> = ({ currentAction, treeData, onDismiss, addNodeApiDetails }) => {
  const { addNodeResultDetails, createTreeNode } = addNodeApiDetails;
  const selectedNodeId = useAppSelector(selectSelectedNodeId);

  useEffect(() => {
    if (addNodeResultDetails.isQueryEnded) {
      onDismiss && onDismiss();
    }
  }, [addNodeResultDetails.isQueryEnded]);

  if (currentAction === null || currentAction === NodeActions.EDIT_NODE) return null;

  const selectedItemObject = getNodeObjectById(selectedNodeId, treeData[0]);

  const childNames = getChildList(selectedNodeId, treeData[0]).filter(
    item => item !== selectedItemObject.text.toLocaleLowerCase()
  );
  // if any children in parent of the newTreeNode, use last item position + 1.
  // if no children, set starting position to 1.
  const position =
    selectedItemObject.children.length > 0
      ? selectedItemObject.children[selectedItemObject.children.length - 1].position + 1
      : 1;

  const iconName = selectedItemObject.type === SiteNodeTypes.SITE ? VaiIcon.MapMarkerHexagon : VaiIcon.FolderOpen;

  switch (currentAction) {
    case NodeActions.NEW_ZONE:
      return (
        <ZoneFormDialog
          formAction={FormActionType.CREATE}
          onSave={zoneNode => {
            createTreeNode({
              ...zoneNode,
              position,
              parent_id: selectedItemObject.id,
              type: SiteNodeTypes.ZONE
            });
          }}
          onDismiss={onDismiss}
          loading={addNodeResultDetails.isLoading}
          icon={iconName}
          parentName={selectedItemObject.text}
          siblingNames={childNames}
        />
      );
    case NodeActions.NEW_LOCATION:
      return (
        <LocationFormDialog
          onDismiss={onDismiss}
          formAction={FormActionType.CREATE}
          parentName={selectedItemObject.text}
          icon={iconName}
          onSave={locationNode =>
            createTreeNode({
              ...locationNode,
              position,
              parent_id: selectedItemObject.id,
              type: SiteNodeTypes.LOCATION
            })
          }
          loading={addNodeResultDetails.isLoading}
          siblingNames={childNames}
        />
      );
    default:
      return null;
  }
};
