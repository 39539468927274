import * as React from 'react';
import { Heading, Button, ButtonType } from '@vaisala/rockhopper-components';
import { routes } from '../../utils/routes';
import './reset-password-confirmation.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { APP_NAME_FULL } from '../../constants';

const ResetPasswordConfirmation = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goBack = () => {
    navigate(routes.login.url);
    // TODO: Should this be removed, or is this still needed
    //props.MFABackToLogin();
  };

  return (
    <div>
      <Heading id="reset-password-confirmation-help-heading" level={2}>
        {t('auth.checkEmailTitle')}
      </Heading>
      <p id="reset-password-confirmation-help-check-email-body" className="reset-password-confirmation-help">
        {t('auth.checkEmailBodyText', { appName: APP_NAME_FULL })}
      </p>
      <p
        id="reset-password-confirmation-help-check-email-warning"
        className="reset-password-confirmation-help vai-margin-top-m"
      >
        {t('auth.checkEmailWarning')}
      </p>
      <Button
        id="reset-password-confirmation-button-back"
        buttonType={ButtonType.Link}
        className="vai-margin-top-m"
        htmlId="reset-password-confirmation-button-back"
        onClick={goBack}
      >
        {t('general.back')}
      </Button>
    </div>
  );
};

export default ResetPasswordConfirmation;
