import * as React from 'react';
import { Buffer } from 'buffer';
import { includes } from 'lodash';
import { Form, Heading, Button } from '@vaisala/rockhopper-components';
import { routes } from '../../utils/routes';
import { AuthError, ChangingPasswordError } from '../../store/actions/auth';
import { LS_COMPANY_ALIAS, LS_LOCALE } from '../../constants';
import { IPasswordPolicy } from '../../utils/common';
import { decodeURIPrime } from '../../utils/common';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PasswordHelpText } from './PasswordHelpText';
import CenteredSpinner from '../BaseComponents/CenteredSpinner';
import usePasswordValidators from '../../hooks/usePasswordValidators';
import { Languages } from '../../i18n';

interface SetUpPasswordProps {
  login: (username: string, password: string, companyName: string, isFirstLogin: boolean) => void;
  setUpPassword: (username: string, oldPassword: string, newPassword: string) => void;
  changeLanguage: (language: Languages) => void;
  language: Languages;
  passwordPolicyLength: number;
  passwordPolicyMaxLength: number;
  passwordPolicyNumberRequired: boolean;
  passwordPolicySpecialCharRequired: boolean;
  passwordPolicyUpperCaseRequired: boolean;
  passwordPolicyLowerCaseRequired: boolean;
  changingPasswordError?: string;
  setUpPasswordPolicy: () => void;
  isSignUp: boolean;
  firstLoginError: AuthError;
  loading: boolean;
}

const SetUpPassword = ({
  login,
  setUpPassword,
  changeLanguage,
  language,
  passwordPolicyLength,
  passwordPolicyMaxLength,
  passwordPolicyNumberRequired,
  passwordPolicySpecialCharRequired,
  passwordPolicyUpperCaseRequired,
  passwordPolicyLowerCaseRequired,
  changingPasswordError,
  setUpPasswordPolicy,
  isSignUp,
  firstLoginError,
  loading
}: SetUpPasswordProps): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [urlUsername, setUrlUsername] = React.useState('');
  const [urlPassword, setUrlPassword] = React.useState('');

  const passwordBlackList: Array<string> = [];
  const companyAlias = localStorage.getItem(LS_COMPANY_ALIAS);
  if (companyAlias) passwordBlackList.push(companyAlias);

  const passwordPolicy: IPasswordPolicy = {
    minimumLength: passwordPolicyLength,
    maximumLength: passwordPolicyMaxLength,
    isNumberRequired: passwordPolicyNumberRequired,
    isSpecialCharRequired: passwordPolicySpecialCharRequired,
    isUpperCaseRequired: passwordPolicyUpperCaseRequired,
    isLowerCaseRequired: passwordPolicyLowerCaseRequired
  };

  const {
    isButtonDisabled,
    renderConfirmPassword,
    renderNewPassword,
    setNewPasswordError,
    newPassword
  } = usePasswordValidators({
    loading,
    // displaySize: displaySize,
    t,
    language,
    passwordPolicy,
    passwordBlackList
  });

  React.useEffect(() => {
    // MAYBE TODO: Maybe add this to the usePasswordValidators hook. Would need to be able to tell the hook which error setter needs called though.
    if (
      includes(
        [ChangingPasswordError.NotAuthorizedException, ChangingPasswordError.InvalidParameterException],
        changingPasswordError
      )
    ) {
      setNewPasswordError('auth.invalidPassword');
    } else if (changingPasswordError === ChangingPasswordError.LimitExceededException) {
      setNewPasswordError('auth.limitExceeded');
    } else if (changingPasswordError === ChangingPasswordError.InvalidPasswordException) {
      setNewPasswordError('auth.doNotMeetCriteria');
    } else {
      setNewPasswordError('');
    }
  }, [changingPasswordError]);

  React.useEffect(() => {
    if (!isSignUp) {
      let urlCompanyAlias = '';
      let urlUsernameParameter = '';
      let urlPasswordParameter = '';
      const decodedString = Buffer.from(location.search, 'base64').toString('ascii');
      const decodedArray = decodedString.split('&');
      let userParameters: string[] = [];
      decodedArray.map(param => {
        userParameters = param.split('=');
        if (userParameters[0] === 'username') {
          urlUsernameParameter = decodeURIPrime(userParameters[1]);
          setUrlUsername(urlUsernameParameter);
          passwordBlackList.push(urlUsernameParameter);
        }
        if (userParameters[0] === 'password') {
          urlPasswordParameter = decodeURIPrime(userParameters[1]);
          setUrlPassword(urlPasswordParameter);
        }
        if (userParameters[0] === LS_LOCALE) {
          changeLanguage(decodeURIPrime(userParameters[1]) as Languages);
        }
        if (userParameters[0] === LS_COMPANY_ALIAS) {
          urlCompanyAlias = decodeURIPrime(userParameters[1]);
          localStorage.setItem(LS_COMPANY_ALIAS, urlCompanyAlias);
          passwordBlackList.push(urlCompanyAlias);
          setUpPasswordPolicy();
        }
      });
      login(urlUsernameParameter, urlPasswordParameter, urlCompanyAlias, true);
    }
  }, []);

  const onChangePassword = () => {
    setUpPassword(urlUsername, urlPassword, newPassword);
  };

  if (firstLoginError === AuthError.firstLoginInvalidCredentialError) {
    navigate(routes.login.url);
  }

  let content = (
    <CenteredSpinner style={{ marginLeft: '-17px' }} htmlId="setup-password-spinner" className="vai-padding-m" />
  );
  if (isSignUp) {
    content = (
      <Form htmlId="setup-password-form">
        <Heading htmlId="setup-password-label" level={2}>
          {t('signup.dialogTitle')}
        </Heading>
        <PasswordHelpText passwordPolicy={passwordPolicy} />
        {renderNewPassword()}
        {renderConfirmPassword()}
        <div id="buttons-container" className="vai-margin-top-m flex-end">
          <Button
            disabled={isButtonDisabled}
            id="setup-password-next-button"
            htmlId="setup-password-next-button"
            onClick={onChangePassword}
          >
            {loading ? <CenteredSpinner htmlId="save-button-spinner" className="" /> : t('auth.next')}
          </Button>
        </div>
      </Form>
    );
  }
  return <div id="setup-password-wrapper">{content}</div>;
};

export default SetUpPassword;
