import { Flex, FlexProps, Spinner } from '@vaisala/rockhopper-components';
import * as React from 'react';
import { Omit } from '../../types';
import { TEST_IDS } from '../../tests/testids';

interface CenteredSpinnerProps {
  htmlId?: string;
  dataTa?: string;
  className?: string;
  wrapperProps?: Omit<FlexProps, 'children'>;
  style?: React.CSSProperties;
  children?: React.ReactChildren;
}

const CenteredSpinner: React.FunctionComponent<CenteredSpinnerProps> = props => {
  const className_ = typeof props.className !== 'undefined' ? props.className : 'vai-margin-top-m';
  const htmlId_ = typeof props.htmlId !== 'undefined' ? props.htmlId : 'centered-spinner';
  const { className = className_, dataTa = TEST_IDS.spinner, htmlId = htmlId_, wrapperProps = {} } = props;
  return (
    <Flex style={props.style} justifyContent="center" {...wrapperProps}>
      <Spinner dataTa={dataTa} htmlId={htmlId} className={className} />
      {props.children}
    </Flex>
  );
};

export default CenteredSpinner;
