import { BodyText, Button, ButtonType, Heading, Stack, Size, Separator, Icon } from '@vaisala/rockhopper-components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetTagsQuery, useGetToursQuery } from '../../../store/services/appcuesApi';
import { TEST_IDS } from '../../../tests/testids';
import { EmptyState } from '../../Utils/EmptyState/EmptyState';
import CenteredSpinner from '../../BaseComponents/CenteredSpinner';
import {
  createIconMap,
  filterPublishedFlows,
  filterTagsByGroup,
  getFlowIconName,
  GroupedFlows,
  groupFlowsByTags,
  orderFlowsByOrderTag,
  orderGroupTags
} from '../../../utils/appcues';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';

const Tours: React.FC = () => {
  const { t } = useTranslation();

  const { data: flows, isLoading: flowsIsLoading } = useGetToursQuery();
  const { data: tags, isLoading: _tagsIsLoading } = useGetTagsQuery();

  const [groupedFlows, setGroupedFlows] = React.useState<GroupedFlows[]>();
  const [iconMap, setIconMap] = React.useState<Record<string, string>>({});

  useEffect(() => {
    if (flows && tags) {
      const groupTags = filterTagsByGroup(tags);
      const publishedFlows = filterPublishedFlows(flows);
      const orderedFlows = orderFlowsByOrderTag(publishedFlows, tags);
      const flowsByGroupTag = groupFlowsByTags(orderedFlows, groupTags);

      setIconMap(createIconMap(tags));
      setGroupedFlows(orderGroupTags(flowsByGroupTag));
    }
  }, [flows, tags]);

  if (flowsIsLoading) return <CenteredSpinner />;
  if (!flows || flows?.length < 1) return <EmptyState heading="noResults" illustration="nothingFound" />;

  return (
    <>
      <Heading level={2} data-ta={TEST_IDS.sidebar_tour_header}>
        {t('header.tours', { count: 0 })}
      </Heading>
      <BodyText dataTa={TEST_IDS.sidebar_tour_description}>{t('tours.description')}</BodyText>
      <Stack dataTa={TEST_IDS.sidebar_tour_list} direction="column" className="sidebar__tour-buttons" spacing={Size.S}>
        {groupedFlows &&
          groupedFlows.map((group, index) => {
            if (group.flows.length === 0) return null;
            return (
              <Stack key={group.group} dataTa={TEST_IDS.sidebar_tour_group} direction="column" spacing={Size.S}>
                {index > 0 && <Separator />}
                <Stack direction="column" spacing={Size.XS} dataTa={TEST_IDS.sidebar_tour_flows}>
                  {group.flows.map(flow => {
                    return (
                      <Button
                        key={flow.id}
                        startIcon={
                          <Icon
                            dataTa={TEST_IDS.sidebar_tour_flow_icon}
                            name={VaiIcon[getFlowIconName(flow, iconMap)]}
                          />
                        }
                        buttonType={ButtonType.Link}
                        onClick={() => window.Appcues.show(flow.id)}
                      >
                        {flow.name}
                      </Button>
                    );
                  })}
                </Stack>
              </Stack>
            );
          })}
      </Stack>
    </>
  );
};

export default Tours;
