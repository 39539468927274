/* eslint-disable react/prop-types */
import React from 'react';
import { HasAcknowledgeAlarms } from './HasAcknowledgeAlarms';
import { RightNames } from '../RightsAccordionTree/constants';
import { ADMIN_RIGHTS_MASK, RIGHTS_MASK } from '../../rightsConstants';
import { useAppSelector } from '../../store/hooks';
import { selectCurrentUser } from '../../store/profile';
import { bitHasMask } from '../RightsAccordionTree/helpers';
import { HasManageAlarmTemplates } from './HasManageAlarmTemplates';
import { HasViewZone } from './HasViewZone';
import { isRightsNonNullable } from '../../utils/ts-utils';

/**
 * Props definition for HasRights.
 * @property {NodeRight} nodeRights - array of node id and right name to check
 * @property {RightCheckScope} rightCheckScope - check if user has all or any of the provided rights
 * @property {React.ReactNode} fallback - component to render if user does not have the rights
 */
export interface Props {
  nodeRights: NodeRight[];
  rightCheckScope: RightCheckScope;
  fallback?: React.ReactNode;
}
interface NodeRight {
  nodeId: string;
  rightName: RightNames;
}

export enum RightCheckScope {
  All = 'All',
  Any = 'Any'
}

interface HasRightsExtended<T> extends React.FC<T> {
  /**
   * Props definition for AcknowledgeAlarms.
   * @property {nodeId} nodeId - node id to apply the rights.
   */
  AcknowledgeAlarms: typeof HasAcknowledgeAlarms;
  /**
   * Props definition for HasManageAlarmTemplates.
   * @property {nodeId} nodeId - node id to apply the rights.
   */
  ManageAlarmTemplates: typeof HasManageAlarmTemplates;
  /**
   * Props definition for HasViewZone.
   * @property {nodeId} nodeId - node id to apply the rights.
   * * **If not provided, all node ids in user rights will be use to find at least one view right.**
   */
  ViewZone: typeof HasViewZone;
}

/**
 * HasRights a parent container for right wrapper components.
 * Right wrapper components are components that will render its children based on
 * the specific rights of the current user. They also use HasRights as a wrapper.
 * HasRights can also be used as a custom rights wrapper,
 * where the rightsConfig prop is passed to the component to check for specific rights. If the user has
 * the specified rights all the children will be visible.
 * @param {Props} props {@link Props} for the properties used by this component.
 * @returns
 */

export const HasRights: HasRightsExtended<Props> = ({
  children,
  fallback,
  rightCheckScope = RightCheckScope.All,
  nodeRights
}) => {
  const hasRights = useHasRights(nodeRights, rightCheckScope);
  if (hasRights) {
    return <>{children}</>;
  } else {
    return fallback ? <>{fallback}</> : null;
  }
};

export const useHasRights = (
  nodeRights: Props['nodeRights'] = [],
  rightCheckScope: Props['rightCheckScope'] = RightCheckScope.All
) => {
  const currentUser = useAppSelector(selectCurrentUser);

  const hasAdminRights = currentUser?.rights?.['*'] ? bitHasMask(currentUser.rights['*'], ADMIN_RIGHTS_MASK) : false;
  // Exit faster scenarios
  if (!isRightsNonNullable(currentUser)) {
    return false;
  }

  if (hasAdminRights) return true;

  let hasRights = false;

  const callback = ({ nodeId, rightName }: NodeRight) =>
    bitHasMask(currentUser.rights[nodeId], RIGHTS_MASK[rightName as keyof typeof RIGHTS_MASK]);

  switch (true) {
    case rightCheckScope === RightCheckScope.All:
      hasRights = nodeRights.reduce((acc, curr) => {
        const currHasRight = callback(curr);
        if (!currHasRight) {
          return currHasRight;
        }
        return acc;
      }, true);
      break;
    case rightCheckScope === RightCheckScope.Any:
      hasRights = nodeRights.some(callback);
      break;
    default:
      throw new Error(`Invalid checkRights value ${rightCheckScope}`);
  }
  return hasRights;
};

HasRights.AcknowledgeAlarms = HasAcknowledgeAlarms;
HasRights.ManageAlarmTemplates = HasManageAlarmTemplates;
HasRights.ViewZone = HasViewZone;
