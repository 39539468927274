import React from 'react';
import { Form, Select, SelectProps, Size } from '@vaisala/rockhopper-components';
import { TEST_IDS } from '../../../../tests/testids';
import { getErrorClass } from '../../../../utils';
import measurementTypes from '../../../../data/measurementTypes.json';
import { FormActionType, MeasurementTypesEnum } from '../../shared';
import { useTranslation } from 'react-i18next';
import { MeasurementType } from '../../../../siteTree/site';

interface Props {
  onChange: SelectProps<MeasurementTypesEnum>['onChange'];
  label: string;
  value: string | undefined;
  errorMessage: string;
  action: FormActionType;
}

export const MeasurementTypes: React.FC<Props> = ({ label, onChange, value, errorMessage, action }) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={label}
      component={Select}
      required={true}
      id={`${action}-zone-and-location-dialog-measurement-type`}
      htmlId={`${action}-zone-and-location-dialog-measurement-type`}
      defaultValue={t('form.selectPlaceholder')}
      value={value}
      width={Size.M}
      onChange={onChange}
      className={getErrorClass(errorMessage)}
      data-ta={TEST_IDS.site_tree_form_dialog_measurementType_field}
    >
      {measurementTypes.map(option => (
        <Select.Option
          key={`${option.value}`}
          title={t(`general.${option.label as MeasurementType}`)}
          value={option.value}
          id={`${action}-zone-and-location-dialog-${option.value}`}
        >
          {t(`general.${option.label as MeasurementType}`)}
        </Select.Option>
      ))}
    </Form.Item>
  );
};
