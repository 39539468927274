import React from 'react';
import { HasRights, RightCheckScope } from './HasRights';
import { RightNames } from '../RightsAccordionTree/constants';

interface Props {
  nodeId: string;
}

export const HasAcknowledgeAlarms: React.FC<Props> = ({ children, nodeId }) => {
  return (
    <HasRights
      rightCheckScope={RightCheckScope.All}
      nodeRights={[{ nodeId, rightName: RightNames.ACKNOWLEDGE_ALARMS }]}
    >
      {children}
    </HasRights>
  );
};

HasAcknowledgeAlarms.displayName = 'HasAcknowledgeAlarms';
