import { Route, Routes } from 'react-router';
import { Application as VaiApplication } from '@vaisala/rockhopper-components';
import { connect } from 'react-redux';
import Main from './components/Main';
import { Logout } from './components/auth';
import LoginContainer from './containers/Login';
import * as React from 'react';
import { StoreState, authDispatchActions } from './store';
import { routes } from './utils';
import Toaster from './components/Toaster';
import { IDLE_TIMEOUT, IDLE_TIMER_DEBOUNCING } from './constants';
import { useIdleTimer } from 'react-idle-timer';
import { useAppSelector } from './store/hooks';
import { selectDialog } from './store/dialog';
import { AuthError } from './store/actions/auth';
import CenteredSpinner from './components/BaseComponents/CenteredSpinner';

type ApplicationProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export function Application({ logout, isAuthenticated }: ApplicationProps) {
  const dialog = useAppSelector(selectDialog);

  const onIdle = () => {
    if (isAuthenticated) {
      console.log('user is idle');
      logout(false, AuthError.InactivityError);
    }
  };

  useIdleTimer({
    crossTab: true,
    syncTimers: 1000,
    timeout: IDLE_TIMEOUT,
    onIdle,
    debounce: IDLE_TIMER_DEBOUNCING
  });

  const fallback = (
    <CenteredSpinner
      style={{ width: '100%' }}
      wrapperProps={{
        alignItems: 'center',
        flexDirection: 'column'
      }}
      htmlId="root-spinner"
    />
  );

  return (
    <VaiApplication>
      <React.Suspense fallback={fallback}>
        <Toaster />
        {dialog}
        <Routes>
          <Route path={`${routes.login.url}/*`} element={<LoginContainer />} />
          <Route path={routes.logout.url} element={<Logout />} />
          <Route path="*" element={<Main />} />
        </Routes>
      </React.Suspense>
    </VaiApplication>
  );
}

const mapStateToProps = (state: StoreState) => ({
  isAuthenticated: state.auth.isAuthenticated
});

const mapDispatchToProps = (dispatch: any) => ({
  logout: (globalSignout: boolean, error?: AuthError | null) =>
    dispatch(authDispatchActions.logout(globalSignout, error))
});

export default connect(mapStateToProps, mapDispatchToProps)(Application);
