import { Icon, Size } from '@vaisala/rockhopper-components';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import React from 'react';

type SideBarTabProps = {
  icon?: VaiIcon | string;
  title?: string;
};

const SideBarTab: React.FC<SideBarTabProps> = ({ title, icon }) => {
  return <Icon title={title} size={Size.L} name={icon}></Icon>;
};

export default SideBarTab;
